import { useEffect, useRef, useState } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { Redirect, useParams } from "react-router-dom";
import Fetch from "../common/fetch";
import AlertBox from "../component/AlertBox";
import BreadCrumb from "../component/breadcrumb";
import Loading from "../component/Loading";
import SendJson from "../component/SendJson";
import LiCSSsLiveEditor from "../../lib/LiCSSsLiveEditor/LiCSSsLiveEditor";

export default function Page(){
    const {id}=useParams();
    const [BCrumb,setBCrumb]=useState({
        'CurrentText':'ページ作成',
        'items':[
            {
                index:0,
                href:'/lp',
                text:'ホーム'
            }
        ]
    });
    const [Visible,setVisible]=useState(true);
    const [Item,setItem]=useState();
    const [Alert,setAlert]=useState({'type':'danger','style':false,'msgs':[]});
    const [HTML,setHTML]=useState();
    const [Uri,setUri]=useState();
    function changeUri(e){
        setUri(e.target.value);
    }
    function selectUrl(e){
        e.target.select();
    }
    function doEntry(){
        setVisible(true);
        var {error,body}=SendJson(document.getElementsByClassName('js-data-custom'),true);
        let c_error=[].concat(error);
        let c_body=Object.assign({},body);
        var {error,body}=SendJson(document.getElementsByClassName('js-data'),true);
        window.scroll({top:0});
        if(error.length || c_error.length){
            setAlert({'type':'danger','style':true,'msgs':error.concat(c_error)});
            setVisible(false);
            return;
        }else{
            let send={
                url:'/v1/lp/edit'+(id=='new'?'':'/'+id),
                method:id=='new'?'POST':'PUT',
                body:Object.assign(body,{
                    customs:c_body,
                    html:HTML
                })
            }
            Fetch(send).then(data=>{
                if(data['result']){
                    setAlert({'type':'success','style':true,'msgs':["ページを登録しました。"]});
                    setItem(false);
                }else{
                    setAlert({'type':'danger','style':true,'description':data['error']['description']});
                }
                setVisible(false);
            }).catch(error=>{
                setAlert({'type':'danger','style':true,'msgs':['通信エラーが発生しました。']});
                setVisible(false);
                console.log(error);
            })
        }
    }
    function doDelete(){
        let send={
            url:'/v1/lp/edit/'+id,
            method:"DELETE"
        }
        Fetch(send).then(data=>{
            if(data['result']){
                setAlert({'type':'success','style':true,'msgs':["ページを削除しました。"]});
                setItem(false);
            }else{
                setAlert({'type':'danger','style':true,'description':data['error']['description']});
            }
            setVisible(false);
        }).catch(error=>{
            setAlert({'type':'danger','style':true,'msgs':['通信エラーが発生しました。']});
            setVisible(false);
            console.log(error);
        })
    }
    useEffect(()=>{
        let send={
            url:'/v1/lp/edit/'+id,
            method:'GET'
        };
        Fetch(send).then(data=>{
            if(data['result']){
                setItem(data['payloads']);
                setUri(data['payloads']['uri']);
                if(id!='new'){
                    setBCrumb({
                        'CurrentText':data['payloads']['title'],
                        'items':[
                            {
                                index:0,
                                href:'/lp',
                                text:'ホーム'
                            }
                        ]
                    });
                }
            }else{
                setAlert({'type':'danger','style':true,'description':data['error']['description']})
            }
            setVisible(false);
        }).catch(error=>{
            setVisible(false);
            console.log(error)
        });
    },[]);
    return (
        <div>
            <BreadCrumb item={BCrumb} />
            <AlertBox Item={Alert} />
            <Loading visible={Visible} />{Item?
            <div>
                <Form.Group>
                    <Form.Label>
                        ページURL
                    </Form.Label>
                    <InputGroup>
                        <InputGroup.Text>{Item['url']}/</InputGroup.Text>
                        <Form.Control type="text" name="uri" className="js-data" placeholder="URL" defaultValue={Item['uri']} onChange={changeUri} required />
                    </InputGroup>
                </Form.Group>
                <Form.Group>
                    <Form.Label>
                        URLコピー用
                    </Form.Label>
                    <Form.Control type="text" readOnly value={Item['url']+'/'+Uri} onFocus={selectUrl} />
                </Form.Group>
                <Form.Group>
                    <Form.Label>
                        公開状態
                    </Form.Label>
                    <Form.Select defaultValue={Item['status']} name="status" className="js-data" required>
                        <option value={0}>非公開</option>
                        <option value={1}>下書き</option>
                        <option value={2}>公開</option>
                    </Form.Select>
                </Form.Group>
                <Form.Group>
                    <Form.Label>
                        ページタイトル
                    </Form.Label>
                    <Form.Control type="text" name="title" className="js-data" placeholder="ページタイトル" defaultValue={Item['title']} required />
                </Form.Group>
                <Form.Group>
                    <Form.Label>
                        ページ説明
                    </Form.Label>
                    <Form.Control type="text" name="description" className="js-data" placeholder="ページ説明" defaultValue={Item['description']} required />
                </Form.Group>
                <Form.Group>
                    <Form.Label>
                        ページコンテンツ
                    </Form.Label>
                    <LiCSSsLiveEditor defaultValue={Item['html']} setContent={setHTML} />
                </Form.Group>
                <Form.Group>
                    <Form.Label>
                        カスタムCSS
                    </Form.Label>
                    <Form.Control as="textarea" rows={3} defaultValue={Item['customs']['css']} name='css' className="js-data-custom" placeholder="カスタムCSS" />
                </Form.Group>
                <Form.Group>
                    <Form.Label>
                        カスタムJavaScript
                    </Form.Label>
                    <Form.Control as="textarea" rows={3} defaultValue={Item['customs']['script']} name='script' className="js-data-custom" placeholder="カスタムJavaScript" />
                </Form.Group>
                <div className="mt-2">
                    <Button variant="primary" type="button" onClick={doEntry}>
                        {id=='new'?'登録':'変更'}
                    </Button>{id=='new'?"":
                    <Button variant="danger" type="button" className="ms-2" onClick={doDelete}>
                        削除
                    </Button>
                    }
                </div>
            </div>
            :""}
        </div>
    );
}