import { useEffect, useState } from "react";
import ReactDOM from 'react-dom';
import { Redirect, Route, Switch } from "react-router";
import { Link } from "react-router-dom";
import AlertBox from "./component/AlertBox";
import BreadCrumb from "./component/breadcrumb";
import Fetch from "./common/fetch";
import {Store} from "./common/store";
import UserForm from "./component/UserForm";
import ProfileForm from "./component/ProfileForm";
import Upload from "./common/upload";
import SendJson from "./component/SendJson";
import Info from "./account/info";
import { FriendCondition } from "./component/Keys";
import Payment from "./account/payment";
import { Accordion, ListGroup } from "react-bootstrap";
import Escape from "./component/Escape";
import { updateVariation } from "../App";
import Loading from "./component/Loading";
import AccountInit from "./account/init";
import LineBtn from "./component/LineBtn";
import { useCookies } from "react-cookie";
import Line from "./account/line";

export default function Account(){
    return (
        <Switch>
            <Route path="/account" exact>
                <Home />
            </Route>
            <Route path="/account/info/:id" exact>
                <Info />
            </Route>
            <Route path="/account/payment/:id" exact>
                <Payment />
            </Route>
            <Route path="/account/line/" exact>
                <Line />
            </Route>
            <Route path="/account/init" exact>
                <AccountInit />
            </Route>
            <Route path="/account">
                <Redirect to="/account" />
            </Route>
        </Switch>
    );
}
let LoadingMsg=[];
let styles={
    info:[['nav-link'],['tab-pane','fade']],
    account:[['nav-link'],['tab-pane','fade']],
    profile:[['nav-link'],['tab-pane','fade']],
    sns:[['nav-link'],['tab-pane','fade']],
    friend:[['nav-link'],['tab-pane','fade']],
    inviting:[['nav-link'],['tab-pane','fade']],
    payment:[['nav-link'],['tab-pane','fade']],
    support:[['nav-link'],['tab-pane','fade']]
};
function Home(){
    const BCrumb={'CurrentText':'アカウント','items':[]};
    const [Alert,setAlert]=useState({'type':'danger','style':false,'msgs':[]});
    const [Contact,setContact]=useState([]);
    const [SNS,setSNS]=useState();
    const [Friend,setFriend]=useState({"applicating":[],"applicated":[],"getted":false});
    const [Inviting,setInviting]=useState({"InvitedCode": "","EntriedCode": true,"UserName": "","users": [],"message":""});
    const [Payment,setPayment]=useState([]);
    const [Support,setSupport]=useState([]);

	const [Visible,setVisible]=useState(false);
	const [Msg,setMsg]=useState(LoadingMsg);

    const [Styles,setStyles]=useState(styles);
    
    const [Method,setMethod]=useState(false);
    const [Token,setToken]=useState(false);
    const [Item,setItem]=useState(false);
	const [cookies,setCookie,removeCookie]=useCookies();

    useEffect(()=>{
        let hash=window.location.hash.replace('#',"");
        if(styles[hash]==undefined){
            hash='info';
        }
        switch(hash){
            case 'info':
                getInfo();
                break;
            case 'profile':
                getPofile();
                break;
            case 'account':
                getAccount();
                break;
            case 'sns':
                getSNS();
                break;
            case 'friend':
                getFriend();
                break;
            case 'inviting':
                getInvitation();
                break;
            case 'payment':
                getPayment();
                break;
            case 'support':
                getSupport();
                break;
        }
        let newStyles=Object.assign({},styles);
        newStyles[hash][0].push('active');
        newStyles[hash][1].push('active');
        newStyles[hash][1].push('show');
        setStyles(newStyles);
    },[])
    function getInfo(){
        let send={
            'url':'v1/contact',
            'method':'GET'
        }
        Fetch(send).then(data=>{
            if(data['result']){
                setAlert({'type':'danger','style':false,'msgs':[]});
                setContact(data['payloads']['lists']);
            }else{
                setAlert({'type':'danger','style':true,'description':data['error']['description']});
            }
        }).catch(error=>console.log(error));
    }
    function getAccount(){
        let send={
            'url':'v1/user/'+Store.getState()['UserId'],
            'method':'GET'
        }
        Fetch(send).then(data=>{
            if(data['result']){
                setAlert({'type':'danger','style':false,'msgs':[]});
                ReactDOM.render(<UserForm Item={data['payloads']} Submit={doAccount} />,document.getElementById('account'));
            }else{
                setAlert({'type':'danger','style':true,'description':data['error']['description']});
            }
        }).catch(error=>console.log(error));
    }
    function getPofile(){
        /*
        let send={
            'url':'v1/teacher/payment',
            'method':'GET'
        }
        Fetch(send).then(data=>{
            if(data['result']){
                setAlert({'type':'danger','style':false,'msgs':[]});
                let PaymentMethod=data['payloads'];
                let send={
                    'url':'v1/profile/'+Store.getState()['UserId'],
                    'method':'GET'
                }
                Fetch(send).then(data=>{
                    if(data['result']){
                        setAlert({'type':'danger','style':false,'msgs':[]});
                        let Item=data['payloads'];
                        Item['paymentMethod']=PaymentMethod;
                        console.log(Item);
                        ReactDOM.render(<ProfileForm Item={Item} edit={true} Submit={doProfile} />,document.getElementById('profile'));
                    }else{
                        setAlert({'type':'danger','style':true,'description':data['error']['description']});
                    }
                }).catch(error=>console.log(error));
            }else{
                setAlert({'type':'danger','style':true,'description':data['error']['description']});
            }
            setVisible(false);
            LoadingMsg=[];
        }).catch((error)=>{
            LoadingMsg.push({type:'warning',title:'エラー',content:'通信エラーが発生しました'});
            setMsg(LoadingMsg);
            LoadingMsg=[];
            setVisible(false);

            console.log(error);
        });*/
        setVisible(true);
        let send={
            'url':'v1/teacher/payment',
            'method':'GET'
        }
        Fetch(send).then(data=>{
            if(data['result']){
                setAlert({'type':'danger','style':false,'msgs':[]});
                let PaymentMethod=data['payloads'];
                send['url']='v1/profile';
                setVisible(true);
                Fetch(send).then(data=>{
                    if(data['result']){
                        setAlert({'type':'danger','style':false,'msgs':[]});
                        let send={
                            'url':'v1/profile/'+data['payloads']['UserId'],
                            'method':'GET'
                        }
                        setVisible(true);
                        Fetch(send).then(data=>{
                            if(data['result']){
                                setAlert({'type':'danger','style':false,'msgs':[]});
                                let newItem=data['payloads'];
                                newItem['paymentMethod']=PaymentMethod;
                                newItem['funcs']=[setMethod,setToken];
                                setTimeout(()=>{
                                    ReactDOM.render(<ProfileForm Item={newItem} edit={true} Submit={doProfile} />,document.getElementById('profile'));
                                    setVisible(false);
                                },1000)
                                LoadingMsg=[];
                            }else{
                                setAlert({'type':'danger','style':true,'description':data['error']['description']});
                                setVisible(false);
                                LoadingMsg=[];
                            }
                        }).catch((error)=>{
                            LoadingMsg.push({type:'warning',title:'エラー',content:'通信エラーが発生しました'});
                            setMsg(LoadingMsg);
                            LoadingMsg=[];
                            setVisible(false);
                
                            console.log(error);
                        });
                    }else{
                        setAlert({'type':'danger','style':true,'description':data['error']['description']});
                        setVisible(false);
                        LoadingMsg=[];
                    }
                }).catch((error)=>{
                    LoadingMsg.push({type:'warning',title:'エラー',content:'通信エラーが発生しました'});
                    setMsg(LoadingMsg);
                    LoadingMsg=[];
                    setVisible(false);
        
                    console.log(error);
                });
            }else{
                setAlert({'type':'danger','style':true,'description':data['error']['description']});
                setVisible(false);
                LoadingMsg=[];
            }
        }).catch((error)=>{
            LoadingMsg.push({type:'warning',title:'エラー',content:'通信エラーが発生しました'});
            setMsg(LoadingMsg);
            LoadingMsg=[];
            setVisible(false);

            console.log(error);
        });
    }
    function getSNS(){
        setVisible(true);
        let send={
            'url':'v1/line',
            'method':'GET'
        }
        Fetch(send).then(data=>{
            if(data['result']){
                setSNS(data['payloads']);
                if(data['payloads']['nonce']){
                    removeCookie('nonce');
                    setCookie('nonce',data['payloads']['nonce']);
                }
                setAlert({'type':'danger','style':false,'msgs':[]});
                setVisible(false);
            }else{
                setAlert({'type':'danger','style':true,'description':data['error']['description']});
                setVisible(false);
                LoadingMsg=[];
            }
        }).catch((error)=>{
            LoadingMsg.push({type:'warning',title:'エラー',content:'通信エラーが発生しました'});
            setMsg(LoadingMsg);
            LoadingMsg=[];
            setVisible(false);

            console.log(error);
        });
    }
    function getFriend(){
        let send={
            'url':'v1/friend',
            'method':'GET'
        }
        Fetch(send).then(data=>{
            if(data['result']){
                setAlert({'type':'danger','style':false,'msgs':[]});
                setFriend(data['payloads']);
            }else{
                setAlert({'type':'danger','style':true,'description':data['error']['description']});
            }
        }).catch(error=>console.log(error));
    }
    function getInvitation(){
        let send={
            'url':'v1/invitation',
            'method':'GET'
        }
        Fetch(send).then(data=>{
            if(data['result']){
                setAlert({'type':'danger','style':false,'msgs':[]});
                let inviting=data['payloads'];
                inviting['message']=data['payloads']['UserName']+"さんがあなたCanTeachを招待しました。\nCanTeachに登録して，下記の招待コードを入力しよう！\n招待コード："+data['payloads']['InvitedCode']+"\n\nCanTeachの登録は\nhttps://app.canteach.jp/login/\n\n ==========\nCanTeachとは？\nhttps://canteach.jp/";
                setInviting(inviting);
            }else{
                setAlert({'type':'danger','style':true,'description':data['error']['description']});
            }
        }).catch(error=>console.log(error));
    }
    function getPayment(){
        let send={
            'url':'v1/payment',
            'method':'GET'
        }
        Fetch(send).then(data=>{
            if(data['result']){
                setAlert({'type':'danger','style':false,'msgs':[]});
                setPayment(data['payloads']);
            }else{
                setAlert({'type':'danger','style':true,'description':data['error']['description']});
            }
        }).catch(error=>console.log(error));
    }
    function getSupport(){
        let send={
            'url':'v1/support',
            'method':'GET'
        }
        Fetch(send).then(data=>{
            if(data['result']){
                setAlert({'type':'danger','style':false,'msgs':[]});
                setSupport(data['payloads']);
            }else{
                setAlert({'type':'danger','style':true,'description':data['error']['description']});
            }
        }).catch(error=>console.log(error));
    }
    function doAccount(){
		setVisible(true);
		LoadingMsg.push({type:'info',title:'確認中',content:'入力内容を確認しています',delay:5000});
		setMsg(LoadingMsg);

        let send={
            'url':'v1/user/'+Store.getState()['UserId'],
            'method':'PUT',
            'body':{}
        }
        send['body']=SendJson(document.getElementById('account').getElementsByClassName('js-data'));
        LoadingMsg.push({type:'info',title:'送信中',content:'入力内容を送信しています',delay:5000});
        setMsg(LoadingMsg);

        Fetch(send).then(data=>{
            if(data['result']){

                LoadingMsg.push({type:'success',title:'送信完了',content:"入力内容を送信しました",delay:5000});
                setMsg(LoadingMsg);
                setVisible(false);

                setAlert({'type':'success','style':true,'msgs':["アカウント情報を変更しました。"]});
            }else{

                LoadingMsg.push({type:'danger',title:'エラー',content:'入力内容が保存できませんでした',delay:5000});
                setMsg(LoadingMsg);
                setVisible(false);

                setAlert({'type':'danger','style':true,'description':data['error']['description']});
            }
        }).catch(error=>{
            console.log(error)

            LoadingMsg.push({type:'warning',title:'エラー',content:'通信エラーが発生しました'});
            setMsg(LoadingMsg);
            setVisible(false);
        });
    }
    function doProfile(){

		setVisible(true);
		LoadingMsg.push({type:'info',title:'確認中',content:'ファイルのアップロード確認中',delay:5000});
		setMsg(LoadingMsg);

        let file=document.getElementById('profile').getElementsByClassName('js-data-file');
        let keys=[];
        let fd=new FormData();
        for(let i=0;i<file.length;++i){
            if(file[i].value){
                keys.push(i);
                fd.append('file[]',file[i].files[0]);
            }
        }
        if(keys.length){
            LoadingMsg.push({type:'info',title:'アップロード中',content:'ファイルをアップロード中',delay:5000});
            setMsg(LoadingMsg);
            Upload(fd).then(data=>{
                if(data['result']){
                    LoadingMsg.push({type:'success',title:'アップロード完了',content:"ファイルをアップロードしました",delay:5000});
                    setMsg(LoadingMsg);
                    let files=["",""];
                    for(let i=0;i<keys.length;++i){
                        files[keys[i]]=data['payloads']['FileIds'][i];
                    }
                    doProfileSubmit(files);
                }else{

                    LoadingMsg.push({type:'danger',title:'エラー',content:'入力内容が保存できませんでした',delay:5000});
                    setMsg(LoadingMsg);
                    setVisible(false);
    
                    setAlert({'type':'danger','style':true,'description':data['error']['description']});
                }
            }).catch(error=>{
                console.log(error)
    
                LoadingMsg.push({type:'warning',title:'エラー',content:'通信エラーが発生しました'});
                setMsg(LoadingMsg);
                setVisible(false);
            });
        }else{
            doProfileSubmit(['','']);
        }
    }
    function doProfileSubmit(files){
		LoadingMsg.push({type:'info',title:'確認中',content:'入力内容を確認しています',delay:5000});
		setMsg(LoadingMsg);

        let send={
            'url':'v1/profile',
            'method':'POST',
            'body':SendJson(document.getElementById('profile').getElementsByClassName('js-data'))
        }
        send['body']['icon']=files[0];
        send['body']['qr']=files[1];
        LoadingMsg.push({type:'info',title:'送信中',content:'入力内容を送信しています',delay:5000});
        setMsg(LoadingMsg);

        Fetch(send).then(data=>{
            if(data['result']){

                LoadingMsg.push({type:'success',title:'送信完了',content:"入力内容を送信しました",delay:5000});
                setMsg(LoadingMsg);
                setVisible(false);
                ReactDOM.render(<></>,document.getElementById('profile'));

                setAlert({'type':'success','style':true,'msgs':["プロフィールを変更しました。"]});
                updateVariation('Profile');
            }else{

                LoadingMsg.push({type:'danger',title:'エラー',content:'入力内容が保存できませんでした',delay:5000});
                setMsg(LoadingMsg);
                setVisible(false);

                setAlert({'type':'danger','style':true,'description':data['error']['description']});
            }
        }).catch(error=>{
            console.log(error)

            LoadingMsg.push({type:'warning',title:'エラー',content:'通信エラーが発生しました'});
            setMsg(LoadingMsg);
            setVisible(false);
        });
    }
    function doSupport(){
		setVisible(true);
		LoadingMsg.push({type:'info',title:'確認中',content:'入力内容を確認しています',delay:5000});
		setMsg(LoadingMsg);
        let inputs=document.getElementById('AccountSupportForm').getElementsByClassName('js-data');
        let {error,body}=SendJson(inputs,true);
        if(error.length){
			setAlert({'type':'danger','style':true,'msgs':error});
			LoadingMsg.push({type:'danger',title:'入力不備',content:'入力内容を確認してください',delay:5000});
			setMsg(LoadingMsg);
			setVisible(false);

			return;
		}else{
            LoadingMsg.push({type:'info',title:'送信中',content:'入力内容を送信しています',delay:5000});
            setMsg(LoadingMsg);
            let send={
                'url':'v1/support',
                'method':'POST',
                'body':body
            }
            Fetch(send).then(data=>{
                if(data['result']){

                    LoadingMsg.push({type:'success',title:'送信完了',content:"入力内容を送信しました",delay:5000});
                    setMsg(LoadingMsg);
                    setVisible(false);
    
                    setAlert({'type':'success','style':true,'msgs':["登録しました。"]});
                    inputs[0].value="";
                    inputs[1].value="";
                    getSupport();
                }else{

                    LoadingMsg.push({type:'danger',title:'エラー',content:'入力内容が保存できませんでした',delay:5000});
                    setMsg(LoadingMsg);
                    setVisible(false);
    
                    setAlert({'type':'danger','style':true,'description':data['error']['description']});
                }
            }).catch(error=>{
                console.log(error)
    
                LoadingMsg.push({type:'warning',title:'エラー',content:'通信エラーが発生しました'});
                setMsg(LoadingMsg);
                setVisible(false);
            });
        }
    }
    function clickFriend(e,key,id){
        let send={
            'url':'v1/friend/'+id,
            'method':'PUT',
            'body':{
                'condition':key
            }
        }
        Fetch(send).then(data=>{
            if(data['result']){
                let friend=JSON.stringify(Friend);
                friend=JSON.parse(friend);
                friend['applicated'][e.target.parentNode.parentNode.dataset.id]['condition']=key;
                let name=friend['applicated'][e.target.parentNode.parentNode.dataset.id]['UserName'];
                setFriend(friend)
                setAlert({'type':'success','style':true,'msgs':[name+'の友達申請を'+FriendCondition(key)+'しました。']});
            }else{
                setAlert({'type':'danger','style':true,'description':data['error']['description']});
            }
        }).catch(error=>console.log(error));
    }
    function entryInvitation(){
		setVisible(true);
		LoadingMsg.push({type:'info',title:'確認中',content:'入力内容を確認しています',delay:5000});
		setMsg(LoadingMsg);
        let send={
            'url':'v1/invitation',
            'method':'POST',
            'body':{
                'InvitedCode':document.getElementById('InvitationCode').value
            }
        }
        LoadingMsg.push({type:'info',title:'送信中',content:'入力内容を送信しています',delay:5000});
        setMsg(LoadingMsg);
        Fetch(send).then(data=>{
            if(data['result']){

                LoadingMsg.push({type:'success',title:'送信完了',content:"入力内容を送信しました",delay:5000});
                setMsg(LoadingMsg);
                setVisible(false);

                setAlert({'type':'success','style':true,'msgs':["招待コードを登録しました。"]});
                let invitation=JSON.stringify(Inviting);
                invitation=JSON.parse(invitation);
                invitation['EntriedCode']=true;
                setInviting(invitation);
            }else{

                LoadingMsg.push({type:'danger',title:'エラー',content:'入力内容が保存できませんでした',delay:5000});
                setMsg(LoadingMsg);
                setVisible(false);

                setAlert({'type':'danger','style':true,'description':data['error']['description']});
            }
        }).catch(error=>{
            console.log(error)

            LoadingMsg.push({type:'warning',title:'エラー',content:'通信エラーが発生しました'});
            setMsg(LoadingMsg);
            setVisible(false);
        });
    }
    return(
        <div>
            <BreadCrumb item={BCrumb} />
            <AlertBox Item={Alert} />
            <Loading visible={Visible} msgs={Msg} />
            <div>
                <ul className="nav nav-tabs">
                    <li className="nav-item">
                        <a href="#info" className={Styles['info'][0].join(' ')} data-bs-toggle="tab" role="tab">
                            お知らせ
                        </a>
                    </li>
                    <li className="nav-item">
                        <a href="#profile" className={Styles['profile'][0].join(' ')} data-bs-toggle="tab" role="tab" onClick={getPofile}>
                            プロフィール設定
                        </a>
                    </li>
                    <li className="nav-item">
                        <a href="#account" className={Styles['account'][0].join(' ')} data-bs-toggle="tab" role="tab" onClick={getAccount}>
                            パスワード変更
                        </a>
                    </li>
                    <li className="nav-item">
                        <a href="#sns" className={Styles['sns'][0].join(' ')} data-bs-toggle="tab" role="tab" onClick={getSNS}>
                            SNS連携
                        </a>
                    </li>
                    <li className="nav-item">
                        <a href="#friend" className={Styles['friend'][0].join(' ')} data-bs-toggle="tab" role="tab" onClick={getFriend}>
                            フレンド申請
                        </a>
                    </li>
                    <li className="nav-item">
                        <a href="#inviting" className={Styles['inviting'][0].join(' ')} data-bs-toggle="tab" role="tab" onClick={getInvitation}>
                            お友達招待
                        </a>
                    </li>
                    <li className="nav-item">
                        <a href="#payment" className={Styles['payment'][0].join(' ')} data-bs-toggle="tab" role="tab" onClick={getPayment}>
                            決済
                        </a>
                    </li>
                    <li className="nav-item">
                        <a href="#support" className={Styles['support'][0].join(' ')} data-bs-toggle="tab" role="tab" onClick={getSupport}>
                            サポート
                        </a>
                    </li>
                </ul>
                <div className="tab-content border border-top-0 rounded-bottom px-2 py-3">
                    <div id="info" className={Styles['info'][1].join(' ')} role="tabpanel">
                        <div className="list-group">{Contact.map((contact,index)=>
                            <Link to={"/account/info/"+contact['ContactId']} className="list-group-item list-group-item-action contact__list-link" data-read={contact['condition']} key={index}>
                                {contact['ContactTitle']}
                            </Link>)}
                        </div>
                    </div>
                    <div id="account" className={Styles['account'][1].join(' ')} role="tabpanel"></div>
                    <div id="profile" className={Styles['profile'][1].join(' ')} role="tabpanel"></div>
                    <div id="sns" className={Styles['sns'][1].join(' ')} role="tabpanel">{SNS &&
                        <ListGroup>
                            <ListGroup.Item>
                                LINE:{SNS['connected']?'連携済み':<>未連携<LineBtn Link={SNS['link']} target="_blank" /></>}
                            </ListGroup.Item>
                        </ListGroup>}
                    </div>
                    <div id="friend" className={Styles['friend'][1].join(' ')} role="tabpanel">
                        <h4 className="my-2">
                            申請済み友達一覧
                        </h4>
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th>
                                        名前
                                    </th>
                                    <th>
                                        状態
                                    </th>
                                </tr>
                            </thead>
                            <tbody>{Friend['applicating'].map((friend,index)=>
                                <tr key={index}>
                                    <td>
                                        <a href={"/profile/"+friend['UserId']} target="_blank">
                                            {friend['UserName']}
                                        </a>
                                    </td>
                                    <td>{friend['condition']==0 ?
                                        <a href={friend['qr']} target="_blank">
                                            QRコード表示
                                        </a>:
                                        <span>
                                            QRコードを表示できません
                                        </span>
                                        }
                                    </td>
                                </tr>)}
                            </tbody>
                        </table>
                        <h4 className="my-2">
                            友達提案一覧
                        </h4>
                        <p>
                            承諾すると，登録しているQRコードを相手が表示できるようになります。
                        </p>
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th>
                                        名前
                                    </th>
                                    <th>
                                        状態
                                    </th>
                                </tr>
                            </thead>
                            <tbody>{Friend['applicated'].map((friend,index)=>
                                <tr data-id={index} key={index}>
                                    <td>
                                        <a href={"/profile/"+friend['UserId']} target="_blank">
                                            {friend['UserName']}
                                        </a>
                                    </td>
                                    <td>
                                        {FriendCondition(friend['condition'])}{friend['condition']!=0 &&
                                        <button type="button" className="btn btn-success ms-1" onClick={e=>clickFriend(e,0,friend['FriendId'])}>承諾</button>}{friend['condition']!=1 &&
                                        <button type="button" className="btn btn-danger ms-1" onClick={e=>clickFriend(e,1,friend['FriendId'])}>拒否</button>}{friend['condition']!=2 &&
                                        <button type="button" className="btn btn-secondary ms-1" onClick={e=>clickFriend(e,2,friend['FriendId'])}>無視</button>}
                                    </td>
                                </tr>)}
                            </tbody>
                        </table>
                    </div>
                    <div id="inviting" className={Styles['inviting'][1].join(' ')} role="tabpanel">{!Inviting['EntriedCode'] &&
                        <div>
                            <h4>
                                招待コードの入力
                            </h4>
                            <div className="input-group my-2">
                                <input type="text" id="InvitationCode" className="form-control" placeholder="招待コード" />
                                <button type="button" className="btn btn-primary" onClick={entryInvitation}>登録</button>
                            </div>
                        </div>
                        }
                        <h4 className="my-2">
                            お友達を招待
                        </h4>
                        <textarea className="form-control" style={{height:'265px'}} defaultValue={Inviting['message']} onFocus={e=>e.target.select()} />
                        <h4 className="my-2">
                            参加友達
                        </h4>
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th>
                                        名前
                                    </th>
                                    <th>
                                        プラン
                                    </th>
                                </tr>
                            </thead>
                            <tbody>{Inviting['users'].map((inviting,index)=>
                                <tr key={index}>
                                    <td>
                                        <a href={"/profile/"+inviting['UserId']} target="_blank">
                                            {inviting['UserName']}
                                        </a>
                                    </td>
                                    <td>
                                        {inviting['plan']}
                                    </td>
                                </tr>)}
                            </tbody>
                        </table>
                    </div>
                    <div id="payment" className={Styles['payment'][1].join(' ')} role="tabpanel">
                        <h4 className="my-2">
                            決済履歴
                        </h4>
                        <div className="list-group">{Payment.map(payment=>
                            <Link to={"/account/payment/"+payment['id']} className="list-group-item list-group-item-action" key={payment['id']}>
                                {payment['title']}
                            </Link>
                        )}
                        </div>
                    </div>
                    <div id="support" className={Styles['support'][1].join(' ')} role="tabpanel">
                        <div className="my-2">
                            CanTeachの操作方法が不明な場合，<a href="/support" target="_blank">よくある質問</a>をご確認ください。<br />
                            それでもわからない場合，下記より運営にご質問ください。<br />
                            なお，コースに関する質問は受け付けておりませんので各講師にご連絡ください。
                        </div>
                        <Accordion defaultActiveKey="0">
                            <Accordion.Item>
                                <Accordion.Header>
                                    質問する
                                </Accordion.Header>
                                <Accordion.Body>
                                    <form id="AccountSupportForm">
                                    <div className="row my-2">
                                        <div className="col-sm-2">
                                            タイトル
                                        </div>
                                        <div className="col-sm-10">
                                            <input type="text" name="SupportTitle" className="form-control js-data" placeholder="タイトル" required />
                                        </div>
                                    </div>
                                    <div className="row my-2">
                                        <div className="col-sm-2">
                                            質問内容
                                        </div>
                                        <div className="col-sm-10">
                                            <textarea name="SupportContent" className="form-control js-data" placeholder="質問内容" required />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <button type="button" className="btn btn-primary" onClick={doSupport}>登録</button>
                                        </div>
                                    </div>
                                </form>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                        <div className="my-2">
                            <h3 className="my-2">
                                過去の質問内容
                            </h3>
                            <Accordion defaultActiveKey="0">
                            {Support.map(support=>
                                <Accordion.Item key={support['SupportId']} eventKey={support['SupportId']}>
                                    <Accordion.Header>
                                        {support['SupportTitle']}
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <h4 className="my-1">
                                            質問内容
                                        </h4>
                                        <div className="my-1">
                                            {Escape(support['content'])}
                                        </div>
                                        <h4 className="my-1">
                                            回答内容
                                        </h4>
                                        <div className="my-1">
                                            {Escape(support['answer'])}
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>)}
                            </Accordion>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}