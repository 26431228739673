import { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { useParams } from "react-router";
import BreadCrumb from "../component/breadcrumb";
import Fetch from "../common/fetch";
import AlertBox from "../component/AlertBox";
import ExaminationForm from "../component/ExaminationForm";
import Loading from "../component/Loading";

let LoadingMsg=[];
export default function EntryExamination(){
    const {course,id}=useParams();
    const [BCrumb,setBCrumb]=useState({'CurrentText':'テスト','items':[{'index':1,'href':'/teacher','text':'ホーム'}]});
    const [Alert,setAlert]=useState({'type':'danger','style':false,'msgs':[]});
    let parent;
	const [Visible,setVisible]=useState(false);
	const [Msg,setMsg]=useState(LoadingMsg);

    useEffect(()=>{
        setVisible(true);
        parent=document.getElementById('content_area');
        let send={
            'url':'v1/course/'+course,
            'method':'GET'
        }
        Fetch(send).then(data=>{
            if(data['result']){
                setAlert({'type':'danger','style':false,'msgs':[]});
                ReactDOM.render(<ExaminationForm Submit={doEntry} setVisible={setVisible} setMsg={setMsg} setAlert={setAlert} />,parent);
                setBCrumb({
                    CurrentText: '新規テスト',
                    items: [
                        {
                            index: 1,
                            href: "/teacher",
                            text: "ホーム"
                        },
                        {
                            index:2,
                            href:'/teacher/course/'+course,
                            text:data['payloads']['CourseName']
                        }
                    ],
                });
            }else{
                setAlert({'type':'danger','style':true,'description':data['error']['description']});
            }
            setVisible(false);
        }).catch((error)=>{
            LoadingMsg.push({type:'warning',title:'エラー',content:'通信エラーが発生しました'});
            setMsg(LoadingMsg);
            LoadingMsg=[];
            setVisible(false);

            console.log(error);
        });
    },[])
    function doEntry(body){
        setVisible(true);
        LoadingMsg.push({type:'info',title:'送信中',content:'入力内容を送信しています',delay:5000});
        setMsg(LoadingMsg);

        let send={
            'url':'v1/course/'+course+'/examination/',
            'method':'POST',
            'body':body
        }
        Fetch(send).then(data=>{
            if(data['result']){
                document.getElementById('content_area').remove();
                LoadingMsg.push({type:'success',title:'送信完了',content:'入力内容を送信しました',delay:5000});
                setMsg(LoadingMsg);
                setAlert({'type':'success','style':true,'msgs':["テストを登録しました。"]});
            }else{
                LoadingMsg.push({type:'danger',title:'エラー',content:'入力内容が保存できませんでした',delay:5000});
                setMsg(LoadingMsg);

                setAlert({'type':'danger','style':true,'description':data['error']['description']});
            }
            setVisible(false);
            LoadingMsg=[];
            window.scroll({top:0});
        }).catch((error)=>{
            LoadingMsg.push({type:'warning',title:'エラー',content:'通信エラーが発生しました'});
            setMsg(LoadingMsg);
            LoadingMsg=[];
            setVisible(false);

            console.log(error);
        });
    }
    return(
        <div>
            <BreadCrumb item={BCrumb} />
            <AlertBox Item={Alert} />
            <Loading visible={Visible} msgs={Msg} />
            <div id="content_area"></div>
        </div>
    )
}