import { useState , useEffect } from "react";
import ReactDOM from "react-dom";
import { Link, useParams, Redirect } from "react-router-dom";
import Fetch from "../common/fetch";
import { Store, Login } from "../common/store";
import BreadCrumb from "../component/breadcrumb";
import AlertBox from "../component/AlertBox";
import Loading from "../component/Loading";
import { CourseFree, number_format } from "../component/Keys";
import { Accept } from "../component/UserForm";
import Escape from "../component/Escape";
import SendJson from "../component/SendJson";
import { OauthToken } from "../sso/content";
import { updateVariation } from "../../App";
import PaymentForm from "../component/PaymentForm";

let LoadingMsg=[];
//受講登録disable [アカウント作成，契約書，支払い]
let btn=[false,false];
let Auth=false;
export default function EntryCourseAdd(){
    Auth=Store.getState()['Auth'];
    const {id}=useParams();
    const [BCrumb,setBCrumb]=useState({
        'CurrentText':'コース受講契約',
        'items':Auth?[
            {
                'index':1,
                'href':'/student',
                'text':'ホーム'
            },
            {
                'index':2,
                'href':'/student/course/search',
                'text':'コース検索'
            },
            {
                'index':3,
                'href':'/course/'+id+'/syllabus',
                'text':'シラバス'
            }, 
        ]:[
            {
                'index':1,
                'href':'/course/'+id+'/syllabus',
                'text':'シラバス'
            }, 
        ]
    });
    const [Syllabus,setSyllabus]=useState({
        "CourseName": "",
        "term": [
            "",
            ""
        ],
        "teachers": [],
        "CourseMethod": "COURSE METHOD",
        "CourseLevel": "LEVEL",
        "keywords": [],
        "MaxStudent": 0,
        "SetupPrice": 0,
        "MonthlyPrice": 0,
        "AccountNumber": 0,
        "free": 0,
        "goal": 0,
        "plans": [],
        "needs": [],
        "attention": "",
        "LineId": "",
        "PaymentMethods": [],
        "contract": {
            "FileId": "",
            "link": "",
            "name": "",
            "type": "",
            "download":""
        }
    });
    const [Alert,setAlert]=useState({'type':'danger','style':false,'msgs':[]});
    const [Price,setPrice]=useState(0);
    const [Button,setButton]=useState(btn);
    const [Payment,setPayment]=useState([]);
    const [Welcome,setWelcome]=useState(false);
    const [Method,setMethod]=useState(false);
    const [Token,setToken]=useState(false);

	const [Visible,setVisible]=useState(false);
	const [Msg,setMsg]=useState(LoadingMsg);

    const date=new Date();
    const month=Number(date.getMonth())+1;
    const today=date.getFullYear()+'-'+('00'+month).slice(-2)+'-'+('00'+date.getDate()).slice(-2);

    useEffect(()=>{
        if(Auth){
            btn=[true,btn[1],btn[2]];
            setButton(btn);
        }else{
            //アカウント作成同意
            document.getElementById('acceptance').addEventListener('change',(e)=>{btn=[e.target.checked,btn[1],btn[2]];setButton(btn);});
        }
        //受講契約同意
        document.getElementById('CourseAcceptance').addEventListener('change',(e)=>{btn=[btn[0],e.target.checked,btn[2]];setButton(btn);});

        setVisible(true);
        let send={
            'url':'/v1/syllabus/'+id,
            'method':'GET'
        }
        Fetch(send).then(data=>{
            if(data['result']){
                setSyllabus(data['payloads']);
                let price=data['payloads']['free']?Number(data['payloads']['SetupPrice']):Number(data['payloads']['SetupPrice'])+Number(data['payloads']['MonthlyPrice']);
                if(price==0){
                    setMethod(true);
                }
                setPrice(price);
                setBCrumb({
                    'CurrentText':data['payloads']['CourseName']+'受講契約',
                    'items':Auth?[
                        {
                            'index':1,
                            'href':'/student',
                            'text':'ホーム'
                        },
                        {
                            'index':2,
                            'href':'/student/course/search',
                            'text':'コース検索'
                        },
                        {
                            'index':3,
                            'href':'/course/'+id+'/syllabus',
                            'text':data['payloads']['CourseName']+'シラバス'
                        },
                    ]:[
                        {
                            'index':1,
                            'href':'/course/'+id+'/syllabus',
                            'text':data['payloads']['CourseName']+'シラバス'
                        }, 
                    ]
                });
                setPayment(data['payloads']['payment']);
            }else{
                setAlert({'type':'danger','style':true,'description':data['error']['description']});
            }
            setVisible(false);
            LoadingMsg=[];
        }).catch((error)=>{
            LoadingMsg.push({type:'warning',title:'エラー',content:'通信エラーが発生しました'});
            setMsg(LoadingMsg);
            LoadingMsg=[];
            setVisible(false);

            console.log(error);
        });
    },[])
    function doEntry(){
        if(Button[0] && Button[1]){
            setVisible(true);
            if(Auth){
                entryCourse();
            }else{
                createAccount();
            }
        }else{
            setAlert({'type':'danger','style':true,'msgs':["同意してください。"]});
            window.scroll({top:0});
        }
    }
    function createAccount(){
        LoadingMsg.push({type:'info',title:'確認中',content:'入力内容を確認しています',delay:5000});
        setMsg(LoadingMsg);

        let {error,body}=SendJson(document.getElementById('CourseAddUser').getElementsByClassName('js-data'),true);
        
        if(error.length){
            setAlert({'type':'danger','style':true,'msgs':error});

			LoadingMsg.push({type:'danger',title:'入力不備',content:'入力内容を確認してください',delay:5000});
			setMsg(LoadingMsg);
            LoadingMsg=[];
			setVisible(false);
            window.scroll({top:0});

            return;

        }else{
            let send={
                'url':'/v1/user',
                'method':'POST',
                'body':body
            }

            LoadingMsg.push({type:'info',title:'送信中',content:'入力内容を送信しています',delay:5000});
            setMsg(LoadingMsg);

            Fetch(send).then(data=>{
                if(data['result']){
                    LoadingMsg.push({type:'success',title:'送信完了',content:'入力内容を送信しました',delay:5000});
                    LoadingMsg.push({type:'success',title:'アカウント作成完了',content:'アカウントを作成しました',delay:5000});
                    setMsg(LoadingMsg);
                    document.getElementById('CourseAddUser').remove();
                    //ログイン処理
                    AuthLogin();
                }else{
                    LoadingMsg.push({type:'danger',title:'エラー',content:'入力内容が保存できませんでした',delay:5000});
                    setMsg(LoadingMsg);
                    setVisible(false);
                    LoadingMsg=[];
                    window.scroll({top:0});

                    setAlert({'type':'danger','style':true,'description':data['error']['description']});
                }
            }).catch((error)=>{
                LoadingMsg.push({type:'warning',title:'エラー',content:'通信エラーが発生しました'});
                setMsg(LoadingMsg);
                LoadingMsg=[];
                setVisible(false);

                console.log(error);
            });
        }
    }
	function AuthLogin(email,password){

		const send={
			'url':'oauth/token',
			'method':'POST',
			'body':OauthToken()
		}
        send['body']['username']=email;
        send['body']['password']=password;
        
        LoadingMsg.push({type:'info',title:'送信中',content:'ログイン情報を送信しています'});
        setMsg(LoadingMsg);

        Fetch(send).then(data=>{
            if(data['access_token']){
                Store.dispatch(Login({'access_token':data['access_token']}));
                setTimeout(()=>
                updateVariation('ALL'),1000);
                Auth=true;
                LoadingMsg.push({type:'success',title:'ログイン成功',content:"ログインに成功しました"});
                setMsg(LoadingMsg);

                LoadingMsg.push({type:'success',title:'コース登録準備',content:"コース登録を行う準備をしています"});
                setMsg(LoadingMsg);

                //コース登録
                setTimeout(() => {
                    entryCourse();
                }, 1500);
            }else{

                LoadingMsg.push({type:'danger',title:'エラー',content:'入力内容が保存できませんでした',delay:5000});
                setMsg(LoadingMsg);
                LoadingMsg=[];
                setVisible(false);

                setAlert({'type':'danger','style':true,"msgs":["メールアドレスもしくはパスワードを確認してください。"]});
            }
        })
        .catch((error)=>{
            LoadingMsg.push({type:'warning',title:'エラー',content:'通信エラーが発生しました'});
            setMsg(LoadingMsg);
            LoadingMsg=[];
            setVisible(false);

            console.log(error);
        });
	}
    function entryCourse(){
        LoadingMsg.push({type:'info',title:'確認中',content:'入力内容を確認しています',delay:5000});
        setMsg(LoadingMsg);

        let {error,body}=SendJson(document.getElementById('CourseAdd').getElementsByClassName('js-data'),true);
        
        if(error.length){
            setAlert({'type':'danger','style':true,'msgs':error});

			LoadingMsg.push({type:'danger',title:'入力不備',content:'入力内容を確認してください',delay:5000});
			setMsg(LoadingMsg);
            LoadingMsg=[];
			setVisible(false);
            window.scroll({top:0});

            return;

        }else{
            let send={
                'url':'/v1/student/course/'+id,
                'method':'PUT',
                'body':{
                    "contracts": {
                        "FileId": Syllabus['contract']['FileId'],
                        "name": body['AcceptName'],
                        "date": body['AcceptDate']
                    },
                    "payments": {
                        "PaymentMethod": body['PaymentMethod']?body['PaymentMethod']:"",
                        "StripeRemember":body['StripeRemember']?true:false,
                        "StripeToken":Token
                    }
                }
            }
            
            LoadingMsg.push({type:'info',title:'送信中',content:'入力内容を送信しています',delay:5000});
            setMsg(LoadingMsg);

            Fetch(send).then(data=>{
                if(data['result']){
                    LoadingMsg.push({type:'success',title:'送信完了',content:'入力内容を送信しました',delay:5000});
                    setMsg(LoadingMsg);
                    let StudentId=data['payloads']['StudentId'];
                    updateVariation('StudentCourse');
                    setWelcome("/student/course/"+StudentId+'/welcome');
                    setAlert({'type':'success','style':true,'msgs':['コースの受講登録をしました。']});
                    setButton([false,false]);
                }else{
                    LoadingMsg.push({type:'danger',title:'エラー',content:'入力内容が保存できませんでした',delay:5000});
                    setMsg(LoadingMsg);

                    setAlert({'type':'danger','style':true,'description':data['error']['description']});
                }
                setVisible(false);
                LoadingMsg=[];
                window.scroll({top:0});
            }).catch((error)=>{
                LoadingMsg.push({type:'warning',title:'エラー',content:'通信エラーが発生しました'});
                setMsg(LoadingMsg);
                LoadingMsg=[];
                setVisible(false);

                console.log(error);
            });
        }
    }
    return(
        <div id="CourseAddWrapper">
            {Welcome?<Redirect to={Welcome} />:''}
            <BreadCrumb item={BCrumb} />
            <AlertBox Item={Alert} />
            <Loading visible={Visible} msgs={Msg} />
            <div className="row">
                <div className="col-sm-6 my-2 mb-5">
                    <a href={Syllabus['contract']['download']} target="_blank">契約書のダウンロード</a>
                    <iframe src={Syllabus['contract']['link']} frameBorder={0} className="w-100 h-100 my-2 iframe-file" />
                </div>
                <div className="col-sm-6 my-2">
                    <form>{Auth?'':
                        <div id="CourseAddUser" className="mb-4">
                            <AlertBox Item={{'type':'info','style':true,'msgs':"ログインしていないため，アカウントを作成します。\nアカウントをお持ちの方はログインしてください。"}} />
                            <div>
                                <span>アカウントをお持ちですか？</span>
                                <Link to={"/login?redirect="+window.location.pathname}>ログイン</Link>
                            </div>
                            <div className="input-group my-2">
                                <span className="input-group-text">メールアドレス</span>
                                <input type="email" name="email" className="form-control js-data" placeholder="メールアドレス" required />
                            </div>
                            <div className="input-group my-2">
                                <span className="input-group-text">パスワード</span>
                                <input type="password" name="password" className="form-control js-data" placeholder="新しいパスワード" autoComplete="new-password" required />
                            </div>
                            <div className="input-group my-2">
                                <span className="input-group-text">パスワード</span>
                                <input type="password" name="password_confirmation" className="form-control js-data" placeholder="確認用パスワード" autoComplete="new-password" required />
                            </div>
                            <Accept />
                        </div>}
                        <div id="CourseAdd" style={{display:Button[0]?'block':'none'}}>
                            <div className="mb-2">
                                <div className="input-group my-2">
                                    <span className="input-group-text">同意日</span>
                                    <input type="date" id="acceptance_date" name="AcceptDate" className="form-control js-data" defaultValue={today} max={today} required readOnly />
                                </div>
                                <div className="input-group my-2">
                                    <span className="input-group-text">名前</span>
                                    <input type="text" id="acceptance_name" name="AcceptName" className="form-control js-data" defaultValue={Auth?Store.getState()['UserName']:''} placeholder="名前" required />
                                </div>
                                <div className="my-2">
                                    <input type="checkbox" id="CourseAcceptance" className="form-check-input" defaultValue={true} onChange required />
                                    <label htmlFor="CourseAcceptance" className="input-check-label">
                                        &nbsp;{Syllabus['CourseName']}の受講契約に関し，契約書を確認の上，同意する
                                    </label>
                                </div>
                            </div>
                            <div className="my-3" style={{display:Button[1]?'block':'none'}}>
                                <h3>
                                    お支払金額
                                </h3>
                                <table className="table">
                                    <tbody>
                                        <tr>
                                            <th>
                                            入会金
                                            </th>
                                            <td>
                                            {number_format(Syllabus['SetupPrice'])}円
                                            </td>
                                        </tr>{Price?
                                        <tr>
                                            <th>
                                            無料期間
                                            </th>
                                            <td>
                                            {CourseFree(Syllabus['free'])}
                                            </td>
                                        </tr>:""}
                                        <tr>
                                            <th>
                                            受講料
                                            </th>
                                            <td>
                                            {number_format(Syllabus['MonthlyPrice'])}円/月
                                            </td>
                                        </tr>{Price?
                                        <tr>
                                            <th>
                                            契約月数
                                            </th>
                                            <td>
                                                {Syllabus['AccountNumber']?number_format(Syllabus['AccountNumber'])+'月':"解約まで"}
                                            </td>
                                        </tr>:""}
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <th>
                                            合計金額
                                            </th>
                                            <td>
                                            入会金：{number_format(Syllabus['SetupPrice'])}円{Syllabus['free']?'':'+受講料：'+number_format(Syllabus['MonthlyPrice'])+'円/月'+(Syllabus['AccountNumber']?'×契約月数：'+number_format(Syllabus['AccountNumber'])+'月':'')}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>
                                            初回支払金額
                                            </th>
                                            <td>
                                            {number_format(Price)}円
                                            </td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>{Price?
                            <div className="my-3" style={{display:((Button[1])?'block':'none')}}>
                                <h3>
                                    お支払
                                </h3>
                                <PaymentForm payment={Payment} setFunc={[setMethod,setToken]} />
                            </div>:""}
                        </div>
                        <div className="my-3">
                            <button type="button" className="btn btn-primary" onClick={doEntry} disabled={!(Button[0] && Button[1] && (Method==true || (Method && Method!='stripe') || (Method=='stripe' && Token)))}>
                                受講登録
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}