import { useEffect, useState } from "react";
import ReactDOM from 'react-dom';
import { useParams } from "react-router";
import BreadCrumb from "../component/breadcrumb";
import Fetch from "../common/fetch";
import AlertBox from "../component/AlertBox";
import TextDetail from "../component/TextDetail";

export default function Text(){
    const {student,id}=useParams();
    const [BCrumb,setBCrumb]=useState({'CurrentText':'教材','items':[{'index':1,'href':'/teacher','text':'ホーム'}]});
    const [Alert,setAlert]=useState({'type':'danger','style':false,'msgs':[]});
    const [Data,setData]=useState(false);
    useEffect(()=>{
        let send={
            'url':'v1/student/course/'+student+'/text/'+id,
            'method':'GET'
        }
        Fetch(send).then(data=>{
            if(data['result']){
                setAlert({'type':'danger','style':false,'msgs':[]});
                setData(data['payloads']['texts'])
                //ReactDOM.render(<TextDetail Item={data['payloads']['texts']} />,document.getElementById('content_area'));
                setBCrumb({
                    CurrentText: data['payloads']['FolderName'],
                    items: [
                        {
                            index: 1,
                            href: "/student",
                            text: "ホーム"
                        },
                        {
                            index:2,
                            href:'/student/course/'+student,
                            text:data['payloads']['CourseName']
                        }
                    ],
                });
            }else{
                setAlert({'type':'danger','style':true,'description':data['error']['description']});
            }
        }).catch(error=>console.log(error));
    },[])
    return(
        <div>
            <BreadCrumb item={BCrumb} />
            <AlertBox Item={Alert} />{Data?
            <div>{Data.length ?
                <TextDetail Item={Data} />:
                <div className="alert alert-info">
                    教材がまだ登録されていないか，公開されていません。
                </div>}
            </div>:''}
        </div>
    )
}