/*import { useEffect, useState } from "react";
import Fetch from "../common/fetch";*/
import { ListGroup } from "react-bootstrap";
import Escape from "./Escape";
import { ContactType } from "./Keys";


/**
 *create Contact Component
 *
 * @export
 * @param {*} props
 */
export default function Contact(props){
    /*const [Contact,setContact]=useState({
        "ContactTitle": "",
        "ContactContent": "",
        "PostedDatetime": "",
        "condition": 0,
        "files": [],
        "EntriedUserId": "",
        "EntriedUserName":"",
        "ContactType": "",
        "CourseName": ""
    });
    useEffect(()=>{
        let send={
            'url':'v1/contact/'+props.Id,
            'method':'GET'
        }
        Fetch(send).then(data=>{
            setContact(data['payloads']);
        }).catch(error=>console.log(error));
    },[])
*/
    const Contact=props.Item;
    return(
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-header">
                        {Contact['ContactTitle']}
                        </div>
                        <div className="card-body">
                            <div className="mb-3">
                            {Escape(Contact['ContactContent'])}
                            </div>
                            <div className="list-group">
                                {Contact['files'].map(file=>
                                    <a href={file['link']} className="list-group-item list-group-item-action" key={file['FileId']} target="_blank">
                                        {file['FileName']}
                                    </a>
                                )}
                            </div>
                        </div>
                        <div className="card-footer">
                        <p className="m-0">
                            掲載日時：{Contact['PostedDatetime']}
                        </p>
                        <p className="m-0">
                            掲載者：{Contact['EntriedUserName']}
                        </p>
                        <p className="m-0">
                            カテゴリー：{ContactType(Contact['ContactType'])}
                        </p>{Contact['CourseName'] &&
                        <p className="m-0">
                            コース名：{Contact['CourseName']}
                        </p>}
                        </div>
                    </div>
                </div>
            </div>
    );
}