import { useEffect, useState } from "react";
import ReactDOM from 'react-dom';
import BreadCrumb from "../component/breadcrumb";
import Fetch from "../common/fetch";
import AlertBox from "../component/AlertBox";
import { useParams } from "react-router";
import { ConvertToDisplayDate, number_format, PaymentCondition, PaymentMethod } from "../component/Keys";
import { Accordion } from "react-bootstrap";
import Loading from "../component/Loading";
import Escape from "../component/Escape";

export default function Payment(){
    const {id}=useParams();
    const [BCrumb,setBCrumb]=useState({'CurrentText':'支払い履歴','items':[{'index':1,'href':'/account','text':'アカウント'}]});
    const [Alert,setAlert]=useState({'type':'danger','style':false,'msgs':[]});
    const [Item,setItem]=useState([]);
    const [Visible,setVisible]=useState(true);
    useEffect(()=>{
        let send={
            'url':'v1/payment/'+id,
            'method':'GET'
        }
        Fetch(send).then(data=>{
            if(data['result']){
                setAlert({'type':'danger','style':false,'msgs':[]});
                //ReactDOM.render(<DetailPayment Item={data['payloads']} />,document.getElementById('content_area'));
                setItem(data['payloads']['histories']);
                setBCrumb({
                    CurrentText: data['payloads']['title'],
                    items: [
                        {
                            index: 1,
                            href: "/account#payment",
                            text: "アカウント"
                        },
                    ],
                });
            }else{
                setAlert({'type':'danger','style':true,'description':data['error']['description']});
            }
            setVisible(false);
        }).catch(error=>{
            setVisible(false);
            console.log(error);
        });
    },[])
    function Color(id){
        switch(id){
            case 0:
                return 'success';
                break;
            case 1:
                return 'info';
                break;
            case 2:
                return 'danger';
                break;
            case 3:
                return 'warning';
                break;
        }
    }
    function convertDescription(description){
        switch(description['type']){
            case 'link':
                return (<a href={description['link']} target="_blank">{description['title']}</a>);
                break;
            case 'text':
                return description['content'];
                break;
        }
    }
    return(
        <div>
            <BreadCrumb item={BCrumb} />
            <AlertBox Item={Alert} />
            <Loading visible={Visible} />
            <div id="content_area"></div>
            <Accordion defaultActiveKey="0">
                {Item.map(item=><Accordion.Item key={item['PaymentId']} eventKey={item['PaymentId']}>
                    <Accordion.Header>
                        {item['title']}
                        <span className={"btn btn-"+Color(item['condition'])+" mx-2"}>{PaymentCondition(item['condition'])}</span>
                    </Accordion.Header>
                    <Accordion.Body>
                        <div className="table-responsive">
                        <table className="table table-striped">
                            <tbody>
                                <tr>
                                    <th>
                                        決済タイトル
                                    </th>
                                    <td>
                                        {item['title']}
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        決済内容
                                    </th>
                                    <td>
                                        {(item['description'] && item['description'].length) ? item['description'].map((description,index)=><li key={index}>{convertDescription(description)}</li>):""}
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        金額
                                    </th>
                                    <td>
                                        {number_format(item['price'])}円
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        予定決済日
                                    </th>
                                    <td>
                                        {ConvertToDisplayDate(item['ScheduledDate'])}
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        決済日時
                                    </th>
                                    <td>
                                        {ConvertToDisplayDate(item['PaymentDatetime'],true)}
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        決済方法
                                    </th>
                                    <td>
                                        {PaymentMethod(item['method'])}
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        備考
                                    </th>
                                    <td>
                                        {Escape(item['note'])}
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        状態
                                    </th>
                                    <td>
                                        {PaymentCondition(item['condition'])}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        </div>
                    </Accordion.Body>
                </Accordion.Item>)}
            </Accordion>
        </div>
    )
}
function DetailPayment(props){
    let item=props.Item;
    return (
        <div>
            <Accordion>

            </Accordion>
            <table className="table table-striped">
                <tbody>
                    <tr>
                        <th>
                            決済タイトル
                        </th>
                        <td>
                            {item['PaymentTitle']}
                        </td>
                    </tr>
                    <tr>
                        <th>
                            決済内容
                        </th>
                        <td>
                            {item['PaymentDescription']}
                        </td>
                    </tr>
                    <tr>
                        <th>
                            金額
                        </th>
                        <td>
                            {item['price']}
                        </td>
                    </tr>
                    <tr>
                        <th>
                            予定決済日
                        </th>
                        <td>
                            {item['ScheduledDate']}
                        </td>
                    </tr>
                    <tr>
                        <th>
                            決済日時
                        </th>
                        <td>
                            {item['PaymentDatetime']}
                        </td>
                    </tr>
                    <tr>
                        <th>
                            決済方法
                        </th>
                        <td>
                            {PaymentMethod(item['method'])}
                        </td>
                    </tr>
                    <tr>
                        <th>
                            備考
                        </th>
                        <td>
                            {item['note']}
                        </td>
                    </tr>
                    <tr>
                        <th>
                            状態
                        </th>
                        <td>
                            {PaymentCondition(item['condition'])}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}