import { useParams } from "react-router-dom";
import BreadCrumb from "../component/breadcrumb";
import Contact from "../component/Contact";
import { useState,useEffect } from "react";
import Fetch from "../common/fetch";
import AlertBox from "../component/AlertBox";

export default function Info(){
    const {course,id}=useParams();
    const [BCrumb,setBCrumb]=useState({
        'CurrentText':'お知らせ',
        'items':[
            {
                'index':1,
                'href':'/teacher',
                'text':'ホーム'
            }
        ]
    });
    const [contact,setContact]=useState({
        "ContactTitle": "",
        "ContactContent": "",
        "PostedDatetime": "",
        "condition": 0,
        "files": [],
        "EntriedUserId": "",
        "EntriedUserName":"",
        "ContactType": "",
        "CourseId":"",
        "CourseName": ""
    });
    const [Alert,setAlert]=useState({'type':'info','style':false, 'msgs':[],'description':[] });
    useEffect(()=>{
        let send={
            'url':'v1/course/'+course+'/contact/'+id,
            'method':'GET'
        }
        Fetch(send).then(data=>{
            if(data['result']){
                setAlert({'type':'danger','style':false, 'msgs':[]});
                setBCrumb({
                    'CurrentText':'お知らせ',
                    'items':[
                        {
                            'index':1,
                            'href':'/teacher',
                            'text':'ホーム'
                        },
                        {
                            'index':2,
                            'href':'/teacher/course/'+course,
                            'text':data['payloads']['CourseName']
                        }
                    ]
                });
                setContact(data['payloads']);
            }else{
                setAlert({'type':'danger','style':true, 'description':data['error']['description'] });
            }
        }).catch(error=>console.log(error));
    },[])
    return(
        <div>
            <BreadCrumb item={BCrumb} />
            <AlertBox Item={Alert} />
            <Contact Item={contact} />
        </div>
    );
}