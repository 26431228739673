import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { Redirect } from "react-router-dom";
import Fetch from "../common/fetch";
import AlertBox from "../component/AlertBox";
import Loading from "../component/Loading";
import getParams from "../component/getParams";

let LoadingMsg=[{type:'info',title:'認証確認中',content:'LINEの認証内容を確認しています。'}];
export default function Line({doAuth}){

    const [Alert,setAlert]=useState({'type':'danger','style':false,'msgs':[]});
	const [Visible,setVisible]=useState(true);
	const [Msg,setMsg]=useState(LoadingMsg);

    const [cookies,setCookies,removeCookies]=useCookies();
    const [ToRedirect,setToRedirect]=useState(false);
    useEffect(()=>{
        let params = getParams();
        //ログイン処理
        let send={
            'url':'v1/line/'+cookies['nonce'],
            'method':'PUT',
            'body':params
        };
        Fetch(send).then(data=>{
            if(data['result'] && data['payloads']['access_token']){

                LoadingMsg.push({type:'success',title:'認証成功',content:"LINE認証に成功しました\nまもなく画面を移動します"});
                setMsg(LoadingMsg);
                LoadingMsg=[];

                setTimeout(()=>{
                    setToRedirect('/account');
                },3000);
            }else{

                LoadingMsg.push({type:'danger',title:'エラー',content:'認証に失敗しました。'});
                setMsg(LoadingMsg);
                LoadingMsg=[];
                setVisible(false);

                setAlert({'type':'danger','style':true,'msgs':["ログインできませんでした。","10秒後にログイン画面にリダイレクトします。"]});
                setTimeout(()=>{
                    setToRedirect('/account');
                },10000);
            }
            removeCookies('nonce');
        })
        .catch((error)=>{
            LoadingMsg.push({type:'warning',title:'エラー',content:'通信エラーが発生しました'});
            setMsg(LoadingMsg);
            LoadingMsg=[];
            setVisible(false);

            console.log(error);
        })
    },[]);
    return (
        <div>
            <AlertBox Item={Alert} />
            <Loading visible={Visible} msgs={Msg} />
            {ToRedirect?<Redirect to={ToRedirect} />:""}
        </div>
    );
}