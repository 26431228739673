import { useEffect, useState } from 'react';
import {Switch,Route,Redirect} from 'react-router-dom';
import Fetch from './common/fetch';
import { Store } from './common/store';
import AlertBox from './component/AlertBox';
import BreadCrumb from './component/breadcrumb';
import CardList from './component/CardList';
import Loading from './component/Loading';
import Welcome from './EntryCourse/welcome';
import Course from './student/course';
import Examination from './student/examination';
import Info from './student/info';
import Line from './student/line';
import Report from './student/report';
import Search from './student/search';
import Text from './student/text';/*
import Detail from './student/detail';
import Entry from './student/entry';
import Welcome from './student/welcome';*/


export default function Student(props){
    return (
        <Switch>
            <Route path="/student/course/search" exact>
                <Search />
            </Route>
            <Route path="/student/course/line/" exact>
                <Line />
            </Route>
            <Route path="/student/course/:id" exact>
                <Course />
            </Route>
            <Route path="/student/course/:student/info/:id" exact>
                <Info />
            </Route>
            <Route path="/student/course/:student/text/:id" exact>
                <Text />
            </Route>
            <Route path="/student/course/:student/report/:id" exact>
                <Report />
            </Route>
            <Route path="/student/course/:student/examination/:id" exact>
                <Examination />
            </Route>{/*
            <Route path="/student/course/detail/:id" exact>
                <Detail />
            </Route>
            <Route path="/student/course/entry/:id" exact>
                <Entry />
            </Route>*/}
            <Route path="/student/course/:id/welcome" exact>
                <Welcome />
            </Route>
            <Route path="/student" exact>
                <Home />
            </Route>
            <Route path="/student">
                <Redirect to="/student" />
            </Route>
        </Switch>
    );
}

function Home(){
    const[courses,setCourses]=useState({
        'title':'受講中コース',
        'items':[]
    });
    const [contacts,setContacts]=useState({
        'title':'新着情報',
        'items':[]
    });
    const [Alert,setAlert]=useState({'type':'danger','style':false,'msgs':[]});
    const [Visible,setVisible]=useState(true);
    useEffect(()=>{
        let send={
            'url':'v1/student',
            'method':'GET'
        }
        Fetch(send).then(data=>{
            if(data['result']){
                setAlert({'type':'danger','style':false,'msgs':[]});
                let items=[];
                let lists=data['payloads']['courses'];
                for(let i=0;i<lists.length;++i){
                    items.push({
                        'index':i,
                        'href':'/student/course/'+lists[i]['StudentId'],
                        'text':lists[i]['CourseName']
                    })
                }
                setCourses({
                    'title':'受講中コース',
                    'items':items
                })
            }else{
                setAlert({'type':'danger','style':true, 'description':data['error']['description'] });
            }
            setVisible(false)
        }).catch(error=>{
            setVisible(false);
            console.log(error);
        });
    },[])
    const BCrumb={
        'CurrentText':'ホーム',
        'items':[]
    };
    return (
        <div>
            <BreadCrumb item={BCrumb} />
            <AlertBox Item={Alert} />
            <Loading visible={Visible} msgs={[]} />
            <div className="row">
                <div className="col-sm-6 col-md-6 mb-2">
                    <CardList item={courses} />
                </div>{/*
                <div className="col-sm-6 col-md-6 mb-2">
                    <CardList item={contacts} />
                </div>*/}
            </div>
        </div>
    );
}