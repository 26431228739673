import { useEffect, useRef, useState } from "react";
import { Accordion, Button, Form, InputGroup, Table } from "react-bootstrap";
import Fetch from "../common/fetch";
import AlertBox from "../component/AlertBox";
import BreadCrumb from "../component/breadcrumb";
import { createKey } from "../component/Keys";
import Loading from "../component/Loading";
import SendJson from "../component/SendJson";

const HeaderItem={
    meta:'メタ',
    link:"リンク",
    script:"スクリプト"
};
const date=new Date();
export default function Setting(){
    const BCrumb={
        'CurrentText':'サイト設定',
        'items':[
            {
                index:0,
                href:'/lp',
                text:'ホーム'
            }
        ]
    };
    const [Visible,setVisible]=useState(true);
    const [Item,setItem]=useState();
    const [Template,setTemplate]=useState([]);
    const [Alert,setAlert]=useState({'type':'danger','style':false,'msgs':[]});
    const template=useRef();
    const SiteName=useRef();
    const SiteNameVisible=useRef();
    const CopyRight=useRef();
    const CopyRightVisible=useRef();
    function doEntry(){
        setVisible(true);
        var {error,body}=SendJson(document.getElementsByClassName('js-data'),true);
        let AllError=[].concat(error);
        let AllBody=Object.assign({
            headers:{},
            header:{menus:{visible:false}},
            footers:{}
        },body);
        //headers
        Object.keys(HeaderItem).forEach(key=>{
            var [error,body]=getItems('header',key);
            AllBody['headers'][key+'s']=body;
            AllError=AllError.concat(error);
        });
        //header
        AllBody['header']['logos']={
            type:'TEXT',
            text:SiteName.current.value,
            visible:SiteNameVisible.current.checked
        };
        //footer
        var [error,body]=getItems('footer','script');
        AllBody['footers']['scripts']=body;
        AllError=AllError.concat(error);
        AllBody['footers']['copyrights']={
            type:'TEXT',
            text:CopyRight.current.value,
            visible:CopyRightVisible.current.checked
        };
        window.scroll({top:0});
        if(AllError.length){
            setAlert({'type':'danger','style':true,'msgs':AllError});
            setVisible(false);
            return;
        }else{
            let send={
                url:"v1/lp/setting",
                method:'POST',
                body:AllBody
            };
            Fetch(send).then(data=>{
                if(data['result']){
                    getSetting();
                    setAlert({'type':'success','style':true,'msgs':['設定を登録しました。']});
                }else{
                    setAlert({'type':'danger','style':true,'description':data['error']['description']});
                }
                setVisible(false);
            }).catch(error=>{
                setVisible(false);
                setAlert({'type':'danger','style':true,'msgs':["通信エラーが発生しました。"]});
                console.log(error);
            });
        }
    }
    function getItems(type,tag){
        let tmpObj=[];
        let AllError=[];
        let tbody=document.getElementById(type+"_"+tag).getElementsByClassName(type+'-'+tag+'__body');
        for(let n=0;n<tbody.length;++n){
            let trs=tbody[n].getElementsByTagName('tr');
            tmpObj[n]=[];
            for(let i=0;i<trs.length;++i){
                var {error,body}=SendJson(trs[i].getElementsByClassName('js-data-list'),true);
                AllError=AllError.concat(error);
                tmpObj[n].push(body);
            }
        }
        return [AllError,tmpObj];
    }
    function getSetting(){
        setVisible(true);
        let send={
            url:'/v1/lp/setting',
            method:'GET'
        };
        Fetch(send).then(data=>{
            if(data['result']){
                setItem(data['payloads']);
            }else{
                setAlert({'type':'danger','style':true,'description':data['error']['description']});
            }
            setVisible(false);
        }).catch(error=>{
            setVisible(false);
            setAlert({'type':'danger','style':true,'msgs':["通信エラーが発生しました。"]});
            console.log(error);
        })
    }
    function TagList({
        title,
        Item,
        tag,
        type
    }){
        const [Items,setItems]=useState(Item[type+'s'][tag+'s']);
        function createItem(e){
            let newItem=Object.assign({},Item);
            newItem[type+'s'][e.target.value]=newItem[type+'s'][e.target.value].concat([[{
                attribute:"",
                value:""
            }]]);
            setItem(newItem);
        }
        function destroyItem(e){
            let newItem=Object.assign({},Item);
            newItem[type+'s'][e.target.dataset.type].splice(e.target.value,1);
            setItem(newItem);
        }
        function addItem(e){
            /*let newItem=Object.assign({},Item);
            newItem[type+'s'][e.target.dataset.type][e.target.value]=newItem[type+'s'][e.target.dataset.type][e.target.value].concat({
                attribute:"",
                value:""
            });
            setItem(newItem);*/
            updateItem(e);
            let newItem=[].concat(updateItem());
            newItem[e.target.value]=newItem[e.target.value].concat({
                attribute:"",
                value:""
            });
            setItems(newItem);
        }
        function deleteItem(e){
            /*let newItem=Object.assign({},Item);
            newItem[type+'s'][e.target.dataset.type][e.target.dataset.index].splice(e.target.value,1);
            setItem(newItem);*/
            updateItem(e);
            let newItem=[].concat(updateItem());
            newItem[e.target.dataset.index].splice(e.target.value,1);
            setItems(newItem);
        }
        function updateItem(){
            let [error,body]=getItems(type,tag);
            return body;
        }
        function changeValue(){
            setItems(updateItem());
        }
        return (
            <Form.Group>
                <h5 className="form-label">
                    {title}
                </h5>
                <Accordion defaultActiveKey="0" id={type+"_"+tag}>{Items.map((items,index)=>
                    <Accordion.Item eventKey={index} key={createKey()}>
                        <Accordion.Header>
                            &lt;{tag}&nbsp;{items.map(item=><span>{item['attribute']}="{item['value']}"&nbsp;</span>)}/&gt;
                        </Accordion.Header>
                        <Accordion.Body>
                            <Table striped bordered>
                                <thead>
                                    <tr>
                                        <th>
                                            属性名
                                        </th>
                                        <th>
                                            属性値
                                        </th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody className={type+"-"+tag+"__body"}>{items.map((item,idx)=>
                                    <tr key={item['attribute']}>
                                        <td>
                                            <Form.Control type="text" name="attribute" className="js-data-list" defaultValue={item['attribute']} placeholder="属性名" onBlur={changeValue} />
                                        </td>
                                        <td>
                                            <Form.Control type="text" name="value" className="js-data-list" defaultValue={item['value']} placeholder="属性値" onBlur={changeValue} />
                                        </td>
                                        <td>
                                            <Button variant="danger" type="button" data-type={tag+"s"} data-index={index} value={idx} onClick={deleteItem}>削除</Button>
                                        </td>
                                    </tr>)}
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td colSpan={3}>
                                            <Button variant="success" type="button" data-type={tag+"s"} value={index} onClick={addItem}>追加</Button>
                                        </td>
                                    </tr>
                                </tfoot>
                            </Table>
                            <Button variant="danger" type="button" data-type={tag+"s"} value={index} onClick={destroyItem}>{title}削除</Button>
                        </Accordion.Body>
                    </Accordion.Item>
                    )}
                    <Button variant="success" type="button" value={tag+"s"} className="my-2" onClick={createItem}>追加</Button>
                </Accordion>
            </Form.Group>
        );
    }
    function openSamplePage(){
        window.open('https://lp.canteach.jp/template/template-'+template.current.value,'_blank');
    }
    useEffect(()=>{
        let send={
            url:'/v1/lp/setting/template',
            method:'GET'
        };
        Fetch(send).then(data=>{
            if(data['result']){
                setTemplate(data['payloads']);
            }else{
                setAlert({'type':'danger','style':true,'description':data['error']['description']});
            }
            setVisible(false);
        }).catch(error=>{
            setVisible(false);
            setAlert({'type':'danger','style':true,'msgs':["通信エラーが発生しました。"]});
            console.log(error);
        })
        getSetting();
    },[])
    return (
        <div>
            <BreadCrumb item={BCrumb} />
            <AlertBox Item={Alert} />
            <Loading visible={Visible} />{(Item && Template.length) ?
            <div>
                <h3>
                    サイト設定
                </h3>
                <div className="my-2">
                    <Form.Group>
                        <Form.Label>
                            テンプレート
                        </Form.Label>
                        <InputGroup>
                            <Form.Select defaultValue={Item['templates']['TemplateId']} name="TemplateId" className="js-data" ref={template} required>{Template.map(template=>
                                <option key={template['TemplateId']} value={template['TemplateId']}>{template['TemplateName']}</option>
                                )}
                            </Form.Select>
                            <Button variant="secondary" type="button" onClick={openSamplePage}>サンプルページ表示</Button>
                        </InputGroup>
                    </Form.Group>
                </div>
                <div className="my-2">
                    <h4>
                        サイト名
                    </h4>
                    <Form.Group>
                        <Form.Control type="text" defaultValue={Item['header']['logos']['text']} placeholder="サイト名" ref={SiteName} />
                        <Form.Check type="checkbox" id="SiteNameVisible" label="サイト名を表示する" defaultChecked={Item['header']['logos']['visible']} ref={SiteNameVisible} />
                    </Form.Group>
                </div>
                <div className="my-2">
                    <h4>
                        ヘッダー
                    </h4>{Object.keys(HeaderItem).map(key=>
                    <TagList key={key} title={HeaderItem[key]} Item={Item} tag={key} setItem={setItem} type="header" />)}
                </div>
                <div className="my-2">
                    <h4>
                        フッター
                    </h4>
                    <Form.Group>
                        <Form.Label>
                            コピーライト
                        </Form.Label>
                        <Form.Control type="text" defaultValue={Item['footers']['copyrights']['text']} placeholder={"© "+date.getFullYear()+" CanTeach."} ref={CopyRight} />
                        <Form.Check type="checkbox" id="CopyRightVisible" label="コピーライトを表示する" defaultChecked={Item['footers']['copyrights']['visible']} ref={CopyRightVisible} />
                    </Form.Group>
                    <TagList key={"f_script"} title={"スクリプト"} Item={Item} tag={"script"} setItem={setItem} type="footer" />
                </div>
                <Button variant="primary" type="button" onClick={doEntry}>登録</Button>
            </div>:""}
        </div>
    );
}

function getItems(type,tag){
    let tmpObj=[];
    let AllError=[];
    let tbody=document.getElementById(type+"_"+tag).getElementsByClassName(type+'-'+tag+'__body');
    for(let n=0;n<tbody.length;++n){
        let trs=tbody[n].getElementsByTagName('tr');
        tmpObj[n]=[];
        for(let i=0;i<trs.length;++i){
            var {error,body}=SendJson(trs[i].getElementsByClassName('js-data-list'),true);
            AllError=AllError.concat(error);
            tmpObj[n].push(body);
        }
    }
    return [AllError,tmpObj];
}