import { useEffect, useState } from "react";
import { ButtonGroup, ToggleButton } from "react-bootstrap";
import { useParams } from "react-router-dom";
import Fetch from "../common/fetch";
import AlertBox from "../component/AlertBox";
import BreadCrumb from "../component/breadcrumb";
import { ConvertToDisplayDate, number_format, PaymentCondition, StudentCondition } from "../component/Keys";
import Loading from "../component/Loading";

export default function StundetDetail(){
    const {course,id}=useParams();
    const [BCrumb,setBCrumb]=useState({'CurrentText':'受講者情報','items':[{'index':0,'href':'/teacher','text':'ホーム'},{'index':1,'href':'/teacher/course/${course}','text':'コース'},{'index':2,'href':'/teacher/course/${course}/student','text':'受講者情報'}]});
    const [Alert,setAlert]=useState({'type':'danger','style':false,'msgs':[]});
    const [Visible,setVisible]=useState(true);
    const [Item,setItem]=useState();
    useEffect(()=>{
        getItem();
    },[])
    function getItem(){
        setVisible(true);
        let send={
            url:`v1/course/${course}/student/${id}`,
            method:'GET'
        };
        Fetch(send).then(data=>{
            if(data['result']){
                setItem(data['payloads']);
                setBCrumb({
                    'CurrentText':data['payloads']['students']['UserName'],
                    'items':[
                        {'index':0,'href':'/teacher','text':'ホーム'},
                        {'index':1,'href':'/teacher/course/${course}','text':data['payloads']['CourseName']},
                        {'index':2,'href':'/teacher/course/${course}/student','text':'受講者情報'}
                    ]}
                );
            }else{
                setAlert({'type':'danger','style':true,'description':data['error']['description']})
            }
            setVisible(false);
        }).catch(error=>{
            setVisible(false);
            console.log(error);
        })
    }
    function doGraduate(){
        setVisible(true);
        let send={
            'url':'v1/course/'+course+'/student/'+id,
            'method':'PUT',
            'body':{
                "condition":2
            }
        }
        Fetch(send).then(data=>{
            if(data['result']){
                setAlert({'type':'success','style':true,'msgs':['修了登録をしました。']});
                getItem();
            }else{
                setVisible(false);
                setAlert({'type':'danger','style':true,'description':data['error']['description']});
            }
        }).catch(error=>{
            setVisible(false);
            console.log(error);
        });
    }
    function changeInfo(){
        setVisible(true);
        let unpay=[];
        let datas=document.getElementsByClassName('js-data-unpay');
        for(let i=0;i<datas.length;++i){
            if(datas[i].checked){
                unpay.push({
                    PaymentId:datas[i].value,
                    condition:0
                });
            }
        }
        let send={
            'url':'v1/course/'+course+'/student/'+id,
            'method':'PUT',
            'body':{
                visible:document.getElementById('CourseVisible').value=='true'?true:false,
                unpay:unpay
            }
        }
        Fetch(send).then(data=>{
            if(data['result']){
                setAlert({'type':'success','style':true,'msgs':['修了登録をしました。']});
                getItem();
            }else{
                setVisible(false);
                setAlert({'type':'danger','style':true,'description':data['error']['description']});
            }
        }).catch(error=>{
            setVisible(false);
            console.log(error);
        });
    }
    return (
        <div>
            <BreadCrumb item={BCrumb} />
            <AlertBox Item={Alert} />
            <Loading visible={Visible} msgs={[]} />{Item?
            <div>
                <div className="table-responsive">
                    <table className="table table-striped">
                        <tbody>
                            <tr>
                                <th>
                                    受講者名
                                </th>
                                <td>
                                    {Item['students']['UserName']}
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    参加日
                                </th>
                                <td>
                                    {ConvertToDisplayDate(Item['students']['FromDate'])}
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    状態
                                </th>
                                <td>
                                    {Item['students']['condition'] ?
                                    <>
                                        {ConvertToDisplayDate(Item['students']['ToDate'])}に{StudentCondition(Item['students']['condition'])}
                                    </>:
                                    <span>{StudentCondition(Item['students']['condition'])}<button type="button" className="btn btn-secondary" onClick={doGraduate}>修了</button></span>
                                    }
                                </td>
                            </tr>{(Item['students']['condition']==1 || Item['students']['condition']==2) ?
                            <tr>
                                <th>
                                    コース内容の表示
                                </th>
                                <td>
                                    <div className="row w-100">
                                        <div className="col-auto">
                                            <select id="CourseVisible" className="form-select d-inline" defaultValue={Item['students']['visible']} data-type="boolean">
                                                <option value={true}>表示する</option>
                                                <option value={false}>表示しない</option>
                                            </select>
                                        </div>
                                        <div className="col"></div>
                                    </div>
                                </td>
                            </tr>:''}
                        </tbody>
                    </table>
                </div>
                <div className="table-responsive">
                    <table className="table table-striped table-hover">
                        <thead>
                            <tr>
                                <th>
                                    タイトル
                                </th>
                                <th>
                                    金額
                                </th>
                                <th>
                                    状態
                                </th>
                                <td></td>
                            </tr>
                        </thead>
                        <tbody>{Item['students']['payments'].map(payment=>
                            <tr>
                                <td>
                                    {payment['PaymentTitle']}
                                </td>
                                <td>
                                    {number_format(payment['price'])}円
                                </td>
                                <td>
                                    {PaymentCondition(payment['condition'])}
                                </td>
                                <td>
                                    {payment['condition']?
                                    <div className="form-check">
                                        <input type="checkbox" id={payment['PaymentId']} className="js-data-unpay form-check-input" value={payment['PaymentId']} defaultChecked={false} />
                                        <label htmlFor={payment['PaymentId']} className="form-check-label">
                                            決済完了
                                        </label>
                                    </div>
                                    :""}
                                </td>
                            </tr>)}
                        </tbody>
                    </table>
                </div>
                <button type="button" className="btn btn-primary" onClick={changeInfo}>変更</button>
            </div>
            :""}
        </div>
    );
}