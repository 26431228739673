import { useEffect, useState } from "react";
import ReactDOM from 'react-dom';
import BreadCrumb from "../component/breadcrumb";
import Fetch from "../common/fetch";
import AlertBox from "../component/AlertBox";
import AccountForm from "../component/AccountForm";
import { updateVariation } from "../../App";

export default function EntryAccount(){
    const [BCrumb,setBCrumb]=useState({'CurrentText':'新規決済方法','items':[{'index':1,'href':'/teacher','text':'ホーム'}]});
    const [Alert,setAlert]=useState({'type':'danger','style':false,'msgs':[]});
    useEffect(()=>{
        ReactDOM.render(<AccountForm Submit={doEntry} />,document.getElementById('content_area'));
    },[])
    function doEntry(send){
        send['url']='v1/teacher/account/';
        send['method']='POST';
        Fetch(send).then(data=>{
            if(data['result']){
                document.getElementById('content_area').textContent="";
                setAlert({'type':'success','style':true,'msgs':["決済方法を登録しました。"]});
                window.scroll({top:0});
                updateVariation('TeacherAccount')
                //window.location.href="/teacher";
            }else{
                setAlert({'type':'danger','style':true,'description':data['error']['description']});
            }
        }).catch(error=>console.log(error));
    }
    return(
        <div>
            <BreadCrumb item={BCrumb} />
            <AlertBox Item={Alert} />
            <div id="content_area"></div>
        </div>
    )
}