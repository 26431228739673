import { ApiUrl } from './fetch';
import { getAccessToken } from "../..";
import { createKey } from "../component/Keys";

let url;
if(window.location.hostname=='localhost'){
    url='http://localhost/cantach/login/upload/';
}else{
    url='https://login.canteach.jp/upload/';
}

/**
 * 
 * @param {*} send 
 * endpoint:
 * method
 * body
 * @returns 
 */
export default function Upload(send){
    let token=getAccessToken();
    let headers={
        "Accept":"application/json",
        "Canteach-Nonce":createKey(),
        "Canteach-Version":1,
        "Canteach-Endpoint":"",
        "Canteach-Method":"POST",
        "Canteach-Token":token['LongToken'],
        "Canteach-Short":token['ShortToken'],
        "Canteach-Value":token['AccessToken']
    };
    let cookies=document.cookie.split('; ');
    for(let i=0;i<cookies.length;++i){
        let cookie=cookies[i].split('=');
        if(cookie[0]==="accesstoken" && token['AccessToken']==cookie[1]){
            return getFetch(headers,send);
        }
    }
    window.alert("ログインしていません。\nログインしてください");
    window.location.href='https://login.canteach.jp/?redirect_url='+encodeURI(window.location.href);
}
function getFetch(headers,body){
    return new Promise((resoleve,reject)=>{
        fetch(url,{
            method:'POST',
            headers:headers,
            body:body
        }).then(res=>res.json())
        .then(data=>{
            if(data['ResultInfo']['nonce']==headers['Canteach-Nonce']){
                return resoleve(data);
            }else{
                data['error']={
                    title:"Nonceエラー",
                    description:[["Nonceが一致しません。"]]
                };
                return reject(data);
            }
        }).catch((error)=>{
            return reject(error);
        });
    })
}