import { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { useParams} from "react-router-dom";
import Fetch from "../common/fetch";
import AlertBox from "../component/AlertBox";
import BreadCrumb from "../component/breadcrumb";
import CardList from "../component/CardList";
import { ConvertToDisplayDate, StudentCondition } from "../component/Keys";
import LineBtn from "../component/LineBtn";
import Syllabus from "../component/Syllabus";
import TextCarousel from "../component/TextCarousel";

export default function Course(){
    const {id}=useParams();
    const [BCrumb,setBCrumb]=useState({
        'CurrentText':'受講コース詳細',
        'items':[
            {
                'index':1,
                'href':'/student',
                'text':'ホーム'
            }
        ]
    });
    const [Student,setStudent]=useState({"display":false});
    const [Contact,setContact]=useState({"title":'新着情報','items':[]});
    const [Cancel,setCancel]=useState({'condition':true,'text':["本当に解約申請をしてもよろしいですか？","※既に支払われた受講料は返金されません。"]});
    const [Alert,setAlert]=useState({'type':'danger','style':false,'msgs':[]});
    const [Text,setText]=useState([]);
    const [Report,setReport]=useState({"title":'課題','items':[]});
    const [Examination,setExamination]=useState({"title":'テスト','items':[]});
	const [CourseSyllabus,setSyllabus]=useState(false);
    useEffect(()=>{
        let send={
            'url':'v1/student/course/'+id,
            'method':'GET'
        };
        Fetch(send).then(data=>{
			if(data['result']){
				setBCrumb({
					'CurrentText':data['payloads']['CourseName'],
					'items':[
						{
							'index':1,
							'href':'/student',
							'text':'ホーム'
						}
					]
				});
				if(data['payloads']['display']){
					setAlert({'type':'danger','style':false,'msgs':[]});
					let Item={
						'Student':data['payloads']
					};
					if(data['payloads']['condition']){
						setAlert({'type':'info','style':true,'msgs':["既に"+StudentCondition(data['payloads']['condition'])+"しています。"]});
					}
					setStudent(data['payloads']);
					let items=[];
					let vals=data['payloads']['contacts'];
					for(let i=0;i<vals.length;++i){
						items.push({'index':i,'href':'/student/course/'+id+'/info/'+vals[i]['ContactId'],'text':vals[i]['ContactTitle']});
					}
					setContact({"title":'新着情報','items':items});
					Item['Contact']=({"title":'新着情報','items':items});
					items=[];
					vals=data['payloads']['texts'];
					for(let i=0;i<vals.length;++i){
						items.push({'index':i,'icon':vals[i]['icon']['link'],'href':'/student/course/'+id+'/text/'+vals[i]['FolderId'],'text':vals[i]['FolderName']});
					}
					Item['Text']=items;
					setText(items);
					//ReactDOM.render(<TextCarousel Item={items} />,document.getElementById('text_area'));
					items=[];
					vals=data['payloads']['reports'];
					for(let i=0;i<vals.length;++i){
						items.push({'index':i,'href':'/student/course/'+id+'/report/'+vals[i]['ReportId'],'text':vals[i]['ReportTitle']});
					}
					Item['Report']={"title":'課題','items':items};
					setReport({"title":'課題','items':items});
					items=[];
					vals=data['payloads']['examinations'];
					for(let i=0;i<vals.length;++i){
						items.push({'index':i,'href':'/student/course/'+id+'/examination/'+vals[i]['ExaminationId'],'text':vals[i]['ExaminationTitle']});
					}
					Item['Examination']={"title":'テスト','items':items};
					setExamination({"title":'テスト','items':items});
					//ReactDOM.render(<StudentCourseDetail Item={Item} actionCancel={doCancel} />,document.getElementById('content_area'));
				}else{
					setAlert({'type':'danger','style':true,'msgs':["未決済の受講料が存在するか，講師によってページの閲覧が停止されています。"]});
				}
			}else{
				setAlert({'type':'danger','style':true,'description':data['error']['description']});
			}
        }).catch(error=>console.log(error));
    },[])
    //解約
    /*function doCancel(res){
		if(res){
			setAlert({'type':'success','style':true,'msgs':["解約申請をしました。"]});
			window.scroll({top:0});
		}else{
			setAlert({'type':'success','style':true,'msgs':["解約申請ができませんでした。"]});
		}
    }*/
    function doCancel(){
        let send={
            'url':'v1/student/course/'+id,
            'method':'DELETE'
        }
        Fetch(send).then(data=>{
            if(data['result']){
				setCancel({'condition':false,'text':["解約申請をしました。","講師が解約申請を受諾した場合，解約となります。"]});
				setAlert({'type':'success','style':true,'msgs':["解約申請をしました。"]});
				window.scroll({top:0});
			}else{
				setCancel({'type':'danger','style':true,'description':data['error']['description']});
				setAlert({'type':'success','style':true,'msgs':["解約申請ができませんでした。"]});
			}
        }).catch(error=>console.log(error));
    }
	function openPayments(){
		window.open('https://app.canteach.jp/account/payment/'+id,'payment_history','width=600px,height=900px');
	}
    return (
        <div>
            <BreadCrumb item={BCrumb} />
			<AlertBox Item={Alert} />{Student['display'] &&
			<div>
				<div class="row">
					<div class="col-12">
						<h2>
							{Student['CourseName']}
						</h2>
					</div>
				</div>
				<div className="row">
					<div className="col-12">
						{Text.length ? 
						<div id="text_area" className="my-4 p-5"><TextCarousel Item={Text} />
						</div>:
						<div className="alert alert-info">
							まだ教材が登録されていません。
						</div>}
					</div>
				</div>
				<div class="row">{
					(Student && Student['condition']==0 && CourseSyllabus && CourseSyllabus['ConnectLine']!="") ?<>
						{(CourseSyllabus['condition'] && CourseSyllabus['condition']['line'] && CourseSyllabus['condition']['line'][id])?'':
						<div class="col-sm-6 col-md-6 my-2">
							<Card className="w-100">
								<Card.Header>
									公式LINE連携
								</Card.Header>
								<Card.Body>
									連携していません。{CourseSyllabus['ConnectLine'] && 
									<LineBtn Link={CourseSyllabus['ConnectLine']+"&state="+id} target="_blank" Text="公式LINEと連携" />}
								</Card.Body>
							</Card>
						</div>}
					</>:""}
					<div class="col-sm-6 col-md-6 my-2">
						<CardList item={Contact} />
					</div>
					{/*<div class="col-sm-6 col-md-6 mb-2">
						<CardList item={Text} />
	</div>*/}
					<div class="col-sm-6 col-md-6 my-2">
					<CardList item={Report} />
					</div>
					<div class="col-sm-6 col-md-6 my-2">
					<CardList item={Examination} />
					</div>
				</div>
				<div class="row">
					<div class="col-sm-6 col-md-6 my-2">
						<div class="card w-100">
							<div class="card-header">
								成績
							</div>
							<div class="card-body">
								<div class="list-group list-group-flush">
									<a href="#" class="btn list-grpup-item list-group-item-action border-bottom" data-bs-toggle="modal" data-bs-target="#gradeReportModal">
										<div class="row">
											<div class="col-6">課題</div>
											<div class="col-6 text-end">{Student['grades']['reports']['summary']['score']}/{Student['grades']['reports']['summary']['total']}点</div>
										</div>
									</a>
									<a href="#" class="btn list-grpup-item list-group-item-action border-bottom" data-bs-toggle="modal" data-bs-target="#gradeExaminationModal">
										<div class="row">
											<div class="col-6">テスト</div>
											<div class="col-6 text-end">{Student['grades']['examinations']['summary']['score']}/{Student['grades']['examinations']['summary']['total']}点</div>
										</div>
									</a>
								</div>
							</div>
						</div>
					</div>
					<div class="col-sm-6 col-md-6 my-2">
						<div class="card w-100">
							<div class="card-header">
								その他
							</div>
							<div class="card-body">
								<div class="list-group list-group-flush">
									<a href="#" class="btn list-grpup-item list-group-item-action border-bottom" onClick={openPayments}>
										決済履歴
									</a>
									<a href="#" class="btn list-grpup-item list-group-item-action border-bottom" data-bs-toggle="modal" data-bs-target="#syllabusModal">
										シラバス
									</a>
									<a href="#" class="btn list-grpup-item list-group-item-action border-bottom" data-bs-toggle="modal" data-bs-target="#contractModal">
										契約内容
									</a>{(Student && Student['condition']==0) ?
									<a href="#" class="btn list-grpup-item list-group-item-action border-bottom" data-bs-toggle="modal" data-bs-target="#cancelModal">
										解約申請
									</a>:""}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="modal fade w-100" id="syllabusModal" tabindex="-1" aria-labelledby="syllabusModalLabel" aria-hidden="true">
					<div class="modal-dialog modal-xl modal-dialog-scrollable">
						<div class="modal-content">
						<div class="modal-header">
							<h5 class="modal-title" id="syllabusModalLabel">シラバス</h5>
							<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
						</div>
						<div class="modal-body">
							<Syllabus Id={Student['CourseId']} get={setSyllabus} />
						</div>
						</div>
					</div>
				</div>
				<div class="modal fade w-100" id="contractModal" tabindex="-1" aria-labelledby="contractModalLabel" aria-hidden="true">
					<div class="modal-dialog modal-xl modal-dialog-scrollable">
						<div class="modal-content">
						<div class="modal-header">
							<h5 class="modal-title" id="contractModalLabel">契約内容</h5>
							<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
						</div>
						<div class="modal-body">
							<div class="container">
								<div class="row">
									<div class="col-sm col-md">
										<h6>
											契約書
										</h6>
										<iframe src={Student['contracts']['file']['link']} className="iframe-file"></iframe>
									</div>
									<div class="col-sm-auto col-md-auto">
										<table class="table">
											<tbody>
												<tr>
													<th>
														同意日時
													</th>
													<td>
														{ConvertToDisplayDate(Student['contracts']['date'],false)}
													</td>
												</tr>
												<tr>
													<th>
														同意者名
													</th>
													<td>
														{Student['contracts']['name']}
													</td>
												</tr>
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>
						</div>
					</div>
				</div>
				<div class="modal fade w-100" id="cancelModal" tabindex="-1" aria-labelledby="cancelModalLabel" aria-hidden="true">
					<div class="modal-dialog modal-dialog-scrollable">
						<div class="modal-content">
						<div class="modal-header">
							<h5 class="modal-title" id="cancelModalLabel">受講コースの解約申請</h5>
							<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
						</div>
						<div class="modal-body">
							{Cancel.text.map(val=><p>{val}</p>)}
						</div>
						{Cancel.condition &&
						<div class="modal-footer">
							<button type="button" class="btn btn-secondary" onClick={doCancel}>解約申請</button>
							<button type="button" class="btn btn-primary" data-bs-dismiss="modal">やめる</button>
						</div>}
						</div>
					</div>
				</div>
				<div class="modal fade w-100" id="gradeReportModal" tabindex="-1" aria-labelledby="gradeReportModalLabel" aria-hidden="true">
					<div class="modal-dialog">
						<div class="modal-content">
						<div class="modal-header">
							<h5 class="modal-title" id="gradeReportModalLabel">課題の成績</h5>
							<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
						</div>
						<div class="modal-body">
							<table class="table table-stripe table-hover">
								<thead>
									<tr>
										<th>
											合計得点
										</th>
										<td>
										{Student['grades']['reports']['summary']['score']}/{Student['grades']['reports']['summary']['total']}点
										</td>
									</tr>
								</thead>
								<tbody>
									{Student['grades']['reports']['details'].map(grade=>
									<tr>
										<th>
											{grade.ReportTitle}
										</th>
										<td>
											{grade.submit?grade.score:'-'}/{grade.total}点
										</td>
									</tr>
									)}
								</tbody>
							</table>
						</div>
						</div>
					</div>
				</div>
				<div class="modal fade w-100" id="gradeExaminationModal" tabindex="-1" aria-labelledby="gradeExaminationModalLabel" aria-hidden="true">
					<div class="modal-dialog">
						<div class="modal-content">
						<div class="modal-header">
							<h5 class="modal-title" id="gradeExaminationModalLabel">テストの成績</h5>
							<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
						</div>
						<div class="modal-body">
							<table class="table table-stripe table-hover">
								<thead>
									<tr>
										<th>
											合計得点
										</th>
										<td>
										{Student['grades']['examinations']['summary']['score']}/{Student['grades']['examinations']['summary']['total']}点
										</td>
									</tr>
								</thead>
								<tbody>
									{Student['grades']['examinations']['details'].map(grade=>
									<tr>
										<th>
											{grade.ExaminationTitle}
										</th>
										<td>
											{grade.submit?grade.score:'-'}/{grade.total}点
										</td>
									</tr>
									)}
								</tbody>
							</table>
						</div>
						</div>
					</div>
				</div>
			</div>}
        </div>
    );
}