import { useEffect, useState } from "react";
import BreadCrumb from "../component/breadcrumb";
import Fetch from "../common/fetch";
import AlertBox from "../component/AlertBox";
import ProfileForm from "../component/ProfileForm";
import Loading from "../component/Loading";
import SendJson from "../component/SendJson";
import Upload from "../common/upload";
import { updateVariation } from "../../App";


let LoadingMsg=[];
let item={
    'UserName':"",
    'icon':'',
    'introduction':'',
    'qr':'',
    'hp':'',
    'HpPublic':false,
    'twitter':'',
    'TwitterPublic':false,
    'UserType':1,
    'plan':0,
    'payment':false,
    'paymentMethod':[],
    'funcs':["",""]
};
export default function AccountInit(){
    const [BCrumb,setBCrumb]=useState({'CurrentText':'プロフィール登録','items':[{'index':1,'href':'/','text':'ホーム'}]});
    const [Alert,setAlert]=useState({'type':'danger','style':false,'msgs':[]});
	const [Visible,setVisible]=useState(false);
	const [Msg,setMsg]=useState(LoadingMsg);
    const [Method,setMethod]=useState(false);
    const [Token,setToken]=useState(false);
    const [Item,setItem]=useState(false);
    useEffect(()=>{
        setVisible(true);
        let send={
            'url':'v1/teacher/payment',
            'method':'GET'
        }
        Fetch(send).then(data=>{
            if(data['result']){
                setAlert({'type':'danger','style':false,'msgs':[]});
                let PaymentMethod=data['payloads'];
                send['url']='v1/profile';
                setVisible(true);
                Fetch(send).then(data=>{
                    if(data['result']){
                        setAlert({'type':'danger','style':false,'msgs':[]});
                        let send={
                            'url':'v1/profile/'+data['payloads']['UserId'],
                            'method':'GET'
                        }
                        setVisible(true);
                        Fetch(send).then(data=>{
                            if(data['result']){
                                setAlert({'type':'danger','style':false,'msgs':[]});
                                if(data['payloads']['init']){
                                    LoadingMsg.push({type:'info',title:'リダイレクト中',content:"既に登録されているため，アカウントページにリダイレクトします",delay:5000});
                                    setMsg(LoadingMsg);
                                    setTimeout(()=>{
                                        window.location.href="/account";
                                    },1000)
                                }else{
                                    let newItem=data['payloads'];
                                    newItem['paymentMethod']=PaymentMethod;
                                    newItem['funcs']=[setMethod,setToken];
                                    setItem(newItem);
                                    setVisible(false);
                                    LoadingMsg=[];
                                }
                            }else{
                                setAlert({'type':'danger','style':true,'description':data['error']['description']});
                                setVisible(false);
                                LoadingMsg=[];
                            }
                        }).catch((error)=>{
                            LoadingMsg.push({type:'warning',title:'エラー',content:'通信エラーが発生しました'});
                            setMsg(LoadingMsg);
                            LoadingMsg=[];
                            setVisible(false);
                
                            console.log(error);
                        });
                    }else{
                        setAlert({'type':'danger','style':true,'description':data['error']['description']});
                        setVisible(false);
                        LoadingMsg=[];
                    }
                }).catch((error)=>{
                    LoadingMsg.push({type:'warning',title:'エラー',content:'通信エラーが発生しました'});
                    setMsg(LoadingMsg);
                    LoadingMsg=[];
                    setVisible(false);
        
                    console.log(error);
                });
            }else{
                setAlert({'type':'danger','style':true,'description':data['error']['description']});
            }
            setVisible(false);
            LoadingMsg=[];
        }).catch((error)=>{
            LoadingMsg.push({type:'warning',title:'エラー',content:'通信エラーが発生しました'});
            setMsg(LoadingMsg);
            LoadingMsg=[];
            setVisible(false);

            console.log(error);
        });
    },[])
    function doSubmit(){

		setVisible(true);
		LoadingMsg.push({type:'info',title:'確認中',content:'ファイルのアップロード確認中',delay:5000});
		setMsg(LoadingMsg);

        let {error,body}=SendJson(document.getElementsByClassName('js-data'),true);
        if(error.length){
            setAlert({'type':'danger','style':true,'msgs':error});

			LoadingMsg.push({type:'danger',title:'入力不備',content:'入力内容を確認してください',delay:5000});
			setMsg(LoadingMsg);
			setVisible(false);
            window.scroll({top:0});

            return;
        }else{
            let file=document.getElementsByClassName('js-data-file');
            let keys=[];
            let fd=new FormData();
            for(let i=0;i<file.length;++i){
                if(file[i].value){
                    keys.push(i);
                    fd.append('file[]',file[i].files[0]);
                }
            }
            if(keys.length){
                LoadingMsg.push({type:'info',title:'アップロード中',content:'ファイルをアップロード中',delay:5000});
                setMsg(LoadingMsg);
                Upload(fd).then(data=>{
                    if(data['result']){
                        LoadingMsg.push({type:'success',title:'アップロード完了',content:"ファイルをアップロードしました",delay:5000});
                        setMsg(LoadingMsg);
                        let files=["",""];
                        for(let i=0;i<keys.length;++i){
                            files[keys[i]]=data['payloads']['FileIds'][i];
                        }
                        doProfileSubmit(files,body);
                    }else{

                        LoadingMsg.push({type:'danger',title:'エラー',content:'入力内容が保存できませんでした',delay:5000});
                        setMsg(LoadingMsg);
                        setVisible(false);
                        window.scroll({top:0});
        
                        setAlert({'type':'danger','style':true,'description':data['error']['description']});
                    }
                }).catch(error=>{
                    console.log(error)
        
                    LoadingMsg.push({type:'warning',title:'エラー',content:'通信エラーが発生しました'});
                    setMsg(LoadingMsg);
                    setVisible(false);
                    window.scroll({top:0});
                });
            }else{
                doProfileSubmit(['',''],body);
            }
        }
    }
    function doProfileSubmit(files,body){
		LoadingMsg.push({type:'info',title:'確認中',content:'入力内容を確認しています',delay:5000});
		setMsg(LoadingMsg);

        body['icon']=files[0];
        body['qr']=files[1];
        body['PlanPayments']={
            method:Method,
            StripeToken:Token
        };
        let send={
            'url':'v1/profile',
            'method':'POST',
            'body':body
        }
			
        LoadingMsg.push({type:'info',title:'送信中',content:'入力内容を送信しています',delay:5000});
        setMsg(LoadingMsg);

        Fetch(send).then(data=>{
            if(data['result']){

                LoadingMsg.push({type:'success',title:'送信完了',content:"入力内容を送信しました",delay:5000});
                setMsg(LoadingMsg);

                if(data['payloads']){
                    if(data['payloads']['approve']){
                        LoadingMsg.push({type:'info',title:'決済連携のお願い',content:"決済を連携するために新しいウインドウが開きます",delay:5000});
                        setMsg(LoadingMsg);
                        setTimeout(() => {
                            window.open(data['payloads']['approve'],'account_window','width=600px,height=600px')
                        }, 1000);
                    }
                }
                LoadingMsg.push({type:'info',title:'リダイレクト中',content:"アカウントページにリダイレクトします",delay:5000});
                setMsg(LoadingMsg);
                setTimeout(()=>{
                    window.location.href="/account";
                },2000)
                setAlert({'type':'success','style':true,'msgs':["プロフィールを変更しました。"]});
                updateVariation('Profile');
            }else{

                LoadingMsg.push({type:'danger',title:'エラー',content:'入力内容が保存できませんでした',delay:5000});
                setMsg(LoadingMsg);
                setVisible(false);

                setAlert({'type':'danger','style':true,'description':data['error']['description']});
            }
        }).catch(error=>{
            console.log(error)

            LoadingMsg.push({type:'warning',title:'エラー',content:'通信エラーが発生しました'});
            setMsg(LoadingMsg);
            setVisible(false);
        });
    }
    return(
        <div>
            <BreadCrumb item={BCrumb} />
            <AlertBox Item={Alert} />
            <Loading visible={Visible} msgs={Msg} />
            {Item?<ProfileForm Item={Item} Submit={doSubmit} edit={true} />:''}
        </div>
    )
}