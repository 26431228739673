import { useEffect, useState } from "react";
//import RainbowPatissierLiveEditor from "../../lib/RainbowPatissierLiveEditor/RainbowPatissierLiveEditor";
import RainbowLiveEditor from "../../lib/LiCSSsLiveEditor/LiCSSsLiveEditor";
import SendJson from "../component/SendJson";
import EmailVar from "./EmailVar";
import Fetch from "../common/fetch";


let RegularBackground=ConvertGradation('linear-gradient(to bottom,#09a603 50%,#09a603)');
let HoverBackground=ConvertGradation('linear-gradient(to bottom,#09a603 50%,#09a603)');
export default function ProjectForm(props){
    const [item,setItem]=useState();
    useEffect(()=>{
        if(props.Item===undefined){
            let send={
                'url':'v1/admin/template/serma-reply',
                'method':'GET'
            }
            Fetch(send).then(data=>{
                if(data['result']){
                    let init={
                        "ProjectId":"PROJECTID",
                        "ProjectName": "",
                        "TeacherName": "",
                        "email": "",
                        "name":true,
                        "thanks":"",
                        "btns": {
                            "text": "申し込む",
                            "RegularStyle": {
                                "background": "linear-gradient(to bottom,#09a603 50%,#09a603)",
                                "border": "1px #09a603 solid",
                                "border-radius": "5px",
                                "color": "#fff",
                                "font-size": "2em",
                                "opacity": 1,
                                "padding": ".25em .75em",
                                "transition": "all .3s"
                            },
                            "HoverStyle": {
                                "background": "linear-gradient(to bottom,#09a603 50%,#09a603)",
                                "border": "1px #09a603 solid",
                                "border-radius": "5px",
                                "color": "#fff",
                                "font-size": "2em",
                                "opacity": 0.75,
                                "padding": ".25em .75em",
                                "transition": "all .3s"
                            }
                        },
                        "LineUrl": "",
                        "reply": data['payloads']['contents']
                    };
                    setItem(init);
                    setVars(init);
                }else{
                    setItem(false);
                }
            }).catch(error=>{
                setItem(false);
                console.log(error);
            });
        }else{
            setItem(props.Item);
            setVars(props.Item);
        }
    },[])
    function setVars(Item){
        setBtnText(Item['btns']['text'])
        setBtnStyle(Item['btns']['RegularStyle']);
        setEmailBody(Item['reply']['body']);
        RegularBackground=ConvertGradation(Item['btns']['RegularStyle']['background']);
        HoverBackground=ConvertGradation(Item['btns']['HoverStyle']['background']);
    }
    const [BtnText,setBtnText]=useState();
    const [BtnStyle,setBtnStyle]=useState({});
    const [RegularGradation,setRegularGradation]=useState({display:'none'});
    const [HoverGradation,setHoverGradation]=useState({display:'none'});
    const [EmailBody,setEmailBody]=useState();
    /*const RegularBackground=ConvertGradation(item['btns']['RegularStyle']['background']);
    const HoverBackground=ConvertGradation(item['btns']['HoverStyle']['background']);*/
    function changeRegularGradation(){
        if(document.getElementById('RegularGradation').value!=0){
            setRegularGradation({display:'flex'});
        }else{
            setRegularGradation({display:'none'});
        }
        changeRegularStyle();
    }
    function changeHoverGradation(){
        if(document.getElementById('HoverGradation').value!=0){
            setHoverGradation({display:'flex'});
        }else{
            setHoverGradation({display:'none'});
        }
        changeHoverStyle();
    }
    function changeRegularStyle(){
        let style=document.getElementById('btn_RegularStyle');
        let NewStyle=JSON.parse(style.value);
        let gradation=document.getElementById('RegularGradation').value;
        if(gradation==0){
            gradation='to bottom';
            NewStyle['background']="linear-gradient("+gradation+","+document.getElementById('RegularColor').value+" "+document.getElementById('RegularPosition').value+"%,"+document.getElementById('RegularColor').value+")";
        }else{
            NewStyle['background']="linear-gradient("+gradation+","+document.getElementById('RegularColor').value+" "+document.getElementById('RegularPosition').value+"%,"+document.getElementById('RegularToColor').value+")";
        }
        style.value=JSON.stringify(NewStyle,null,"\t");
        setBtnStyle(NewStyle);
    }
    function changeHoverStyle(){
        let style=document.getElementById('btn_HoverStyle');
        let NewStyle=JSON.parse(style.value);
        let gradation=document.getElementById('HoverGradation').value;
        if(gradation==0){
            gradation='to bottom';
            NewStyle['background']="linear-gradient("+gradation+","+document.getElementById('HoverColor').value+" "+document.getElementById('HoverPosition').value+"%,"+document.getElementById('HoverColor').value+")";
        }else{
            NewStyle['background']="linear-gradient("+gradation+","+document.getElementById('HoverColor').value+" "+document.getElementById('HoverPosition').value+"%,"+document.getElementById('HoverToColor').value+")";
        }
        style.value=JSON.stringify(NewStyle,null,"\t");
    }
    function Hover(){
        setBtnStyle(JSON.parse(document.getElementById('btn_HoverStyle').value));
    }
    function Leave(){
        setBtnStyle(JSON.parse(document.getElementById('btn_RegularStyle').value));
    }
    function doEntry(){
        let parent=document.getElementById('ProjectForm');
        let {error,body}=SendJson(parent.getElementsByClassName('js-data'),true);
        if(error.length){
            if(props.funcs[0]){
                props.funcs[0]({'type':'danger','style':true,'msgs':error});
            }
            window.scroll({top:0});
            return;
        }else{
            body['btns']={
                "text":document.getElementById('btn_text').value,
                "RegularStyle":JSON.parse(document.getElementById('btn_RegularStyle').value),
                "HoverStyle":JSON.parse(document.getElementById('btn_HoverStyle').value)
            }
            body['reply']={
                subject:document.getElementById('ReplySubject').value,
                body:EmailBody
            };
            props.Submit(body);
        }
    }
    return(
        <>{item ? <div id="ProjectForm">
            <div className="row my-2">
                <label className="col-sm-2 col-form-label">
                    プロジェクト名
                </label>
                <div className="col-sm-10">
                    <input type="text" name="ProjectName" className="form-control js-data" placeholder="プロジェクト名" defaultValue={item['ProjectName']} required />
                </div>
            </div>
            <div className="row my-2">
                <label className="col-sm-2 col-form-label">
                    講師名
                </label>
                <div className="col-sm-10">
                    <input type="text" name="TeacherName" className="form-control js-data" placeholder="講師名" defaultValue={item['TeacherName']} required />
                </div>
            </div>
            <div className="row my-2">
                <label className="col-sm-2 col-form-label">
                    メールアドレス
                </label>
                <div className="col-sm-10">
                    <input type="email" name="email" className="form-control js-data" placeholder="メールアドレス" defaultValue={item['email']} required />
                </div>
            </div>
            <div className="row my-2">
                <label className="col-sm-2 col-form-label">
                    申込者名の収集
                </label>
                <div className="col-sm-10">
                    <select name="name" className="form-select js-data" defaultValue={item['name']} data-type="boolean" required>
                        <option value={true}>収集する</option>
                        <option value={false}>収集しない</option>
                    </select>
                </div>
            </div>
            <div className="row my-2">
                <label className="col-sm-2 col-form-label">
                    サンクスページ
                </label>
                <div className="col-sm-10">
                    <input type="url" name="thanks" className="form-control js-data" placeholder="サンクスページ" defaultValue={item['thanks']}/>
                </div>
            </div>
            <div className="row my-2">
                <label className="col-sm-2 col-form-label">
                    公式LINEURL
                </label>
                <div className="col-sm-10">
                    <input type="url" name="LineUrl" className="form-control js-data" placeholder="公式LINEURL" defaultValue={item['LineUrl']} />
                </div>
            </div>
            <div className="row my-2">
                <label className="col-sm-2 col-form-label">
                    申し込みボタン
                </label>
                <div className="col-sm">
                    <div className="input-group">
                        <span className="input-group-text">
                            ボタンテキスト
                        </span>
                        <input type="text" name="text" id="btn_text" className="form-control js-data-btns" placeholder="ボタンテキスト" defaultValue={item['btns']['text']} onChange={e=>setBtnText(e.target.value)} required />
                    </div>
                    <div>
                        基本スタイル
                        <div className="input-group mt-2">
                            <span className="input-group-text">
                                背景カラー
                            </span>
                            <input type="color" id="RegularColor" className="form-control form-control-color" defaultValue={RegularBackground[1][0]} onChange={changeRegularStyle} required />
                            <span className="input-group-text">
                                グラデーション
                            </span>
                            <select id="RegularGradation" className="form-select" defaultValue={RegularBackground[0]} onChange={changeRegularGradation}>
                                <option value={0}>なし</option>
                                <option value={"to bottom"}>上から下</option>
                                <option value={"to right"}>左から右</option>
                            </select>
                        </div>
                        <div className="input-group mt-2" style={RegularGradation}>
                            <span className="input-group-text">
                                グラデーション設定
                            </span>
                            <span className="input-group-text">
                                位置
                            </span>
                            <input type="number" id="RegularPosition" className="form-control" min={0} max={100} defaultValue={RegularBackground[1][1]} onChange={changeRegularStyle} />
                            <span className="input-group-text">
                                %
                            </span>
                            <span className="input-group-text">
                                グラデーションカラー
                            </span>
                            <input type="color" id="RegularToColor" className="form-control form-control-color" defaultValue={RegularBackground[2]} onChange={changeRegularStyle} />
                        </div>
                        <div className="input-group mt-2">
                            <span className="input-group-text">
                                スタイル詳細設定
                            </span>
                            <textarea name="RegularStyle" id="btn_RegularStyle" className="form-control js-data-btns" style={{height:'100px'}} defaultValue={JSON.stringify(item['btns']['RegularStyle'],null,"\t")} onChange={e=>setBtnStyle(JSON.parse(e.target.value))} />
                        </div>
                    </div>
                    <div>
                        マウスオーバースタイル
                        <div className="input-group mt-2">
                            <span className="input-group-text">
                                背景カラー
                            </span>
                            <input type="color" id="HoverColor" className="form-control form-control-color" defaultValue={HoverBackground[1][0]} onChange={changeHoverStyle} required />
                            <span className="input-group-text">
                                グラデーション
                            </span>
                            <select id="HoverGradation" className="form-select" defaultValue={HoverBackground[0]} onChange={changeHoverGradation}>
                                <option value={0}>なし</option>
                                <option value={"to bottom"}>上から下</option>
                                <option value={"to right"}>左から右</option>
                            </select>
                        </div>
                        <div className="input-group mt-2" style={HoverGradation}>
                            <span className="input-group-text">
                                グラデーション設定
                            </span>
                            <span className="input-group-text">
                                位置
                            </span>
                            <input type="number" id="HoverPosition" className="form-control" min={0} max={100} defaultValue={HoverBackground[1][1]} onChange={changeHoverStyle} />
                            <span className="input-group-text">
                                %
                            </span>
                            <span className="input-group-text">
                                グラデーションカラー
                            </span>
                            <input type="color" id="HoverToColor" className="form-control form-control-color" defaultValue={HoverBackground[2]} onChange={changeHoverStyle} />
                        </div>
                        <div className="input-group mt-2">
                            <span className="input-group-text">
                                スタイル詳細設定
                            </span>
                            <textarea name="HoverStyle" id="btn_HoverStyle" className="form-control js-data-btns" style={{height:'100px'}} defaultValue={JSON.stringify(item['btns']['HoverStyle'],null,"\t")} onChange={e=>setBtnStyle(JSON.parse(e.target.value))} />
                        </div>
                    </div>
                </div>
                <div className="col-sm-auto">
                    <button type="button" className="serma-apply-btn" style={BtnStyle} onMouseOver={Hover} onMouseLeave={Leave}>{BtnText}</button>
                </div>
            </div>
            <div className="row my-2">
                <label className="col-sm-2 col-form-label">
                    自動返信メール
                </label>
                <div className="col-sm-10">
                    <div className="input-group">
                        <span className="input-group-text">
                            件名
                        </span>
                        <input type="text" id="ReplySubject" name="subject" className="form-control js-data-reply" placeholder="件名" defaultValue={item['reply']['subject']} required />
                    </div>
                    <div className="mt-2">
                        <div>
                            本文
                        </div>
                        <RainbowLiveEditor defaultValue={item['reply']['body']} setContent={setEmailBody} />
                        <EmailVar />
                    </div>{/*
                        <RainbowPatissierLiveEditor defaultValue={item['reply']['body']} setContent={setEmailBody} />
                    <div className="input-group mt-2">
                        <span className="input-group-text">
                            本文
                        </span>
                        <textarea name="text" className="form-control js-data-reply" placeholder="本文" defaultValue={item['reply']['text']} required />
                    </div>
                    <div className="mt-2">
                        HTML本文(任意)
                    <RainbowPatissierEditor />
                    </div>*/}
                </div>
            </div>
            <div className="row my-2">
                <div className="col-sm-2"></div>
                <div className="col">
                    {/*<RichEditor Item={item['reply']['html']} />*/}
                </div>
            </div>
            <div className="row my-2">
                <div className="col">
                    <button type="button" className="btn btn-primary" onClick={doEntry}>登録</button>{props.Delete &&
                    <button type="button" className="btn btn-danger ms-2" onClick={props.Delete}>削除</button>
                    }
                </div>
            </div>
        </div>:""}</>
    );
}
function ConvertGradation(key){
    let style=key.substr(16).replace(')','').replace('%','').split(",");
    style[1]=style[1].split(' ');
    if(style[1][0]==style[2]){
        style[0]=0;
    }
    return style;
}