import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Fetch from "../common/fetch";

export default function User(){
    const {id}=useParams();
    const [Visible,setVisible]=useState(true);
    const [Profile,setProfile]=useState(false);
    useEffect(()=>{
        let send={
            url:'v1/getProfile/U'+id,
            method:'GET'
        }
        Fetch(send).then(data=>{
            if(data['result']){
                setProfile(data['payloads']);
            }
            setVisible(false);
        }).catch(error=>{
            setVisible(false);
            console.log(error);
        })
    },[])
    return (
        <div>{Profile ?
            <>
                <h3>
                    {Profile['UserName']}
                </h3>
                <UserProfile Profile={Profile} />
            </>:""
        }</div>
    );
}
export function UserProfile({Profile}){
    return (
        <>
            <img src={Profile['icon']['link']} className="files__icon-lg" />
            <div>
                {Profile['introduction']}
            </div>
            <div>
                ホームページ：{(Profile['hp']['public'] && Profile['hp']['link']) ?
                <a href={Profile['hp']['link']} target="_blank">
                    {Profile['hp']['link']}
                </a>:"非公開"}
            </div>
            <div>
                Twitter：{(Profile['twitter']['public'] && Profile['twitter']['username']) ?
                <a href={"https://twitter.com/"+Profile['twitter']['username']} target="_blank">
                    @{Profile['twitter']['username']}
                </a>:"非公開"}
            </div>
        </>
    );
}