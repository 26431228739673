import React from 'react';
import { Route ,Switch,Redirect} from 'react-router-dom';
import Account from './Account';
import Admin from './Admin';
import Error from './common/error';
import Home from './Home';
import Lp from './Lp';
import ProfileTeacher from './profile/teacher';
import User from './profile/user';
import Serma from './Serma';
import Student from './Student';
import Teacher from './Teacher';

export default function Logged(props){
    return(
        <Switch>
            <Route path="/" exact>
                <Home Render={props.Render} />
            </Route>
            <Route path="/teacher">
                <Teacher Render={props.Render} Update={props.Update} Teacher={props.Teacher} />
            </Route>
            <Route path="/student">
                <Student Render={props.Render} />
            </Route>
            <Route path="/account">
                <Account Render={props.Render} />
            </Route>
            <Route path="/serma">
                <Serma Render={props.Render} />
            </Route>
            <Route path="/lp">
                <Lp />
            </Route>
            <Route path="/admin">
                <Admin />
            </Route>
            <Route path="/profile/teacher/:id" exact>
                <ProfileTeacher />
            </Route>
            <Route path="/profile/:id" exact>
                <User />
            </Route>
            <Route path="/">
                <Error error={404} />
            </Route>
        </Switch>
    );
}