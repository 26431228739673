import ReactDOM from "react-dom";
import { useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { CardCvcElement, CardExpiryElement, CardNumberElement, Elements, useElements, useStripe } from "@stripe/react-stripe-js";
import AlertBox from "./AlertBox";
import Loading from "./Loading";
//https://arrown-blog.com/php-laravel-stripe-creditcard2/
let stripePromise;
export default function Stripe({PublicKey,setToken}){
    stripePromise=loadStripe(PublicKey);
    return(
        <>{PublicKey?
            <Elements stripe={stripePromise}>
                <StripeElement setToken={setToken} />
            </Elements>:
        ''}
        </>
    );
}
function StripeElement({setToken}){
    const stripe=useStripe();
    const elements=useElements();

    const [Alert,setAlert]=useState({'type':'danger','style':false,'msgs':[]});
    const [Card,setCard]=useState({cardNumber:false,cardExpiry:false,cardCvc:false});//cardNumber,expiry,cvc
    const [Errors,setErrors]=useState({cardNumber:'',cardExpiry:'',cardCvc:''});
	const [Visible,setVisible]=useState(false);

    function displayCardError(e){
        changeError(e);
    }
    function displayExpiryError(e){
        changeError(e);
    }
    function displayCvcError(e){
        changeError(e);
    }
    function checkCard(card){
        setCard(card);
        if(!card['cardNumber']){
            elements.getElement('cardNumber').focus();
        }else if(!card['cardExpiry']){
            elements.getElement('cardExpiry').focus();
        }else if(!card['cardCvc']){
            elements.getElement('cardCvc').focus();
        }else if(card['cardNumber'] && card['cardExpiry'] && card['cardCvc']){
            doEntry();
        }
    }
    function changeError(e){
        let error={};
        let card={};
        error[e.elementType]=e.error?e.error.message:false;
        card[e.elementType]=!e.error;
        if(e.complete){
            checkCard(Object.assign(Card,card));
            setErrors(Object.assign(Errors,error));
        }else{
            checkCard(Object.assign(Card,card));
            error=Object.assign(Errors,error);
            setErrors(error);
        }
        let check=(error['cardNumber'] || error['cardExpiry'] || error['CardCvc']);
        setAlert({'type':'danger','style':true,'msgs':check?[Errors['cardNumber'],Errors['cardExpiry'],Errors['cardCvc']]:[""]});
        setToken(false);
    }
    function doEntry(){
        setVisible(true);
        if(!stripe || !elements){
            setAlert({'type':'danger','style':true,'msgs':['エラーが発生しました。']});
            setToken(false);
            setVisible(false);
        }else{
            /*stripe.createSource(elements.getElement('cardNumber'),{
                type:'card',
                currency:'jpy'
            })*/
            stripe.createPaymentMethod({
                type:'card',
                card:elements.getElement('cardNumber')
            }).then(result=>{
                if(result.error){
                    setAlert({'type':'danger','style':true,'msgs':[result.error.message]});
                }else{
                    setAlert({'type':'success','style':true,'msgs':["カードを利用できます。"]});
                    //setToken(result.source.id);
                    setToken(result.paymentMethod.id);
                }
                setVisible(false);
            }).catch(error=>{
                setAlert({'type':'danger','style':true,'msgs':['エラーが発生しました。']});
                console.log(error)
                setToken(false);
                setVisible(false);
            });
        }
    }
    return(
        <form id="StripeForm" className="my-2">
            <Loading visible={Visible} msgs={[]} />
            <div className="input-group mb-2">
                <span className="input-group-text">カード番号</span>
                <CardNumberElement id="card" className="form-control" onChange={displayCardError} options={{showIcon:true,iconStyle:'default'}} />
            </div>
            <div className="input-group my-2">
                <span className="input-group-text">有効期限</span>
                <CardExpiryElement className="form-control" onChange={displayExpiryError} />
            </div>
            <div className="input-group my-2">
                <span className="input-group-text">セキュリティーコード</span>
                <CardCvcElement className="form-control" onChange={displayCvcError} />
            </div>{/*
            <div className="my-2">
                <label>
                    <input type="checkbox" name="StripeRemember" className="form-check-input js-data" value="true" data-type="boolean" />
                    カード情報を紐づける
                </label>
            </div>*/}
            <div className="mt-2">
                カード情報は<a href="https://stripe.com" target="_blank">Stripe</a>に送信され，CanTeachのサーバーには送信されません。<br/>
                支払いのため，カード情報はStripeに保存されます。
            </div>
            <AlertBox Item={Alert} />
        </form>
    );
}
/*<Elements stripe={props.stripe}>
            <CardElement
  options={{
    style: {
      base: {
        fontSize: '16px',
        color: '#424770',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#9e2146',
      },
    },
  }}
/>
//import {CardElement, CardCvcElement, CardExpiryElement, CardNumberElement, Elements } from "@stripe/react-stripe-js";
//import { loadStripe } from "@stripe/stripe-js";
        </Elements>
            <CardNumberElement />
            <CardExpiryElement />
            <CardCvcElement />*/
function Rendering(){
    return(
        <div className="my-2">
            <div className="input-group my-1">
            <span className="input-group-text">
                名前
            </span>
            <input type="text" className="form-control js-data" placeholder="名前" required />
            </div>
            <div className="input-group my-1">
            <span className="input-group-text">
                メールアドレス
            </span>
            <input type="email" className="form-control js-data" placeholder="メールアドレス" required />
            </div>
            <div className="input-group my-1">
            <span className="input-group-text">
                カード番号
            </span>
            <input type="number" className="form-control js-data" placeholder="1234 1234 1234 1234" required />
            </div>
            <div className="input-group my-1">
            <span className="input-group-text">
                セキュリティコード
            </span>
            <input type="number" className="form-control js-data" placeholder="セキュリティコード" required />
            </div>
            <div className="input-group my-1">
            <span className="input-group-text">
                有効期限
            </span>
            <input type="text" className="form-control js-data" placeholder="月 / 年" required />
            </div>
            <p>
            カード情報は<a href="https://strip.com" target="_blank">Stripe</a>に送信され，決済されます。<br />
            ※CanTeachがカード情報を保有することはありません。
            </p>
        </div>
    );
}