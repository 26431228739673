import { useEffect, useState } from "react";
import BreadCrumb from "../component/breadcrumb";
import Fetch from "../common/fetch";
import AlertBox from "../component/AlertBox";
import SendJson from "../component/SendJson";
import Loading from "../component/Loading";
import AccountForm from "../component/AccountForm";

export default function Payments(){
    const BCrumb={'CurrentText':'支払い設定','items':[{'index':1,'href':'/admin','text':'ホーム'}]};
    const [Item,setItem]=useState([false,{}]);
    const [Alert,setAlert]=useState({'type':'danger','style':false,'msgs':[]});
    const [Visible,setVisible]=useState(false);
    useEffect(()=>{
        setVisible(true);
        let send={
            'url':'v1/admin/account',
            'method':'GET'
        }
        Fetch(send).then(data=>{
            if(data['result']){
                setAlert({'type':'danger','style':false,'msgs':[]});
                setItem([true,data['payloads']]);
            }else{
                setAlert({'type':'danger','style':true,'description':data['error']['description']});
            }
            setVisible(false);
        }).catch(error=>{
            console.log(error);
            setVisible(false);
        });
    },[])
    function doEntry(send){
        let SendBody={};
        let AllError=[];
        //stripe
        var {error,body}=SendJson(document.getElementById('AdminAccountForm').getElementsByClassName('js-data-stripe'),true);
        if(error.length){
            AllError=AllError.concat(error);
        }else{
            SendBody=Object.assign(SendBody,{'stripes':body});
        }
        //paypay
        var {error,body}=SendJson(document.getElementById('AdminAccountForm').getElementsByClassName('js-data-paypay'),true);
        if(error.length){
            AllError=AllError.concat(error);
        }else{
            SendBody=Object.assign(SendBody,{'paypays':body});
        }
        //paypal
        var {error,body}=SendJson(document.getElementById('AdminAccountForm').getElementsByClassName('js-data-paypal'),true);
        if(error.length){
            AllError=AllError.concat(error);
        }else{
            SendBody=Object.assign(SendBody,{'paypals':body});
        }

        if(AllError.length){
            setAlert({'type':'danger','style':true,'msgs':AllError});
            return;
        }else{
            let send={
                'url':'v1/admin/account',
                'method':'POST',
                'body':SendBody
            }
            Fetch(send).then(data=>{
                if(data['result']){
                    setAlert({'type':'success','style':true,'msgs':["支払い方法を登録しました。"]});
                }else{
                    setAlert({'type':'danger','style':true,'description':data['error']['description']});
                }
            }).catch(error=>console.log(error));
        }
    }
    return(
        <div>
            <BreadCrumb item={BCrumb} />
            <AlertBox Item={Alert} />
            <Loading visible={Visible} msgs={[]} />
            <div>
                {Item[0] && 
                <form id="AdminAccountForm">
                    <AccountForm Item={Item[1]} Submit={doEntry} type="admin" />{/*
                    <table className="table table-striped table-hover">
                        <tbody>
                            <tr>
                                <th>
                                    Stripe
                                </th>
                                <td>
                                    <div className="my-2 input-group">
                                    <span className="input-group-text">手動決済</span>
                                    <select name="manual" className="form-select js-data-stripe" defaultValue={Item[1]['stripes']['manual']} data-type="boolean" required>
                                        <option value={true}>許可する</option>
                                        <option value={false}>許可しない</option>
                                    </select>
                                    </div>
                                    <div className="my-2 input-group">
                                    <span className="input-group-text">
                                        公開APIキー
                                    </span>
                                    <input type="text" name="api" className="form-control js-data-stripe" placeholder="公開APIキー" defaultValue={Item[1]['stripes']['api']} required />
                                    </div>
                                    <div className="my-2 input-group">
                                    <span className="input-group-text">
                                        シークレットキー
                                    </span>
                                    <input type="text" name="secret" className="form-control js-data-stripe" placeholder="シークレットキー" defaultValue={Item[1]['stripes']['secret']}  required />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                            <th>
                                PayPay
                            </th>
                            <td>
                                <div className="my-2 input-group">
                                <span className="input-group-text">手動決済</span>
                                <select name="manual" className="form-select js-data-paypay" defaultValue={Item[1]['paypays']['manual']} data-type="boolean" required>
                                    <option value={true}>許可する</option>
                                    <option value={false}>許可しない</option>
                                </select>
                                </div>
                                <div className="my-2 input-group">
                                <span className="input-group-text">
                                    APIキー
                                </span>
                                <input type="text" name="api" className="form-control js-data-paypay" placeholder="APIキー" defaultValue={Item[1]['paypays']['api']}  required />
                                </div>
                                <div className="my-2 input-group">
                                <span className="input-group-text">
                                    シークレットキー
                                </span>
                                <input type="text" name="secret" className="form-control js-data-paypay" placeholder="シークレットキー" defaultValue={Item[1]['paypays']['secret']} required />
                                </div>
                                <div className="my-2 input-group">
                                <span className="input-group-text">
                                    マーチャントID
                                </span>
                                <input type="text" name="merchant" className="form-control js-data-paypay" placeholder="マーチャントID" defaultValue={Item[1]['paypays']['merchant']} required />
                                </div>
                                <div className="card">
                                <div className="card-header">
                                    PayPay for Developersでの設定
                                </div>
                                <div className="card-body">
                                    <ol>
                                    <li>
                                        <a href="https://developer.paypay.ne.jp/settings" target="_blank">PayPay for Developers \ 構成</a>に移動
                                    </li>
                                    <li>
                                        コールバックドメインを<input type="text" defaultValue="canteach.jp" onfocus="this.selct()" readOnly />に設定
                                    </li>
                                    <li>
                                        突合ファイル通知Webhookを<input type="text" defaultValue="https://peipay:ausupWd@canteach.jp/data/hook/webhook/file/" onfocus="this.selct()" readOnly />に設定
                                    </li>
                                    <li>
                                        カスタマーイベント通知Webhookを<input type="text" defaultValue="https://peipay:ausupWd@canteach.jp/data/hook/webhook/customer/" onfocus="this.selct()" readOnly />に設定
                                    </li>
                                    <li>
                                        決済トランザクション通知Webhookを<input type="text" defaultValue="https://peipay:ausupWd@canteach.jp/data/hook/webhook/transaction/" onfocus="this.selct()" readOnly />に設定
                                    </li>
                                    </ol>
                                </div>
                                </div>
                            </td>
                            </tr>
                            <tr>
                            <th>
                                PayPal
                            </th>
                            <td>
                                <div className="my-2 input-group">
                                <span className="input-group-text">
                                    APIキー
                                </span>
                                <input type="text" name="api" className="form-control js-data-paypal" placeholder="APIキー" defaultValue={Item[1]['paypals']['api']} required />
                                </div>
                                <div className="my-2 input-group">
                                <span className="input-group-text">
                                    シークレットキー
                                </span>
                                <input type="text" name="secret" className="form-control js-data-paypal" placeholder="シークレットキー" defaultValue={Item[1]['paypals']['secret']} required />
                                </div>
                                <div className="my-2 input-group">
                                <span className="input-group-text">
                                    マーチャントID
                                </span>
                                <input type="text" name="merchant" className="form-control js-data-paypal" placeholder="マーチャントID" defaultValue={Item[1]['paypals']['merchant']} required />
                                </div>
                            </td>
                            </tr>
                        </tbody>
                    </table>
                    <button type="button" className="btn btn-primary" onClick={doEntry}>登録</button>*/}
                </form>
                }
            </div>
        </div>
    )
}