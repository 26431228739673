import ReactDOM from 'react-dom';

export default function UploadFile(props){
    return (
        <div id={"file_wrapper_"+props.Id} className="list-group list-group-flush js-data-file-wrapper">
            <div className="list-group-item js-data-file-item">
                <AddFile Add="true" Parent={document.getElementById('file_wrapper_'+props.Id)} />
            </div>
        </div>);
}
export function AddFile(props){
    function addFile(e){
        let files=e.target.files;
        const parent=props.Parent;
        for(let i=0;i<files.length;++i){
            const div=document.createElement('div');
            div.setAttribute('class','list-group-item js-data-file-item');
            parent.appendChild(div);
            ReactDOM.render(<AddFile Add="false" Parent={props.Parent} />,div);
            const dt=new DataTransfer();
            dt.items.add(files[i]);
            const file=div.getElementsByClassName('js-file')[0];
            file.files=dt.files;
            file.dataset.name=files[i].name;
            div.getElementsByClassName('js-file-name')[0].value=files[i].name;
        }
        if(e.target.dataset.add=='false'){
            e.target.parentNode.parentNode.remove();
        }else{
            e.target.value="";
        }
    }
    function removeFile(e){
        e.target.parentNode.parentNode.remove();
    }
    function changeName(e){
        e.target.parentNode.getElementsByClassName('js-file')[0].dataset.name=e.target.value;
    }
    if(props.Add=='true'){
        var style={display:'none'};
    }else{
        var style={};
    }
    return(
        <div className="input-group">
            <input type="file" className="form-control js-file" data-add={props.Add} data-name="" onChange={addFile} multiple/>
            <span className="input-group-text">ファイル名</span>
            <input type="text" className="form-control js-file-name" placeholder="ファイル名" onChange={changeName} />
            <input type="hidden" name="answers" className="js-data-answer js-data-file" />
            <button type="button" className="btn btn-danger input-group-text text-decoration-none" onClick={removeFile} style={style}>削除</button>
        </div>
    );
}