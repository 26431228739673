import BreadCrumb from "../component/breadcrumb";

import AlertBox from "../component/AlertBox";
import Loading from "../component/Loading";
import { useEffect, useState } from "react";
import { Redirect, useParams } from "react-router-dom";
import Syllabus from "../component/Syllabus";
import CourseTeacherForm from "../component/CourseTeacherForm";
import SendJson from "../component/SendJson";
import Fetch from "../common/fetch";
import { updateVariation } from "../../App";

let LoadingMsg=[];
export default function TeacherAdd(){
    const {id}=useParams();
    const [Alert,setAlert]=useState({'type':'danger','style':false,'msgs':[]});
    const [CourseSyllabus,setSyllabus]=useState();
    const [Res,setRes]=useState(false);
    const [Rdr,setRdr]=useState(false);

	const [Visible,setVisible]=useState(false);
	const [Msg,setMsg]=useState(LoadingMsg);
    const [BCrumb,setBCrumb]=useState({
        'CurrentText':'講師登録',
        'items':[
            {
                'index':1,
                'href':'/teacher',
                'text':'ホーム'
            },
        ]
    });
    function doEntry(){
        setVisible(true);
        LoadingMsg.push({type:'info',title:'確認中',content:'入力内容を確認しています',delay:5000});
        setMsg(LoadingMsg);
        let {error,body}=SendJson(document.getElementsByClassName('js-data'),true);
        if(error.length){
            setAlert({'type':'danger','style':true,'msgs':error});

			LoadingMsg.push({type:'danger',title:'入力不備',content:'入力内容を確認してください',delay:5000});
			setMsg(LoadingMsg);
			setVisible(false);
            window.scroll({top:0});

        }else{
            let send={
                url:"v1/course/"+id+"/teacher",
                method:"POST",
                body:body
            };
            
			LoadingMsg.push({type:'info',title:'送信中',content:'入力内容を送信しています',delay:5000});
			setMsg(LoadingMsg);
            Fetch(send).then(data=>{
                if(data['result']){

                    LoadingMsg.push({type:'success',title:'送信完了',content:'入力内容を送信しました',delay:5000});
                    setMsg(LoadingMsg);
                    setVisible(false);

                    setAlert({'type':'success','style':true,'msgs':["講師登録しました。"]});
                    updateVariation('TeacherCourse');
                    window.scroll({top:0});
                    setRes(true);
                    setTimeout(()=>{
                        setRdr('/teacher/course/'+id)
                    },10000)
                }else{
                    LoadingMsg.push({type:'danger',title:'エラー',content:'入力内容が保存できませんでした',delay:5000});
                    setMsg(LoadingMsg);
                    setVisible(false);
                    window.scroll({top:0});

                    setAlert({'type':'danger','style':true,'description':data['error']['description']});
                }
            })
            .catch((error)=>{
                LoadingMsg.push({type:'warning',title:'エラー',content:'通信エラーが発生しました'});
                setMsg(LoadingMsg);
                setVisible(false);
    
                console.log(error);
            })
        }
    }
    useEffect(()=>{
        if(CourseSyllabus && CourseSyllabus['condition'] && CourseSyllabus['condition']['teacher']){
            setRes(true);
            setTimeout(()=>{
                setRdr('/teacher/course/'+id)
            },10000)
        }else{
            setRes(false);
        }
    },[CourseSyllabus])
    return(
        <div>
            <BreadCrumb item={BCrumb} />
            <AlertBox Item={Alert} />
            <Loading visible={Visible} msgs={Msg} />
            <p>
                {CourseSyllabus && CourseSyllabus['CourseName']+'の'}講師登録を行います。<br />
                コース情報については以下のコースシラバスをご確認ください。
            </p>{Res?
            <div>
                既に登録されています。<br />
                10秒後にコースページにリダイレクトします。
                {Rdr && <Redirect to={Rdr} />}
            </div>:
            <div>
                講師登録にあたって以下の情報をご入力ください。
                <div>
                    <CourseTeacherForm teacher={{"link":"","tel":"","email":"","line":""}} />
                </div>
                <button type="button" className="btn btn-primary my-2" onClick={doEntry}>
                    登録
                </button>
            </div>}
            <h2>
                コースシラバス
            </h2>
            <Syllabus Id={id} get={setSyllabus} />
        </div>
    );
}