import { useEffect, useState } from "react";
import ReactDOM from 'react-dom';
import BreadCrumb from "../component/breadcrumb";
import Fetch from "../common/fetch";
import AlertBox from "../component/AlertBox";
import { BrowserRouter, Link } from "react-router-dom";
import { AdminInfoTo, AdminInfoType } from "../component/Keys";

export default function Infos(){
    const BCrumb={'CurrentText':'お知らせ','items':[{'index':1,'href':'/admin','text':'ホーム'}]};
    const [Item,setItem]=useState([false,{}]);
    const [Alert,setAlert]=useState({'type':'danger','style':false,'msgs':[]});
    useEffect(()=>{
        let send={
            'url':'v1/admin/info',
            'method':'GET'
        }
        Fetch(send).then(data=>{
            if(data['result']){
                setAlert({'type':'danger','style':false,'msgs':[]});
                setItem([true,data['payloads']]);
            }else{
                setAlert({'type':'danger','style':true,'description':data['error']['description']});
            }
        }).catch(error=>console.log(error));
    },[])
    function doEntry(){
        let send={
            'url':'v1/admin/',
            'method':'PUT'
        }
        Fetch(send).then(data=>{
            if(data['result']){
                setAlert({'type':'success','style':true,'msgs':[]});
            }else{
                setAlert({'type':'danger','style':true,'description':data['error']['description']});
            }
        }).catch(error=>console.log(error));
    }
    return(
        <div>
            <BreadCrumb item={BCrumb} />
            <AlertBox Item={Alert} />
            <Link to="/admin/infos/entry" className="btn btn-primary">
                お知らせ配信
            </Link>
            <div className="table-responsive">
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th>
                                タイトル
                            </th>
                            <th>
                                登録日時
                            </th>
                            <th>
                                配信種類
                            </th>
                            <th>
                                対象利用者
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {Item[0] && 
                        Item[1].map(info=>
                        <tr key={info['InfoId']}>
                            <td>
                                <Link to={"/admin/infos/show/"+info['InfoId']}>
                                    {info['InfoTitle']}
                                </Link>
                            </td>
                            <td>
                                {info['PostedDate']}
                            </td>
                            <td>
                                {AdminInfoType(info['type'])}
                            </td>
                            <td>
                                {AdminInfoTo(info['to'])}
                            </td>
                        </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    )
}