import {createStore} from 'redux';
import {persistStore,persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
//import storageSession from 'redux-persist/lib/storage/session';

//初期値

const state_value={
	"Auth": false,
	"access_token": "",
	"UserId": "",
	"UserType": 0,
	"UserRight": -1,
	"web": 0,
	"report": 0,
	"test": 0,
	"serma": 0,
	"edit": 0,
	"StudentCourse": [],
	"TeacherCourse": {"summary":{"HavingCourse":0,"MaxCourse":0,"MaxStudent":0,"web":0,"report":0,"test":0,"serma":0},"courses":[]},
	"TeacherAccount": [],
	"TeacherLine": [],
	"Serma": [],
    'variation':{
            "student":{
            "course":[]
        },
        "teacher":{
            'course':[],
            'account':[],
            'line':[]
        },
        "serma":{
            "project":[]
        }
    }
};
//persisit
const persistConfig={
    key:'root',
    //storage:storageSession//セッションストレージ
    storage:storage//ローカルストレージ
}
const persistedReducer=persistReducer(persistConfig,ReducerAuth);
//ストア
//const StoreAuth=createStore(ReducerAuth,state_value);
export const Store=createStore(persistedReducer);
const PersistedStore=persistStore(Store);
//レデューサー
function ReducerAuth(state=state_value,action){
    let data=action.message;
	switch(action.type){
        case 'LOGIN':
            data['Auth']=true;
            return Object.assign({},state,data);
            break;
        case 'REGISTER':
            data['Auth']=false;
            data['variation']={};
            return data;
            break;
        case 'LOGOUT':
            return state_value;
            break;
        case 'USERID':
        case 'VARIATION':
            return Object.assign({},state,data);
            break;
		default:
			return state;
	}
}
//アクションクリエーター
export function Login(payloads){
    return {
        type:'LOGIN',
        message:payloads
    }
}
export function getUserId(payloads){
    return {
        type:'USERID',
        message:payloads
    }
}
export function Register(payloads){
    return {
        type:'REGISTER',
        message:payloads
    }
}
export function Variation(payloads){
    return {
        type:'VARIATION',
        message:payloads
    }
}
export function Logout(){
    return {
        type:'LOGOUT'
    }
}
export default PersistedStore;