import Escape from "../component/Escape";

export default function Error({error}){
    let title;
    let description;
    switch(Number(error)){
        case 403:
            title="403 ACCESS DENIED";
            description="ご希望のページはアクセスできません。";
            break;
        case 404:
            title="404 NOT FOUND";
            description="お探しのページは見つかりませんでした。\nURLをご確認ください。";
            break;
    }
	return (
        <div>
            <h2>
                {title}
            </h2>
            <div>
                {Escape(description)}
            </div>
        </div>
	);
}