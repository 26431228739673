import { useEffect, useState } from "react"
import { ToastContainer } from "react-bootstrap";import Toast from 'react-bootstrap/Toast';
import Escape from "./Escape";

/**
 *
 *
 * @export
 * @param boolean visible
 * @param array msgs {type:"info",title:"TITLE",content:"content",delay:3000}
 * @return {*} 
 */
export default function Loading({visible,msgs=[]}){
    function closeToast(id){
        if(document.getElementById("LoadingToast_"+id)){
            document.getElementById("LoadingToast_"+id).remove();
        }
    }
    return(
        <>
            <div id="loading_wrapper" className="loading__wrapper" style={{display:visible?'block':'none'}}>
                <div className="loading"></div>
                <div className="loading__spinner">
                    <div className="spinner-border"></div>
                </div>
            </div>
            <ToastContainer style={{zIndex:5000,position:'fixed',right:'5px',bottom:'15px'}}>
                {msgs.map((msg,index)=>
                    <Toast id={"LoadingToast_"+index} bg={msg['type']} onClose={()=>closeToast(index)} autohide={msg['delay']?true:false} delay={msg['delay']?msg['dealy']:1000000}>
                        <Toast.Header closeButton={true}>
                            <span className="me-auto">{msg['title']}</span>
                        </Toast.Header>
                        <Toast.Body>
                            {Escape(msg['content'])}
                        </Toast.Body>
                    </Toast>
                )}
            </ToastContainer>
        </>
    );
}
