import { useEffect, useState } from "react";
import ReactDOM from 'react-dom';
import { useParams } from "react-router";
import BreadCrumb from "../component/breadcrumb";
import Fetch from "../common/fetch";
import AlertBox from "../component/AlertBox";
import LineForm from "../component/LineForm";
import { updateVariation } from "../../App";
import Loading from "../component/Loading";

let LoadingMsg=[];
export default function EditLine(){
    const {id}=useParams();
    const [BCrumb,setBCrumb]=useState({'CurrentText':'公式LINE編集','items':[{'index':1,'href':'/teacher','text':'ホーム'}]});
    const [Alert,setAlert]=useState({'type':'danger','style':false,'msgs':[]});
    const [Data,setData]=useState(false);

	const [Visible,setVisible]=useState(false);
	const [Msg,setMsg]=useState(LoadingMsg);

    useEffect(()=>{
        setAlert({'type':'danger','style':false,'msgs':[]});
        getLine();
    },[])
    function getLine(visible=true){
        setVisible(true);
        let send={
            'url':'v1/teacher/line/'+id,
            'method':'GET'
        }
        Fetch(send).then(data=>{
            if(data['result']){
                setAlert({'type':'danger','style':false,'msgs':[]});
                setData(data['payloads']);
                //ReactDOM.render(<LineForm Item={data['payloads']} Submit={doEntry} Delete={doDelete} />,document.getElementById('content_area'));
                setBCrumb({
                    CurrentText: data['payloads']['LineName']+'編集',
                    items: [
                        {
                            index: 1,
                            href: "/teacher",
                            text: "ホーム"
                        }
                    ],
                });
            }else{
                setAlert({'type':'danger','style':true,'description':data['error']['description']});
            }
            if(visible){
                setVisible(false);
            }
        }).catch((error)=>{
            LoadingMsg.push({type:'warning',title:'エラー',content:'通信エラーが発生しました'});
            setMsg(LoadingMsg);
            LoadingMsg=[];
            if(visible){
                setVisible(false);
            }

            console.log(error);
        });
    }
    function doEntry(send){
        setVisible(true);
        LoadingMsg.push({type:'info',title:'確認中',content:'入力内容を確認しています',delay:5000});
        setMsg(LoadingMsg);

        send['url']='v1/teacher/line/'+id;
        send['method']='PUT';

        LoadingMsg.push({type:'info',title:'送信中',content:'入力内容を送信しています',delay:5000});
        setMsg(LoadingMsg);

        Fetch(send).then(data=>{
            if(data['result']){
                getLine(false);
                LoadingMsg.push({type:'success',title:'送信完了',content:'入力内容を送信しました',delay:5000});
                setMsg(LoadingMsg);

                setAlert({'type':'success','style':true,'msgs':["公式LINEを編集しました。"]});
                updateVariation('TeacherLine');
            }else{
                LoadingMsg.push({type:'danger',title:'エラー',content:'入力内容が保存できませんでした',delay:5000});
                setMsg(LoadingMsg);

                setAlert({'type':'danger','style':true,'description':data['error']['description']});
            }
            setVisible(false);
            LoadingMsg=[];
            window.scroll({top:0});
        }).catch((error)=>{
            LoadingMsg.push({type:'warning',title:'エラー',content:'通信エラーが発生しました'});
            setMsg(LoadingMsg);
            LoadingMsg=[];
            setVisible(false);

            console.log(error);
        });
    }
    function doDelete(){
        setVisible(true);
        LoadingMsg.push({type:'info',title:'送信中',content:'データを送信しています',delay:5000});
        setMsg(LoadingMsg);

        let send={};
        send['url']='v1/teacher/line/'+id;
        send['method']='DELETE';
        Fetch(send).then(data=>{
            if(data['result']){
                LoadingMsg.push({type:'success',title:'送信完了',content:'データを送信しました',delay:5000});
                setMsg(LoadingMsg);

                setAlert({'type':'success','style':true,'msgs':["公式LINEを削除しました。"]});
                updateVariation('TeacherLine');
                document.getElementById('content_area').textContent="";
            }else{
                LoadingMsg.push({type:'danger',title:'エラー',content:'課題を削除できませんでした',delay:5000});
                setMsg(LoadingMsg);

                setAlert({'type':'danger','style':true,'description':data['error']['description']});
            }
            setVisible(false);
            LoadingMsg=[];
            window.scroll({top:0});
        }).catch((error)=>{
            LoadingMsg.push({type:'warning',title:'エラー',content:'通信エラーが発生しました'});
            setMsg(LoadingMsg);
            LoadingMsg=[];
            setVisible(false);

            console.log(error);
        });
    }
    return(
        <div>
            <BreadCrumb item={BCrumb} />
            <AlertBox Item={Alert} />
            <Loading visible={Visible} msgs={Msg} />
            <div id="content_area">
                <LineForm Item={Data} Submit={doEntry} Delete={doDelete} />
            </div>
        </div>
    )
}