import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import pStore,{Store} from './src/common/store';
import {Provider} from 'react-redux';
import {PersistGate} from 'redux-persist/integration/react';
import { CookiesProvider } from "react-cookie";
import { createKey } from './src/component/Keys';
import Fetch from './src/common/fetch';

const root=document.getElementById('root');
let ifm=document.createElement('iframe');
ifm.src="https://login.canteach.jp/get/"
ifm.style.display="none";
root.appendChild(ifm);
let AccessToken={
	LongToken:"",
	ShortToken:"",
	AccessToken:""
};
ifm.addEventListener('load',function(){
	let nonce=createKey();
	ifm.contentWindow.postMessage({type:'GetSessions',nonce:nonce},"https://login.canteach.jp");
	window.addEventListener('message',function(event){
		if(event.data.type && event.data.type=='GetSessions' && event.data.nonce===nonce){
			if(event.data.result){
				AccessToken=event.data['access_token'];
				let send={
					url:"",
					method:'GET'
				}
				Fetch(send).then(data=>{
					//ログイン済み
					if(data['result'] && data['auth']){
						ReactDOM.render(
						<React.StrictMode>
							<CookiesProvider>
							<Provider store={Store}>
								<PersistGate loading={null} persistor={pStore}>
								<App />
								</PersistGate>
							</Provider>
							</CookiesProvider>
						</React.StrictMode>,
						root
						);
					}else{
						//未ログイン
						window.location.href='https://login.canteach.jp/?redirect_url='+encodeURI(this.window.location.href);
					}
				})
			}else{
				//未ログイン
				window.location.href='https://login.canteach.jp/?redirect_url='+encodeURI(this.window.location.href);
			}
		}
	},false);
},false);

export function getAccessToken(){
	return AccessToken;
}


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
