import { useParams } from "react-router-dom";
import BreadCrumb from "../component/breadcrumb";
import { useState,useEffect } from "react";
import Fetch from "../common/fetch";
import AlertBox from "../component/AlertBox";
import UploadFile from "../component/UploadFile";
import Upload from "../common/upload";
import SendJson from "../component/SendJson";
import Loading from "../component/Loading";

let LoadingMsg=[];
export default function EntryInfo(){
    const {course}=useParams();
    const [BCrumb,setBCrumb]=useState({
        'CurrentText':'新規連絡',
        'items':[
            {
                'index':1,
                'href':'/teacher',
                'text':'ホーム'
            }
        ]
    });

	const [Visible,setVisible]=useState(false);
	const [Msg,setMsg]=useState(LoadingMsg);

    const [Data,setData]=useState([]);
    const [Alert,setAlert]=useState({'type':'info','style':false, 'msgs':[],'description':[] });
    useEffect(()=>{
        setVisible(true);
        let send={
            'url':'v1/course/'+course+'/student',
            'method':'GET'
        }
        Fetch(send).then(data=>{
            if(data['result']){
                setAlert({'type':'danger','style':false, 'msgs':[]});
                setBCrumb({
                    'CurrentText':'新規連絡',
                    'items':[
                        {
                            'index':1,
                            'href':'/teacher',
                            'text':'ホーム'
                        },
                        {
                            'index':2,
                            'href':'/teacher/course/'+course,
                            'text':data['payloads']['CourseName']
                        }
                    ]
                });
                setData(data['payloads']['students']);
            }else{
                setAlert({'type':'danger','style':true, 'description':data['error']['description'] });
            }
            setVisible(false);
        }).catch((error)=>{
            LoadingMsg.push({type:'warning',title:'エラー',content:'通信エラーが発生しました'});
            setMsg(LoadingMsg);
            LoadingMsg=[];
            setVisible(false);

            console.log(error);
        });
    },[])
    function doEntry(){
        
		setVisible(true);
		LoadingMsg.push({type:'info',title:'確認中',content:'ファイルを確認中',delay:5000});
		setMsg(LoadingMsg);

        let files=document.getElementById('file_wrapper_1').getElementsByClassName('js-file');
        let fd=new FormData();
        if(files.length>1){
            for(let i=1;i<files.length;++i){
                if(files[i].files.length){
                    fd.append('file['+i+']',files[i].files[0]);
                    fd.append('FileName['+i+']',files[i].dataset.name);
                }
            }
            LoadingMsg.push({type:'info',title:'アップロード中',content:'ファイルをアップロード中',delay:5000});
            setMsg(LoadingMsg);
    
            Upload(fd).then(data=>{
                if(data['result']){
                    LoadingMsg.push({type:'success',title:'アップロード完了',content:'ファイルをアップロードしました',delay:5000});
                    setMsg(LoadingMsg);
            
                    doSubmit(data['payloads']['FileIds']);
                }else{
                    LoadingMsg.push({type:'danger',title:'アップロード失敗',content:'ファイルをアップロードできませんでした',delay:5000});
                    setMsg(LoadingMsg);
                    LoadingMsg=[];
                    setVisible(false);
                    window.scroll({top:0});

                    setAlert({'type':'danger','style':true, 'description':data['error']['description'] });
                }
            }).catch((error)=>{
                LoadingMsg.push({type:'warning',title:'エラー',content:'通信エラーが発生しました'});
                setMsg(LoadingMsg);
                LoadingMsg=[];
                setVisible(false);
    
                console.log(error);
            });
        }else{
            doSubmit([]);
        }
    }
    function doSubmit(FileId){
        LoadingMsg.push({type:'info',title:'確認中',content:'入力内容を確認しています',delay:5000});
        setMsg(LoadingMsg);
        
        let tos=document.getElementsByClassName('js-data-to');
        let to=[];
        for(let i=0;i<tos.length;++i){
            if(tos[i].checked){
                to.push(tos[i].dataset.id);
            }
        }
        
        let parent=document.getElementById('contact_new');
        let {error,body}=SendJson(parent.getElementsByClassName('js-data'),true);
        
        if(error.length){
            setAlert({'type':'danger','style':true,'msgs':error});

			LoadingMsg.push({type:'danger',title:'入力不備',content:'入力内容を確認してください',delay:5000});
			setMsg(LoadingMsg);
			setVisible(false);
            window.scroll({top:0});

            return;

        }else{
            body['to']=to;
            body['files']=FileId;
            let send={
                'url':'v1/course/'+course+'/contact',
                'method':'POST',
                'body':body
            }

            LoadingMsg.push({type:'info',title:'送信中',content:'入力内容を送信しています',delay:5000});
            setMsg(LoadingMsg);

            Fetch(send).then(data=>{
                if(data['result']){

                    LoadingMsg.push({type:'success',title:'送信完了',content:'入力内容を送信しました',delay:5000});
                    setMsg(LoadingMsg);
                    setVisible(false);
                    LoadingMsg=[];
                    window.scroll({top:0});

                    document.getElementById('contact_new').remove();
                    setAlert({'type':'success','style':true, 'msgs':["連絡を登録しました。"]});
                }else{
                    LoadingMsg.push({type:'danger',title:'エラー',content:'入力内容が保存できませんでした',delay:5000});
                    setMsg(LoadingMsg);
                    LoadingMsg=[];
                    setVisible(false);
                    window.scroll({top:0});

                    setAlert({'type':'danger','style':true, 'description':data['error']['description'] });
                }
            }).catch((error)=>{
                LoadingMsg.push({type:'warning',title:'エラー',content:'通信エラーが発生しました'});
                setMsg(LoadingMsg);
                LoadingMsg=[];
                setVisible(false);

                console.log(error);
            });
        }
    }
    return(
        <div>
            <BreadCrumb item={BCrumb} />
            <AlertBox Item={Alert} />
            <Loading visible={Visible} msgs={Msg} />
            <div className="row">
                <div className="col-12">
                <h2>
                    一斉連絡
                </h2>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                <form id="contact_new">
                    <div className="my-2 row form-group">
                    <label className="col-sm-2 col-form-label">
                        宛先
                    </label>
                    <div className="col-sm-10">
                        <div className="row">{Data.map((user,index)=>
                            <div className="col-auto form-check" key={index}>
                                <input type="checkbox" name="to" id={"to_"+index} className="form-check-input js-data-to" data-id={user['StudentId']} />
                                <label htmlFor={"to_"+index} className="form-check-label">
                                {user['UserName']}
                                </label>
                            </div>)}
                        </div>
                    </div>
                    </div>
                    <div className="my-2 row form-group">
                    <label htmlFor="ContactTitle" className="col-sm-2 col-form-label">
                        件名
                    </label>
                    <div className="col-sm-10">
                        <input type="text" name="ContactTitle" id="ContactTitle" className="form-control js-data" placeholder="件名" required />
                    </div>
                    </div>
                    <div className="my-2 row form-group">
                    <label htmlFor="ContactContent" className="col-sm-2 col-form-label">
                        内容
                    </label>
                    <div className="col-sm-10">
                        <textarea name="ContactContent" id="ContactContent" className="form-control js-data" placeholder="内容" required defaultValue={""} />
                    </div>
                    </div>
                    <div className="my-2 row form-group">
                    <label className="col-sm-2 col-form-label">
                        添付ファイル
                    </label>
                    <div className="col-sm-10">
                        <UploadFile Id="1" />
                    </div>
                    </div>
                    <div className="mt-2 row form-group">
                    <div className="col-auto">
                        <button type="button" className="btn btn-primary" onClick={doEntry}>送信</button>
                    </div>
                    </div>
                </form>
                </div>
            </div>
        </div>
    );
}