import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import Fetch from "../common/fetch";
import AlertBox from "../component/AlertBox";
import getParams from "../component/getParams";
import { PaymentMethodKey } from "../component/Keys";
import LineBtn from "./../component/LineBtn";


export default function Welcome(){
    const {id}=useParams();
    const [Welcome,setWelcome]=useState({
        "CourseId": "",
        "CourseName": "",
        "payment":{
            "method":0,
            "approve":""
        },
        "LINE": false
    });
    const [Alert,setAlert]=useState({'type':'danger','style':false,'msgs':[]});
    useEffect(()=>{
        let send={
            'url':'v1/student/course/'+id+'/welcome',
            'method':'GET'
        }
        Fetch(send).then(data=>{
            if(data['result']){
                setAlert({'type':'danger','style':false,'msgs':[]});
                setWelcome(data['payloads']);
            }else{
                setAlert({'type':'danger','style':true,'description':data['error']['description']});
            }
        }).catch(error=>console.log(error));
    },[])
    return(
        <div>
            <AlertBox Item={Alert} />
            <div className="row" style={{display:Alert['style']?'none':'flex'}}>
                <div className="col-12">
                    <h2>
                    ようこそ！{Welcome['CourseName']}へ
                    </h2>
                    <div className="my-3">
                        <div className="my-4">
                            <h3>
                                受講登録ありがとうございます！
                            </h3>
                            <p>
                            この度は，受講登録頂きありがとうございます！
                            </p>
                            <p>
                            これから皆さんと一緒に多くのことを学べることが楽しみです♪
                            </p>
                            <p>
                            沢山のことを学んでいきましょう！
                            </p>
                        </div>
                        {Welcome['payment']['method']?<Payment payment={Welcome['payment']} />:""}
                        {Welcome['LINE'] && <Line LINE={Welcome['LINE']} />}
                    </div>
                    <div className="mt-2">
                        <Link to={"/student/course/"+id} className="btn btn-primary">
                            コースページ
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}
function Payment({payment}){
    function Open(){
        if(payment['approve']){
            window.open(payment['approve'],'new-window','width=600px,height=800px');
        }
    }
    const [method,setMethod]=useState();
    useEffect(()=>{
        setMethod(PaymentMethodKey(payment['method']))
        Open();
    },[payment])
    return(
        <>
            {payment['method']!='stripe'?
            <div className="my-4 mt-5">
                <h3>
                    お支払いについて
                </h3>
                <p>
                    お支払方法を{method}で設定しています。
                </p>
                <p>
                    下記のリンクをクリックし，{method}と連携してください。
                </p>
                <p>
                    なお，お支払いが完了するまでは受講が開始できませんので必ずお支払いをしてください。
                </p>
                <button type="button" class="btn btn-danger" onClick={Open}>お支払い</button>
            </div>:''}
        </>
    );
}
function Line({LINE}){
    return(
        <div className="my-4">
            <h3>
                公式LINEについて
            </h3>
            <p>
                このコースは公式LINEと連携されています。
            </p>
            <p>
                公式LINEと連携を行い，公式LINEを追加してください。
            </p>{LINE['login']?
            <div className="my-2">
                <LineBtn Link={LINE['login']} Text={"公式LINEと連携"} />
            </div>:""}{LINE['entry']?
            <div className="my-2">
                <LineBtn Link={LINE['entry']} Text={"公式LINE追加"} target="_blank" />
            </div>:""}
        </div>
    );
}