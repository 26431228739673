import { useEffect, useState } from "react";
import { useParams } from "react-router";
import BreadCrumb from "../component/breadcrumb";
import Fetch from "../common/fetch";
import AlertBox from "../component/AlertBox";
import Loading from "../component/Loading";

export default function Grade(){
    const {course}=useParams();
    const [BCrumb,setBCrumb]=useState({'CurrentText':'成績','items':[{'index':1,'href':'/teacher','text':'ホーム'}]});
    const [Alert,setAlert]=useState({'type':'danger','style':false,'msgs':[]});
    const [Data,setData]=useState([]);
    const [User,setUser]=useState({
        "StudentId": "STUDENTID",
        "UserName": "USER NAME",
        "summary": {
            "total": {
                "score": "130",
                "total": "200"
            },
            "reports": {
                "score": "50",
                "total": "100"
            },
            "examinations": {
                "score": "80",
                "total": "100"
            }
        },
        "reports": [
            {
                "StudentReportId": "STUDENTREPORTID",
                "ReportId": "REPORTID",
                "ReportTitle": "REPORTTITLE",
                "score": "50",
                "total": "100"
            }
        ],
        "examinations": [
            {
                "StudentExaminationId": "STUDENTEXAMINATIONID",
                "ExaminationId": "EXAINATIONID",
                "ExaminationTitle": "EXAMINATIONTITLE",
                "score": "80",
                "total": "100"
            }
        ]
    });
    const [Visible,setVisible]=useState(false);
    useEffect(()=>{
        setVisible(true);
        let send={
            'url':'v1/course/'+course+'/grade',
            'method':'GET'
        }
        Fetch(send).then(data=>{
            if(data['result']){
                setAlert({'type':'danger','style':false,'msgs':[]});
                setBCrumb({
                    CurrentText: '成績',
                    items: [
                        {
                            index: 1,
                            href: "/teacher",
                            text: "ホーム"
                        },
                        {
                            index:2,
                            href:'/teacher/course/'+course,
                            text:data['payloads']['CourseName']
                        }
                    ],
                });
                setData(data['payloads']['grades']);
            }else{
                setAlert({'type':'danger','style':true,'description':data['error']['description']});
            }
            setVisible(false);
        }).catch(error=>{
            console.log(error);
            setVisible(false);
        });
    },[])
    function clickUser(e){
        setUser(Data[e.target.parentNode.dataset.id]);
    }
    return(
        <div>
            <BreadCrumb item={BCrumb} />
            <AlertBox Item={Alert} />
            <Loading visible={Visible} msgs={[]} />
            <div className="row">
                <div className="col-12">
                <h2>
                    受講者成績
                </h2>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                <table className="table table-striped table-hover">
                    <thead>
                        <tr>
                            <th>
                            受講者名
                            </th>
                            <th>
                            課題成績
                            </th>
                            <th>
                            テスト成績
                            </th>
                            <th>
                            合計
                            </th>
                        </tr>
                    </thead>
                    <tbody>{Data.map((user,index)=>
                        <tr data-bs-toggle="modal" data-bs-target="#gradeModal" data-id={index} key={index} onClick={clickUser}>
                            <td>
                            {user['UserName']}
                            </td>
                            <td>
                            {user['summary']['reports']['score']}/{user['summary']['reports']['total']}点
                            </td>
                            <td>
                            {user['summary']['examinations']['score']}/{user['summary']['examinations']['total']}点
                            </td>
                            <td>
                            {user['summary']['total']['score']}/{user['summary']['total']['total']}点
                            </td>
                        </tr>)}
                    </tbody>
                </table>
                </div>
            </div>
            <div className="modal fade w-100" id="gradeModal" tabIndex={-1} aria-labelledby="gradeModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-xl modal-dialog-scrollable">
                <div className="modal-content">
                    <div className="modal-header">
                    <h5 className="modal-title" id="gradeModalLabel">{User['UserName']}</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                    </div>
                    <div className="modal-body">
                    <h3>
                        課題成績
                    </h3>
                    <table className="table table-striped">
                        <thead>
                        <tr>
                            <th>
                            課題名
                            </th>
                            <td>
                            得点
                            </td>
                        </tr>
                        </thead>
                        <tbody>{User['reports'].map((row,index)=>
                            <tr key={index}>
                                <td>
                                {row['ReportTitle']}
                                </td>
                                <td>
                                {row['score']}/{row['total']}点
                                </td>
                            </tr>)}
                        </tbody>
                        <tfoot>
                            <tr>
                                <th>
                                    合計
                                </th>
                                <td>
                                    {User['summary']['reports']['score']}/{User['summary']['reports']['total']}点
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                    <h3>
                        テスト成績
                    </h3>
                    <table className="table table-striped">
                        <thead>
                        <tr>
                            <th>
                            テスト名
                            </th>
                            <td>
                            得点
                            </td>
                        </tr>
                        </thead>
                            <tbody>{User['examinations'].map((row,index)=>
                                <tr key={index}>
                                    <td>
                                    {row['ExaminationTitle']}
                                    </td>
                                    <td>
                                    {row['score']}/{row['total']}点
                                    </td>
                                </tr>)}
                        </tbody>
                        <tfoot>
                            <tr>
                                <th>
                                    合計
                                </th>
                                <td>
                                    {User['summary']['examinations']['score']}/{User['summary']['examinations']['total']}点
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                    </div>
                </div>
                </div>
            </div>
        </div>
    )
}