import { useEffect, useState } from "react";
import { Redirect, Route, Switch } from "react-router";
import { Link } from "react-router-dom";
import BreadCrumb from "./component/breadcrumb";
import Fetch from "./common/fetch";
import CardList from "./component/CardList";
import Project from "./serma/project";
import ProjectEdit from "./serma/ProjectEdit";
import ProjectEntry from "./serma/ProjectEntry";
import Seminar from "./serma/seminar";
import Apply from "./serma/application";
import Loading from "./component/Loading";

export default function Serma(){
    return (
        <Switch>
            <Route path="/serma" exact>
                <Home />
            </Route>
            <Route path="/serma/project" exact>
                <ProjectEntry />
            </Route>
            <Route path="/serma/project/:id" exact>
                <Project />
            </Route>
            <Route path="/serma/project/:id/edit" exact>
                <ProjectEdit />
            </Route>
            <Route path="/serma/project/:id/seminar" exact>
                <Seminar />
            </Route>
            <Route path="/serma/project/:id/apply" exact>
                <Apply />
            </Route>
            <Route path="/serma">
                <Redirect to="/serma" />
            </Route>
        </Switch>
    );
}
function Home(){
    const BCrumb={
        'CurrentText':'ホーム',
        'items':[]
    };
    const [Visible,setVisible]=useState(true);
    const [Project,setProject]=useState({
        'title':'プロジェクト',
        'items':[]
    });
    useEffect(()=>{
        let send={
            url:'v1/serma/project',
            method:'GET'
        }
        Fetch(send).then(data=>{
            if(data['result']){
                let items=[];
                for(let i=0;i<data['payloads'].length;++i){
                    items.push({
                        'index':i,
                        'href':'/serma/project/'+data['payloads'][i]['ProjectId'],
                        'text':data['payloads'][i]['ProjectName']
                    })
                }
                setProject({
                    'title':'プロジェクト',
                    'items':items
                });
            }
            setVisible(false);
        }).catch(error=>{
            setVisible(false);
            console.log(error);
        })
    },[])
    return (
        <div>
            <BreadCrumb item={BCrumb} />
            <Loading visible={Visible} msgs={[]} />
            <div className="row">
                <div className="col-sm-6 col-md-6 mb-2">
                    <CardList item={Project} />
                    <Link to="/serma/project">新規プロジェクト</Link>
                </div>
            </div>
        </div>
    );
}