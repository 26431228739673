import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import BreadCrumb from "../component/breadcrumb";
import Fetch from "../common/fetch";
import AlertBox from "../component/AlertBox";
import ReportOutline from "../component/ReportOutline";
import { ConvertToDisplayDate } from "../component/Keys";

export default function Report(){
    const {course,id}=useParams();
    const [BCrumb,setBCrumb]=useState({'CurrentText':'課題','items':[{'index':1,'href':'/teacher','text':'ホーム'}]});
    const [Alert,setAlert]=useState({'type':'danger','style':false,'msgs':[]});
    const [GradeAlert,setGradeAlert]=useState({'type':'danger','style':false,'msgs':[]});
    const [Data,setData]=useState();
    const [Score,setScore]=useState(0);
    const [Submits,setSubmits]=useState([]);
    const [Result, setResult] = useState({
        StudentReportId: "",
        UserName:"",
        answers: {
            comment: "",
            datetime:"",
            files: [],
        },
        grades: {
            score: "",
            comment: "",
        },
    });
    useEffect(()=>{
        let send={
            'url':'/v1/course/'+course+'/report/'+id,
            'method':'GET'
        }
        Fetch(send).then(data=>{
            if(data['result']){
                setAlert({'type':'danger','style':false,'msgs':[]});
                setData(data['payloads']['reports']);
                setScore(data['payloads']['reports']['score']);
                setSubmits(data['payloads']['submits']);
                setBCrumb({
                    'CurrentText':data['payloads']['reports']['ReportTitle'],
                    'items':[
                        {
                            'index':1,
                            'href':'/teacher',
                            'text':'ホーム'
                        },
                        {
                            'index':2,
                            'href':'/teacher/course/'+course,
                            'text':data['payloads']['reports']['CourseName']
                        }
                    ]
                });
            }else{
                setAlert({'type':'danger','style':true,'description':data['error']['description']});
            }
        }).catch(error=>console.log(error));
    },[])
    function showResult(e){
        setGradeAlert({'type':'success','style':false,'msgs':["採点結果を登録しました。"]});
        setResult(Submits[e.target.dataset.id]);
    }
    function doGrading(){
        let send={
            'url':'v1/course/'+course+'/report/grading/'+Result['StudentReportId'],
            'method':'PUT',
            'body':{
                "score":document.getElementById('report_score').value,
                "comment":document.getElementById('report_comment').value
            }
        }
        Fetch(send).then(data=>{
            if(data['result']){
                setGradeAlert({'type':'success','style':true,'msgs':["採点結果を登録しました。"]});
            }else{
                setGradeAlert({'type':'danger','style':true,'description':data['error']['description']});
            }
        }).catch(error=>console.log(error));
    }
    return(
        <div>
            <BreadCrumb item={BCrumb} />
            <AlertBox Item={Alert} />
            <div className="row">
                <div className="col-12">
                    <ReportOutline Item={Data} />
                    <div className="text-end">
                        <Link to={"/teacher/course/"+course+'/report/'+id+'/edit'} className="btn btn-secondary">
                            編集
                        </Link>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <h3>
                        提出課題
                    </h3>
                    <div className="col-12 list-group list-group-flush">{Submits.map((submit,index)=>
                        <a href="#" className="btn list-group-item list-group-item-action" data-bs-toggle="modal" data-bs-target="#gradeReportModal" data-id={index} onClick={showResult}>
                            {submit['UserName']}
                        </a>)}
                    </div>
                    <div className="modal fade w-100" id="gradeReportModal" tabIndex={-1} aria-labelledby="gradeReportModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-xl modal-dialog-scrollable">
                            <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="gradeReportModalLabel">{Result['UserName']}</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                            </div>
                            <div className="modal-body">
                                <table className="table table-stripe">
                                <tbody>
                                    <tr>
                                    <th>
                                        提出日時
                                    </th>
                                    <td>
                                        {ConvertToDisplayDate(Result['answers']['datetime'],true)}
                                    </td>
                                    </tr>
                                    <tr>
                                    <th>
                                        コメント
                                    </th>
                                    <td>
                                        {Result['answers']['comment']}
                                    </td>
                                    </tr>
                                    <tr>
                                    <th>
                                        添付ファイル
                                    </th>
                                    <td>
                                        <div className="list-group list-group-flush">{Result['answers']['files'].map(file=>
                                            <a href={file['link']} target="_blank" className="list-group-item list-group-item-action" key={file['FileId']}>
                                                {file['FileName']}
                                            </a>)}
                                        </div>
                                    </td>
                                    </tr>
                                </tbody>
                                </table>
                                <form>
                                <div className="mb-2 input-group">
                                    <span className="input-group-text">得点</span>
                                    <input type="number" name="score" id="report_score" className="form-control js-data" placeholder="得点" min={0} defaultValue={Result['grades']['score']} max={Score} required />
                                    <span className="input-group-text">点</span>
                                </div>
                                <div className="input-group">
                                    <span className="input-group-text">講評</span>
                                    <textarea name="comment" id="report_comment" className="form-control js-data" placeholder="講評" defaultValue={Result['grades']['comment']} />
                                </div>
                                <div className="mt-2">
                                    <AlertBox Item={GradeAlert} />
                                    <button type="button" className="btn btn-primary" onClick={doGrading}>採点</button>
                                </div>
                                </form>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}