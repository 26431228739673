import { useEffect, useState } from "react";
import ReactDOM from 'react-dom';
import BreadCrumb from "../component/breadcrumb";
import Fetch from "../common/fetch";
import AlertBox from "../component/AlertBox";
import { useParams } from "react-router";
import Contact from '../component/Contact';

export default function Info(){
    const {id}=useParams();
    const [BCrumb,setBCrumb]=useState({'CurrentText':'','items':[{'index':1,'href':'/account','text':'アカウント'}]});
    const [Alert,setAlert]=useState({'type':'danger','style':false,'msgs':[]});
    useEffect(()=>{
        let send={
            'url':'v1/contact/'+id,
            'method':'GET'
        }
        Fetch(send).then(data=>{
            if(data['result']){
                setAlert({'type':'danger','style':false,'msgs':[]});
                ReactDOM.render(<Contact Item={data['payloads']} />,document.getElementById('content_area'));
                setBCrumb({
                    CurrentText: data['payloads']['ContactTitle'],
                    items: [
                        {
                            index: 1,
                            href: "/account",
                            text: "アカウント"
                        }
                    ],
                });
            }else{
                setAlert({'type':'danger','style':true,'description':data['error']['description']});
            }
        }).catch(error=>console.log(error));
    },[])
    return(
        <div>
            <BreadCrumb item={BCrumb} />
            <AlertBox Item={Alert} />
            <div id="content_area"></div>
        </div>
    )
}