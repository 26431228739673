import React, { useState,useEffect } from 'react';
import { BrowserRouter, Route ,Switch, Redirect, HashRouter } from 'react-router-dom';
import './App.css';

import {Login,Store,Logout,Register, Variation} from './src/common/store';
import Header from './src/common/header';
import Footer from './src/common/footer';
import Sso from './src/Sso';
import Logged from './src/Logged';
import Fetch from './src/common/fetch';
import EntryCourseSyllabus from './src/EntryCourse/detail';
import EntryCourseAdd from './src/EntryCourse/add';
import Error from './src/common/error';
import AccountInit from './src/account/init';
import Support from './src/support/support';

const green='#09a603';
//const blue='#049dd9';
//const pink='#f22771';
const BasicText={
	color:green
}
export default function App(){
	const [Auth,setAuth]=useState(true);
	const [Render,setRender]=useState(Store.getState()['edit']);
	const [RedirectURL,setRedirectURL]=useState('/');
	const [State,setState]=useState(Store.getState()['variation']);
	const [Init,setInit]=useState(false);
	const [User,setUser]=useState({
		'UserId':'',
		'UserName':'',
		'UserRight':'',
		'UserType':''
	});
	const [Right,setRight]=useState({
		"report": 0,
		"serma": 0,
		"test": 0,
		"web": 0
	});
	const [StudentCourse,setStudentCourse]=useState([]);
	const [TeacherAccount,setTeacherAccount]=useState([]);
	const [TeacherCourse,setTeacherCourse]=useState([]);
	const [TeacherLine,setTeacherLine]=useState([]);
	const [SermaProject,setSermaProject]=useState([]);

	function doRegister(props){
		Store.dispatch(Register(props));
	}
	function doLogin(props){
		Store.dispatch(Login(props));
		updateVariation('Profile');
		//updateVariation('ALL');
		let url=new URL(window.location.href);
		let params=url.searchParams;
		if(params.get('redirect')){
			setRedirectURL(params.get('redirect'));
		}else{
			setRedirectURL('/');
		}
		setTimeout(()=>{
			setAuth(true);
		},1500)
	}
	function doLogout(){
		Store.dispatch(Logout());
		setAuth(false);
	}
	if(!Auth && Store.getState()['Auth']){
		setAuth(true);
	}
	let pathName=window.location.pathname;
	useEffect(() => {
		setState(Store.getState()['variation']);
		//updateInfo();
	},[])
	function updateInfo(){
		let send={
            'url':'v1/auth',
            'method':'GET'
        }
        Fetch(send).then(data=>{
            if(data['result']){
				setUser({
					'UserId':data['payloads']['UserId'],
					'UserName':data['payloads']['UserName'],
					'UserRight':data['payloads']['UserRight'],
					'UserType':data['payloads']['UserType']
				});
				setRight(data['payloads']['rights']);
				setStudentCourse(data['payloads']['student']['course']);
				setTeacherAccount(data['payloads']['teacher']['account']);
				setTeacherCourse(data['payloads']['teacher']['course']);
				setTeacherLine(data['payloads']['teacher']['line']);
				setSermaProject(data['payloads']['serma']['project']);
            }
        }).catch(error=>console.log(error));
	}
	useEffect(()=>{
		setRender(Store.getState()['edit']);
	},[Store.getState()['edit']])
	useEffect(()=>{
		Store.dispatch(Login({access_token:""}));
		updateVariation('Profile');
		if(Store.getState()['UserName']==""){
			setInit(true);
		}
	},[])
	return (
		<BrowserRouter basename='/'>
			<Header Render={Render} h1style={BasicText} Logout={doLogout} StudentCourse={State['student']['course']}  SermaProject={State['serma']['project']} />
			<main className="document__main" role="main">
				<div className="container h-100">
					<div className="row h-100">
						<div className="col-12 h-100">
							<Switch>
								<Route path="/course/:id/add" exact>
									<EntryCourseAdd />
								</Route>
								<Route path="/course/:id/syllabus" exact>
									<EntryCourseSyllabus />
								</Route>
								<Route path="/support">
									<Support />
								</Route>
								<Route path="/">
									<Logged Render={Render} />
								</Route>
								{/*Auth?//Logged
									<>{Init?
										<Switch>
											<Route path="/account/init" exact>
												<AccountInit />
											</Route>
											<Route path="/">
												<Redirect to={'/account/init'} />
											</Route>
										</Switch>:
										<Switch>
											<Route path="/login">
												<Redirect to={RedirectURL} />
											</Route>
											<Route path="/">
												<Logged Render={Render} />
											</Route>
									</Switch>}
									</>:///not Logged
									<Switch>
										<Route path="/login">
											<Sso Render={Render} Auth={doLogin} Register={doRegister} />
										</Route>
										<Route path="/course">
											<Error error={404} />
										</Route>
										<Route path="/">
											<Redirect to={"/login"+(pathName!="/"?"?redirect="+encodeURI(window.location.pathname):'')} />
										</Route>
									</Switch>*/
								}
							</Switch>
						</div>
					</div>
				</div>
			</main>
			<Footer />
		</BrowserRouter>
	);
}
export function updateVariation(props,reset=false){
	let urls=[];
	switch(props){
		case 'Profile':
			urls.push({link:'v1/profile',key:''});
			break;
		case 'StudentCourse':
			urls.push({link:'v1/student/course',key:'StudentCourse'});
			break;
		case 'TeacherCourse':
			urls.push({link:'v1/course',key:'TeacherCourse'});
			break;
		case 'TeacherAccount':
			urls.push({link:'v1/teacher/account',key:'TeacherAccount'});
			break;
		case 'TeacherLine':
			urls.push({link:'v1/teacher/line',key:'TeacherLine'});
			break;
		case 'Serma':
			urls.push({link:'v1/serma/project',key:'Serma'});
			break;
		case 'ALL':
			urls.push({link:'v1/profile',key:''});
			urls.push({link:'v1/student/course',key:'StudentCourse'});
			urls.push({link:'v1/course',key:'TeacherCourse'});
			urls.push({link:'v1/teacher/account',key:'TeacherAccount'});
			urls.push({link:'v1/teacher/line',key:'TeacherLine'});
			urls.push({link:'v1/serma/project',key:'Serma'});
			break;
	}
	if(reset){
		for(let i=0;i<urls.length;++i){
			let val={};
			if(urls[i].key!=""){
				val[urls[i].key]=[];
			}else{
				val=[];
			}
			Store.dispatch(Variation(val))
		}
	}else{
		for(let i=0;i<urls.length;++i){
			setTimeout(()=>{
				Fetch({
					'url':urls[i].link,
					'method':'GET'
				}).then(data=>{
					let val={};
					if(urls[i].key!=""){
						val[urls[i].key]=data['payloads'];
					}else{
						val=data['payloads'];
					}
					Store.dispatch(Variation(val))
					Store.dispatch(Variation({edit:Math.random()}))
				}).catch(error=>console.log(error));
			},500)
		}
	}
}/*
export function updateVariation(props,reload=true){
	let send={
		'url':'',
		'method':'GET'
	}
	let key=[];
	let state=JSON.stringify(Store.getState());
	let store=JSON.parse(state);
	if(props==='ALL'){
		send['url']='v1/student/course';
		Fetch(send).then(data=>{
			send['url']='v1/course';
			store['variation']['student']['course']=data['payloads'];

			Fetch(send).then(data=>{
				send['url']='v1/teacher/account';
				store['variation']['teacher']['course']=data['payloads']['courses'];

				Fetch(send).then(data=>{
					send['url']='v1/teacher/line';
					store['variation']['teacher']['account']=data['payloads'];

					Fetch(send).then(data=>{
						send['url']='v1/serma/project';
						store['variation']['teacher']['line']=data['payloads'];

						Fetch(send).then(data=>{
							send['url']='v1/profile';
							store['variation']['serma']['project']=data['payloads'];

							Fetch(send).then(data=>{
								store=Object.assign(store,data['payloads']);
								/*store['UserId']=data['payloads']['UserId'];
								store['UserType']=data['payloads']['UserType'];
								store['UserRight']=data['payloads']['UserRight'];
								//store['UserName']=data['payloads']['UserName'];
								Store.dispatch(Variation(store));
							}).catch(error=>console.log(error));
						}).catch(error=>console.log(error));
					}).catch(error=>console.log(error));
				}).catch(error=>console.log(error));
			}).catch(error=>console.log(error));
		}).catch(error=>console.log(error));
	}else{
		switch(props){
			case 'StudentCourse':
				send['url']='v1/student/course';
				key=['student','course'];
				break;
			case 'TeacherCourse':
				send['url']='v1/course';
				key=['teacher','course'];
				break;
			case 'TeacherAccount':
				send['url']='v1/teacher/account';
				key=['teacher','account'];
				break;
			case 'TeacherLine':
				send['url']='v1/teacher/line';
				key=['teacher','line'];
				break;
			case 'Serma':
				send['url']='v1/serma/project'
				key=['serma','project'];
				break;
			case 'Profile':
				send['url']='v1/profile'
				break;
		}
		Fetch(send).then(data=>{
			if(props==='Profile'){
				store=Object.assign(store,data['payloads']);
			}else if(props==='TeacherCourse'){
				store['variation'][key[0]][key[1]]=data['payloads']['courses'];
			}else{
				store['variation'][key[0]][key[1]]=data['payloads'];
			}
			//Store.dispatch(Variation(store));
			Store.dispatch(Variation({TestKey:1,test:1,TEST:'TEST'}))
			if(reload){
				window.location.reload();
			}
		}).catch(error=>console.log(error));
	}
}*/