export default function getParams(){
    let SplitUrl=window.location.href.split('?');
    let params = {}

    if (SplitUrl[1]) {
        let urlParamStr = SplitUrl[1];
        urlParamStr.split('&').forEach( param => {
            const temp = param.split('=')
            params = {
                ...params,
                [temp[0]]: decodeURI(temp[1])
            }
        });
    }
    return params;
}