import { useEffect, useState } from "react";
import ReactDOM from 'react-dom';
import { useParams } from "react-router";
import BreadCrumb from "../component/breadcrumb";
import Fetch from "../common/fetch";
import AlertBox from "../component/AlertBox";
import ReportForm from "../component/ReportForm";
import Upload from "../common/upload";
import SendJson from "../component/SendJson";
import Loading from "../component/Loading";

let LoadingMsg=[];
export default function EntryReport(){
    const {course,id}=useParams();
    const [BCrumb,setBCrumb]=useState({'CurrentText':'新規課題','items':[{'index':1,'href':'/teacher','text':'ホーム'}]});
    const [Alert,setAlert]=useState({'type':'danger','style':false,'msgs':[]});
    let parent;

	const [Visible,setVisible]=useState(false);
	const [Msg,setMsg]=useState(LoadingMsg);

    useEffect(()=>{
        setVisible(true);
        parent=document.getElementById('report_area');
        let send={
            'url':'/v1/course/'+course,
            'method':'GET'
        }
        Fetch(send).then(data=>{
            if(data['result']){
                setAlert({'type':'danger','style':false,'msgs':[]});
                ReactDOM.render(<ReportForm Submit={doEntry} />,parent);
                setBCrumb({
                    'CurrentText':'新規課題',
                    'items':[
                        {
                            'index':1,
                            'href':'/teacher',
                            'text':'ホーム'
                        },
                        {
                            'index':2,
                            'href':'/teacher/course/'+course,
                            'text':data['payloads']['CourseName']
                        },
                    ]
                });
            }else{
                setAlert({'type':'danger','style':true,'description':data['error']['description']});
            }
            setVisible(false);
        }).catch((error)=>{
            LoadingMsg.push({type:'warning',title:'エラー',content:'通信エラーが発生しました'});
            setMsg(LoadingMsg);
            LoadingMsg=[];
            setVisible(false);

            console.log(error);
        });
    },[])
    function doEntry(){
        
		setVisible(true);
		LoadingMsg.push({type:'info',title:'確認中',content:'ファイルを確認中',delay:5000});
		setMsg(LoadingMsg);

        let datas=parent.getElementsByClassName('js-file');
        if(datas.length>1){
            let fd=new FormData();
            for(let i=1;i<datas.length;++i){
                fd.append('file['+i+']',datas[i].files[0]);
                fd.append('FileName['+i+']',datas[i].dataset.name);
            }

            LoadingMsg.push({type:'info',title:'アップロード中',content:'ファイルをアップロード中',delay:5000});
            setMsg(LoadingMsg);
    
            Upload(fd).then(data=>{
                if(data['result']){
                    LoadingMsg.push({type:'success',title:'アップロード完了',content:'ファイルをアップロードしました',delay:5000});
                    setMsg(LoadingMsg);
            
                    doSubmit(data['payloads']['FileIds']);
                }else{
                    LoadingMsg.push({type:'danger',title:'アップロード失敗',content:'ファイルをアップロードできませんでした',delay:5000});
                    setMsg(LoadingMsg);
                    LoadingMsg=[];
                    setVisible(false);
                    window.scroll({top:0});

                    setAlert({'type':'danger','style':true,'description':data['error']['description']});
                }
            }).catch((error)=>{
                LoadingMsg.push({type:'warning',title:'エラー',content:'通信エラーが発生しました'});
                setMsg(LoadingMsg);
                LoadingMsg=[];
                setVisible(false);
    
                console.log(error);
            });
        }else{
            doSubmit([]);
        }
    }
    function doSubmit(FileId){
        LoadingMsg.push({type:'info',title:'確認中',content:'入力内容を確認しています',delay:5000});
        setMsg(LoadingMsg);
        let {error,body}=SendJson(parent.getElementsByClassName('js-data'),true);
        
        if(error.length){
            setAlert({'type':'danger','style':true,'msgs':error});

			LoadingMsg.push({type:'danger',title:'入力不備',content:'入力内容を確認してください',delay:5000});
			setMsg(LoadingMsg);
			setVisible(false);
            window.scroll({top:0});

            return;

        }else{
            let send={
                'url':'/v1/course/'+course+'/report',
                'method':'POST',
                'body':body
            }
            send['body']['files']=FileId;

            LoadingMsg.push({type:'info',title:'送信中',content:'入力内容を送信しています',delay:5000});
            setMsg(LoadingMsg);

            Fetch(send).then(data=>{
                if(data['result']){

                    LoadingMsg.push({type:'success',title:'送信完了',content:'入力内容を送信しました',delay:5000});
                    setMsg(LoadingMsg);
                    parent.remove();

                    setAlert({'type':'success','style':true,'msgs':['課題を登録しました。']});
                }else{
                    LoadingMsg.push({type:'danger',title:'エラー',content:'入力内容が保存できませんでした',delay:5000});
                    setMsg(LoadingMsg);

                    setAlert({'type':'danger','style':true,'description':data['error']['description']});
                }
                setVisible(false);
                LoadingMsg=[];
                window.scroll({top:0});
            }).catch((error)=>{
                LoadingMsg.push({type:'warning',title:'エラー',content:'通信エラーが発生しました'});
                setMsg(LoadingMsg);
                LoadingMsg=[];
                setVisible(false);

                console.log(error);
            });
        }
    }
    return(
        <div>
            <BreadCrumb item={BCrumb} />
            <AlertBox Item={Alert} />
            <Loading visible={Visible} msgs={Msg} />
            <div id="report_area"></div>
        </div>
    )
}