import { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Redirect, Route, Switch, Link } from "react-router-dom";
import Fetch from "./common/fetch";
import BreadCrumb from "./component/breadcrumb";
import CardList from "./component/CardList";
import Loading from "./component/Loading";
import Init from "./lp/init";
import Page from "./lp/page";
import Setting from "./lp/setting";

export default function Lp(){
    return (
        <Switch>
            <Route path="/lp" exact>
                <Home />
            </Route>
            <Route path="/lp/init" exact>
                <Init />
            </Route>
            <Route path="/lp/page/:id" exact>
                <Page />
            </Route>
            <Route path="/lp/setting">
                <Setting />
            </Route>
            <Route path="/lp">
                <Redirect to="/lp" />
            </Route>
        </Switch>
    );
}
function Home(){
    const BCrumb={
        'CurrentText':'ホーム',
        'items':[]
    };
    const [Visible,setVisible]=useState(true);
    const [Item,setItem]=useState();
    const setting={
        'title':'その他',
        'items':[
            {
                index:0,
                href:"/lp/setting",
                text:'設定'
            }
        ]
    };
    useEffect(()=>{
        let send={
            url:"v1/lp/edit",
            method:'GET'
        };
        Fetch(send).then(data=>{
            if(data['result']){
                let items=[];
                for(let i=0;i<data['payloads']['pages'].length;++i){
                    items.push({
                        'index':i,
                        'href':'/lp/page/'+data['payloads']['pages'][i]['uri'],
                        'text':data['payloads']['pages'][i]['title']
                    })
                }
                setItem({
                    url:data['payloads']['url'],
                    pages:{
                        'title':'ページ',
                        'items':items
                    }
                });
            }else{
                //登録ページ
                setItem({
                    url:false,
                    pages:{
                        'title':'ページ',
                        'items':[]
                    }
                });
            }
            setVisible(false);
        }).catch(error=>{
            setVisible(false);
            console.log(error)
        })
    },[])
    return (
        <div>
            <BreadCrumb item={BCrumb} />
            <Loading visible={Visible} />{Item?
            <Row>
                <Col sm="12" className="mb-2">
                    <Form.Group>
                        <Form.Label>
                            あなたのURL
                        </Form.Label>
                        {Item['url']?<Form.Control type="text" readonly value={Item['url']} onFocus={e=>{e.target.select();}} />:<Redirect to="/lp/init" />}
                    </Form.Group>
                </Col>
                <Col sm="6">
                    <CardList item={Item['pages']} />
                    <Link to="/lp/page/new">新しいページ</Link>
                </Col>
                <Col>
                    <CardList item={setting} />
                </Col>
            </Row>:""}
        </div>
    );
}