import Escape from "./Escape";


export default function ExaminationOutline(props){
    let Exam;
    if(props.Item!=undefined){
        Exam=props.Item;
    }else{
        Exam={
            "CourseName": "",
            "ExaminationTitle": "TITLE",
            "note": "",
            "deadline": "",
            "PublicDate": 0,
            "EntriedNumber": 5,
            "FromDate": "",
            "ToDate": "",
            "score": 0,
            "multi": true,
            "limit": 5,
            "questions": []
        }
    }
    return(
        <div>
            <h2>
                {Exam['ExaminationTitle']}
            </h2>
            <table className="table table-stripe table-hover">
                <tbody>
                <tr>
                    <th>
                    解答締切
                    </th>
                    <td>
                    {Exam['deadline']}
                    </td>
                </tr>
                <tr>
                    <th>
                    複数回受験
                    </th>
                    <td>
                    {Exam['multi'] ? <span>許可</span>:<span>許可されていません</span>}
                    </td>
                </tr>
                <tr>
                    <th>
                    制限時間
                    </th>
                    <td>
                    {Exam['limit']}分
                    </td>
                </tr>
                <tr>
                    <th>
                    得点
                    </th>
                    <td>
                    {Exam['score']}点
                    </td>
                </tr>
                <tr>
                    <th>
                    備考
                    </th>
                    <td>
                        {Escape(Exam['note'])}
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    )
}