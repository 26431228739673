import { useEffect, useState } from "react";
import ReactDOM from 'react-dom';
import BreadCrumb from "../component/breadcrumb";
import Fetch from "../common/fetch";
import AlertBox from "../component/AlertBox";
import { Link } from "react-router-dom";
import { Accordion } from "react-bootstrap";
import Escape from "../component/Escape";
import SendJson from "../component/SendJson";

export default function Support(){
    const BCrumb={'CurrentText':'サポート','items':[{'index':1,'href':'/admin','text':'ホーム'}]};
    const [Item,setItem]=useState([false,{}]);
    const [Alert,setAlert]=useState({'type':'danger','style':false,'msgs':[]});
    useEffect(()=>{
        let send={
            'url':'v1/admin/support',
            'method':'GET'
        }
        Fetch(send).then(data=>{
            if(data['result']){
                setAlert({'type':'danger','style':false,'msgs':[]});
                //ReactDOM.render(< Item={data['payloads']} Submit={doEntry} />,document.getElementById('content_area'));
                setItem([true,data['payloads']]);
            }else{
                setAlert({'type':'danger','style':true,'description':data['error']['description']});
            }
        }).catch(error=>console.log(error));
    },[])
    function changeQA(e){
        let id=e.target.dataset.id;
        let tar=document.getElementById('AdminSupport_'+id).getElementsByClassName('js-QA')[0].style;
        if(e.target.checked){
            tar.display="flex";
        }else{
            tar.display="none";
        }
    }
    function copyAnswer(e){
        let id=e.target.dataset.id;
        let parent=document.getElementById('AdminSupport_'+id);
        parent.getElementsByClassName('js-QaAnswer')[0].value=parent.getElementsByClassName('js-answer')[0].value;
    }
    function doEntry(e){
        let id=e.target.dataset.id;
        let parent=document.getElementById('AdminSupport_'+id);
        let {error,body}=SendJson(parent.getElementsByClassName('js-answer'),true);
        if(error.length){
            setAlert({'type':'danger','style':false,'msgs':error});
            return;
        }else{
            if(parent.getElementsByClassName('js-QA-check')[0].checked){
                let QA=SendJson(parent.getElementsByClassName('js-QA')[0].getElementsByClassName('js-data'),true);
                if(QA['error'].length){
                    setAlert({'type':'danger','style':true,'msgs':error});
                    return;
                }
            }
            let send={
                'url':'v1/admin/support/'+id,
                'method':'PUT',
                'body':body
            }
            Fetch(send).then(data=>{
                if(data['result']){
                    setAlert({'type':'success','style':true,'msgs':["回答を登録しました。"]});
                    console.log(parent.getElementsByClassName('js-answer')[0]);
                    console.log(parent.getElementsByClassName('js-answer')[0].value);
                    parent.parentNode.innerHTML=parent.getElementsByClassName('js-answer')[0].value;
                    if(parent.getElementsByClassName('js-QA-check')[0].checked){
                        let QA=SendJson(parent.getElementsByClassName('js-QA')[0].getElementsByClassName('js-data'),true);
                        send['url']='v1/admin/qa';
                        send['method']='POST';
                        send['body']=QA['body'];
                        Fetch(send).then(data=>{
                            if(data['result']){
                                setAlert({'type':'success','style':true,'msgs':["回答を登録しました。","よくあるお問い合わせを登録しました。"]});
                            }else{
                                setAlert({'type':'danger','style':true,'description':data['error']['description']});
                            }
                        }).catch(error=>console.log(error));
                    }
                }else{
                    setAlert({'type':'danger','style':true,'description':data['error']['description']});
                }
            }).catch(error=>console.log(error));
        }
    }
    return(
        <div>
            <BreadCrumb item={BCrumb} />
            <AlertBox Item={Alert} />
            <div className="list list-flush">
                <Accordion defaultActiveKey="0">
                    {Item[0] && 
                    Item[1].map(support=>
                    <Accordion.Item key={support['SupportId']} eventKey={support['SupportId']}>
                        <Accordion.Header>
                            {support['SupportTitle']}
                            {!support['answer'] && <span className="btn btn-danger">未回答</span>}
                        </Accordion.Header>
                        <Accordion.Body>
                            <h4 className="my-1">
                                質問内容
                            </h4>
                            <div className="my-1">
                                {Escape(support['content'])}
                            </div>
                            <h4 className="mt-3 my-1">
                                回答内容
                            </h4>
                            <div className="my-1">
                                {support['answer']?Escape(support['answer']):
                                <form id={"AdminSupport_"+support['SupportId']}>
                                    <div className="row my-2">
                                        <div className="col-sm-2">
                                            回答
                                        </div>
                                        <div className="col-sm-10">
                                            <textarea name="answer" className="form-control js-data js-answer" placeholder="回答" required />
                                            <label>
                                                <input type="checkbox" name="QA" className="form-check-input js-data js-QA-check" value="true" data-type="boolean" data-id={support['SupportId']} onChange={changeQA} />
                                                よくある問い合わせに登録
                                            </label>
                                        </div>
                                    </div>
                                    <div className="row my-2 js-QA" style={{display:'none'}}>
                                        <div className="col-sm-2">
                                            よくある問い合わせ
                                        </div>
                                        <div className="col-sm-10">
                                            <div className="input-group mb-2">
                                                <span className="input-group-text">タイトル</span>
                                                <input type="text" name="QaTitle" className="form-control js-data" placeholder="タイトル" defaultValue={support['SupportTitle']} />
                                            </div>
                                            <div className="input-group my-2">
                                                <span className="input-group-text">質問内容</span>
                                                <textarea name="QaContent" className="form-control js-data" placeholder="質問内容" defaultValue={support['content']} />
                                            </div>
                                            <div className="input-group my-2">
                                                <div className="input-group-text">
                                                    回答内容
                                                    <button type="button" className="btn btn-success ms-2 fas fa-copy" data-id={support['SupportId']} onClick={copyAnswer}></button>
                                                </div>
                                                <textarea name="QaAnswer" className="form-control js-data js-QaAnswer" placeholder="回答内容" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col">
                                            <button type="button" className="btn btn-primary" data-id={support['SupportId']} onClick={doEntry}>
                                                登録
                                            </button>
                                        </div>
                                    </div>
                                </form>
                                }
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                    )}
                </Accordion>
            </div>
        </div>
    )
}