import { useEffect, useState } from "react";
import ReactDOM from 'react-dom';
import BreadCrumb from "../component/breadcrumb";
import Fetch from "../common/fetch";
import AlertBox from "../component/AlertBox";
import LineForm from "../component/LineForm";
import { updateVariation } from "../../App";

export default function EntryLine(){
    const [BCrumb,setBCrumb]=useState({'CurrentText':'公式LINE登録','items':[{'index':1,'href':'/teacher','text':'ホーム'}]});
    const [Alert,setAlert]=useState({'type':'danger','style':false,'msgs':[]});
    useEffect(()=>{
        ReactDOM.render(<LineForm Submit={doEntry} />,document.getElementById('content_area'));
    },[])
    function doEntry(send){
        Fetch(send).then(data=>{
            if(data['result']){
                setAlert({'type':'success','style':true,'msgs':["公式LINEを登録しました。"]});
                updateVariation('TeacherLine');
                //window.location.href="/teacher";
            }else{
                setAlert({'type':'danger','style':true,'description':data['error']['description']});
            }
        }).catch(error=>console.log(error));
    }
    return(
        <div>
            <BreadCrumb item={BCrumb} />
            <AlertBox Item={Alert} />
            <div id="content_area"></div>
        </div>
    )
}