import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import BreadCrumb from "../component/breadcrumb";
import Fetch from "../common/fetch";
import AlertBox from "../component/AlertBox";
import ExaminationOutline from "../component/ExaminationOutline";

export default function Examination(){
    const {course,id}=useParams();
    const [BCrumb,setBCrumb]=useState({'CurrentText':'テスト','items':[{'index':1,'href':'/teacher','text':'ホーム'}]});
    const [Alert,setAlert]=useState({'type':'danger','style':false,'msgs':[]});
    const [GradeAlert,setGradeAlert]=useState({'type':'danger','style':false,'msgs':[]});
    const [Data,setData]=useState();
    const [Quiz,setQuiz]=useState([]);
    const [Submits,setSubmits]=useState([]);
    const [Result,setResult]=useState({
        "StudentExaminationId": "",
        "UserName":"",
        "SubmitDatetime": "",
        "answers": []
    });
    const [Score,setScore]=useState(0);
    useEffect(()=>{
        let send={
            'url':'v1/course/'+course+'/examination/'+id,
            'method':'GET'
        }
        Fetch(send).then(data=>{
            if(data['result']){
                setAlert({'type':'danger','style':false,'msgs':[]});
                setData(data['payloads']);
                let answers=[];
                for(let i=0;i<data['payloads']['questions'].length;++i){
                    answers.push({
                        answer:"",
                        gradings:{
                            score:"",
                            comment:""
                        }
                    })
                }
                setResult({
                    "StudentExaminationId": "",
                    "UserName":"",
                    "SubmitDatetime": "",
                    "answers": answers
                });
                setQuiz(data['payloads']['questions']);
                setBCrumb({
                    CurrentText: data['payloads']['ExaminationTitle'],
                    items: [
                        {
                            index: 1,
                            href: "/teacher",
                            text: "ホーム"
                        },
                        {
                            index:2,
                            href:'/teacher/course/'+course,
                            text:data['payloads']['CourseName']
                        }
                    ],
                });
            }else{
                setAlert({'type':'danger','style':true,'description':data['error']['description']});
            }
        }).catch(error=>console.log(error));
        send['url']='v1/course/'+course+'/examination/'+id+'/grading';
        Fetch(send).then(data=>{
            if(data['result']){
                setAlert({'type':'danger','style':false,'msgs':[]});
                setSubmits(data['payloads']);
            }else{
                setAlert({'type':'danger','style':true,'description':data['error']['description']});
            }
        }).catch(error=>console.log(error));
    },[])
    function clickUser(e){
        setScore(0);
        setResult(Submits[e.target.dataset.id]);
    }
    function updateScore(e){
        if(Number(e.target.value)>Number(e.target.max)){
            alert('最高点を超えています。');
            e.target.value=e.target.max;
        }
        let scores=document.getElementsByClassName('js-data-grading-score');
        let score=0;
        for(let i=0;i<scores.length;++i){
            score+=Number(scores[i].value);
        }
        setScore(score);
    }
    function doGrade(){
        let body=[];
        let gradings=document.getElementsByClassName('js-data-grading');
        for(let i=0;i<gradings.length;++i){
            body.push({
                'QuestionId':gradings[i].dataset.id,
                'score':Number(gradings[i].getElementsByClassName('js-data-grading-score')[0].value),
                'comment':gradings[i].getElementsByClassName('js-data-grading-comment')[0].value
            })
        }
        let send={
            'url':'v1/course/'+course+'/examination/'+id+'/grading/'+Result['StudentExaminationId'],
            'method':'PUT',
            'body':body
        }
        Fetch(send).then(data=>{
            if(data['result']){
                setGradeAlert({'type':'success','style':true,'msgs':["採点結果を登録しました。"]});
            }else{
                setGradeAlert({'type':'danger','style':true,'description':data['error']['description']});
            }
        }).catch(error=>console.log(error));
    }
    return(
        <div>
            <BreadCrumb item={BCrumb} />
            <AlertBox Item={Alert} />
            <div className="row">
                <div className="col-12">
                    <ExaminationOutline Item={Data} />
                    <div className="text-end">
                        <Link to={"/teacher/course/"+course+'/examination/'+id+'/edit'} className="btn btn-secondary">
                            編集
                        </Link>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <h3>
                        受験済みテスト
                    </h3>
                    <div className="list-group list-group-flush">{Submits.map((submit,index)=>
                        <button type="button" className="btn list-group-item list-group-item-action" data-bs-toggle="modal" data-bs-target="#gradeExaminationModal" data-id={index} key={index} onClick={clickUser}>
                            {submit['UserName']}
                        </button>)}
                    </div>
                    <div className="modal fade w-100" id="gradeExaminationModal" tabIndex={-1} aria-labelledby="gradeExaminationModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-xl modal-dialog-scrollable">
                            <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="gradeExaminationModalLabel">{Result['UserName']}</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                            </div>
                            <div className="modal-body">
                                <table className="table table-stripe">
                                    <tbody>
                                        <tr>
                                            <th>
                                                提出日時
                                            </th>
                                            <td>
                                                {Result['SubmitDatetime']}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>
                                                合計点
                                            </th>
                                            <td>
                                                <span id="result_score">{Score}</span>点
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <form>
                                    <ol>{Quiz.map((quiz,index)=>
                                        <li key={index}>
                                            <div className="fw-bold">
                                                {quiz['question']}
                                            </div>
                                            <div>
                                                <ConvertMethod Item={quiz} Answer={Result['answers'][index]['answer']} />
                                            </div>
                                            <div className="row js-data-grading my-2" data-id={quiz['QuestionId']}>
                                                <div className="col-sm-auto">
                                                    <div className="input-group">
                                                        <span className="input-group-text">得点</span>
                                                        <input type="number" className="js-data-grading-score form-control" placeholder="得点" min="0" max={quiz['score']} defaultValue={Result['answers'][index]['gradings']['score']} onChange={updateScore} required  />
                                                        <span className="input-group-text">/{quiz['score']}点</span>
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <div className="input-group">
                                                        <span className="input-group-text">講評</span>
                                                        <textarea className="js-data-grading-comment form-control" placeholder="講評">{Result['answers'][index]['gradings']['comment']}</textarea>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>)}
                                    </ol>
                                </form>
                                <div className="mt-2">
                                    <AlertBox Item={GradeAlert} />
                                    <button type="button" className="btn btn-primary" onClick={doGrade}>採点</button>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export function ConvertMethod(props){
    const items=props.Item;
    switch(items.method){
        case 'ShortText':
            return (<input type="text" className="form-control" defaultValue={props.Answer} readOnly />);
            break;
        case 'LongText':
            return (<textarea className="form-control" defaultValue={props.Answer} readOnly  />);
            break;
        case 'radio':
        case 'checkbox':
            return(
                <div className="row">
                    {items.options.map(opt=><Options type={items.method} index={items['QuestionId']} option={opt.option} optIndex={opt.OptionId} Answer={props.Answer} />)}
                </div>
            );
            break;
        case 'supplementation':
        default:
            return(<span></span>);
    }
}
function Options(props){
    let check;
    if(Array.isArray(props.Answer)){
        if(props['Answer'].indexOf(props.optIndex)>-1){
            check=true;
        }else{
            check=false;
        }
    }else{
        check=false;
    }
    return (
        <div className="col-auto me-2">
            <input type={props.type} id={"question_"+props.index+'_'+props.optIndex} className="form-check-input" key={props.optIndex} disabled checked={check} />
            <label htmlFor={"question_"+props.index+'_'+props.optIndex} className="form-check-label">
                {props.option}
            </label>
        </div>
    );
}