import { Col, FormControl, Row } from "react-bootstrap";
import SendJson from "./SendJson";

export default function UserForm(props){
    let User;
    let Create;
    if(props.Item===undefined){
        User={
            'email':'',
        }
        Create=true;
    }else{
        User=props.Item;
        Create=false;
    }
    function doSubmit(){
        props.Submit(SendJson(document.getElementsByClassName('js-data'),true));
    }
    return (
        <>
        {Create &&
            <div className="row my-2">
                <label className="col-sm-2 col-form-label">
                    メールアドレス
                </label>
                <div className="col-sm-10">
                    <input type="email" name="email" data-label="メールアドレス" className="form-control js-data" placeholder="メールアドレス" defaultValue={User['email']} required />
                </div>
            </div>}{!Create &&
            <div className="row my-2">
                <label className="col-sm-2 col-form-label">
                    現在のパスワード
                </label>
                <div className="col-sm-10">
                    <input type="password" name="CurrentPassword" data-label="現在のパスワード" className="form-control js-data" placeholder="現在のパスワード" autoComplete="current-password" required />
                </div>
            </div>}
            <div className="row my-2">
                <label className="col-sm-2 col-form-label">
                    新しいパスワード
                </label>
                <div className="col-sm-10">
                    <input type="password" name="password" data-label="パスワード" className="form-control js-data" placeholder="新しいパスワード" autoComplete="new-password" required />
                </div>
            </div>
            <div className="row my-2">
                <label className="col-sm-2 col-form-label">
                    確認用パスワード
                </label>
                <div className="col-sm-10">
                    <input type="password" name="password_confirmation" data-label="確認用パスワード" className="form-control js-data" placeholder="確認用パスワード" autoComplete="confirm-password" required />
                </div>
            </div>{Create &&
            <div className="row my-3">
                <div className="col-12">
                    <Accept />
                </div>
            </div>}
            <div className="row mt-2">
                <div className="col">
                    <button type="button" className="btn btn-primary" onClick={doSubmit}>登録</button>
                </div>
            </div>
        </>
    );
}
export function Accept(){
    return(
        <>
            <input type="checkbox" name="accept" id="acceptance" className="form-check-input js-data" defaultValue="true" data-label="同意" data-type="boolean" required />&nbsp;
            <label htmlFor="acceptance" className="form-label">
                CanTeachアカウント作成に関し，
            </label>
            <a href="https://canteach.jp/canteach-term/" target="_blank">CanTeach利用規約</a>と<a href="https://canteach.jp/privacy-policy/" target="_blank">プライバシーポリシー</a>
            <label htmlFor="acceptance" className="form-label">
                を確認の上，同意します。
            </label>
        </>
        );
}