import Host from "./Host";

export default function FolderForm(props){
    let Folder;
    if(props.Item===undefined){
        Folder={
            "FolderName": "",
            "icon": Host('assets/img/noimage.jpg'),
            "parent": ""
        }
    }else{
        Folder=props.Item;
    }
    function changeIcon(e){
        document.getElementById('SampleIcon').src=window.URL.createObjectURL(e.target.files[0]);
    }
    return(
        <div id="folder_form">
            <div className="row">
                <label className="col-sm-2 my-2 col-form-label">
                    フォルダー名
                </label>
                <div className="col-sm-10 my-2">
                    <input type="text" name="FolderName" id="FolderName" className="form-control" placeholder="フォルダー名" defaultValue={Folder['FolderName']} required />
                </div>
            </div>
            <div className="row">
                <label className="col-sm-2 my-2 col-form-label">
                    サムネイル
                </label>
                <div className="col-sm-10 my-2">
                    <img id="SampleIcon" src={Folder['icon']} className="files__icon-lg mb-2" />
                    <input type="file" id="FolderIcon" className="form-control" placeholder="フォルダー名" accept="image/*" onChange={changeIcon} required />
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <button type="button" className="btn btn-primary" onClick={props.Submit}>登録</button>
                </div>
            </div>
        </div>
    );
}