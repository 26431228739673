import { useEffect, useState } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { Redirect } from "react-router-dom";
import Fetch from "../common/fetch";
import AlertBox from "../component/AlertBox";
import BreadCrumb from "../component/breadcrumb";
import Loading from "../component/Loading";
import SendJson from "../component/SendJson";

export default function Init(){
    const BCrumb={
        'CurrentText':'利用登録',
        'items':[
            {
                index:0,
                href:'/lp',
                text:'ホーム'
            }
        ]
    };
    const [Visible,setVisible]=useState(true);
    const [Item,setItem]=useState();
    const [Alert,setAlert]=useState({'type':'danger','style':false,'msgs':[]});
    function doEntry(){
        setVisible(true);
        const {error,body}=SendJson(document.getElementsByClassName('js-data'),true);
        if(error.length){
            setVisible(false);
            setAlert({'type':'danger','style':true,'msgs':error});
            return;
        }else{
            let send={
                url:'/v1/lp/init',
                method:'POST',
                body:body
            }
            Fetch(send).then(data=>{
                setItem(data['result']);
                if(!data['result']){
                    setAlert({'type':'danger','style':true,'description':data['error']['description']});
                }
            }).catch(error=>{
                setVisible(false);
                setAlert({'type':'danger','style':true,'msgs':['通信エラーが発生しました。']});
                console.log(error);
            })
        }
    }
    useEffect(()=>{
        let send={
            url:"v1/lp/edit",
            method:'GET'
        };
        Fetch(send).then(data=>{
            setItem(data['result']);
            setVisible(false);
        }).catch(error=>{
            setVisible(false);
            console.log(error)
        })
    },[])
    return (
        <div>
            <BreadCrumb item={BCrumb} />
            <AlertBox Item={Alert} />
            <Loading visible={Visible} />{Item?<Redirect to="/lp" />:
            <div>
                <h3>
                    LP利用登録
                </h3>
                <Form.Group>
                    <Form.Label>
                        ご希望のURLを入力してください。
                        ※変更不可
                    </Form.Label>
                    <InputGroup>
                        <InputGroup.Text>https://lp.canteach.jp/</InputGroup.Text>
                        <Form.Control type="text" name="uri" className="js-data" data-label="URL" placeholder="URL" required />
                        ※英字は全て小文字に変換されます。
                    </InputGroup>
                </Form.Group>
                <Button variant="primary" type="button" className="mt-2" onClick={doEntry}>登録</Button>
            </div>}
        </div>
    );
}