import { useEffect, useState } from "react";
import ReactDOM from 'react-dom';
import BreadCrumb from "../component/breadcrumb";
import Fetch from "../common/fetch";
import AlertBox from "../component/AlertBox";
import { useParams } from "react-router";
import SeminarFrom from "./SeminarForm";

export default function Seminar(){
    const {id}=useParams();
    const [BCrumb,setBCrumb]=useState({'CurrentText':'','items':[{'index':1,'href':'/serma','text':'ホーム'}]});
    const [Alert,setAlert]=useState({'type':'danger','style':false,'msgs':[]});
    useEffect(()=>{
        let send={
            'url':'v1/serma/project/'+id+'/seminar',
            'method':'GET'
        }
        Fetch(send).then(data=>{
            if(data['result']){
                setAlert({'type':'danger','style':false,'msgs':[]});
                ReactDOM.render(<SeminarFrom Item={data['payloads']['seminars']} Submit={doEntry} />,document.getElementById('content_area'));
                setBCrumb({
                    CurrentText: "セミナー",
                    items: [
                        {
                            index: 1,
                            href: "/serma",
                            text: "ホーム"
                        },
                        {
                            index:2,
                            href:'/serma/project/'+id,
                            text:data['payloads']['ProjectName']
                        }
                    ],
                });
            }else{
                setAlert({'type':'danger','style':true,'description':data['error']['description']});
            }
        }).catch(error=>console.log(error));
    },[])
    function doEntry(body){
        let send={
            'url':'v1/serma/project/'+id+'/seminar',
            'method':'POST',
            'body':body
        }
        Fetch(send).then(data=>{
            if(data['result']){
                setAlert({'type':'success','style':true,'msgs':["セミナーを登録しました。"]});
                window.scroll({top:0})
            }else{
                setAlert({'type':'danger','style':true,'description':data['error']['description']});
            }
        }).catch(error=>console.log(error));
    }
    return(
        <div>
            <BreadCrumb item={BCrumb} />
            <AlertBox Item={Alert} />
            <div id="content_area"></div>
        </div>
    )
}