import { useEffect, useState } from "react";
import ReactDOM from 'react-dom';
import BreadCrumb from "../component/breadcrumb";
import Fetch from "../common/fetch";
import AlertBox from "../component/AlertBox";
import { useParams } from "react-router";
import { AdminInfoTo, AdminInfoType } from "../component/Keys";
import Escape from "../component/Escape";

export default function InfoDetail(){
    const [BCrumb,setBCrumb]=useState({'CurrentText':'','items':[{'index':1,'href':'/admin','text':'ホーム'},{'index':2,'href':'/admin/infos','text':'お知らせ'}]});
    const [Alert,setAlert]=useState({'type':'danger','style':false,'msgs':[]});
    const {id}=useParams();
    useEffect(()=>{
        let send={
            'url':'v1/admin/info/'+id,
            'method':'GET'
        }
        Fetch(send).then(data=>{
            if(data['result']){
                setAlert({'type':'danger','style':false,'msgs':[]});
                ReactDOM.render(<Info Item={data['payloads']} Submit={doEntry} />,document.getElementById('content_area'));
                setBCrumb({
                    CurrentText: data['payloads']['InfoTitle'],
                    items: [
                        {
                            index: 1,
                            href: "/admin",
                            text: "ホーム"
                        },
                        {
                            index:2,
                            href:'/admin/infos',
                            text:"お知らせ"
                        }
                    ],
                });
            }else{
                setAlert({'type':'danger','style':true,'description':data['error']['description']});
            }
        }).catch(error=>console.log(error));
    },[])
    function doEntry(){
        let send={
            'url':'v1/admin/',
            'method':'PUT'
        }
        Fetch(send).then(data=>{
            if(data['result']){
                setAlert({'type':'success','style':true,'msgs':[]});
            }else{
                setAlert({'type':'danger','style':true,'description':data['error']['description']});
            }
        }).catch(error=>console.log(error));
    }
    return(
        <div>
            <BreadCrumb item={BCrumb} />
            <AlertBox Item={Alert} />
            <div id="content_area"></div>
        </div>
    )
}

function Info({Item}){
    return(
        <div className="row">
            <div className="col-12">
                <div className="card">
                    <div className="card-header">
                    {Item['InfoTitle']}
                    </div>
                    <div className="card-body">
                    {Escape(Item['InfoContent'])}
                    </div>
                    <div className="card-footer">
                        <p className="m-0">
                            掲載日時：{Item['PostedDate']}
                        </p>
                        <p className="m-0">
                            配信種類：{AdminInfoType(Item['type'])}
                        </p>
                        <p className="m-0">
                            対象利用者：{AdminInfoTo(Item['to'])}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}