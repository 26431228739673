import { useEffect, useState } from "react";
import ReactDOM from 'react-dom';
import BreadCrumb from "../component/breadcrumb";
import Fetch from "../common/fetch";
import AlertBox from "../component/AlertBox";
import { ConvertToDisplayDate } from "../component/Keys";

export default function Courses(){
    const BCrumb={'CurrentText':'コース管理','items':[{'index':1,'href':'/admin','text':'ホーム'}]};
    const [Item,setItem]=useState([false,{}]);
    const [Alert,setAlert]=useState({'type':'danger','style':false,'msgs':[]});
    useEffect(()=>{
        let send={
            'url':'v1/admin/course',
            'method':'GET'
        }
        Fetch(send).then(data=>{
            if(data['result']){
                setAlert({'type':'danger','style':false,'msgs':[]});
                setItem([true,data['payloads']]);
            }else{
                setAlert({'type':'danger','style':true,'description':data['error']['description']});
            }
        }).catch(error=>console.log(error));
    },[])
    return(
        <div>
            <BreadCrumb item={BCrumb} />
            <AlertBox Item={Alert} />
            <div className="table-responsive">
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th>
                                コース名
                            </th>
                            <th>
                                登録日
                            </th>
                            <th>
                                開講日
                            </th>
                            <th>
                                終了日
                            </th>
                            <th>
                                講師
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {Item[0] && Item[1].map((course,index)=>
                        <tr key={index}>
                            <td>
                                <a href={"/course/"+course['CourseId']+"/syllabus"} target="_blank">{course['CourseName']}</a>
                            </td>
                            <td>
                                {ConvertToDisplayDate(course['EntriedDate'],true)}
                            </td>
                            <td>
                                {ConvertToDisplayDate(course['term'][0])}
                            </td>
                            <td>
                                {ConvertToDisplayDate(course['term'][1])}
                            </td>
                            <td>
                                {course['teachers'].map((teacher,index)=><li key={index}>
                                    <a href={"/profile/teacher/"+teacher['TeacherId']} target="_blank">
                                        {teacher['UserName']}
                                    </a>
                                </li>)}
                            </td>
                        </tr>)}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

function Course({Item}){
    return (
        <div className="table-responsive">
            <table className="table table-striped">
                <thead>
                    <tr>
                        <th>
                            コース名
                        </th>
                        <th>
                            登録日
                        </th>
                        <th>
                            開講日
                        </th>
                        <th>
                            終了日
                        </th>
                        <th>
                            講師
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {Item.map((course,index)=>
                    <tr key={index}>
                        <td>
                            {course['CourseName']}
                        </td>
                        <td>
                            {course['EntriedDate']}
                        </td>
                        <td>
                            {course['term'][0]}
                        </td>
                        <td>
                            {course['term'][1]}
                        </td>
                        <td>
                            {course['teachers'].map((teacher,index)=><li key={index}>{teacher['UserName']}</li>)}
                        </td>
                    </tr>)}
                </tbody>
            </table>
        </div>
    );
}