import { useEffect, useState } from "react";
import ReactDOM from 'react-dom';
import BreadCrumb from "../component/breadcrumb";
import Fetch from "../common/fetch";
import AlertBox from "../component/AlertBox";
import { useParams } from "react-router";
//import RichEditro from "../component/RichEditor";
import SendJson from "../component/SendJson";
//import RainbowPatissierLiveEditor from "../../lib/RainbowPatissierLiveEditor/RainbowPatissierLiveEditor";
import EmailVar from "./EmailVar";
import RainbowLiveEditor from "../../lib/LiCSSsLiveEditor/LiCSSsLiveEditor";

export default function Apply(){
    const {id}=useParams();
    const [BCrumb,setBCrumb]=useState({'CurrentText':'','items':[{'index':1,'href':'/serma','text':'ホーム'}]});
    const [Alert,setAlert]=useState({'type':'danger','style':false,'msgs':[]});
    const [Email,setEmail]=useState();
    const [Template,setTemplate]=useState();
    const [Item,setItem]=useState();
    const [MailStyle,setMailStyle]=useState({display:'none'});
    const [BodyStyle,setBodyStyle]=useState({display:'none'});
    function changeDisplay(e){
        let tr=document.getElementById('ApplyList').getElementsByTagName('tr');
        if(e.target.value==0){
            setMailStyle({display:'none'});
            setBodyStyle({display:'none'});
            for(let i=0;i<tr.length;++i){
                tr[i].style.display='table-row';
            }
        }else{
            setMailStyle({display:'inline-block'})
            for(let i=0;i<tr.length;++i){
                if(tr[i].dataset.id==e.target.value){
                    tr[i].style.display='table-row';
                }else{
                    tr[i].style.display='none';
                }
            }
        }
    }
    function exportCSV(){
        window.open(Item['export']+document.getElementById('FilterDisplay').value,'_blank');
    }
    useEffect(()=>{
        let send={
            'url':'v1/serma/project/'+id+'/apply',
            'method':'GET'
        }
        Fetch(send).then(data=>{
            if(data['result']){
                setAlert({'type':'danger','style':false,'msgs':[]});
                //ReactDOM.render(<ApplyList Item={data['payloads']} Submit={doEntry} Email={doEmail} Delete={doDelete} />,document.getElementById('content_area'));
                setItem(data['payloads'])
                setBCrumb({
                    CurrentText: "申込者一覧",
                    items: [
                        {
                            index: 1,
                            href: "/serma",
                            text: "ホーム"
                        },
                        {
                            index:2,
                            href:'/serma/project/'+id,
                            text:data['payloads']['ProjectName']
                        }
                    ],
                });
            }else{
                setAlert({'type':'danger','style':true,'description':data['error']['description']});
            }
        }).catch(error=>console.log(error));
        send={
            'url':'v1/admin/template/serma-email',
            'method':'GET'
        }
        Fetch(send).then(data=>{
            if(data['result']){
                setAlert({'type':'danger','style':false,'msgs':[]});
                setTemplate(data['payloads']['contents'])
            }else{
                setAlert({'type':'danger','style':true,'description':data['error']['description']});
            }
        }).catch(error=>console.log(error));
    },[])
    function doEmail(){
        setAlert({'type':'info','style':true,'msgs':["メールを送信しています..."]});
        window.scroll({top:0});
        let data=document.getElementsByClassName('js-data-check');
        let seminar=document.getElementById('FilterDisplay').value;
        let students=[];
        for(let i=0;i<data.length;++i){
            if(data[i].checked && data[i].parentNode.parentNode.dataset.id==seminar){
                students.push(data[i].value);
            }
        }
        if(students.length){
            let body=SendJson(document.getElementsByClassName('js-data-email'));
            body['body']=Email;//document.getElementsByClassName('js-editor-html').value;
            body['tos']=students;
            let send={
                'url':'v1/serma/project/'+id+'/seminar/'+seminar+'/email',
                'method':'POST',
                'body':body
            }
            Fetch(send).then(data=>{
                if(data['result']){
                    setAlert({'type':'success','style':true,'msgs':["メールを送信しました。"]});
                    for(let i=0;i<data.length;++i){
                        if(data[i].checked && data[i].parentNode.parentNode.dataset.id==id){
                            data[i].parentNode.parentNode.remove();
                        }
                    }
                }else{
                    setAlert({'type':'danger','style':true,'description':data['error']['description']});
                }
            }).catch(error=>console.log(error));
        }else{
            setAlert({'type':'danger','style':true,'msgs':["選択してください。"]});
        }
    }
    function doDelete(){
        let check=document.getElementsByClassName('js-data-check');
        let seminar=document.getElementById('FilterDisplay').value;
        let students=[];
        let ids=[];
        for(let i=0;i<check.length;++i){
            if(check[i].checked && (check[i].parentNode.parentNode.dataset.id==seminar || seminar==0)){
                students.push(check[i].value);
                ids.push(check[i].id);
            }
        }
        if(students.length){
            let send={
                'url':'v1/serma/project/'+id+'/apply/delete',
                'method':'DELETE',
                'body':students
            }
            Fetch(send).then(data=>{
                if(data['result']){
                    setAlert({'type':'success','style':true,'msgs':["削除しました。"]});
                    for(let i=0;i<ids.length;++i){
                        document.getElementById(ids[i]).parentNode.parentNode.remove();
                    }
                }else{
                    setAlert({'type':'danger','style':true,'description':data['error']['description']});
                }
            }).catch(error=>console.log(error));
        }else{
            setAlert({'type':'danger','style':true,'msgs':["選択してください。"]});
        }
    }
    return(
        <div>
            <BreadCrumb item={BCrumb} />
            <AlertBox Item={Alert} />
            <div id="content_area"></div>{Item?
        <div>
            <div className="row">
                <div className="col-sm-auto">
                    <select id="FilterDisplay" className="form-select" onChange={changeDisplay}>
                        <option value={0}>全表示</option>{Item['seminars'].map((seminar,index)=>
                        <option value={seminar['SeminarId']} key={index}>{seminar['holded'][0]}&nbsp;{seminar['holded'][1]}-{seminar['holded'][2]}</option>
                        )}
                    </select>
                </div>
                <div className="col-sm-auto">
                    <button type="button" className="btn btn-primary" onClick={exportCSV}>CSV出力</button>
                </div>
            </div>
            <div className="table-responsive">
                <table className="table table-striped table-hover">
                    <thead>
                        <tr>
                            <th></th>
                            <th>
                                名前
                            </th>
                            <th>
                                Email
                            </th>
                            <th>
                                開催日時
                            </th>
                            <th>
                                申込日時
                            </th>
                        </tr>
                    </thead>
                    <tbody id="ApplyList">{Item['lists'].map((item,index)=>
                        <tr data-id={item['SeminarId']} data-key={index} key={index}>
                            <td>
                                <input type="checkbox" id={"student_"+index} className="form-checkbox js-data-check" value={item['ApplyId']} />
                            </td>
                            <td>
                                <label htmlFor={"student_"+index}>
                                    {item['name']}
                                </label>
                            </td>
                            <td>
                                <a href={"email:"+item['email']}>
                                    {item['email']}
                                </a>
                            </td>
                            <td>
                                <label htmlFor={"student_"+index}>
                                    {item['holded'][0]}&nbsp;{item['holded'][1]}-{item['holded'][2]}
                                </label>
                            </td>
                            <td>
                                <label htmlFor={"student_"+index}>
                                    {item['AppliedDatetime']}
                                </label>
                            </td>
                        </tr>
                    )}
                    </tbody>
                </table>
            </div>
            <p>
                チェックした人に(を)
            </p>
            <button type="button" id="ApplyEmail" className="btn btn-primary me-2" style={MailStyle} onClick={()=>setBodyStyle({display:'block'})}>メール送信</button>
            <button type="button" className="btn btn-danger" onClick={doDelete}>削除</button>
            <div className="border mt-3 p-2" style={BodyStyle}>
                <h4>
                    メール作成
                </h4>
                <div className="input-group my-2">
                    <span className="input-group-text">件名</span>
                    {Template?<input type="text" name="subject" className="form-control js-data-email" defaultValue={Template['subject']} placeholder="件名" />:""}
                </div>
                <div className="input-group my-2">
                    <span className="input-group-text">本文</span>
                    <div className="form-control">
                        申込者名(申込者メールアドレス) 様
                        {Template?<RainbowLiveEditor defaultValue={Template['body']} setContent={setEmail} />:""}
                        {/*<RainbowPatissierLiveEditor defaultValue={Template['body']} setContent={setEmail} />*/}
                    </div>
                </div>
                <div className="my-2">
                    <EmailVar />
                </div>
                <div className="mt-2">
                    <button type="button" className="btn btn-primary" onClick={doEmail}>送信</button>
                </div>
            </div>
        </div>:""}
        </div>
    )
}