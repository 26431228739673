import { useEffect, useState } from "react";

export default function Examination(props){
    const [Quizs,setQuizs]=useState([]);
	const [Load,setLoad]=useState([false,{display:'none'},'']);
    let cnt=props.Limit*60;
    let Int;
    const [Min,setMin]=useState(cnt);
    useEffect(()=>{
        setQuizs(props.Quiz);
        Int=setInterval(function(){
            if(cnt>0){
                --cnt;
                setMin(cnt);
            }else if(cnt==0){
                cnt=-1;
                setMin(0);
                alert('提出します');
                setSend();
            }
        },1000);
    },[])
    function clickSend(){
        if(window.confirm('提出しますか？')){
            clearInterval(Int);
            setSend();
        }
    }
    function setSend(){
        setLoad([true,{display:'block'},"提出しています。"])
        clearInterval(Int);
        const res=props.Submit();
        setLoad([false,{display:'none'},res])
    }
    return(
        <div>
            <form id="examination" name="examination">
                <ol>
                    {Quizs.map((quiz,index)=>
                    <li key={index}>
                        <div className="fw-bold">
                        {quiz['question']}
                        </div>
                        <div>
                        <ConvertMethod Item={quiz} />
                        </div>
                    </li>)}
                </ol>
                <div>
                    <div className="row">
                        <div className="col-auto">
                            <button type="button" className="btn btn-primary" onClick={clickSend} disabled={Load[0]}>提出</button>
                        </div>
                        <div className="col-auto" style={Load[1]}>
                            <div className="row">
                            <div className="col-auto">
                                <div className="spinner-border"></div>
                            </div>
                            <div className="col-auto ps-2">{Load[2]}</div>
                            </div>
                        </div>
                    </div>
                    <div className="position-fixed top-0 start-0 w-100 bg-light">
                        制限時間：{props.Limit}分&nbsp;<span className="fs-3">残り：{Min}秒</span>
                    </div>
                </div>
            </form>
        </div>
    );
}
export function ConvertMethod(props){
    const items=props.Item;
    switch(items.method){
        case 'ShortText':
            return (<input type="text" name={items['QuestionId']} className="form-control js-data-txt" data-id={items['QuestionId']} />);
            break;
        case 'LongText':
            return (<textarea name={items['QuestionId']} className="form-control js-data-txt" data-id={items['QuestionId']}  />);
            break;
        case 'radio':
        case 'checkbox':
            return(
                <div className="row js-data-opt" data-id={items['QuestionId']}>
                    {items.options.map(opt=><Options type={items.method} index={items['QuestionId']} option={opt.option} optIndex={opt.OptionId} />)}
                </div>
            );
            break;
        case 'supplementation':
        default:
            return(<span></span>);
    }
}
function Options(props){
    return (
        <div className="col-auto me-2">
            <input type={props.type} name={props.index} id={"question_"+props.index+'_'+props.optIndex} className="form-check-input js-opt" data-id={props.index} value={props.optIndex} key={props.optIndex} />
            <label htmlFor={"question_"+props.index+'_'+props.optIndex} className="form-check-label">
                {props.option}
            </label>
        </div>
    );
}