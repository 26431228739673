export default function CourseTeacherForm({teacher}){
    return (
        <>
            <div className="input-group my-2">
                <span className="input-group-text">HP</span>
                <input type="url" name="link" className="form-control js-data-teacher" placeholder="HP" defaultValue={teacher['link']} required />
            </div>
            <div className="input-group my-2">
                <span className="input-group-text">メールアドレス</span>
                <input type="email" name="email" className="form-control js-data-teacher" placeholder="メールアドレス" defaultValue={teacher['email']} required />
            </div>
            <div className="input-group my-2">
                <span className="input-group-text">電話番号</span>
                <input type="tel" name="tel" className="form-control js-data-teacher" placeholder="電話番号" defaultValue={teacher['tel']} required />
            </div>
            <div className="input-group my-2">
                <span className="input-group-text">LINE登録URL</span>
                <input type="url" name="line" className="form-control js-data-teacher" placeholder="LINE登録URL" defaultValue={teacher['line']} required />
            </div>
        </>
    );
}