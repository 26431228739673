import { useEffect, useState } from "react";
import { useParams } from "react-router";
import BreadCrumb from "../component/breadcrumb";
import Fetch from "../common/fetch";
import AlertBox from "../component/AlertBox";
import { ConvertToDisplayDate, PaymentCondition, StudentCondition } from "../component/Keys";
import Loading from "../component/Loading";
import { Link } from "react-router-dom";

export default function Student(){
    const {course}=useParams();
    const [BCrumb,setBCrumb]=useState({'CurrentText':'受講者情報','items':[{'index':1,'href':'/teacher','text':'ホーム'}]});
    const [Alert,setAlert]=useState({'type':'danger','style':false,'msgs':[]});
    const [ModalAlert,setModalAlert]=useState({'type':'danger','style':false,'msgs':[]});
    const [Data,setData]=useState([]);
    const [User,setUser]=useState({
        "StudentId": "",
        "UserName": "",
        "email": "",
        "FromDate": "",
        "ToDate": "",
        "condition": 0,
        "visible":true,
        "unpay": [],
        "payments": []
    });
    const [Visible,setVisible]=useState(false);
    useEffect(()=>{
        setVisible(true);
        let send={
            'url':'v1/course/'+course+'/student',
            'method':'GET'
        }
        Fetch(send).then(data=>{
            if(data['result']){
                setAlert({'type':'danger','style':false,'msgs':[]});
                setBCrumb({
                    CurrentText: '受講者情報',
                    items: [
                        {
                            index: 1,
                            href: "/teacher",
                            text: "ホーム"
                        },
                        {
                            index:2,
                            href:'/teacher/course/'+course,
                            text:data['payloads']['CourseName']
                        }
                    ],
                });
                setData(data['payloads']['students']);
            }else{
                setAlert({'type':'danger','style':true,'description':data['error']['description']});
            }
            setVisible(false);
        }).catch(error=>{
            console.log(error);
            setVisible(false);
        });
    },[])
    function clickUser(e){
        setUser(Data[e.target.parentNode.dataset.id]);
        setModalAlert({'type':'danger','style':false,'msgs':[]})
    }
    function changeVisible(e){
        let send={
            'url':'v1/course/'+course+'/student/'+User['StudentId'],
            'method':'PUT',
            'body':{
                "visible":e.target.parentNode.getElementsByClassName('js-data-visible')[0].value=='true'?true:false
            }
        }
        Fetch(send).then(data=>{
            if(data['result']){
                setModalAlert({'type':'success','style':true,'msgs':[User['UserName']+'の表示設定を変更しました。']});
            }else{
                setModalAlert({'type':'danger','style':true,'description':data['error']['description']});
            }
        }).catch(error=>console.log(error));
    }
    function doGraduate(e){
        let send={
            'url':'v1/course/'+course+'/student/'+User['StudentId'],
            'method':'PUT',
            'body':{
                "condition":2
            }
        }
        Fetch(send).then(data=>{
            if(data['result']){
                setModalAlert({'type':'success','style':true,'msgs':[User['UserName']+'の修了登録をしました。']});
                e.target.parentNode.textContent="修了";
            }else{
                setModalAlert({'type':'danger','style':true,'description':data['error']['description']});
            }
        }).catch(error=>console.log(error));
    }
    return(
        <div>
            <BreadCrumb item={BCrumb} />
            <AlertBox Item={Alert} />
            <Loading visible={Visible} msgs={[]} />
            <div className="row">
                <div className="col-12">
                <h2>
                    受講者情報
                </h2>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                <table className="table table-striped table-hover">
                    <thead>
                        <tr>
                            <th>
                            受講者名
                            </th>
                            <th>
                            未払い
                            </th>
                            <th>
                            参加日
                            </th>
                        </tr>
                    </thead>
                    <tbody>{Data.map((user,index)=>
                        <tr key={index}>
                            {/*<tr data-bs-toggle="modal" data-bs-target="#userModal" data-id={index} key={index} onClick={clickUser}>*/}
                            <td>
                                <Link to={"/teacher/course/"+course+"/student/"+user['StudentId']}>{user['UserName']}</Link>
                            </td>
                            <td>
                                <ul>
                                    {user['unpay'].map((unpay,index)=><li key={index}>[{unpay['PaymentTitle']}]{unpay['price']}円</li>)}
                                </ul>
                            </td>
                            <td>
                                {ConvertToDisplayDate(user['FromDate'])}[{StudentCondition(user['condition'])}]
                            </td>
                        </tr>)}
                    </tbody>
                </table>
                </div>
            </div>
            <div className="modal fade w-100" id="userModal" tabIndex={-1} aria-labelledby="userModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-xl modal-dialog-scrollable">{User?
                <div className="modal-content">
                    <div className="modal-header">
                    <h5 className="modal-title" id="userModalLabel">{User['UserName']}</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                    </div>
                    <div className="modal-body">
                    <table className="table table-striped">
                        <tbody>{/*
                        <tr>
                            <th>
                                メールアドレス
                            </th>
                            <td>
                            <a href={"mailto:"+User['email']}>
                                {User['email']}
                            </a>
                            </td>
                        </tr>*/}
                        <tr>
                            <th>
                                参加日
                            </th>
                            <td>
                                {ConvertToDisplayDate(User['FromDate'])}
                            </td>
                        </tr>
                        <tr>
                            <th>
                                状態
                            </th>
                            <td>
                                {User['condition'] ?
                                    <div className="row">
                                        <div className="col-auto">
                                            {ConvertToDisplayDate(User['ToDate'])}に{StudentCondition(User['condition'])}
                                        </div>
                                        <div className="col-auto">
                                            {User['condition']==1 || User['condition']==2 ?
                                            <div className="input-group">
                                                <span className="input-group-text">コース内容を</span>
                                                <select name="visible" className="form-select d-inline js-data-visible" defaultValue={User['visible']} data-type="boolean">
                                                    <option value={true}>表示する</option>
                                                    <option value={false}>表示しない</option>
                                                </select>
                                                <button type="button" className="btn btn-secondary" onClick={changeVisible}>変更</button>
                                            </div>:''}
                                        </div>
                                    </div>:
                                    <span>{StudentCondition(User['condition'])}<button type="button" className="btn btn-secondary" onClick={doGraduate}>修了</button></span>
                                }
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <form>
                        <table className="table table-striped table-hover">
                        <thead>
                            <tr>
                            <th>
                                タイトル
                            </th>
                            <th>
                                金額
                            </th>
                            <th>
                                状態
                            </th>
                            </tr>
                        </thead>
                        <tbody>{User['payments'].map(payment=>
                            <tr>
                                <td>
                                    {payment['PaymentTitle']}
                                </td>
                                <td>
                                    {payment['price']}円
                                </td>
                                <td>{/*payment['condition'] ?
                                    <div className="form-check">
                                        <input type="checkbox" name="paid[]" id={"paid_"+payment['PaymentId']} className="form-check-input js-data" defaultValue={payment['PaymentId']} />
                                        <label htmlFor={"paid_"+payment['PaymentId']} className="form-check-label">支払い確認</label>
                                    </div>:*/
                                    PaymentCondition(payment['condition'])}
                                </td>
                            </tr>)}
                        </tbody>
                        </table>
                        <AlertBox Item={ModalAlert} />
                        {/*<button type="button" className="btn btn-primary" onclick>変更</button>*/}
                    </form>
                    </div>
                </div>:''}
                </div>
            </div>
        </div>
    )
}