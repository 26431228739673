import { useEffect, useState } from "react";
import ReactDOM from 'react-dom';
import { useParams } from "react-router";
import BreadCrumb from "../component/breadcrumb";
import Fetch from "../common/fetch";
import AlertBox from "../component/AlertBox";
import Upload from "../common/upload";
import Host from "../component/Host";
import Loading from "../component/Loading";

let LoadingMsg=[];
export default function AdminFolder(){
    const {id}=useParams();
    const [BCrumb,setBCrumb]=useState({'CurrentText':'教材フォルダ','items':[{'index':1,'href':'/teacher','text':'ホーム'}]});
    const [Alert,setAlert]=useState({'type':'danger','style':false,'msgs':[]});
    const [Data,setData]=useState([]);

	const [Visible,setVisible]=useState(false);
	const [Msg,setMsg]=useState(LoadingMsg);

    useEffect(()=>{
        getFolder();
    },[])
    function getFolder(){
        setVisible(true);
        let send={
            'url':'v1/course/'+id+'/folder/',
            'method':'GET'
        }
        Fetch(send).then(data=>{
            if(data['result']){
                setData(data['payloads']['folders'])
                setBCrumb({
                    CurrentText: 'フォルダー管理',
                    items: [
                        {
                            index: 1,
                            href: "/teacher",
                            text: "ホーム"
                        },
                        {
                            index:2,
                            href:'/teacher/course/'+id,
                            text:data['payloads']['CourseName']
                        }
                    ],
                });
            }else{
                setAlert({'type':'danger','style':true,'description':data['error']['description']});
            }
            setVisible(false);
        }).catch((error)=>{
            LoadingMsg.push({type:'warning',title:'エラー',content:'通信エラーが発生しました'});
            setMsg(LoadingMsg);
            setVisible(false);

            console.log(error);
        });
    }
    function doEntry(){
		setVisible(true);
		LoadingMsg.push({type:'info',title:'確認中',content:'ファイルを確認中',delay:5000});
		setMsg(LoadingMsg);

        let file=document.getElementsByClassName('js-data-file');
        let ids=[];
        let fd=new FormData();
        for(let i=0;i<file.length;++i){
            if(file[i].value!=""){
                fd.append('file[]',file[i].files[0]);
                ids.push(file[i]);
            }
        }
        if(ids.length){

            LoadingMsg.push({type:'info',title:'アップロード中',content:'ファイルをアップロード中',delay:5000});
            setMsg(LoadingMsg);
    
            Upload(fd).then(data=>{
                if(data['result']){
                    LoadingMsg.push({type:'success',title:'アップロード完了',content:'ファイルをアップロードしました',delay:5000});
                    setMsg(LoadingMsg);
                    setVisible(false);
            
                    for(let i=0;i<ids.length;++i){
                        ids[i].previousElementSibling.dataset.file=data['payloads']['FileIds'][i];
                    }
                    doSubmit();
                }else{
                    LoadingMsg.push({type:'danger',title:'アップロード失敗',content:'ファイルをアップロードできませんでした',delay:5000});
                    setMsg(LoadingMsg);
                    setVisible(false);
                    window.scroll({top:0});

                    setAlert({'type':'danger','style':true,'description':data['error']['description']});
                }
            }).catch((error)=>{
                LoadingMsg.push({type:'warning',title:'エラー',content:'通信エラーが発生しました'});
                setMsg(LoadingMsg);
                setVisible(false);
    
                console.log(error);
            });
        }else{
            doSubmit();
        }
    }
    function doSubmit(){
        LoadingMsg.push({type:'info',title:'確認中',content:'入力内容を確認しています',delay:5000});
        setMsg(LoadingMsg);
        
        let send={
            'url':'v1/course/'+id+'/folder',
            'method':'POST',
            'body':[]
        }
        let data=document.getElementsByClassName('js-data-name');
        for(let i=0;i<data.length;++i){
            send['body'].push({
                "FolderId": data[i].dataset.id,
                "FolderName": data[i].value,
                "icon": data[i].dataset.file,
                "number": i,
                "condition": data[i].dataset.condition,
                "parent": "0"
            });
        }

        LoadingMsg.push({type:'info',title:'送信中',content:'入力内容を送信しています',delay:5000});
        setMsg(LoadingMsg);
        
        Fetch(send).then(data=>{
            if(data['result']){

                getFolder();
                
                LoadingMsg.push({type:'success',title:'送信完了',content:'入力内容を送信しました',delay:5000});
                setMsg(LoadingMsg);
                setVisible(false);

                LoadingMsg=[]
                setAlert({'type':'success','style':true,'msgs':["フォルダーを編集しました。"]});
            }else{
                LoadingMsg.push({type:'danger',title:'エラー',content:'入力内容が保存できませんでした',delay:5000});
                setMsg(LoadingMsg);
                setVisible(false);
                window.scroll({top:0});

                setAlert({'type':'danger','style':true,'description':data['error']['description']});
            }
        }).catch((error)=>{
            LoadingMsg.push({type:'warning',title:'エラー',content:'通信エラーが発生しました'});
            setMsg(LoadingMsg);
            setVisible(false);

            console.log(error);
        });
    }
    function addFolder(){
        setData(Data.concat([{'icon':Host('assets/img/noimage.jpg'),'FolderName':'新しいフォルダー','FolderId':'new'}]));
        /*let div=document.createElement('div');
        div.setAttribute('class','list-group-item');
        document.getElementById('folder_wrapper').appendChild(div);
        ReactDOM.render(<AddFolder folder={{'icon':Host('assets/img/noimage.jpg'),'FolderName':'新しいフォルダー','FolderId':'new'}} />,div);*/
    }
    function toUp(e){
        let parent=document.getElementById('folder_wrapper');
        let tar=e.target.parentNode.parentNode;
        let pre=tar.previousElementSibling;
        if(pre.id!=="folder_title"){
            parent.insertBefore(tar,pre);
        }
    }
    function toDown(e){
        let parent=document.getElementById('folder_wrapper');
        let tar=e.target.parentNode.parentNode;
        let nxt=tar.nextElementSibling;
        if(nxt){
            parent.insertBefore(nxt,tar);
        }
    }
    function changeIcon(e){
        let Src=e.target.parentNode.getElementsByClassName('js-icon')[0];
        if(e.target.files.length){
            Src.src=window.URL.createObjectURL(e.target.files[0]);
        }else{
            Src.src=Src.dataset.src;
        }
    }
    function doCopy(e){
        let tar=e.target.parentNode.parentNode;
        let name=tar.getElementsByClassName('js-data-name')[0];
        let icon=tar.getElementsByClassName('js-icon')[0];
        setData(Data.concat([{'icon':Host('assets/img/noimage.jpg'),'FolderName':name.value+' - copy','FolderId':'new'}]));
    }
    function doDelete(e){
        e.target.parentNode.getElementsByClassName('js-data-name')[0].dataset.condition="0";
        e.target.parentNode.parentNode.style.display="none";
    }
    return(
        <div>
            <BreadCrumb item={BCrumb} />
            <AlertBox Item={Alert} />
            <Loading visible={Visible} msgs={Msg} />
            <div id="folder_wrapper" className="list-group">
                <div id="folder_title" className="list-group-item">
                    <div className="row justify-content-between">
                        <div className="col-auto">
                        フォルダー
                        </div>
                        <div className="col-auto">
                            <button type="button" className="btn btn-success" onClick={addFolder}>
                                フォルダ作成
                            </button>
                        </div>
                    </div>
                </div>{Data.map((folder,index)=>
                <div className="list-group-item" key={index}>
                    {/*<AddFolder index={index} folder={folder} />*/}
                    <div className="input-group">
                        <span className="input-group-text">
                            <img src={folder['icon']} data-src={folder['icon']} className="files__icon js-icon" />
                        </span>
                        <input type="text" className="form-control js-data-name" placeholder="フォルダー名" data-id={folder['FolderId']} data-condition="1" defaultValue={folder['FolderName']} required />
                        <input type="file" className="form-control js-data-file" accept="image/*" onChange={changeIcon} />
                        <button type="button" className="btn btn-secondary fas fa-arrow-up" onClick={toUp}></button>
                        <button type="button" className="btn btn-secondary fas fa-arrow-down" onClick={toDown}></button>
                        <button type="button" className="btn btn-success fas fa-copy" onClick={doCopy}></button>
                        <button type="button" className="btn btn-danger fas fa-trash-alt" onClick={doDelete}></button>
                    </div>
                </div>
                )}
            </div>
            <button type="button" className="btn btn-primary mt-3" onClick={doEntry}>登録</button>
        </div>
    );
}
function AddFolder(props){
    function toUp(e){
        let parent=document.getElementById('folder_wrapper');
        let tar=e.target.parentNode.parentNode;
        let pre=tar.previousElementSibling;
        if(pre.id!=="folder_title"){
            parent.insertBefore(tar,pre);
        }
    }
    function toDown(e){
        let parent=document.getElementById('folder_wrapper');
        let tar=e.target.parentNode.parentNode;
        let nxt=tar.nextElementSibling;
        if(nxt){
            parent.insertBefore(nxt,tar);
        }
    }
    function changeIcon(e){
        let Src=e.target.parentNode.getElementsByClassName('js-icon')[0];
        if(e.target.files.length){
            Src.src=window.URL.createObjectURL(e.target.files[0]);
        }else{
            Src.src=Src.dataset.src;
        }
    }
    function doCopy(e){
        let parent=document.getElementById('folder_wrapper');
        let tar=e.target.parentNode.parentNode;
        let nxt=tar.nextElementSibling;
        let NewEle=tar.cloneNode(true);
        NewEle.classList.add('js-new-folder');
        let name=NewEle.getElementsByClassName('js-data-name')[0];
        name.dataset.id="new";
        name.value+=' - copy';
        let btn=NewEle.getElementsByTagName('button');
        btn[0].addEventListener('click',toUp);
        btn[1].addEventListener('click',toDown);
        btn[2].addEventListener('click',doCopy);
        btn[3].addEventListener('click',doDelete);
        parent.insertBefore(NewEle,nxt);
    }
    function doDelete(e){
        e.target.parentNode.getElementsByClassName('js-data-name')[0].dataset.condition="0";
        e.target.parentNode.parentNode.style.display="none";
    }
    return(
            <div className="input-group">
                <span className="input-group-text">
                    <img src={props.folder['icon']} data-src={props.folder['icon']} className="files__icon js-icon" />
                </span>
                <input type="text" className="form-control js-data-name" placeholder="フォルダー名" data-id={props.folder['FolderId']} data-condition="1" defaultValue={props.folder['FolderName']} required />
                <input type="file" className="form-control js-data-file" accept="image/*" onChange={changeIcon} />
                <button type="button" className="btn btn-secondary fas fa-arrow-up" onClick={toUp}></button>
                <button type="button" className="btn btn-secondary fas fa-arrow-down" onClick={toDown}></button>
                <button type="button" className="btn btn-success fas fa-copy" onClick={doCopy}></button>
                <button type="button" className="btn btn-danger fas fa-trash-alt" onClick={doDelete}></button>
            </div>
    );
}