import { useEffect, useState } from "react";
import ReactDOM from 'react-dom';
import BreadCrumb from "../component/breadcrumb";
import Fetch from "../common/fetch";
import AlertBox from "../component/AlertBox";
import { useParams } from "react-router";
import Host from '../component/Host';
//import { ConvertToHTML } from "../../lib/RainbowPatissierLiveEditor/RainbowPatissierLiveEditor";
import { Link } from "react-router-dom";
import ConvertElements from "../../lib/LiCSSsLiveEditor/LiCSSLiveEditorConverter";

export default function Project(){
    const {id}=useParams();
    const [BCrumb,setBCrumb]=useState({'CurrentText':'','items':[{'index':1,'href':'/serma','text':'ホーム'}]});
    const [Alert,setAlert]=useState({'type':'danger','style':false,'msgs':[]});
    const [BtnStyle,setBtnStyle]=useState({});
    const [item,setItem]=useState();
    useEffect(()=>{
        let send={
            'url':'v1/serma/project/'+id,
            'method':'GET'
        }
        Fetch(send).then(data=>{
            if(data['result']){
                setAlert({'type':'danger','style':false,'msgs':[]});
                setItem(data['payloads']);
                setBtnStyle(data['payloads']['btns']['RegularStyle']);
                setBCrumb({
                    CurrentText: data['payloads']['ProjectName'],
                    items: [
                        {
                            index: 1,
                            href: "/serma",
                            text: "ホーム"
                        }
                    ],
                });
            }else{
                setAlert({'type':'danger','style':true,'description':data['error']['description']});
            }
        }).catch(error=>console.log(error));
    },[])
    return(
        <div>
            <BreadCrumb item={BCrumb} />
            <AlertBox Item={Alert} />{item?
            <div>
                <h2 className="mb-2">
                    {item['ProjectName']}
                </h2>
                <table className="table table-striped">
                    <tbody>
                        <tr>
                            <th>
                                講師名
                            </th>
                            <td>
                                {item['ProjectName']}
                            </td>
                        </tr>
                        <tr>
                            <th>
                                連絡メールアドレス
                            </th>
                            <td>
                                {item['email']}
                            </td>
                        </tr>
                        <tr>
                            <th>
                                申し込みボタン
                            </th>
                            <td>
                                <button type="button" style={BtnStyle} onMouseOver={()=>setBtnStyle(item['btns']['HoverStyle'])} onMouseLeave={()=>setBtnStyle(item['btns']['RegularStyle'])}>
                                    {item['btns']['text']}
                                </button>
                            </td>
                        </tr>
                        <tr>
                            <th>
                                公式LINEURL
                            </th>
                            <td>
                                {item['LineUrl']}
                            </td>
                        </tr>
                        <tr>
                            <th>
                                自動返信
                            </th>
                            <td>
                                <div className="row">
                                    <div className="col-sm-1">
                                        件名
                                    </div>
                                    <div className="col-sm-11">
                                        {item['reply']['subject']}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-1">
                                        本文
                                    </div>
                                    <div className="col-sm-11">{item['reply']['body'] &&
                                        //<ConvertToHTML blocks={item['reply']['body']} />
                                        <ConvertElements mode={item['reply']['body']['mode']} blockElements={item['reply']['body']} />
                                        
                                    }
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="2">
                                <p>
                                    下記のコードをフォームを設置したい場所にコピペしてください。
                                </p>
                                <input type="text" className="form-control mb-2 h-auto" defaultValue={'<form class="canteach-serma-project__form-wrapper" data-id="'+item['ProjectId']+'"></form>'} onFocus={e=>e.target.select()} readOnly />
                                <p>
                                    下記のコードを上記のコードより後ろにコピペしてください。<br />
                                    ※1ページに複数フォームを設置する場合，最後に一度コピペで十分です。
                                </p>
                                <input type="text" className="form-control" defaultValue={'<script src="'+Host('assets/js/serma-form.js')+'"></script>'} onFocus={e=>e.target.select()} readOnly />
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div className="text-end">
                    <Link to={"/serma/project/"+item['ProjectId']+'/edit'} className="btn btn-secondary">
                        プロジェクト編集
                    </Link>
                </div>
                <h3 className="my-2">
                    セミナー一覧
                </h3>
                <div className="table-responsive"> 
                    <table className="table table-striped table-hober">
                        <thead>
                            <tr>
                                <th>
                                    開催日時
                                </th>
                                <th>
                                    開催場所
                                </th>
                                <th>
                                    最大受講者数
                                </th>
                                <th>
                                    申し込み人数
                                </th>
                            </tr>
                        </thead>
                        <tbody>{item['seminars'].map((seminar,index)=>
                            <tr key={index}>
                                <td>
                                    {seminar['holded']}
                                </td>
                                <td>
                                    {seminar['location']}
                                </td>
                                <td>
                                    {seminar['MaxPeople']}人
                                </td>
                                <td>
                                    {seminar['ApplyPeople']}人
                                </td>
                            </tr>)}
                        </tbody>
                    </table>
                </div>
                <div className="text-end">
                    <Link to={"/serma/project/"+item['ProjectId']+'/apply'} className="btn btn-success">
                        申込者一覧
                    </Link>
                    <Link to={"/serma/project/"+item['ProjectId']+'/seminar'} className="btn btn-secondary ms-2">
                        セミナー編集
                    </Link>
                </div>
            </div>:""}
        </div>
    )
}