import { useEffect, useState } from "react";
import ReactDOM from 'react-dom';
import BreadCrumb from "../component/breadcrumb";
import Fetch from "../common/fetch";
import AlertBox from "../component/AlertBox";
import { useParams } from "react-router";
import ProjectForm from "./ProjectForm";


export default function ProjectEdit(){
    const {id}=useParams();
    const [BCrumb,setBCrumb]=useState({'CurrentText':'','items':[{'index':1,'href':'/serma','text':'ホーム'}]});
    const [Alert,setAlert]=useState({'type':'danger','style':false,'msgs':[]});
    const [Item,setItem]=useState();
    useEffect(()=>{
        let send={
            'url':'v1/serma/project/'+id,
            'method':'GET'
        }
        Fetch(send).then(data=>{
            if(data['result']){
                setAlert({'type':'danger','style':false,'msgs':[]});
                //ReactDOM.render(<ProjectForm Item={data['payloads']} Submit={doEntry} Delete={doDelete} />,document.getElementById('content_area'));
                setItem(data['payloads']);
                setBCrumb({
                    CurrentText: "編集",
                    items: [
                        {
                            index: 1,
                            href: "/serma",
                            text: "ホーム"
                        },
                        {
                            index: 1,
                            href: "/serma/project/"+id,
                            text: data['payloads']['ProjectName']
                        }
                    ],
                });
            }else{
                setAlert({'type':'danger','style':true,'description':data['error']['description']});
            }
        }).catch(error=>console.log(error));
    },[])
    function doEntry(body){
        let send={
            'url':'v1/serma/project/'+id,
            'method':'PUT',
            'body':body
        }
        Fetch(send).then(data=>{
            if(data['result']){
                setAlert({'type':'success','style':true,'msgs':["プロジェクトを変更しました。"]});
                window.scroll({top:0});
            }else{
                setAlert({'type':'danger','style':true,'description':data['error']['description']});
            }
        }).catch(error=>console.log(error));
    }
    function doDelete(){
        let send={
            'url':'v1/serma/project/'+id,
            'method':'DELETE'
        }
        Fetch(send).then(data=>{
            if(data['result']){
                setAlert({'type':'success','style':true,'msgs':["プロジェクトを削除しました。"]});
                document.getElementById('content_area').textContent="";
            }else{
                setAlert({'type':'danger','style':true,'description':data['error']['description']});
            }
        }).catch(error=>console.log(error));
    }
    return(
        <div>
            <BreadCrumb item={BCrumb} />
            <AlertBox Item={Alert} />
            <div id="content_area"></div>{Item ?<ProjectForm Item={Item} Submit={doEntry} Delete={doDelete} />:""}
        </div>
    )
}