import { useEffect, useState } from "react";
import { useParams } from "react-router";
import BreadCrumb from "../component/breadcrumb";
import Fetch from "../common/fetch";
import AlertBox from "../component/AlertBox";
import TextDetail from "../component/TextDetail";
import Loading from "../component/Loading";

let LoadingMsg=[];
export default function Text(){
    const {course,id}=useParams();
    const [BCrumb,setBCrumb]=useState({'CurrentText':'教材','items':[{'index':1,'href':'/teacher','text':'ホーム'}]});
    const [Alert,setAlert]=useState({'type':'danger','style':false,'msgs':[]});
    const [Data,setData]=useState([]);

	const [Visible,setVisible]=useState(false);
	const [Msg,setMsg]=useState(LoadingMsg);

    useEffect(()=>{
        setVisible(true);
        let send={
            'url':'v1/course/'+course+'/folder/'+id+'/text',
            'method':'GET'
        }
        Fetch(send).then(data=>{
            if(data['result']){
                setAlert({'type':'danger','style':false,'msgs':[]});
                setData(data['payloads']['texts']);
                setBCrumb({
                    CurrentText: data['payloads']['FolderName'],
                    items: [
                        {
                            index: 1,
                            href: "/teacher",
                            text: "ホーム"
                        },
                        {
                            index:2,
                            href:'/teacher/course/'+course,
                            text:data['payloads']['CourseName']
                        }
                    ],
                });
            }else{
                setAlert({'type':'danger','style':true,'description':data['error']['description']});
            }
            setVisible(false);
        }).catch((error)=>{
            LoadingMsg.push({type:'warning',title:'エラー',content:'通信エラーが発生しました'});
            setMsg(LoadingMsg);
            setVisible(false);

            console.log(error);
        });
    },[])
    return(
        <div>
            <BreadCrumb item={BCrumb} />
            <AlertBox Item={Alert} />
            <Loading visible={Visible} msgs={Msg} />
            <div className="row">
                <div className="col-12">
                    <TextDetail Item={Data} Course={course} Folder={id} Edit={true} />
                </div>
            </div>
        </div>
    )
}