import { useEffect, useState } from "react";
import ReactDOM from 'react-dom';
import { useParams } from "react-router";
import BreadCrumb from "../component/breadcrumb";
import Fetch from "../common/fetch";
import AlertBox from "../component/AlertBox";
import StepForm from "../component/StepForm";
import Loading from "../component/Loading";

let LoadingMsg=[];
export default function EntryStep(){
    const {course,id}=useParams();
    const [BCrumb,setBCrumb]=useState({'CurrentText':'ステップ配信','items':[{'index':1,'href':'/teacher','text':'ホーム'}]});
    const [Alert,setAlert]=useState({'type':'danger','style':false,'msgs':[]});

	const [Visible,setVisible]=useState(false);
	const [Msg,setMsg]=useState(LoadingMsg);

    useEffect(()=>{
        let send={
            'url':'v1/course/'+course,
            'method':'GET'
        }
        Fetch(send).then(data=>{
            if(data['result']){
                setAlert({'type':'danger','style':false,'msgs':[]});
                ReactDOM.render(<StepForm Submit={doEntry} setVisible={setVisible} setMsg={setMsg} setAlert={setAlert} />,document.getElementById('content_area'));
                setBCrumb({
                    CurrentText: '新規ステップ',
                    items: [
                        {
                            index: 1,
                            href: "/teacher",
                            text: "ホーム"
                        },
                        {
                            index:2,
                            href:'/teacher/course/'+course,
                            text:data['payloads']['CourseName']
                        }
                    ],
                });
            }else{
                setAlert({'type':'danger','style':true,'description':data['error']['description']});
            }
            setVisible(false);
        }).catch((error)=>{
            LoadingMsg.push({type:'warning',title:'エラー',content:'通信エラーが発生しました'});
            setMsg(LoadingMsg);
            LoadingMsg=[];
            setVisible(false);

            console.log(error);
        });
    },[])
    function doEntry(body){
        let send={
            'url':'v1/course/'+course+'/step/',
            'method':'POST',
            'body':body
        }

        LoadingMsg.push({type:'info',title:'送信中',content:'入力内容を送信しています',delay:5000});
        setMsg(LoadingMsg);

        Fetch(send).then(data=>{
            if(data['result']){
                LoadingMsg.push({type:'success',title:'送信完了',content:'入力内容を送信しました',delay:5000});
                setMsg(LoadingMsg);
                setVisible(false);

                document.getElementById('content_area').remove();
                setAlert({'type':'success','style':true,'msgs':["ステップ配信を登録しました。"]});
            }else{
                LoadingMsg.push({type:'danger',title:'エラー',content:'入力内容が保存できませんでした',delay:5000});
                setMsg(LoadingMsg);
                setVisible(false);

                setAlert({'type':'danger','style':true,'description':data['error']['description']});
            }
        }).catch((error)=>{
            LoadingMsg.push({type:'warning',title:'エラー',content:'通信エラーが発生しました'});
            setMsg(LoadingMsg);
            LoadingMsg=[];
            setVisible(false);

            console.log(error);
        });
    }
    return(
        <div>
            <BreadCrumb item={BCrumb} />
            <AlertBox Item={Alert} />
            <Loading visible={Visible} msgs={Msg} />
            <div id="content_area"></div>
        </div>
    )
}