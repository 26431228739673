import { Redirect, useParams } from "react-router-dom";
import BreadCrumb from "../component/breadcrumb";
import Contact from "../component/Contact";
import { useState,useEffect } from "react";
import Fetch from "../common/fetch";
import AlertBox from "../component/AlertBox";
import Loading from "../component/Loading";
import getParams from "../component/getParams";

let LoadingMsg=[];

export default function Line(){

	const [Visible,setVisible]=useState(true);
	const [Msg,setMsg]=useState(LoadingMsg);
    const [Alert,setAlert]=useState({'type':'info','style':false, 'msgs':[],'description':[] });
    const [BCrumb,setBCrumb]=useState({
        'CurrentText':'LINE連携',
        'items':[
            {
                'index':1,
                'href':'/student',
                'text':'ホーム'
            }
        ]
    });

    const [RedirectTo,setRedirectTo]=useState(false);
    useEffect(()=>{
        let params=getParams();
        let send={
            'url':'v1/student/course/'+params['state']+'/line',
            'method':'POST',
            'body':params
        }
        Fetch(send).then(data=>{
            if(data['result']){
                setRedirectTo("/student/course/"+params['state']+"/welcome");
            }else{
                LoadingMsg.push({type:'warning',title:'エラー',content:'LINE連携に失敗しました'});
                setMsg(LoadingMsg);
                setVisible(false);
                setAlert({'type':'danger','style':true,'description':data['error']['description']});
            }
        })
        .catch((error)=>{
            LoadingMsg.push({type:'warning',title:'エラー',content:'通信エラーが発生しました'});
            setMsg(LoadingMsg);
            setVisible(false);
            console.log(error);
        })
    },[])
    return(
        <div>
            <BreadCrumb item={BCrumb} />
            <AlertBox Item={Alert} />
            <Loading visible={Visible} msgs={Msg} />
            {RedirectTo?<Redirect to={RedirectTo} />:""}
        </div>
    );
}