import { useEffect, useState } from "react";
import BreadCrumb from "../component/breadcrumb";
import Fetch from "../common/fetch";
import AlertBox from "../component/AlertBox";
import { UserRight, UserType } from "../component/Keys";
import { Link } from "react-router-dom";

export default function Users(){
    const BCrumb={'CurrentText':'会員管理','items':[{'index':1,'href':'/admin','text':'ホーム'}]};
    const [Item,setItem]=useState([false,{}]);
    const [Alert,setAlert]=useState({'type':'danger','style':false,'msgs':[]});
    useEffect(()=>{
        let send={
            'url':'v1/admin/user',
            'method':'GET'
        }
        Fetch(send).then(data=>{
            if(data['result']){
                setAlert({'type':'danger','style':false,'msgs':[]});
                setItem([true,data['payloads']])
            }else{
                setAlert({'type':'danger','style':true,'description':data['error']['description']});
            }
        }).catch(error=>console.log(error));
    },[])
    return(
        <div>
            <BreadCrumb item={BCrumb} />
            <AlertBox Item={Alert} />
            <div className="table-responsive">
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th>ユーザー名</th>
                            <th>ユーザー種類</th>
                            <th>ユーザー権限</th>
                            <th>システム利用料未払い</th>
                        </tr>
                    </thead>
                    <tbody>{Item[0] && Item[1].map((user,index)=>
                        <tr key={index}>
                            <td>
                                <Link to={"/admin/users/"+user['UserId']}>{user['UserName']}</Link>
                            </td>
                            <td>
                                {UserType(user['UserType'])}
                            </td>
                            <td>
                                {UserRight(user['UserRight'])}
                            </td>
                            <td>
                                {user['unpay'].map((unpay,index)=>
                                <span key={index}>{unpay}</span>)}
                            </td>
                        </tr>)}
                    </tbody>
                </table>
            </div>
        </div>
    )
}