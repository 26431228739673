import { useState,useEffect } from "react";
import { Redirect, Route, Switch } from "react-router";
import { Store } from "./common/store";
import AlertBox from "./component/AlertBox";
import BreadCrumb from "./component/breadcrumb";
import CardList from "./component/CardList";
import Course from "./teacher/course";
import Info from "./teacher/info";
import Text from "./teacher/text";
import Fetch from "./common/fetch";
import Report from "./teacher/report";
import Examination from "./teacher/examination";
import Student from "./teacher/student";
import StudentDetail from "./teacher/StudentDetail";
import Grade from "./teacher/grade";
import Step from "./teacher/linestep";
import Cancellation from "./teacher/cancellation";
import EntryInfo from "./TeacherEntry/info";
import EditReport from "./TeacherEdit/report";
import EntryReport from "./TeacherEntry/report";
import EditExamination from "./TeacherEdit/examination";
import EntryExamination from "./TeacherEntry/examination";
import EntryStep from "./TeacherEntry/linestep";
import { Link } from "react-router-dom";
import EditCourse from "./TeacherEdit/course";
import EntryCourse from "./TeacherEntry/course";
import EditAccount from "./TeacherEdit/account";
import EntryAccount from "./TeacherEntry/account";
import EditLine from "./TeacherEdit/line";
import EntryLine from "./TeacherEntry/line";
import EditFolder from "./TeacherEdit/folder";
import EditText from "./TeacherEdit/text";
import EntryText from "./TeacherEntry/text";
import AdminFolder from "./TeacherEdit/AdminFolder";
import Error from "./common/error";
import TeacherAdd from "./TeacherEntry/add";
import Loading from "./component/Loading";

export default function Teacher(props){
    let store=Store.getState();
    return(
        <>
        {(store['UserType']==0 || store['UserType']==2) ? 
        <Switch>
            <Route path="/teacher" exact>
                <Home Render={props.Render} />
            </Route>
            <Route path="/teacher/course" exact>
                <EntryCourse />
            </Route>
            <Route path="/teacher/course/:id" exact>
                <Course />
            </Route>
            <Route path="/teacher/course/:id/edit" exact>
                <EditCourse />
            </Route>
            <Route path="/teacher/course/:course/info" exact>
                <EntryInfo />
            </Route>
            <Route path="/teacher/course/:course/info/:id" exact>
                <Info />
            </Route>
            <Route path="/teacher/course/:id/folder" exact>
                <AdminFolder />
            </Route>
            <Route path="/teacher/course/:id/add">
                <TeacherAdd />
            </Route>
            <Route path="/teacher/course/:course/folder/:id/edit" exact>
                <EditFolder />
            </Route>
            <Route path="/teacher/course/:course/folder/:folder/text" exact>
                <EntryText />
            </Route>
            <Route path="/teacher/course/:course/folder/:folder/text/:id/edit" exact>
                <EditText />
            </Route>
            <Route path="/teacher/course/:course/text/:id" exact>
                <Text />
            </Route>
            
            <Route path="/teacher/course/:course/report" exact>
                {store['report']?<EntryReport />:<Error error={403} />}
            </Route>
            <Route path="/teacher/course/:course/report/:id" exact>
                {store['report']?<Report />:<Error error={403} />}
            </Route>
            <Route path="/teacher/course/:course/report/:id/edit" exact>
                {store['report']?<EditReport />:<Error error={403} />}
            </Route>

            <Route path="/teacher/course/:course/examination" exact>
                {store['test']?<EntryExamination />:<Error error={403} />}
            </Route>
            <Route path="/teacher/course/:course/examination/:id" exact>
                {store['test']?<Examination />:<Error error={403} />}
            </Route>
            <Route path="/teacher/course/:course/examination/:id/edit" exact>
                {store['test']?<EditExamination />:<Error error={403} />}
            </Route>



            <Route path="/teacher/course/:course/step" exact>
                <EntryStep />
            </Route>
            <Route path="/teacher/course/:course/step/:id/edit" exact>
                <Step />
            </Route>
            <Route path="/teacher/course/:course/student" exact>
                <Student />
            </Route>
            <Route path="/teacher/course/:course/student/:id" exact>
                <StudentDetail />
            </Route>
            <Route path="/teacher/course/:course/grade" exact>
                <Grade />
            </Route>
            <Route path="/teacher/course/:course/cancellation" exact>
                <Cancellation />
            </Route>
            <Route path="/teacher/account" exact>
                <EntryAccount />
            </Route>
            <Route path="/teacher/account/:id/edit" exact>
                <EditAccount />
            </Route>
            <Route path="/teacher/line" exact>
                <EntryLine />
            </Route>
            <Route path="/teacher/line/:id/edit" exact>
                <EditLine />
            </Route>
            <Route path="/teacher">
                <Error error={404} />
            </Route>
            </Switch>
            :<Redirect to="/" />}
        </>
    );
}
function Home({Render}){
    const [contacts,setContacts]=useState({'title':'新着情報','items':[]});
    const BCrumb={'CurrentText':'ホーム','items':[]};
    const [Alert,setAlert]=useState({'type':'danger','style':false,'msgs':[]});
    const [Visible,setVisible]=useState(true);
    
    const [courses,setCourses]=useState({'title':'開講コース','items':[]});
    const [courseOutline,setCourseOutline]=useState({"HavingCourse":0,"MaxCourse":0,"MaxStudent":0,"web":0,"report":0,"test":0,"serma":0});
    const [accounts,setAccounts]=useState({'title':'決済','items':[]});
    const [lines,setLines]=useState({'title':'公式LINE','items':[]});

	useEffect(()=>{
        let send={
            url:'v1/teacher',
            method:'GET'
        }
        Fetch(send).then(data=>{
            if(data['result']){
                setCourses({'title':'開講コース','items':getCourse(data['payloads']['courses']['courses'])});
                setCourseOutline(data['payloads']['courses']['summary']);
                setAccounts({'title':'決済','items':getAccount(data['payloads']['accounts'])});
                setLines({'title':'公式LINE','items':getLine(data['payloads']['lines'])});
                setContacts({'title':'新着情報','items':getContact(data['payloads']['contacts'])})
            }
            setVisible(false);
        }).catch(error=>{
            setVisible(false);
            console.log(error)
        });
        /*setCourses({'title':'開講コース','items':getCourse()});
        setCourseOutline(getCourseOutline());
        setAccounts({'title':'決済','items':getAccount()});
        setLines({'title':'公式LINE','items':getLine()});*/
	},[])
    function getCourseOutline(){
        return Store.getState()['TeacherCourse']['summary'];
    }
    function getContact(data){
        let items=[];
        for(let i=0;i<data.length;++i){
            items.push({
                'index':i,
                'href':'/teacher/course/'+data[i]['CourseId']+'/info/'+data[i]['ContactId'],
                'text':data[i]['ContactTitle']
            })
        }
        return items;
    }
    function getCourse(data){
        let items=[];
        for(let i=0;i<data.length;++i){
            items.push({
                'index':i,
                'href':'/teacher/course/'+data[i]['CourseId'],
                'text':data[i]['CourseName']
            })
        }
        return items;
    }
    function getAccount(data){
        let items=[];
        for(let i=0;i<data.length;++i){
            items.push({
                'index':i,
                'href':'/teacher/account/'+data[i]['AccountId']+'/edit',
                'text':data[i]['AccountName']
            })
        }
        return items;
    }
    function getLine(data){
        let items=[];
        //let data=Teacher[2];
        for(let i=0;i<data.length;++i){
            items.push({
                'index':i,
                'href':'/teacher/line/'+data[i]['uid']+'/edit',
                'text':data[i]['LineName']
            })
        }
        return items;
    }
    return(
        <div>
            <BreadCrumb item={BCrumb} />
            <AlertBox Item={Alert} />
            <Loading visible={Visible} msgs={[]} />
            <div className="row">
                <div className="col-sm-6 col-md-6 mb-2">
                    <CardList item={courses} />{
                        courseOutline['HavingCourse']<courseOutline['MaxCourse']?
                        <Link to="/teacher/course">新規コース</Link>:
                        '開講可能コース数の上限に達しています'
                    }
                </div>
                <div className="col-sm-6 col-md-6 mb-2">
                    <CardList item={contacts} />
                </div>
                <div className="col-sm-6 col-md-6 mb-2">
                    <CardList item={accounts} />
                    <Link to="/teacher/account">新規決済</Link>
                </div>
                <div className="col-sm-6 col-md-6 mb-2">
                    <CardList item={lines} />
                    <Link to="/teacher/line">新規公式LINE</Link>
                </div>
            </div>
        </div>
    )
}