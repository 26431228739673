import { useEffect, useState } from "react";
import { PaymentMethodImg, PaymentMethodKey, PaymentMethodLink } from "./Keys";
import Stripe from "./Stripe";


/**
 *支払いフォーム
 *
 * @export
 * @param array payment
 * @param array setFunc=[setMethod,setStripeToken]
 * @return {*} 
 */
export default function PaymentForm({payment,setFunc,required=true}){
    const [Method,setMethod]=useState(false);
    const [Token,setToken]=useState('');
    function changePayment(e){
        setMethod(e.target.value);
        setFunc[0](e.target.value);
    }
    useEffect(()=>{
        setFunc[1](Token)
    },[Token])
    return(
        <>
            <div className="row">
                <div className="col-sm-auto my-2">
                    お支払方法
                </div>
                <div className="col-sm-auto my-2">
                    <select name="PaymentMethod" className="form-select js-data" onChange={changePayment} required={required}>
                        <option label="選択してください"></option>{payment.map(method=>
                        <option value={method['method']} key={method['method']}>{PaymentMethodKey(method['method'])}</option>)}
                    </select>
                </div>
            </div>{payment.map(method=>
            <div className="row" style={{display:(Method==method['method']?'flex':'none')}}>
                {method['method']=='stripe' &&
                <div className="col my-2">
                    <input type="hidden" className="js-data" name="StripeToken" value={Token} />
                    <Stripe PublicKey={method['apiKey']} setToken={setToken} />
                </div>}
            </div>)}
            <div className="row mt-2" style={{maxWidth:'700px'}}>{payment.map(method=>
                <div className="col-sm-3">
                    <a href={PaymentMethodLink(method['method'])} target="_blank">
                        <img src={PaymentMethodImg(method['method'])} alt={PaymentMethodKey(method['method'])} className="w-100 h-100" />
                    </a>
                </div>)}
            </div>
        </>
    );
}