import ReactDOM from 'react-dom';
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import { ConvertToDate } from './Keys';
import { useEffect, useState } from 'react';
import SendJson from './SendJson';
import Fetch from '../common/fetch';
import AlertBox from './AlertBox';
import { updateVariation } from '../../App';
import AccountForm from './AccountForm';
import LineForm from './LineForm';
import { Store } from '../common/store';
import CourseTeacherForm from './CourseTeacherForm';


export default function CourseForm(props){
    let Course=props.Item;
    let Title;
    let New;
    const [LINE,setLINE]=useState([]);
    const [LineAlert,setLineAlert]=useState({'type':'danger','style':false,'msgs':[]});
    const [AccountAlert,setAccountAlert]=useState({'type':'danger','style':false,'msgs':[]});
    const [ACCOUNT,setACCOUNT]=useState([]);
    const [AccountNumber,setAccountNumber]=useState({display:Course['AccountNumberType']==0?'none':'flex'});
    if(props.Item['CourseName']===""){
        Title='新規コース';
        New=true;
    }else{
        Title=Course['CourseName']+'編集';
        New=false;
    }
    useEffect(()=>{
        let send={
            'url':'v1/teacher/line',
            'method':'GET'
        }
        Fetch(send).then(data=>{
            if(data['result']){
                setLineAlert({'type':'success','style':false,'msgs':[]});
                setLINE(data['payloads']);
            }else{
                setLineAlert({'type':'danger','style':true,'description':data['error']['description']});
            }
        }).catch(error=>console.log(error));
        send['url']='v1/teacher/account';
        Fetch(send).then(data=>{
            if(data['result']){
                setLineAlert({'type':'success','style':false,'msgs':[]});
                setACCOUNT(data['payloads']);
            }else{
                setLineAlert({'type':'danger','style':true,'description':data['error']['description']});
            }
        }).catch(error=>console.log(error));
        ReactDOM.render(<AccountForm Submit={addAccount} />,document.getElementById('AccountModal'));
        ReactDOM.render(<LineForm Submit={addLine} />,document.getElementById('LineModal'));
    },[])
    function addLine(){
        let send={
            'url':'v1/teacher/line',
            'method':'POST',
            'body':{}
        }
        let parent=document.getElementById('addLineModal');
        let datas=parent.getElementsByClassName('js-data');
        let login=parent.getElementsByClassName('js-data-login');
        let messaging=parent.getElementsByClassName('js-data-messaging');
        send['body']=SendJson(datas);
        send['body']['login']=SendJson(login);
        send['body']['messaging']=SendJson(messaging);
        Fetch(send).then(data=>{
            if(data['result']){
                setLineAlert({'type':'success','style':true,'msgs':["公式LINEを追加しました。"]});
                setLINE(data['payloads']);
                updateVariation('TeacherLine');
            }else{
                setLineAlert({'type':'danger','style':true,'description':data['error']['description']});
            }
        }).catch(error=>console.log(error));
    }
    function addAccount(send){
        Fetch(send).then(data=>{
            if(data['result']){
                setAccountAlert({'type':'success','style':true,'msgs':["公式LINEを追加しました。"]});
                setACCOUNT(data['payloads']);
                updateVariation('TeacherAccount');
            }else{
                setLineAlert({'type':'danger','style':true,'description':data['error']['description']});
            }
        }).catch(error=>console.log(error));
    }
    function resetAlert(){
        let parent=document.getElementById('addLineModal');
        let data=parent.getElementsByClassName('js-data');
        for(let i=0;i<data.length;++i){
            data[i].value="";
        }
        data=parent.getElementsByClassName('js-data-login');
        for(let i=0;i<data.length;++i){
            data[i].value="";
        }
        data=parent.getElementsByClassName('js-data-messaging');
        for(let i=0;i<data.length;++i){
            data[i].value="";
        }
        document.getElementById('AccountName').value="";
        data=parent.getElementsByClassName('js-data-stripe');
        for(let i=0;i<data.length;++i){
            data[i].value="";
        }
        data=parent.getElementsByClassName('js-data-paypay');
        for(let i=0;i<data.length;++i){
            data[i].value="";
        }
        data=parent.getElementsByClassName('js-data-paypal');
        for(let i=0;i<data.length;++i){
            data[i].value="";
        }
        setLineAlert({'type':'danger','style':false,'msgs':[]});
        setAccountAlert({'type':'danger','style':false,'msgs':[]});
    }
    function changeFile(e){
        e.target.dataset.name=e.target.files[0].name;
        e.target.parentNode.getElementsByClassName('js-file-name')[0].value=e.target.files[0].name;
    }
    function changeFileName(e){
        e.target.parentNode.getElementsByClassName('js-file')[0].dataset.name=e.target.value;
    }
    function changeKeiyaku(e){
        e.target.value==0?setAccountNumber({display:'none'}):setAccountNumber({display:'flex'});
        let AccountNumber=document.getElementById('AccountNumber');
        if(AccountNumber.value==""){
            AccountNumber.value=0;
        }
    }
    return(
        <>
        <form id="CourseForm">
            <div className="row">
                <div className="col-12">
                <h2>
                    {Title}
                </h2>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="alert alert-warning">
                        {props.Delete ?
                        <>
                            支払い内容は変更できません。
                        </>:
                        <>
                            一度登録しますと，支払い内容を変更できませんのでご注意ください。
                        </>
                        }
                    </div>
                    <div className="my-2 row form-group">
                    <label htmlFor="CourseName" className="col-sm-2 col-form-label">
                        コース名
                    </label>
                    <div className="col-sm-10">
                        <input type="text" name="CourseName" id="CourseName" className="form-control js-data" placeholder="コース名" maxLength={250} defaultValue={Course['CourseName']} required />
                    </div>
                    </div>
                    <div className="my-2 row form-group">
                    <label className="col-sm-2 col-form-label">
                        開講期間
                    </label>
                    <div className="col-sm-10">
                        <div className="input-group">
                        <input type="date" name="CourseTerm[0]" className="form-control js-data-term" defaultValue={ConvertToDate(Course['term'][0])} required />
                        <span className="input-group-text">から</span>
                        <input type="date" name="CourseTerm[1]" className="form-control js-data-term" defaultValue={ConvertToDate(Course['term'][1])} required />
                        <span className="input-group-text">まで</span>
                        </div>
                    </div>
                    </div>
                    <div className="my-2 row form-group">
                    <labe className="col-sm-2 col-form-label">
                        講師
                    </labe>
                    <div className="col-sm-10">
                        <ul className="list-group">{Course['teachers'].map((teacher,index)=>
                            <li className="list-group-item" key={index}>{teacher['UserId']!=Course['UserId'] ?
                                <span>{teacher['UserName']}</span>:
                                <div>
                                    {teacher['UserName']}(自分)
                                    <CourseTeacherForm teacher={teacher} />
                                </div>
                            }</li>
                            )}
                        </ul>
                    </div>
                    </div>
                    <div className="my-2 row form-group">
                    <label htmlFor="CourseMethod" className="col-sm-2 col-form-label">
                        講義方法
                    </label>
                    <div className="col-sm-10">
                        <textarea name="CourseMethod" id="CourseMethod" className="form-control js-data" placeholder="講義方法" maxLength={250} required defaultValue={Course['CourseMethod']} />
                    </div>
                    </div>
                    <div className="my-2 row form-group">
                        <label htmlFor="CourseLevel" className="col-sm-2 col-form-label">
                            受講レベル
                        </label>
                        <div className="col-sm-10">
                            <input type="text" name="CourseLevel" className="form-control js-data" list="CourseLevelOptions" placeholder="受講レベル" maxLength={250} defaultValue={Course['CourseLevel']} required />
                            <datalist id="CourseLevelOptions">
                            <option value="指定なし" />
                            <option value="初心者" />
                            <option value="初級者" />
                            <option value="中級者" />
                            <option value="上級者" />
                            <option value="セミプロ" />
                            <option value="プロ" />
                            </datalist>
                        </div>
                    </div>
                    <div className="my-2 row form-group">
                        <label htmlFor="CourseLevel" className="col-sm-2 col-form-label">
                            対象者
                        </label>
                        <div className="col-sm-10">
                            <input type="text" name="CourseTarget" className="form-control js-data"  placeholder="対象者" maxLength={250} defaultValue={Course['CourseTarget']} />
                        </div>
                    </div>
                    <div className="my-2 row form-group">
                    <label htmlFor="CourseKeywords" className="col-sm-2 col-form-label">
                        キーワード
                    </label>
                    <div className="col-sm-10">
                        <ul id="course_keyword" className="list-unstyled">{Course['keywords'].map((key,index)=>
                            <li>
                                <AddKeyword Item={key} Index={index} />
                            </li>
                        )}</ul>
                    </div>
                    </div>
                    <div className="my-2 row form-group">
                    <label htmlFor="CourseMaxStudent" className="col-sm-2 col-form-label">
                        最大受講者数
                    </label>
                    <div className="col-sm-10">
                        <div className="input-group">
                        <input type="number" name="MaxStudent" id="CourseMaxStudent" className="form-control js-data" placeholder="最大受講者数" min={0} max={Course['summary']['MaxStudent']} defaultValue={Course['MaxStudent']} data-type="numeric" required />
                        <span className="input-group-text">名</span>
                        </div>
                    </div>
                    </div>
                    <div className="my-2 row form-group">
                    <label htmlFor="SetupPrice" className="col-sm-2 col-form-label">
                        初期費用
                    </label>
                    <div className="col-sm-10">
                        <div className="input-group">
                            <input type="number" name="SetupPrice" id="SetupPrice" className="form-control js-data" placeholder="初期費用" min={0} defaultValue={Course['SetupPrice']} data-type="numeric" readOnly={props.Delete?true:false} required />
                            <span className="input-group-text">円</span>
                        </div>
                    </div>
                    </div>
                    <div className="my-2 row form-group">
                    <label htmlFor="MontylyPrice" className="col-sm-2 col-form-label">
                        月額費用
                    </label>
                    <div className="col-sm-10">
                        <div className="input-group">
                            <input type="number" name="MonthlyPrice" id="MonthlyPrice" className="form-control js-data" placeholder="月額費用" min={0} defaultValue={Course['MonthlyPrice']} data-type="numeric" readOnly={props.Delete?true:false} required />
                            <span className="input-group-text">円</span>
                        </div>
                    </div>
                    </div>
                    <div className="my-2 row form-group">
                    <label htmlFor="AccountNumber" className="col-sm-2 col-form-label">
                        契約月数
                    </label>
                    <div className="col-sm-10">
                        <div className="row">
                            <div className="col-sm-auto">
                                <select name="AccountNumberType" className="form-select js-data" defaultValue={Course['AccountNumberType']} data-type="numeric" disabled={props.Delete?true:false} onChange={changeKeiyaku} required>
                                    <option value={1}>有限回</option>
                                    <option value={0}>無限回</option>
                                </select>
                            </div>
                            <div className="col">
                                <div className="input-group" style={AccountNumber}>
                                    <input type="number" name="AccountNumber" id="AccountNumber" className="form-control js-data" placeholder="契約月数" min={0} defaultValue={Course['AccountNumber']} data-type="numeric" readOnly={props.Delete?true:false} required />
                                    <span className="input-group-text">月</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                    <div className="my-2 row form-group">
                    <label htmlFor="free" className="col-sm-2 col-form-label">
                        無料期間
                    </label>
                    <div className="col-sm-10">
                        <select name="free" id="free" data-label="無料期間" className="form-select js-data" defaultValue={Course['free']} disabled={props.Delete?true:false} data-type="numeric" required>
                        <option label="選択してください" />
                        <option value={0}>無料期間なし</option>
                        <option value={1}>1カ月無料</option>
                        </select>
                    </div>
                    </div>
                    <div className="my-2 row form-group">
                    <label htmlFor="goal" className="col-sm-2 col-form-label">
                        講義の目標
                    </label>
                    <div className="col-sm-10">
                        <textarea name="goal" id="goal" className="form-control js-data" placeholder="講義の目標" maxLength={250} defaultValue={Course['goal']} />
                    </div>
                    </div>
                    <div className="my-2 row form-group">
                    <label className="col-sm-2 col-form-label">
                        講義計画
                    </label>
                    <ol id="course_plan" className="col-sm-10">{Course['plans'].map((plan,index)=>
                        <li>
                            <AddPlan Item={plan} Index={index} />
                        </li>)}
                    </ol>
                    </div>
                    <div className="my-2 row form-group">
                    <label className="col-sm-2 col-form-label">
                        必要物
                    </label>
                    <ol id="course_need" className="col-sm-10">{Course['needs'].map((need,index)=>
                        <li>
                            <AddNeed Item={need} Index={index} />
                        </li>)}
                    </ol>
                    </div>
                    <div className="my-2 row form-group">
                    <label htmlFor="attention" className="col-sm-2 col-form-label">
                        受講の注意事項
                    </label>
                    <div className="col-sm-10">
                        <textarea name="attention" id="attention" className="form-control js-data" placeholder="受講の注意事項" maxLength={250} defaultValue={Course['attention']} />
                    </div>
                    </div>{(!props.Delete || (props.Delete && Course['UserId']==Store.getState()['UserId'])) &&
                    <>
                    <div className="my-2 row form-group">
                        <label htmlFor="LineId" className="col-sm-2 col-form-label">
                            公式LINE連携
                        </label>
                        <div className="col-sm-10">
                            <select name="LineId" id="LineId" data-label="公式LINE連携" className="form-select js-data" defaultValue={Course['LineId']} required>
                                <option value={0}>連携しない</option>
                                {LINE.map((line,index)=>
                                    <option value={line['LineId']} key={index} selected={Course['LineId']==line['LineId'] ? true:false}>{line['LineName']}</option>
                                )}
                            </select>
                            {/*<a href="#" data-bs-toggle="modal" data-bs-target="#addLineModal" onClick={resetAlert}>公式LINE追加</a>*/}{props.Delete && 
                            <p>
                                ※既に受講登録されている方のLINE連携内容は変更されません。
                            </p>}
                        </div>
                    </div>
                    <div className="my-2 row form-group">
                        <label htmlFor="AccountId" className="col-sm-2 col-form-label">
                        決済方法
                        </label>
                        <div className="col-sm-10">
                            <select name="AccountId" id="AccountId" data-label="決済方法" className="form-select js-data" defaultValue={Course['AccountId']} required>
                                <option label="選択してください" />{ACCOUNT.map((account,index)=>
                                    <option value={account['AccountId']} key={index} selected={Course['AccountId']==account['AccountId'] ? true:false}>{account['AccountName']}</option>
                                )}
                            </select>
                            {/*<button type='button' className='btn text-primary text-decoration-underline' data-bs-toggle="modal" data-bs-target="#addAccountModal" onClick={resetAlert} disabled={props.Delete?true:false}>決済方法追加</button>*/}
                        </div>
                    </div></>}
                <div className="my-2 row form-group">
                    <label className="col-sm-2 col-form-label">
                    契約書
                    </label>
                    <div className="col-sm-10">
                        {New ?
                        <div className="input-group">
                            <input type="file" className="form-control js-file" data-name accept="application/pdf" onChange={changeFile} required />
                            <span className="input-group-text">契約書名</span>
                            <input type="text" className="form-control js-file-name" placeholder="契約書名" onChange={changeFileName} required />
                        </div>:
                        <Tabs defaultActiveKey="current">
                            <Tab eventKey="current" title="現在の契約書">
                                <div className="border border-top-0 py-2 px-3">
                                    <a href={Course['contract']['link']} id="TeacherCourseContract" data-id={Course['contract']['FileId']} target="_blank">{Course['contract']['name']}</a>
                                </div>
                            </Tab>
                            <Tab.Container eventKey="new" title="新規契約書">
                                <div className="border border-top-0 py-2 px-3">
                                    <div className="input-group">
                                        <input type="file" className="form-control js-file" data-name accept="application/pdf" onChange={changeFile} required />
                                        <span className="input-group-text">契約書名</span>
                                        <input type="text" className="form-control js-file-name" placeholder="契約書名" onChange={changeFileName} required />
                                    </div>
                                </div>
                            </Tab.Container>
                        </Tabs>}
                    </div>
                </div>
                <div className="my-2 row form-group">
                    <label htmlFor="display" className="col-sm-2 col-form-label">
                    検索結果への表示
                    </label>
                    <div className="col-sm-10">
                    <select name="display" id="display" className="form-select js-data" defaultValue={Course['display']} data-type="numeric" required>
                        <option value={1}>表示する</option>
                        <option value={0}>表示しない</option>
                    </select>
                    </div>
                </div>
                <div className="my-2 row form-group">
                    <div className="col-auto">
                    <button type="button" className="btn btn-primary" onClick={props.Submit}>
                        登録
                    </button>{props.Delete &&
                    <button type="button" className="btn btn-danger ms-2" onClick={props.Delete}>
                        削除
                    </button>}
                    </div>
                </div>
                </div>
            </div>
        </form>
                    {/*公式LINE追加モーダル*/}
                    <div className="modal fade w-100" id="addLineModal" tabIndex={-1} aria-labelledby="addLineModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-lg modal-dialog-scrollable">
                        <div className="modal-content">
                            <div className="modal-header">
                            <h5 className="modal-title" id="addLineModalLabel">公式LINE追加</h5>
                            </div>
                            <div className="modal-body">
                                <AlertBox Item={LineAlert} />
                                <div id="LineModal"></div>
                                <button type="button" className="btn btn-secondary mt-3" data-bs-dismiss="modal" aria-label="Close">キャンセル</button>
                            </div>
                        </div>
                        </div>
                    </div>
                    {/*お支払方法追加モーダル*/}
                    <div className="modal fade w-100" id="addAccountModal" tabIndex={-1} aria-labelledby="addAccountModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-lg modal-dialog-scrollable">
                            <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="addAccountModalLabel">決済方法登録</h5>
                            </div>
                            <div className="modal-body">
                                <AlertBox Item={AccountAlert} />
                                <div id="AccountModal"></div>
                                <button type="button" className="btn btn-secondary mt-3" data-bs-dismiss="modal" aria-label="Close">キャンセル</button>
                            </div>
                            </div>
                        </div>
                    </div>
    </>
    );
}
function AddKeyword(props){
    function addPlan(){
        let parent=document.getElementById('course_keyword');
        let li=document.createElement('li');
        parent.appendChild(li);
        let len=parent.getElementsByTagName('li').length;
        ReactDOM.render(<AddKeyword Item="" Index={len} />,li);
        li.getElementsByTagName('input')[0].focus();
    }
    function doEnter(e){
        if(e.keyCode===13){
            addPlan();
        }else if(e.target.value==="" && e.keyCode===8){
            const parent=document.getElementById('course_keyword');
            let li=parent.getElementsByTagName('li');
            let len=li.length-2;
            if(li[len]){
                li[len].getElementsByTagName('input')[0].focus();
                e.target.parentNode.remove();
            }
        }
    }
    return(
        <input type="text" name="keyword[]" className="form-control js-data-keywords" placeholder="キーワード" maxLength={30} defaultValue={props.Item} key={props.Index} onKeyUp={doEnter} />
    );
}
function AddPlan(props){
    function addPlan(){
        let parent=document.getElementById('course_plan');
        let li=document.createElement('li');
        parent.appendChild(li);
        let len=parent.getElementsByTagName('li').length;
        ReactDOM.render(<AddPlan Item="" Index={len} />,li);
        li.getElementsByTagName('input')[0].focus();
    }
    function doEnter(e){
        if(e.keyCode===13){
            addPlan();
        }else if(e.target.value==="" && e.keyCode===8){
            const parent=document.getElementById('course_need');
            let li=parent.getElementsByTagName('li');
            let len=li.length-2;
            if(li[len]){
                li[len].getElementsByTagName('input')[0].focus();
                e.target.parentNode.remove();
            }
        }
    }
    return(
        <input type="text" name="plans[]" className="form-control js-data-plans" placeholder="講義計画" maxLength={250} defaultValue={props.Item} key={props.Index} onKeyUp={doEnter} />
    );
}
function AddNeed(props){
    function addNeed(){
        const parent=document.getElementById('course_need');
        let li=document.createElement('li');
        parent.appendChild(li);
        let len=parent.getElementsByTagName('li').length;
        ReactDOM.render(<AddNeed Item="" Index={len} />,li);
        li.getElementsByTagName('input')[0].focus();
    }
    function doEnter(e){
        if(e.keyCode===13){
            addNeed();
        }else if(e.target.value==="" && e.keyCode===8){
            const parent=document.getElementById('course_need');
            let li=parent.getElementsByTagName('li');
            let len=li.length-2;
            if(li[len]){
                li[len].getElementsByTagName('input')[0].focus();
                e.target.parentNode.remove();
            }
        }
    }
    return(
        <input type="text" name="needs[]" className="form-control js-data-needs" placeholder="必要物" maxLength={250} defaultValue={props.Item} key={props.Index} onKeyUp={doEnter} />
    );
}