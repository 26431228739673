import { useEffect, useState } from "react";
import { ConvertToDatetime } from "./Keys";
import SendJson from "./SendJson";
import UploadedFile from "./UploadedFile";
import UploadFile from "./UploadFile";


export default function TextForm(props){
    let Data;
    const [PublicStyle,setPublicStyle]=useState([{display:'none'},{display:'none'}]);
    if(props.Item===undefined){
        Data={
            "TextName": "",
            "ClassMethod": "",
            "video": {
                "LinkName": "",
                "link": "",
                "explanation": ""
            },
            "note": "",
            "PublicDate": 0,
            "EntriedNumber": 0,
            "FromDate": "",
            "ToDate": "",
            "free": ""
        };
    }else{
        Data=props.Item;
    }
    useEffect(()=>{
        changePublic();
    },[])
    function changePublic(){
        if(document.getElementById('PublicDate').value==0){
            setPublicStyle(([{display:'block'},{display:'none'}]));
        }else{
            setPublicStyle(([{display:'none'},{display:'block'}]));
        }
    }
    function doEntry(){
        let body=SendJson(document.getElementsByClassName('js-data'));
        body['video']=SendJson(document.getElementsByClassName('js-data-video'));
        let data=document.getElementsByClassName('js-data-file-delete');
        body['deletes']=[];
        for(let i=0;i<data.length;++i){
            if(data[i].value!=""){
                body['deletes'].push(data[i].value);
            }
        }
        body['files']=[];
        props.Submit(body);
    }
    return(
        <div className="container">
            <div className="row">
                <div className="col-12">
                <h2>
                    {Data['TextName']}
                </h2>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                <form>
                    <div className="my-2 row form-group">
                    <label htmlFor="Textname" className="col-sm-2 col-form-label">
                        教材名
                    </label>
                    <div className="col-sm-10">
                        <input type="text" name="TextName" id="TextName" className="form-control js-data" placeholder="教材名" defaultValue={Data['TextName']} required />
                    </div>
                    </div>
                    <div className="my-2 row form-group">
                    <label htmlFor="ClassMethod" className="col-sm-2 col-form-label">
                        講義方法
                    </label>
                    <div className="col-sm-10">
                        <input type="text" name="ClassMethod" id="ClassMethod" className="form-control js-data" placeholder="講義方法" defaultValue={Data['ClassMethod']} required />
                    </div>
                    </div>
                    <div className="my-2 row form-group">
                    <label className="col-sm-2 col-form-label">
                        動画教材
                    </label>
                    <div className="col-sm-10">
                            <div className="my-2 input-group">
                                <span className="input-group-text">動画名</span>
                                <input type="text" name="LinkName" className="form-control js-data-video" placeholder="動画名" defaultValue={Data['video']['LinkName']} />
                            </div>
                            <div className="my-2 input-group">
                                <span className="input-group-text">動画URL</span>
                                <input type="url" name="link" className="form-control js-data-video" placeholder="動画URL" defaultValue={Data['video']['link']} onBlur />
                            </div>
                            <div className="my-2 input-group">
                                <span className="input-group-text">参加方法等</span>
                                <textarea name="explanation" className="form-control js-data-video" placeholder="参加方法等" defaultValue={Data['video']['explanation']} />
                            </div>
                    </div>
                    </div>
                    <div className="my-2 row form-group">
                    <label htmlFor="FreeText" className="col-sm-2 col-form-label">
                        無料体験
                    </label>
                    <div className="col-sm-10">
                        <select name="FreeText" id="FreeText" className="form-select js-data" defaultValue={Data['free']} data-type="boolean" required>
                        <option value={false}>いいえ</option>
                        <option value={true}>はい</option>
                        </select>
                    </div>
                    </div>
                    <div className="my-2 row form-group">
                    <label htmlFor="note" className="col-sm-2 col-form-label">
                        備考
                    </label>
                    <div className="col-sm-10">
                        <textarea name="note" className="form-control js-data" placeholder="備考">{Data['note']}</textarea>
                    </div>
                    </div>
                    <div className="my-2 row form-group">
                    <label className="col-sm-2 col-form-label">
                        公開設定
                    </label>
                    <div className="col-sm-10">
                        <div className="row">
                        <div className="col-auto">
                            <select name="PublicDate" id="PublicDate" className="form-select js-data" defaultValue={Data['PublicDate']} data-type="numeric" onChange={changePublic} required>
                            <option value={0}>絶対日時</option>
                            <option value={1}>相対日時</option>
                            </select>
                        </div>
                        <div className="col-auto" style={PublicStyle[0]}>
                            <div className="input-group">
                            <input type="datetime-local" name="FromDate" className="form-control js-data" defaultValue={ConvertToDatetime(Data['FromDate'])} required />
                            <span className="input-group-text">から</span>
                            <input type="datetime-local" name="ToDate" className="form-control js-data" defaultValue={ConvertToDatetime(Data['ToDate'])} required />
                            <span className="input-group-text">まで</span>
                            </div>
                        </div>
                        <div className="col-auto" style={PublicStyle[1]}>
                            <div className="input-group">
                            <span className="input-group-text">受講登録から</span>
                            <input type="number" name="EntriedNumber" className="form-control js-data" min={0} data-type="numeric" defaultValue={Data['EntriedNumber']} required />
                            <span className="input-group-text">日経過後</span>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                    <div className="my-2 row form-group">
                        <label htmlFor="Textname" className="col-sm-2 col-form-label">
                            添付ファイル
                        </label>
                        <div className="col-sm-10">{Data['files'] &&
                            <UploadedFile Item={Data['files']} />
                        }
                            <h5>
                                新規ファイル
                            </h5>
                            <UploadFile />
                        </div>
                    </div>
                </form>
                </div>
            </div>
            <div className="mt-2 row form-group">
                <div className="col-12">
                    <button type="button" className="btn btn-primary" onClick={doEntry}>登録</button>{props.Delete &&
                    <button type="button" className="btn btn-danger ms-2" onClick={props.Delete}>削除</button>
                    }
                </div>
            </div>
        </div>
    );
}