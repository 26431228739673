import { useEffect, useState } from "react";
import SendJson from "./SendJson";

export default function LineForm({Delete,Submit,Item}){
    const [Line,setLine]=useState({
        "LineName": "",
        "link": "",
        "login": {
            "ChannelId": "",
            "ChannelSecret": ""
        },
        "messaging": {
            "ChannelId": "",
            "ChannelSecret": "",
            "AccessToken": ""
        }
    });
    useEffect(()=>{
        if(Item){
            setLine(Item);
        }
        console.log(Item);
    },[Item])
    function doEntry(){
        let send={
            'url':'v1/teacher/line',
            'method':'POST',
            'body':{}
        }
        let parent=document.getElementById('LineForm');
        let datas=parent.getElementsByClassName('js-data');
        let login=parent.getElementsByClassName('js-data-login');
        let messaging=parent.getElementsByClassName('js-data-messaging');
        send['body']=SendJson(datas);
        send['body']['login']=SendJson(login);
        send['body']['messaging']=SendJson(messaging);
        Submit(send);
    }
    function focus(e){
        e.target.select();
    }
    return (
        <form id="LineForm">
            <table className="table table-striped table-hover">
                <tbody>
                    <tr>
                        <th>
                            登録名
                        </th>
                        <td>
                            <input type="text" name="LineName" className="form-control js-data" placeholder="登録名" defaultValue={Line['LineName']} required />
                        </td>
                    </tr>
                    <tr>
                        <th>
                        公式LINE登録URL
                        </th>
                        <td>
                        <input type="url" name="link" className="form-control js-data" placeholder="公式LINE登録URL" defaultValue={Line['link']} required />
                        </td>
                    </tr>
                    <tr>
                        <th>
                        ログインAPI
                        </th>
                        <td>
                        <div className="my-2 input-group">
                            <span className="input-group-text">チャネルID</span>
                            <input type="text" name="ChannelId" className="form-control js-data-login" placeholder="チャネルID" defaultValue={Line['login']['ChannelId']} required />
                        </div>
                        <div className="my-2 input-group">
                            <span className="input-group-text">
                            チャネルシークレット
                            </span>
                            <input type="text" name="ChannelSecret" className="form-control js-data-login" placeholder="チャネルシークレット" defaultValue={Line['login']['ChannelSecret']} required />
                        </div>
                        <div className="card">
                            <div className="card-header">
                            LINE Developersでの設定
                            </div>
                            <div className="card-body">
                            <ol>
                                <li>
                                <a href="https://developers.line.biz/ja/" target="_blank">LINE Developers</a>に移動
                                </li>
                                <li>
                                チャネルを「公開」に設定
                                </li>
                                <li>
                                コールバックURLを<input type="text" className="form-control" defaultValue="https://app.canteach.jp/student/course/line/" onFocus={focus} readOnly />に設定
                                </li>
                            </ol>
                            </div>
                        </div>
                        </td>
                    </tr>
                    <tr>
                        <th>
                        メッセージングAPI
                        </th>
                        <td>
                        <div className="my-2 input-group">
                            <span className="input-group-text">チャネルID</span>
                            <input type="text" name="ChannelId" className="form-control js-data-messaging" placeholder="チャネルID" defaultValue={Line['messaging']['ChannelId']}  required />
                        </div>
                        <div className="my-2 input-group">
                            <span className="input-group-text">
                            チャネルシークレット
                            </span>
                            <input type="text" name="ChannelSecret" className="form-control js-data-messaging" placeholder="チャネルシークレット" defaultValue={Line['messaging']['ChannelSecret']}  required />
                        </div>
                        <div className="my-2 input-group">
                            <span className="input-group-text">
                            アクセストークン
                            </span>
                            <input type="text" name="AccessToken" className="form-control js-data-messaging" placeholder="アクセストークン" defaultValue={Line['messaging']['AccessToken']}  required />
                        </div>
                        <div className="card">
                            <div className="card-header">
                            LINE Developersでの設定
                            </div>
                            <div className="card-body">
                            <ol>
                                <li>
                                <a href="https://developers.line.biz/ja/" target="_blank">LINE Developers</a>に移動
                                </li>
                                <li>
                                Webhookを<input type="text" className="form-control" defaultValue="https://api.canteach.jp/v1/webhook/line" onFocus={focus} readOnly />に設定
                                </li>
                                <li>
                                Webhookを「有効」に設定
                                </li>
                            </ol>
                            </div>
                        </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <button type="button" className="btn btn-primary" onClick={doEntry}>登録</button>{Delete &&
            <button type="button" className="btn btn-danger ms-2" onClick={Delete}>
                削除
            </button>}
        </form>
    );
}