import { useEffect, useState } from "react";
import { ConvertToDatetime } from "./Keys";
import UploadedFile from "./UploadedFile";
import UploadFile from "./UploadFile";


export default function ReportForm(props){
    let Report;
    const [PublicStyle,setPublicStyle]=useState([{display:'none'},{display:'none'}]);
    if(props.Item!=undefined){
        Report=props.Item;
        Report['deadline']=ConvertToDatetime(Report['deadline']);
        Report['FromDate']=ConvertToDatetime(Report['FromDate']);
        Report['ToDate']=ConvertToDatetime(Report['ToDate']);
    }else{
        Report={
            "ReportId": "",
            "ReportTitle": "",
            "content": "",
            "deadline": "",
            "PublicDate": 0,
            "EntriedNumber": 0,
            "FromDate": "",
            "ToDate": "",
            "score": "",
            "files": []
        };
    }
    useEffect(()=>{
        changePublic();
    },[])
    function changePublic(){
        if(document.getElementById('PublicDate').value==0){
            setPublicStyle([{display:'block'},{display:'none'}]);
        }else{
            setPublicStyle([{display:'none'},{display:'block'}]);
        }
    }
    return(
        <div>
            <div className="row">
                <div className="col-12">
                <h2>
                    {Report['ReportTitle']}
                </h2>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                <form>
                    <div className="my-2 row form-group">
                    <label htmlFor="ReportTitle" className="col-sm-2 col-form-label">
                        課題名
                    </label>
                    <div className="col-sm-10">
                        <input type="text" name="ReportTitle" id="ReportTitle" className="form-control js-data" placeholder="課題名" defaultValue={Report['ReportTitle']} maxLength={250} required />
                    </div>
                    </div>
                    <div className="my-2 row form-group">
                    <label htmlFor="content" className="col-sm-2 col-form-label">
                        説明等
                    </label>
                    <div className="col-sm-10">
                        <textarea name="content" id="content" className="form-control js-data" placeholder="説明等" defaultValue={Report['content']} />
                    </div>
                    </div>
                    <div className="my-2 row form-group">
                    <label htmlFor="dedline" className="col-sm-2 col-form-label">
                        提出期限
                    </label>
                    <div className="col-sm-10">
                        <input type="datetime-local" name="deadline" id="deadline" className="form-control js-data" placeholder="提出期限" defaultValue={Report['deadline']} required />
                    </div>
                    </div>
                    <div className="my-2 row form-group">
                    <label htmlFor="PublicDate" className="col-sm-2 col-form-label">
                        公開設定
                    </label>
                    <div className="col-sm-10 row">
                        <div className="col-auto">
                            <select name="PublicDate" id="PublicDate" className="form-select js-data" defaultValue={Report['PublicDate']} data-type="numeric" onChange={changePublic}>
                                <option value={0}>絶対日時</option>
                                <option value={1}>相対日時</option>
                            </select>
                        </div>
                        <div className="col-auto" style={PublicStyle[0]}>
                            <div className="input-group">
                                <input type="datetime-local" name="FromDate" className="form-control js-data" defaultValue={Report['FromDate']} placeholder="公開開始日" />
                                <span className="input-group-text">から</span>
                                <input type="datetime-local" name="ToDate" className="form-control js-data" defaultValue={Report['ToDate']} placeholder="公開終了日" />
                                <span className="input-group-text">まで</span>
                            </div>
                        </div>
                        <div className="col-auto" style={PublicStyle[1]}>
                            <div className="input-group">
                                <span className="input-group-text">受講登録から</span>
                                <input type="number" name="EntriedNumber" className="form-control js-data" min={0} defaultValue={Report['EntriedNumber']} placeholder="登録経過日数" />
                                <span className="input-group-text">日経過後</span>
                            </div>
                        </div>
                    </div>
                    </div>
                    <div className="my-2 row form-group">
                        <label htmlFor="score" className="col-sm-2 form-group">
                            得点
                        </label>
                        <div className="col-sm-10">
                            <div className="input-group">
                            <input type="number" name="score" id="score" className="form-control js-data" placeholder="得点" min={0} defaultValue={Report['score']} required />
                            <span className="input-group-text">点</span>
                            </div>
                        </div>
                    </div>
                    <div className="my-2 row form-group">
                        <label className="col-sm-2 form-group">
                            添付ファイル
                        </label>
                        <div className="col-sm-10">{Report['files'] &&
                            <UploadedFile Item={Report['files']} />
                        }
                            <h5>
                                新規ファイル
                            </h5>
                            <UploadFile Id={1} />
                        </div>
                    </div>
                    <div className="mt-2 row form-group">
                        <div className="col-auto">
                            <button type="button" className="btn btn-primary" onClick={props.Submit}>登録</button>{props.Delete &&
                            <button type="button" className="btn btn-danger ms-2" onClick={props.Delete}>削除</button>}
                        </div>
                    </div>
                </form>
                </div>
            </div>
        </div>
    );
}