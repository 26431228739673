import { Card, ListGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import Escape from "./Escape";

export default function TextDetail(props){
    return(
        <div>{props.Edit &&
            <div className="mb-3">
                <Link to={"/teacher/course/"+props.Course+"/folder/"+props.Folder+'/text'} className="btn btn-success">教材の追加</Link>
            </div>}
            <div className="d-flex align-items-start">
                <div className="nav flex-column me-3 nav-pills" id="TextNav" role="tablist" aria-orientation="vertical">{props.Item.map((text,index)=>
                    <a href={"#text_"+index} className={index ?"nav-link":"nav-link active"} data-bs-toggle="pill" role="tab" key={index}>{text['TextName']}</a>)}
                </div>
                <div className="tab-content">{props.Item.map((text,index)=>
                    <Card id={"text_"+index} className={index ? "tab-pane fade":"tab-pane fade show active"} role="tabpanel" key={index}>
                        <Card.Header>
                            {text['TextName']}
                        </Card.Header>
                        <Card.Body>{(text['video']['link']!="" && text['video']['LinkName']!="" && text['video']['explanation'])?
                            <Card.Text>
                                <iframe src={text['video']['link']} className="w-100 h-auto"></iframe>
                                <div className="text-end">
                                    <a href={text['video']['link']} target="_blank">{text['video']['LinkName']}</a>
                                </div>
                                <h6>
                                    動画の説明
                                </h6>
                                <div>
                                    <p>
                                        {Escape(text['video']['explanation'])}
                                    </p>
                                </div>
                                <h6>

                                </h6>
                            </Card.Text>:""}
                            <Card.Text>
                                <h5>
                                    添付ファイル
                                </h5>
                                <ListGroup>{text['files'].map((file,index2)=>
                                    <a href={file['link']} className="list-group-item list-group-item-action" target="_blank" key={index2}>
                                        {file['FileName']}
                                    </a>
                                )}</ListGroup>
                            </Card.Text>
                            <Card.Text>
                                <h5>
                                    その他
                                </h5>
                                <table className="table table-striped">
                                    <tbody>
                                        <tr>
                                            <th>
                                                講義方法
                                            </th>
                                            <td>
                                                {text['ClassMethod']}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>
                                                備考
                                            </th>
                                            <td>
                                                {Escape(text['note'])}
                                            </td>
                                        </tr>{text['free'] &&
                                        <tr>
                                            <th>
                                                体験教材
                                            </th>
                                            <td>
                                                この教材は無料で受講できます。
                                            </td>
                                        </tr>}
                                    </tbody>
                                </table>
                            </Card.Text>
                        </Card.Body>{props.Edit &&
                        <Card.Footer className="text-end">
                            <Link to={"/teacher/course/"+props.Course+'/folder/'+props.Folder+'/text/'+text['TextId']+'/edit'}>
                                教材の編集
                            </Link>
                        </Card.Footer>}
                    </Card>)}
                </div>
            </div>
        </div>
    );
}