import { useEffect, useState } from "react";
import Fetch from '../common/fetch';
import PaymentForm from "./PaymentForm";


export default function ProfileForm(props){
    let [item,setItem]=useState(props.Item);
    let edit=props.edit?true:false;
    const [TeacherStyle,setTeacherStyle]=useState({display:'none'});
    const [Payment,setPayment]=useState(false);
    const [Plan,setPlan]=useState([]);
    useEffect(()=>{
        changeUserType();
        let send={
            'url':'v1/admin/plan',
            'method':'GET'
        }
        Fetch(send).then(data=>{
            if(data['result']){
                setPlan(data['payloads']);
                console.log(data['payloads']);
            }else{
                console.log("can't get plans");
            }
        }).catch(error=>console.log(error))
    },[])
    function changeImg(e){
        let src=e.target.parentNode.parentNode.getElementsByClassName('js-img')[0];
        if(e.target.value){
            src.src=window.URL.createObjectURL(e.target.files[0]);
        }else{
            src.src=src.dataset.src;
        }
    }
    function changeUserType(){
        if(document.getElementById('UserType').value!=1){
            setTeacherStyle({display:'flex'});
            setPayment(true);
        }else{
            setTeacherStyle({display:'none'});
            setPayment(false);
        }
    }
    return (
        <div>{edit && 
            <div className="row my-2">
                <label className="col-sm-2 col-form-label">
                    メールアドレス
                </label>
                <div className="col-sm-10">
                    <input type="email" name="email" className="form-control js-data" placeholder="メールアドレス" defaultValue={item['email']} required />
                </div>
            </div>}
            <div className="row my-2">
                <label className="col-sm-2 col-form-label">
                    名前
                </label>
                <div className="col-sm-10">
                    <input type="text" name="UserName" className="form-control js-data" placeholder="名前" defaultValue={item['UserName']} required />
                </div>
            </div>
            <div className="row my-2">
                <label className="col-sm-2 col-form-label">
                    アイコン
                </label>
                <div className="col-sm-auto">
                    <img src={item['icon']} data-src={item['icon']} className="files__icon-lg js-img my-2" />
                </div>
                <div className="col-sm-auto">
                    <input type="file" className="form-control js-data-file" accept="image/*" onChange={changeImg} />
                </div>
            </div>
            <div className="row my-2">
                <label className="col-sm-2 col-form-label">
                    ステータスメッセージ
                </label>
                <div className="col-sm-10">
                    <textarea name="introduction" className="form-control js-data" placeholder="ステータスメッセージ" defaultValue={item['introduction']} />
                </div>
            </div>
            <div className="row my-2">
                <label className="col-sm-2 col-form-label">
                    LINE QRコード
                </label>
                <div className="col-sm-auto">
                    <img src={item['qr']} data-src={item['qr']} className="files__icon-lg js-img my-2" />
                </div>
                <div className="col-sm-auto">
                    <input type="file" className="form-control js-data-file" accept="image/*" onChange={changeImg} />
                </div>
            </div>
            <div className="row my-2">
                <label className="col-sm-2 col-form-label">
                    ホームページ
                </label>
                <div className="col-sm">
                    <input type="url" name="hp" className="form-control js-data" placeholder="ホームページ" defaultValue={item['hp']} />
                </div>
                <div className="col-sm-auto">
                    <select name="HpPublic" className="form-select js-data" defaultValue={item['HpPublic']} data-type="boolean">
                        <option value={true}>公開</option>
                        <option value={false}>非公開</option>
                    </select>
                </div>
            </div>
            <div className="row my-2">
                <label className="col-sm-2 col-form-label">
                    Twitter
                </label>
                <div className="col-sm">
                    <div className="input-group">
                        <span className="input-group-text">@</span>
                        <input type="text" name="twitter" className="form-control js-data" placeholder="Twitter" defaultValue={item['twitter']} />
                    </div>
                </div>
                <div className="col-sm-auto">
                    <select name="TwitterPublic" className="form-select js-data" defaultValue={item['TwitterPublic']} data-type="boolean">
                        <option value={true}>公開</option>
                        <option value={false}>非公開</option>
                    </select>
                </div>
            </div>
            <div className="row my-2">
                <label className="col-sm-2 col-form-label">
                    ユーザー種類
                </label>
                <div className="col-sm-10">
                    <select name="UserType" id="UserType" className="form-control js-data" defaultValue={item['UserType']} onChange={changeUserType} required>
                        <option value={0}>受講者・講師</option>
                        <option value={1}>受講者</option>
                        <option value={2}>講師</option>
                    </select>
                </div>
            </div>
            <div className="row my-2" style={TeacherStyle}>
                <label className="col-sm-2 col-form-label">
                    講師プラン
                </label>
                <div className="col-sm-10">
                    <select name="plan" className="form-select js-data">{Plan.map((plan,index)=>
                    <option value={plan['PlanId']} key={index} selected={plan['PlanId']==item['plan']?true:false}>{plan['PlanName']}({plan['price']}円)</option>
                    )}</select>
                </div>
            </div>{item['payment']?'':
            <div className="row my-2" style={TeacherStyle}>
                <label className="col-sm-2 col-form-label">
                    お支払い方法
                </label>
                <div className="col-sm-10">
                    <PaymentForm payment={item['paymentMethod']?item['paymentMethod']:[]} required={Payment} setFunc={item['funcs']} />
                </div>
            </div>}
            <div className="row mt-2">
                <div className="col">
                    <button type="button" className="btn btn-primary" onClick={props.Submit}>登録</button>
                </div>
            </div>
        </div>
    );
}