import Host from "./Host";

export default function LineBtn({Link,Text='LINEでログイン',target="_self"}){

    return (
        <a href={Link} className="line" target={target}>
            <div className="line__wrapper">
                <img src={Host('assets/img/line_icon.png')} className="line__icon" />
                <span className="line__login">{Text}</span>
            </div>
        </a>
    );
}