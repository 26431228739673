import { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { useParams } from "react-router";
import BreadCrumb from "../component/breadcrumb";
import Fetch from "../common/fetch";
import AlertBox from "../component/AlertBox";
import FolderForm from "../component/FolderForm";
import Upload from "../common/upload";

export default function EntryFolder(){
    const {course,id}=useParams();
    const [BCrumb,setBCrumb]=useState({'CurrentText':'新規教材フォルダ','items':[{'index':1,'href':'/teacher','text':'ホーム'}]});
    const [Alert,setAlert]=useState({'type':'danger','style':false,'msgs':[]});
    useEffect(()=>{
        let send={
            'url':'v1/course/'+course+'/folder/'+id,
            'method':'GET'
        }
        Fetch(send).then(data=>{
            if(data['result']){
                setAlert({'type':'danger','style':false,'msgs':[]});
                ReactDOM.render(<FolderForm Item={data['payloads']} Submit={doEntry} />,document.getElementById('folder_area'));
                setBCrumb({
                    CurrentText: data['payloads']['FolderName']+'編集',
                    items: [
                        {
                            index: 1,
                            href: "/teacher",
                            text: "ホーム"
                        },
                        {
                            index:2,
                            href:'/teacher/course/'+course,
                            text:data['payloads']['CourseName']
                        }
                    ],
                });
            }else{
                setAlert({'type':'danger','style':true,'description':data['error']['description']});
            }
        }).catch(error=>console.log(error));
    },[])
    function doEntry(){
        let name=document.getElementById('FolderName').value;
        let file=document.getElementById('FolderIcon');
        if(name.value==""){
            setAlert({'type':'danger','style':true,'msgs':["フォルダー名を入力してください。"]});
        }else if(file.value!=""){
            let fd=new FormData();
            fd.append('file[]',file.files[0]);
            Upload(fd).then(data=>{
                if(data['result']){
                    doSubmit(data['payloads']['FileIds'][0]);
                }else{
                    setAlert({'type':'danger','style':true,'description':data['error']['description']});
                }
            }).catch(error=>console.log(error));
        }else{
            doSubmit('');
        }
    }
    function doSubmit(FileId){
        let send={
            'url':'v1/course/'+course+'/folder',
            'method':'POST',
            'body':{
                "FolderName":document.getElementById('FolderName').value,
                "icon":FileId,
                "parent":0
            }
        }
        Fetch(send).then(data=>{
            if(data['result']){
                setAlert({'type':'success','style':true,'msgs':["フォルダーを作成しました。"]});
                document.getElementById('folder_area').textContent="";
            }else{
                setAlert({'type':'danger','style':true,'description':data['error']['description']});
            }
        }).catch(error=>console.log(error));
    }
    return(
        <div>
            <BreadCrumb item={BCrumb} />
            <AlertBox Item={Alert} />
            <div id="folder_area"></div>
        </div>
    )
}