import { useState,useEffect } from "react";
import ReactDOM from 'react-dom';
import { Link } from "react-router-dom";
import Fetch from "../common/fetch";
import { Store } from "../common/store";
import Escape from "./Escape";
import { ConvertToDisplayDate, CourseFree } from "./Keys";

/**
 *show a syllabus
 *
 * @export
 * @param {*} props=Course[]
 * @return {*} 
 */
export default function Syllabus(props){
    useEffect(() => {
        let send={
            'url':'v1/syllabus/'+props.Id,
            'method':'GET'
        }
        if(!Store.getState()['Auth']){
            send['url']+='/show';
        }
        Fetch(send).then(data=>{
            if(data['result']){
                if(props.get){
                    props.get(data['payloads']);
                }
                ReactDOM.render(<CourseSyllabus Course={data['payloads']} />,document.getElementById('SyllabusContentArea'));
            }
        }).catch(error=>{console.log(error)});
    }, [])
    return(
        <div id="SyllabusContentArea"></div>
    );
}
export function CourseSyllabus({Course}){
    return(
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <table className="table table-striped table-hover">
                        <tbody>
                        <tr>
                            <th>
                            コース名
                            </th>
                            <td>
                            {Course['CourseName']}
                            </td>
                        </tr>
                        <tr>
                            <th>
                            開講期間
                            </th>
                            <td>
                            {ConvertToDisplayDate(Course['term'][0])}から{ConvertToDisplayDate(Course['term'][1])}まで
                            </td>
                        </tr>
                        <tr>
                            <th>
                            講師
                            </th>
                            <td>
                            <div className="list-group list-group-flush">
                                {Course['teachers'].map((teacher,index)=><a href={"/profile/teacher/"+teacher['TeacherId']} className="list-group-item list-group-item-action" target="_blank" key={index}>{teacher.UserName}</a>)}
                            </div>
                            </td>
                        </tr>
                        <tr>
                            <th>
                            講義方法
                            </th>
                            <td>
                                {Escape(Course['CourseMethod'])}
                            </td>
                        </tr>
                        <tr>
                            <th>
                            受講レベル
                            </th>
                            <td>
                                {Course['CourseLevel']}
                            </td>
                        </tr>
                        <tr>
                            <th>
                            キーワード
                            </th>
                            <td>
                                {Course['keywords'].map(key=><span key={key}>{key}&nbsp;</span>)}
                            </td>
                        </tr>
                        <tr>
                            <th>
                            最大受講者数
                            </th>
                            <td>
                                {Course['MaxStudent']}名
                            </td>
                        </tr>
                        <tr>
                            <th>
                            初期費用
                            </th>
                            <td>
                                {Course['SetupPrice']}円
                            </td>
                        </tr>
                        <tr>
                            <th>
                            月額費用
                            </th>
                            <td>
                                {Course['MonthlyPrice']}円/月{Course['AccountNumber']?' × '+Course['AccountNumber']+'月':''}
                            </td>
                        </tr>
                        <tr>
                            <th>
                            無料期間
                            </th>
                            <td>
                                {CourseFree(Course['free'])}
                            </td>
                        </tr>
                        <tr>
                            <th>
                            講義の目標
                            </th>
                            <td>
                                {Escape(Course['goal'])}
                            </td>
                        </tr>
                        <tr>
                            <th>
                            講義計画
                            </th>
                            <td>
                                <ol>
                                    {Course['plans'].map((plan,index)=><li key={index}>{plan}</li>)}
                                </ol>
                            </td>
                        </tr>
                        <tr>
                            <th>
                            必要物
                            </th>
                            <td>
                                <ul>
                                    {Course['needs'].map((need,index)=><li key={index}>{need}</li>)}
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <th>
                            受講の注意事項
                            </th>
                            <td>
                                {Escape(Course['attention'])}
                            </td>
                        </tr>
                        <tr>
                            <th>
                                契約書
                            </th>
                            <td>
                                <a href={Course['contract']['link']} target="_blank">{Course['contract']['name']}</a>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}