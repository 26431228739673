import { useState } from "react";
import { Redirect, Route, Switch } from "react-router";
import AlertBox from "./component/AlertBox";
import BreadCrumb from "./component/breadcrumb";
import Users from "./admin/user";
import UserDetail from "./admin/UserDetail";
import Courses from "./admin/courses";
import Infos from "./admin/infos";
import InfoEntry from "./admin/InfoEntry";
import InfoDetail from "./admin/InfoDetail";
import Support from "./admin/support";
import Invitations from "./admin/invitations";
import Plans from "./admin/plans";
import Payments from "./admin/payments";
import Templates from "./admin/templates";

export default function Admin(){
    return (
        <Switch>
            <Route path="/admin" exact>
                <Home />
            </Route>
            <Route path="/admin/users" exact>
                <Users />
            </Route>
            <Route path="/admin/users/:id" exact>
                <UserDetail />
            </Route>
            <Route path="/admin/courses" exact>
                <Courses />
            </Route>
            <Route path="/admin/infos" exact>
                <Infos />
            </Route>
            <Route path="/admin/infos/show/:id" exact>
                <InfoDetail />
            </Route>
            <Route path="/admin/infos/entry" exact>
                <InfoEntry />
            </Route>
            <Route path="/admin/template" exact>
                <Templates />
            </Route>
            <Route path="/admin/support" exact>
                <Support />
            </Route>
            <Route path="/admin/invitations" exact>
                <Invitations />
            </Route>
            <Route path="/admin/plans" exact>
                <Plans />
            </Route>
            <Route path="/admin/payments" exact>
                <Payments />
            </Route>
            <Route path="/admin">
                <Redirect to="/admin" />
            </Route>
        </Switch>
    );
}
function Home(){
    const BCrumb={
        'CurrentText':'ホーム',
        'items':[]
    };
    const [Alert,setAlert]=useState({'type':'danger','style':false,'msgs':[]});
    return(
        <div>
            <BreadCrumb item={BCrumb} />
            <AlertBox Item={Alert} />
            <div>
                メニューを選択してください。
            </div>
        </div>
    );
}