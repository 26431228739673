import {useState} from "react";
import { Link } from "react-router-dom";
import Fetch from "../common/fetch";
import AlertBox from "../component/AlertBox";
import BreadCrumb from "../component/breadcrumb";
import Loading from "../component/Loading";

let LoadingMsg=[];
export default function Search(){
    const BCrumb={
        'CurrentText':'コース検索',
        'items':[
            {
                'index':1,
                'href':'/student',
                'text':'ホーム'
            }
        ]
    };
    const [Result,setResult]=useState([]);
    const [Alert,setAlert]=useState({'type':'danger','style':false,'msgs':[]});
    const [ResultMeta,setResultMeta]=useState();
	const [Visible,setVisible]=useState(false);
	const [Msg,setMsg]=useState(LoadingMsg);
    function doSearch(){
        setVisible(true);
        LoadingMsg.push({type:'info',title:'検索中',content:'コースを検索しています',delay:5000});
        setMsg(LoadingMsg);
        const keyword=document.getElementById('keyword');
        let send={
            'url':'v1/syllabus/search/'+encodeURI(keyword.value),
            'method':'GET'
        }
        Fetch(send).then(data=>{
            if(data['result']){
                LoadingMsg.push({type:'success',title:'検索完了',content:'コースの検索が完了しました',delay:5000});
                setMsg(LoadingMsg);
                setAlert({'type':'danger','style':false,'msgs':[]});
                setResult(data['payloads']);
                setResultMeta('"'+keyword.value+'"の検索結果('+data['payloads'].length+'件)');
            }else{
                setAlert({'type':'danger','style':true,'description':data['error']['description']});
            }
            setVisible(false);
            LoadingMsg=[];
        }).catch(error=>{
            LoadingMsg.push({type:'warning',title:'エラー',content:'通信エラーが発生しました'});
            setMsg(LoadingMsg);
            LoadingMsg=[];
            setVisible(false);
            console.log(error);
        })
    }
    function pressKey(e){
        if(e.key=='Enter'){
            e.preventDefault();
            doSearch();
        }
    }
    return (
        <div>
            <BreadCrumb item={BCrumb} />
            <AlertBox Item={Alert} />
            <Loading visible={Visible} msgs={Msg} />
            <div className="row">
                <div className="col-12">
                <h2>
                    コース検索
                </h2>
                </div>
            </div>
            <div className="row">
                <form className="col-sm-6 col-md-6 form-group my-2">
                    <div className="input-group">
                        <input type="search" id="keyword" className="form-control js-data" placeholder="キーワード" aria-label="キーワード" aria-describedby="student-searchbtn" onKeyDown={pressKey} />
                        <button type="button" id="student_searchbtn" className="btn btn-primary" onClick={doSearch}>
                            検索
                        </button>
                    </div>
                </form>
            </div>
            <div className="row">
                <div className="col-auto my-3">
                <h3>
                    検索結果
                </h3>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="my-2">
                        {ResultMeta}
                    </div>
                    <div className="list-group list-group-flush">
                        {Result.map(res=><Link to={'/course/'+res.CourseId+'/syllabus'} className="list-group-item list-group-item-action" key={res.CourseId}>{res.CourseName}</Link>)}
                    </div>
                </div>
            </div>
        </div>
    );
}