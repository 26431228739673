import { Route ,Switch, Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import { Store } from './store';
import { useEffect, useState } from 'react';
export default function Header(props){
	function logout(){
		window.location.href="https://login.canteach.jp/logout/";
	}
	return(
		<header className="w-100 document__header" role="heading">
			<nav className="navbar navbar-expand-lg navbar-light bg-light p-0">
				<div className="container-fluid">
					<Switch>
						<Route path="/login">
							<Brand href="/login" h1style={props.h1style} />
						</Route>
						<Route path="/teacher">
							<Brand href="/teacher" h1style={props.h1style} />
						</Route>
						<Route path="/student">
							<Brand href="/student" h1style={props.h1style} />
						</Route>
						<Route path="/account">
							<Brand href="/account" h1style={props.h1style} />
						</Route>
						<Route path="/serma">
							<Brand href="/serma" h1style={props.h1style} />
						</Route>
						<Route path="/admin">
							<Brand href="/admin" h1style={props.h1style} />
						</Route>
						<Route path="/lp">
							<Brand href="/lp" h1style={props.h1style} />
						</Route>
						<Route path="/">
							<Brand href={window.location.pathname} h1style={props.h1style} />
						</Route>
					</Switch>{//Store.getState()['Auth']?
					<>
						<Switch>
							<Route path="/login" />
							<Route path="/">
								<NavbarToggle />
							</Route>
						</Switch>
						<div id="navbarGlobal" className="collapse navbar-collapse">
							<Switch>
								<Route path="/admin">
									<Admin />
								</Route>
								<Route path="/student">
									<Student Render={props.Render} Course={props.StudentCourse} />
								</Route>
								<Route path="/">
									<Home />
								</Route>
								<Route path="/login" />
								<Route path="/teacher">
									<Teacher Render={props.Render} />
								</Route>
								<Route path="/serma">
									<Serma Project={props.SermaProject} />
								</Route>
							</Switch>
							<Switch>
								<Route path="/login" />
								<Route path="/">
									<NavbarAccount />
									<button className="btn link-secondary align-middle link-underline w-auto" onClick={logout}>
										ログアウト
									</button>
								</Route>
							</Switch>
						</div>
					</>//:""
					}
				</div>
			</nav>
		</header>
	);
}
function Brand(props){
	return(<Link to={props.href} className="navbar-brand" style={props.h1style}>CanTeach</Link>);
}
function NavbarToggle(){
	return(
	<button type="button" className="navbar-toggler" data-bs-toggle="collapse" data-bs-target="#navbarGlobal" aria-controls="navbarGlobal" aria-expanded="false" aria-label="Toggle navigation">
		<span className="navbar-toggler-icon" />
	</button>);
}
//<img src={host("assets/img/person.png")} alt="アカウント" />
function NavbarAccount(){
	return(
	<div className="d-flex">
			<Dropdown align="end">
				<Dropdown.Toggle variant="">
					<i className="fas fa-user text-gray header__user"></i>
				</Dropdown.Toggle>
				<Dropdown.Menu align={{lg:'end'}}>
					<Dropdown.Item href="/">
						ホーム
					</Dropdown.Item>
					<Dropdown.Item href="/account" target="_blank">
						アカウント
					</Dropdown.Item>
					<Dropdown.Item href="/support" target="_blank">
						サポート
					</Dropdown.Item>
				</Dropdown.Menu>
			</Dropdown>
	</div>);
}
function Home(){
	return(<ul className="navbar-nav me-auto mb-3 mb-lg-0"></ul>);
}
function Student(props){
	const [menu,setMenu]=useState({
		'ToggleText':'受講中コース',
		'items':getCourse()
	});
	useEffect(()=>{
		setMenu({
			'ToggleText':'受講中コース',
			'items':getCourse()
		});
	},[props.Render])
	function getCourse(){
		let items=[];
		let data=Store.getState()['StudentCourse'];
		for(let i=0;i<data.length;++i){
			items.push({
				'index':i,
				'href':'/student/course/'+data[i]['StudentId'],
				'text':data[i]['CourseName']
			})
		}
		return items;
	}
	return(
		<ul className="navbar-nav me-auto mb-3 mb-lg-0">{/*
			<li className="nav-item dropdown">
				<Dropdown>
					<Dropdown.Toggle variant="">
						{menu.ToggleText}
					</Dropdown.Toggle>
					<Dropdown.Menu>
						{menu.items.map(item=><Dropdown.Item href={item.href} key={item.index}>{item.text}</Dropdown.Item>)}
					</Dropdown.Menu>
				</Dropdown>
			</li>*/}
			<li className="nav-item">
				<Link to="/student/course/search/" className="nav-link">コース検索</Link>
			</li>
		</ul>);
}
function Teacher(props){
	const [account,setAccount]=useState({'ToggleText':'決済設定','items':getAccount()});
	const [course,setCourse]=useState({'ToggleText':'開講中コース','items':getCourse()});
	const [line,setLine]=useState({'ToggleText':'公式LINE','items':getLine()});
	useEffect(()=>{
		setAccount({'ToggleText':'決済設定','items':getAccount()});
		setCourse({'ToggleText':'開講中コース','items':getCourse()});
		setLine({'ToggleText':'公式LINE','items':getLine()});
	},[props.Render])
	function getAccount() {
		let data=Store.getState()['TeacherAccount'];
		//let data=props.Teacher[0];
		let items=[];
		for(let i=0;i<data.length;++i){
			items.push({
				'index':i,
				'href':'/teacher/account/'+data[i]['AccountId']+'/edit',
				'text':data[i]['AccountName']
			});
		}
		return items;
	}
	function getCourse() {
		let data=Store.getState()['TeacherCourse']['courses'];
		//let data=props.Teacher[1];
		let items=[];
		for(let i=0;i<data.length;++i){
			items.push({
				'index':i,
				'href':'/teacher/course/'+data[i]['CourseId'],
				'text':data[i]['CourseName']
			});
		}
		return items;
	}
	function getLine() {
		let data=Store.getState()['TeacherLine'];
		//let data=props.Teacher[2];
		let items=[];
		for(let i=0;i<data.length;++i){
			items.push({
				'index':i,
				'href':'/teacher/line/'+data[i]['LineId']+'/edit',
				'text':data[i]['LineName']
			});
		}
		return items;
	}
	return(
		<ul className="navbar-nav me-auto mb-3 mb-lg-0">
			<li className="nav-item dropdown">
				<Dropdown>
					<Dropdown.Toggle variant="">
						{course.ToggleText}
					</Dropdown.Toggle>
					<Dropdown.Menu>
						{course.items.map(item=><Dropdown.Item href={item.href} key={item.index}>{item.text}</Dropdown.Item>)}
					</Dropdown.Menu>
				</Dropdown>
			</li>
			<li className="nav-item dropdown">
				<Dropdown>
					<Dropdown.Toggle variant="">
						{account.ToggleText}
					</Dropdown.Toggle>
					<Dropdown.Menu>
						{account.items.map(item=><a href={item.href} className="dropdown-item" key={item.index}>{item.text}</a>)}
					</Dropdown.Menu>
				</Dropdown>
			</li>
			<li className="nav-item dropdown">
				<Dropdown>
					<Dropdown.Toggle variant="">
						{line.ToggleText}
					</Dropdown.Toggle>
					<Dropdown.Menu>
						{line.items.map(item=><a href={item.href} className="dropdown-item" key={item.index}>{item.text}</a>)}
					</Dropdown.Menu>
				</Dropdown>
			</li>
		</ul>);
}
function Serma(props){
	let items=[];
	let data=props.Project;
	for(let i=0;i<data.length;++i){
		items.push({
			'index':i,
			'href':'/serma/project/'+data[i]['ProjectId'],
			'text':data[i]['ProjectName']
		})
	}
	let dp={
		'ToggleText':'プロジェクト',
		'items':items
	}
	return(
		<ul className="navbar-nav me-auto mb-3 mb-lg-0">
			<li className="nav-item dropdown">
				<Dropdown>
					<Dropdown.Toggle variant="">
						{dp.ToggleText}
					</Dropdown.Toggle>
					<Dropdown.Menu>
						{dp.items.map(item=><Dropdown.Item href={item.href} key={item.index}>{item.text}</Dropdown.Item>)}
					</Dropdown.Menu>
				</Dropdown>
			</li>
		</ul>);
}
function Admin(){
	let store=Store.getState();
	return(
		<ul className="navbar-nav me-auto mb-3 mb-lg-0">{store['UserRight']==2 ?<>
			<li className="nav-item dropdown">
				<Link to="/admin/users" className="nav-link">会員管理</Link>
			</li>
			<li className="nav-item dropdown">
				<Link to="/admin/courses" className="nav-link">コース管理</Link>
			</li>
			<li className="nav-item dropdown">
				<Link to="/admin/infos" className="nav-link">お知らせ</Link>
			</li>
			<li className="nav-item dropdown">
				<Link to="/admin/template" className="nav-link">テンプレート</Link>
			</li>
			<li className="nav-item dropdown">
				<Link to="/admin/support" className="nav-link">サポート</Link>
			</li>
			<li className="nav-item dropdown">
				<Link to="/admin/invitations" className="nav-link">紹介制度</Link>
			</li>
			<li className="nav-item dropdown">
				<Link to="/admin/plans" className="nav-link">プラン設定</Link>
			</li></>:''}
			<li className="nav-item dropdown">
				<Link to="/admin/payments" className="nav-link">支払い設定</Link>
			</li>
		</ul>);
}