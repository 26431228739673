import Card from 'react-bootstrap/Card'
import { Link } from 'react-router-dom';
import Slider from 'react-slick';

export default function TextCarousel(props){
    const toShow=props.Item.length>3?4:props.Item.length;
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: toShow,
        slidesToScroll: 1,
        className:'w-auto',
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: toShow>3?3:toShow,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: toShow>2?2:toShow,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                }
            }
        ]
    };
    return(
            <Slider {...settings}>{props.Item.map(item=>
                <div className="px-2 mb-5 carousel__wrapper text-center" key={item['index']}>
                    <Link to={item['href']} className="text-decoration-none">
                        <Card>
                            <Card.Img variant="top" src={item['icon']} className="w-100 h-auto" />
                            <Card.Body>
                                <Card.Title className="text-center">
                                    {item['text']}
                                </Card.Title>
                            </Card.Body>
                        </Card>
                    </Link>
                </div>)}
            </Slider>
    );
}