import Escape from "./Escape";

export default function ReportOutline(props){
    let Report;
    if(props.Item!=undefined){
        Report=props.Item;
    }else{
        Report={
            "ReportId": "",
            "ReportTitle": "",
            "content": "",
            "deadline": "",
            "PublicDate": 0,
            "EntriedNumber": 0,
            "FromDate": "",
            "ToDate": "",
            "score": 0,
            "files": []
        };
    }
    return(
        <div>
            <h2>
                {Report['ReportTitle']}
            </h2>
            <table className="table table-stripe table-hover">
                <tbody>
                    <tr>
                        <th>
                        説明
                        </th>
                        <td>
                        {Escape(Report['content'])}
                        </td>
                    </tr>
                    <tr>
                        <th>
                        添付ファイル
                        </th>
                        <td>
                        <div className="list-group list-group-flush">
                            {Report['files'].map(file=>
                            <a href={file['link']} className="list-group-item list-group-item-action" target="_blank" key={file['FileId']}>
                            {file['FileName']}
                            </a>)}
                        </div>
                        </td>
                    </tr>
                    <tr>
                        <th>
                        得点
                        </th>
                        <td>
                        {Report['score']}点
                        </td>
                    </tr>
                    <tr>
                        <th>
                        提出期限
                        </th>
                        <td>
                        {Report['deadline']}まで
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}