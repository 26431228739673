import { useEffect, useState } from "react";
import SendJson from "./SendJson";

let LoadingMsg=[];
export default function StepForm(props){
    let Data;
    const [TimingStyle,setTimingStyle]=useState({display:'block'});
    if(props.Item!=undefined){
        Data=props.Item;
    }else{
        Data={
            "CourseName": "",
            "StepTitle": "",
            "condition": true,
            "timings": {
                "timing": "",
                "days": "",
                "time": ""
            },
            "content": ""
        }
    }
    useEffect(()=>{
        changeTiming();
    },[])
    function changeTiming(){
        if(document.getElementById('timings').value=='DATE'){
            setTimingStyle({display:'block'});
        }else{
            setTimingStyle({display:'none'});
        }
    }
    function addParm(){
        let textarea=document.getElementById('content');
        let content=textarea.value;
        let BeforeLength=textarea.selectionStart;
        textarea.value=content.substr(0,BeforeLength)+document.getElementById('parm').value+content.substr(BeforeLength);
    }
    function doEntry(){
        props.setVisible(true);
        LoadingMsg.push({type:'info',title:'確認中',content:'入力内容を確認しています',delay:5000});
        props.setMsg(LoadingMsg);

        var {error,body}=SendJson(document.getElementsByClassName('js-data'),true);
        let SendBody=body;
        let AllError=error;
        var {error,body}=SendJson(document.getElementsByClassName('js-data-timings'),true);
        SendBody['timings']=body;
        AllError=AllError.concat(error);
        if(AllError.length){
            
            props.setAlert({'type':'danger','style':true,'msgs':AllError});

			LoadingMsg.push({type:'danger',title:'入力不備',content:'入力内容を確認してください',delay:5000});
			props.setMsg(LoadingMsg);
            LoadingMsg=[];
			props.setVisible(false);
            window.scroll({top:0});

        }else{
            props.setAlert({'type':'danger','style':false,'msgs':[]});
            props.Submit(SendBody);
        }
    }
    return(
        <div>
            <div className="row">
                <div className="col-12">
                <h2>
                    {Data['StepTitle']}
                </h2>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <form>
                        <div className="my-2 row form-group">
                            <label htmlFor="StepName" className="col-sm-2 col-form-label">
                                ステップ名
                            </label>
                            <div className="col-sm-10">
                                <input type="text" name="StepTitle" id="StepName" className="form-control js-data" placeholder="ステップ名" maxLength={250} defaultValue={Data['StepTitle']} required />
                            </div>
                        </div>
                        <div className="my-2 row form-group">
                            <label htmlFor="condition" className="col-sm-2 col-form-label">
                                状態
                            </label>
                            <div className="col-sm-10">
                                <select name="condition" id="condition" className="form-select js-data" defaultValue={Data['condition']} data-type="boolean" required>
                                    <option value={true}>配信中</option>
                                    <option value={false}>配信停止</option>
                                </select>
                            </div>
                        </div>
                        <div className="my-2 row form-group">
                            <label htmlFor="timings" className="col-sm-2 col-form-label">
                                タイミング
                            </label>
                            <div className="col-sm-10">
                                <div className="row">
                                <div className="col-auto">
                                    <div className="my-2 input-group">
                                    <span className="input-group-text">
                                        配信タイミング
                                    </span>
                                    <select name="timing" id="timings" className="form-select js-data-timings" defaultValue={Data['timings']['timing']} onChange={changeTiming} required>
                                        <option value={"DATE"}>経過時間指定</option>
                                        <option value={"SEND"}>一斉送信</option>
                                    </select>
                                    </div>
                                </div>
                                <div id="timing_detail" className="col-auto" style={TimingStyle}>
                                    <div className="my-2 input-group">
                                    <span className="input-group-text">
                                        受講登録後
                                    </span>
                                    <input type="number" name="days" id="days" className="form-control js-data-timings" min={1} defaultValue={Data['timings']['days']} placeholder="登録経過日数" required />
                                    <span className="input-group-text">
                                        日経過後の
                                    </span>
                                    <input type="number" name="time" id="time" className="form-control js-data-timings" min={0} max={24} defaultValue={Data['timings']['time']} placeholder="時間" required />
                                    <span className="input-group-text">
                                        時に配信
                                    </span>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                        <div className="my-2 row form-group">
                            <label htmlFor="content" className="col-sm-2 col-form-label">
                                内容
                            </label>
                            <div className="col-sm-10">
                                <textarea name="content" id="content" className="form-control js-data" placeholder="内容" defaultValue={Data['content']} required />
                                <div className="row">
                                <div className="col-auto">
                                    <div className="my-2 input-group">
                                    <span className="input-group-text">パラメータ</span>
                                    <select id="parm" className="form-select">
                                        <option value="[%name%]">お名前</option>
                                    </select>
                                    <button type="button" className="btn btn-secondary" onClick={addParm}>追加</button>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                        <div className="my-2 row form-group">
                            <div className="col-auto">
                                <button type="button" className="btn btn-primary" onClick={doEntry}>登録</button>{props.Delete &&
                            <button type="button" className="btn btn-danger ms-2" onClick={props.Delete}>削除</button>}
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}