import React, { useEffect, useState } from 'react';
import {Link} from 'react-router-dom';
import AlertBox from '../component/AlertBox';
import LineBtn from '../component/LineBtn';
import Loading from '../component/Loading';
import SendJson from '../component/SendJson';
import Fetch from './../common/fetch';
import { useCookies } from "react-cookie";
import RandomStr from '../common/RandomStr';

let LoadingMsg=[];
export default function Login({Auth}){

    const [Alert,setAlert]=useState({'type':'danger','style':false,'msgs':[]});
	const [Visible,setVisible]=useState(false);
	const [Msg,setMsg]=useState(LoadingMsg);
	const [cookies,setCookie,removeCookie]=useCookies();

	useEffect(()=>{
		if(!cookies['nonce']){
			setCookie('nonce',RandomStr());
		}
		if(!cookies['state']){
			setCookie('state',RandomStr());
		}
	},[])

	function AuthLogin(){

		setVisible(true);
		LoadingMsg.push({type:'info',title:'確認中',content:'入力内容を確認しています',delay:5000});
		setMsg(LoadingMsg);

		let {error,body}=SendJson(document.getElementById('LoginForm').getElementsByClassName('js-data'),true);
		const send={
			'url':'oauth/token',
			'method':'POST',
			'body':OauthToken()
		}
		if(error.length){
			setAlert({'type':'danger','style':true,'msgs':error});

			LoadingMsg.push({type:'danger',title:'入力不備',content:'入力内容を確認してください',delay:5000});
			setMsg(LoadingMsg);
			setVisible(false);

		}else{
			send['body']['username']=body['email'];
			send['body']['password']=body['password'];
			
			LoadingMsg.push({type:'info',title:'送信中',content:'ログイン情報を送信しています'});
			setMsg(LoadingMsg);

			Fetch(send).then(data=>{
				if(data['access_token']){

					LoadingMsg.push({type:'success',title:'ログイン成功',content:"ログインに成功しました\nまもなく画面を移動します"});
					setMsg(LoadingMsg);
					LoadingMsg=[];

					Auth({'access_token':data['access_token']});
				}else{

					LoadingMsg.push({type:'danger',title:'エラー',content:'入力内容が保存できませんでした',delay:5000});
					setMsg(LoadingMsg);
					LoadingMsg=[];
					setVisible(false);

					setAlert({'type':'danger','style':true,"msgs":["メールアドレスもしくはパスワードを確認してください。"]});
				}
			})
			.catch((error)=>{
				LoadingMsg.push({type:'warning',title:'エラー',content:'通信エラーが発生しました'});
				setMsg(LoadingMsg);
				LoadingMsg=[];
				setVisible(false);

				console.log(error);
			})
		}
	}
	
	return(
		<form id="LoginForm">
			<AlertBox Item={Alert} />
			<Loading visible={Visible} msgs={Msg} />
			<input type="hidden" name="method" className="js-data" defaultValue="EMAIL" />
			<div className="row">
				<div className="col-sm-8 col-md-8">
					<div className="row">
					<div className="col">
						<label htmlFor="login_email" className="form-label">
						メールアドレス
						</label>
						<input type="email" name="email" id="login_email" className="form-control js-data" placeholder="example@licsss.com" data-label="メールアドレス" autoComplete="username" required />
					</div>
					</div>
					<div className="row">
					<div className="col">
						<label htmlFor="login_password" className="form-label">
						パスワード
						</label>
						<input type="password" name="password" id="login_password" className="form-control js-data" placeholder="パスワード" data-label="パスワード" autoComplete="current-password" required />
					</div>
					</div>
					<div className="row mt-3">
					<div className="col-auto">
						<button type="button" className="btn btn-primary" onClick={AuthLogin}>
							ログイン
						</button>
					</div>
					<div className="col-auto">
						<Link to="/login/reset" className="text-decoration-none link-secondary align-middle link-underline" >
						パスワードをお忘れですか？
						</Link>
					</div>
					<div className="col-auto">
						<Link to="/login/register" className="text-decoration-none link-secondary align-middle link-underline">
						アカウントをお持ちでないですか？
						</Link>
					</div>
					</div>
				</div>
				<div className="col-auto">
					<div className="my-2">
						<LineBtn Link={encodeURI("https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=1656801294&redirect_uri=https://app.canteach.jp/login/line/&state="+cookies['state']+"&scope=profile openid&nonce="+cookies['nonce'])} />
					</div>
				</div>
			</div>
		</form>
	);
}

export function OauthToken(){
	let body={};
	body['grant_type']='password';
	body['client_id']=2;
	body['client_secret']='g62zqu3uLDOOMbT16O2uqEcY7uVsyuivkoQhp4rp';//本番環境
	body['client_secret']='8iKkGcw2dSoBhDQAyUKCSnTrxDWJPv330U6ToqmX';//テスト環境
	body['scope']='';
	return body;
}