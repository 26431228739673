import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Fetch from "../common/fetch";
import Upload from "../common/upload";
import AlertBox from "../component/AlertBox";
import BreadCrumb from "../component/breadcrumb";
import { ConvertToDisplayDate } from "../component/Keys";
import Loading from "../component/Loading";
import ReportOutline from "../component/ReportOutline";
import UploadFile from "../component/UploadFile";

let LoadingMsg=[];
export default function Report(){
    const {student,id}=useParams();
	const [Load,setLoad]=useState([false,{display:'none'},'']);
    const [Visible,setVisible]=useState(false);
    const [Msg,setMsg]=useState(LoadingMsg);
    const [AlertStyle,setAlertStyle]=useState({display:'none'});
    const [Alert,setAlert]=useState({'type':'danger','style':false,'msgs':[]});
	const [Validate,setValidate]=useState([false]);
    const [BCrumb,setBCrumb]=useState({
        'CurrentText':'課題',
        'items':[
            {
                'index':1,
                'href':'/student',
                'text':'ホーム'
            }
        ]
    });
    const [Report,setReport]=useState();
    const [submitedReport,setSubmitReport]=useState([]);
    const [SubmitedResult,setSubmitedResult]=useState({
        "StudentReportId": "",
        "ReportId": "",
        "SubmitDatetime": "",
        "answers": {
            "comment":"",
            "file":[]
        },
        "score": 0,
        "total": 0,
        "comment": 0
    });
    const [Res,setRes]=useState(false);
    useEffect(()=>{
        setVisible(true);
        let send={
            'url':'v1/student/course/'+student+'/report/'+id,
            'method':'GET'
        }
        Fetch(send).then(data=>{
            if(data['result']){
                setAlert({'type':'danger','style':false,'msgs':[]})
                setReport(data['payloads']['reports']);
                setSubmitReport(data['payloads']['submits']);
                setBCrumb({
                    'CurrentText':data['payloads']['reports']['ReportTitle'],
                    'items':[
                        {
                            'index':1,
                            'href':'/student',
                            'text':'ホーム'
                        },
                        {
                            'index':2,
                            'href':'/student/course/'+student,
                            'text':data['payloads']['reports']['CourseName']
                        }
                    ]
                });
                setRes(true);
            }else{
                setAlert({'type':'danger','style':true,'msgs':[],'description':data['error']['description']})
            }
            setVisible(false);
        }).catch((error)=>{
            LoadingMsg.push({type:'warning',title:'エラー',content:'通信エラーが発生しました'});
            setMsg(LoadingMsg);
            LoadingMsg=[];
            setVisible(false);

            console.log(error);
        });
    },[]);
    //ファイルアップロード
    function doSubmit(){
        
		setVisible(true);
		LoadingMsg.push({type:'info',title:'確認中',content:'ファイルを確認中',delay:5000});
		setMsg(LoadingMsg);

        //ファイルアップロード
        let data=document.getElementById('report_submit').getElementsByClassName('js-file');
        if(data.length>1){
            let fd=new FormData();

            LoadingMsg.push({type:'info',title:'アップロード中',content:'ファイルをアップロード中',delay:5000});
            setMsg(LoadingMsg);
    
            for(let i=1;i<data.length;++i){
                if(data[i].value!=""){
                    fd.append('file['+i+']',data[i].files[0]);
                    fd.append('FileName['+i+']',data[i].dataset.name);
                }
            }
            Upload(fd).then(data=>{
                if(data['result']){
                    LoadingMsg.push({type:'success',title:'アップロード完了',content:'ファイルをアップロードしました',delay:5000});
                    setMsg(LoadingMsg);
            
                    setAlert({'type':'danger','style':false,'msgs':[]})
                    submitReport(data['payloads']['FileIds']);
                }else{
                    LoadingMsg.push({type:'danger',title:'アップロード失敗',content:'ファイルをアップロードできませんでした',delay:5000});
                    setMsg(LoadingMsg);
                    LoadingMsg=[];
                    setVisible(false);
                    window.scroll({top:0});

                    setAlert({'type':'danger','style':true,'msgs':[],'description':data['error']['description']})
                }
            })
        }else{
            submitReport([]);
        }
    }
    //データ送信
    function submitReport(FileIds){
        LoadingMsg.push({type:'info',title:'確認中',content:'入力内容を確認しています',delay:5000});
        setMsg(LoadingMsg);
        
        let send={
            'url':'v1/student/course/'+student+'/report/'+id,
            'method':'PUT',
            'body':{}
        }

        LoadingMsg.push({type:'info',title:'送信中',content:'課題を送信しています',delay:5000});
        setMsg(LoadingMsg);

        //課題提出
        send['body']={
            'comment':document.getElementById('report_comment').value,
            'files':FileIds
        }
        Fetch(send).then(data=>{
            if(data['result']){
                LoadingMsg.push({type:'success',title:'提出完了',content:'課題を提出しました',delay:5000});
                setMsg(LoadingMsg);
                setVisible(false);
                LoadingMsg=[];
                window.scroll({top:0});

                setAlert({'type':'danger','style':false,'msgs':[]})
                document.getElementById('report_submit').textContent="課題を提出しました。";
                setLoad([false,{display:'none'},'']);
            }else{
                LoadingMsg.push({type:'danger',title:'エラー',content:'課題を提出できませんでした',delay:5000});
                setMsg(LoadingMsg);
                LoadingMsg=[];
                setVisible(false);
                window.scroll({top:0});

                setAlert({'type':'danger','style':true,'msgs':[],'description':data['error']['description']})
            }
        }).catch((error)=>{
            LoadingMsg.push({type:'warning',title:'エラー',content:'通信エラーが発生しました'});
            setMsg(LoadingMsg);
            LoadingMsg=[];
            setVisible(false);

            console.log(error);
        });
    }
    //提出履歴取得
    function getReportDetail(e){
        document.getElementById('show_file').src="";
        setSubmitedResult(submitedReport[e.target.dataset.id]);
    }
    function openFile(e){
        document.getElementById('show_file').src=e.target.dataset.link;
    }
    return(
        <div>
            <BreadCrumb item={BCrumb} />
            <AlertBox Item={Alert} />
            <Loading visible={Visible} msgs={Msg} />{Res?<>
            <div className="row">
                <div className="col-12">
                    <ReportOutline Item={Report} />
                </div>
            </div>{Report['editable']?
            <div className="row">
                <div className="col-12 my-2">
                    <h2>
                        提出
                    </h2>
                    <div className="alert alert-danger" role="alert" style={AlertStyle}>
                        <ul className="m-0">
                            {Validate[0] &&
                            Validate[1].map(
                                (msg)=><li>{msg}</li>
                                )
                            }
                        </ul>
                    </div>
                    <form id="report_submit">
                        {/*<div id="file_wrapper" className="list-group list-group-flush">
                            <div className="list-group-item">
                                <AddFile Add="true" Parent={document.getElementById('file_wrapper')} />
                            </div>
                        </div>*/}
                        <UploadFile Id="1" />
                        <textarea name="comment" id="report_comment" className="my-2 form-control" placeholder="コメント" defaultValue={""} />
                        <div className="row">
                            <div className="col-auto">
                                <button type="button" className="btn btn-primary" onClick={doSubmit} disabled={Load[0]}>提出</button>
                            </div>
                            <div className="col-auto" style={Load[1]}>
                                <div className="row">
                                <div className="col-auto">
                                    <div className="spinner-border"></div>
                                </div>
                                <div className="col-auto ps-2">{Load[2]}</div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>:
            <div className="row">
                <div className="col-12">
                    <div className="alert alert-info">
                        公開期間ではないもしくは受講が修了しているため，提出できません
                    </div>
                </div>
            </div>}
            <div className="row">
                <div className="col-12 mt-2">
                    <h2>
                    提出履歴
                    </h2>
                    <div className="list-group list-group-flush">
                        {submitedReport.map((report,index)=>
                    <a href="#" className="list-group-item list-group-item-action" data-bs-toggle="modal" data-bs-target="#ReportResultModal" data-id={index} key={index} onClick={getReportDetail}>
                        {ConvertToDisplayDate(report['SubmitDatetime'],true)}提出
                    </a>)}
                    </div>
                    <div className="modal fade w-100" id="ReportResultModal" tabIndex={-1} aria-labelledby="ReportResultModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-xl modal-dialog-scrollable">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="ReportResultModalLabel">提出課題詳細</h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                                </div>
                                <div className="modal-body">
                                    <div className="row">
                                        <div className="col">
                                            <iframe id="show_file" className="iframe-file" />
                                        </div>
                                        <div className="col-sm-auto">
                                            <div>
                                            <h3>
                                                提出内容
                                            </h3>
                                            <h4>
                                                提出ファイル
                                            </h4>
                                            <div className="list-group list-group-flush">
                                                {SubmitedResult['answers']['file'].map(file=><a href="#" className="list-group-item list-group-item-action" data-link={file['link']} onClick={openFile}>{file['FileName']}</a>)}
                                            </div>
                                            <div>
                                                <h4>
                                                コメント
                                                </h4>
                                                {SubmitedResult['answers']['comment']}
                                            </div>
                                            </div>
                                            <div>
                                            <h3>
                                                採点内容
                                            </h3>
                                            <div>
                                                {SubmitedResult['score']}/{SubmitedResult['total']}点
                                            </div>
                                            <div>
                                                <h4>
                                                講評
                                                </h4>
                                                {SubmitedResult['comment']}
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div></>:''}
        </div>
    );
}