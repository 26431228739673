import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { ConvertToDatetime } from "./Keys";
import SendJson from '../component/SendJson';

let LoadingMsg=[];
export default function ExaminationForm(props){
    let Exam;
    let parent;

    if(props.Item!=undefined){
        Exam=props.Item;
        Exam['deadline']=ConvertToDatetime(Exam['deadline']);
        Exam['FromDate']=ConvertToDatetime(Exam['FromDate']);
        Exam['ToDate']=ConvertToDatetime(Exam['ToDate']);
    }else{
        Exam={
            "CourseName": "",
            "ExaminationTitle": "",
            "note": "",
            "deadline": "",
            "PublicDate": 0,
            "EntriedNumber": 0,
            "FromDate": "",
            "ToDate": "",
            "score": 0,
            "multi": true,
            "limit": "",
            "questions": []
        }
    }

    const [PublicDate,setPublicDate]=useState(Exam['PublicDate']);

    useEffect(()=>{
        parent=document.getElementById('question_area');
    },[])
    function addQuiz(){
        let parent=document.getElementById('question_area');
        let li=document.createElement('li');
        parent.appendChild(li);
        let cnt=parent.getElementsByTagName('li').length;
        ReactDOM.render(<AddQuestion Id={cnt} QuestionId={0} Method='ShortText' Question="" Score="" Answer="" Option={[]}/>,li);
    }
    function doEntry(){
        props.setVisible(true);
        LoadingMsg.push({type:'info',title:'確認中',content:'入力内容を確認しています',delay:5000});
        props.setMsg(LoadingMsg);

        var {error,body}=SendJson(document.getElementsByClassName('js-data'),true);
        
        let SendBody=body;
        let AllError=error;

        let data=document.getElementsByClassName('js-data-question-wrapper');
        let quizs=[];
        for(let i=0;i<data.length;++i){
            let questions=data[i].getElementsByClassName('js-data-question');
            var {error,body}=SendJson(questions,true);
            if(error.length){
                AllError=AllError.concat(error);
            }else{
                let items=body;
                items['QuestionId']=Number(questions[0].dataset.id)?Number(questions[0].dataset.id):quizs.length;
                switch(items['method']){
                    case 'checkbox':
                    case 'radio':
                        items['options']=[];
                        items['answer']=[];
                        let options=data[i].getElementsByClassName('js-data-question-option');
                        for(let n=0;n<options.length;++n){
                            items['options'].push({
                                'OptionId':options[n].dataset.id,
                                'option':options[n].value
                            })
                        }
                        let answers=data[i].getElementsByClassName('js-data-question-answer');
                        for(let n=0;n<answers.length;++n){
                            if(answers[n].checked){
                                items['answer'].push(answers[n].value)
                            }
                        }
                        break;
                    default:
                }
                quizs.push(items);
            }
        }
        SendBody['questions']=quizs;
        SendBody['deletes']=[];
        let dels=document.getElementsByClassName('js-data-question-delete');
        for(let i=0;i<dels.length;++i){
            SendBody['deletes'].push(dels[i].value);
        }

        if(AllError.length){
            props.setAlert({'type':'danger','style':true,'msgs':AllError});

			LoadingMsg.push({type:'danger',title:'入力不備',content:'入力内容を確認してください',delay:5000});
			props.setMsg(LoadingMsg);
            window.scroll({top:0});

        }else{
            props.Submit(SendBody);
        }
        props.setVisible(false);
    }
    function changePublicDate(){
        setPublicDate(!PublicDate);
    }
    return (
        <div className="container">
            <div className="row">
                <div className="col-12">
                <h2>
                    {Exam['ExaminationTitle']}
                </h2>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                <form>
                    <div className="my-2 row form-group">
                    <label htmlFor="ExaminationTitle" className="col-sm-2 col-form-label">
                        テスト名
                    </label>
                    <div className="col-sm-10">
                        <input type="text" name="ExaminationTitle" id="ExaminationTitle" className="form-control js-data" placeholder="テスト名" maxLength={250} defaultValue={Exam['ExaminationTitle']} required />
                    </div>
                    </div>
                    <div className="my-2 row form-group">
                    <label htmlFor="note" className="col-sm-2 col-form-label">
                        備考
                    </label>
                    <div className="col-sm-10">
                        <textarea name="content" id="note" className="form-control js-data" placeholder="備考" defaultValue={Exam['note']} />
                    </div>
                    </div>
                    <div className="my-2 row form-group">
                    <label htmlFor="dedline" className="col-sm-2 col-form-label">
                        提出期限
                    </label>
                    <div className="col-sm-10">
                        <input type="datetime-local" name="deadline" id="deadline" className="form-control js-data" defaultValue={Exam['deadline']} placeholder="提出期限" required />
                    </div>
                    </div>
                    <div className="my-2 row form-group">
                    <label htmlFor="PublicDate" className="col-sm-2 col-form-label">
                        公開設定
                    </label>
                    <div className="col-sm-10 row">
                        <div className="col-auto">
                        <select name="PublicDate" className="form-select js-data" defaultValue={Exam['PublicDate']} data-type="numeric" onChange={changePublicDate} required>
                            <option value={0}>絶対日時</option>
                            <option value={1}>相対日時</option>
                        </select>
                        </div>
                        <div className="col-auto" style={{display:PublicDate?'none':'flex'}}>
                            <div className="input-group">
                                <input type="datetime-local" name="FromDate" className="form-control js-data" defaultValue={Exam['FromDate']} placeholder="公開開始日" />
                                <span className="input-group-text">から</span>
                                <input type="datetime-local" name="ToDate" className="form-control js-data" defaultValue={Exam['ToDate']} placeholder="公開終了日" />
                                <span className="input-group-text">まで</span>
                            </div>
                        </div>
                        <div className="col-auto" style={{display:PublicDate?'flex':'none'}}>
                            <div className="input-group">
                                <span className="input-group-text">受講登録から</span>
                                <input type="number" name="EntriedNumber" className="form-control js-data" min={0} defaultValue={Exam['EntriedNumber']} placeholder="登録経過日数" />
                                <span className="input-group-text">日経過後</span>
                            </div>
                        </div>
                    </div>
                    </div>
                    <div className="my-2 row form-group">
                    <label htmlFor="multi" className="col-sm-2 form-group">
                        複数回受験
                    </label>
                    <div className="col-sm-10">
                        <select name="multi" id="multi" className="form-select js-data" defaultValue={Exam['multi']} data-type="boolean" required>
                        <option value={true}>許可する</option>
                        <option value={false}>許可しない</option>
                        </select>
                    </div>
                    </div>
                    <div className="my-2 row form-group">
                    <label htmlFor="limit" className="col-sm-2 form-group">
                        制限時間
                    </label>
                    <div className="col-sm-10">
                        <div className="input-group">
                        <input type="number" name="limit" id="limit" className="form-control js-data" placeholder="制限時間" min={0} defaultValue={Exam['limit']} required />
                        <span className="input-group-text">分</span>
                        </div>
                    </div>
                    </div>
                    <div className="my-2 row form-group">
                    <label className="col-sm-2 form-group">
                        テスト問題
                    </label>
                    <div className="col-sm-10">
                        <button type="button" className="btn btn-secondary" onClick={addQuiz}>問題追加</button>
                        <ol id="question_area">{Exam['questions'].map((quiz,index)=>
                        <li><AddQuestion Id={index} QuestionId={index} Method={quiz['method']} Question={quiz['question']} Score={quiz['score']} Answer={quiz['answer']} Option={quiz['options'] ? quiz['options']:[]} /></li>
                        )}</ol>
                        <button type="button" className="btn btn-secondary" onClick={addQuiz}>問題追加</button>
                    </div>
                    </div>
                    <div className="mt-2 row form-group">
                        <div className="col-auto">
                            <button type="button" className="btn btn-primary" onClick={doEntry}>登録</button>{props.Delete &&
                            <button type="button" className="btn btn-danger ms-2" onClick={props.Delete}>削除</button>}
                        </div>
                    </div>
                </form>
                </div>
            </div>
        </div>
        );
}
function AddQuestion(props){
    const [OptionStyle,setOptionStyle]=useState({display:'none'});
    const [Type,setType]=useState(props.Method);
    useEffect(()=>{
        changeType(props.Method);
    },[])
    function changeMethod(e){
        changeType(e.target.value);
    }
    function changeType(props){
                setType(props);
        switch(props){
            case 'radio':
                setOptionStyle({display:'block'});
                changeOptType('radio');
                break;
            case 'checkbox':
                setOptionStyle({display:'block'});
                changeOptType('checkbox');
                break;
            default:
                setOptionStyle({display:'none'});
        }
    }
    function changeOptType(type){
        let parent=document.getElementById("options_area_"+props.Id);
        let inputs=parent.getElementsByClassName('js-data-question-answer');
        for(let i=0;i<inputs.length;++i){
            inputs[i].type=type;
        }
    }
    function addOption(){
        let parent=document.getElementById("options_area_"+props.Id);
        let div=document.createElement('div');
        div.setAttribute('class','list-group-item');
        parent.appendChild(div);
        let index=parent.getElementsByClassName('js-data-question-answer').length;
        ReactDOM.render(<AddOption type={Type} name={"opt_"+props.Index} text="" answer={[]} Index={index} Id={0} />,div);
    }
    function deleteQuiz(e){
        //e.target.parentNode.getElementsByClassName('js-data-question-delete')[0].value=e.target.dataset.id;
        e.target.parentNode.parentNode.parentNode.parentNode.style.display="none";
        e.target.parentNode.parentNode.parentNode.parentNode.innerHTML='<input type="hidden" className="js-data-question-delete" value="'+e.target.dataset.id+'"/>';
    }
    return(
        <div className="js-data-question-wrapper" data-id={props.Id} key={props.Id}>
            <div className="row">
                <div className="col">
                    <div className="my-2 input-group">
                        <span className="input-group-text">問題文</span>
                        <textarea name="question" className="form-control js-data-question" placeholder="問題文" data-id={props['QuestionId']} defaultValue={props.Question} required />
                    </div>
                </div>
                <div className="col-auto">
                    <button type="button" className="btn btn-danger mt-2 mb-auto" data-id={props['QuestionId']} onClick={deleteQuiz}>削除</button>
                </div>
            </div>
            <div className="row">
                <div className="col-auto">
                    <div className="my-2 input-group">
                    <span className="input-group-text">得点</span>
                    <input type="number" name="score" className="form-control js-data-question" placeholder="得点" defaultValue={props.Score} required />
                    <span className="input-group-text">点</span>
                    </div>
                </div>
                <div className="col-auto">
                    <div className="my-2 input-group">
                    <span className="input-group-text">回答方式</span>
                    <select name="method" className="form-select js-data-question" onChange={changeMethod} defaultValue={props.Method} required>
                        <option value={"ShortText"}>短文回答</option>
                        <option value={"LongText"}>長文回答</option>
                        <option value={"radio"}>単一選択</option>
                        <option value={"checkbox"}>複数選択</option>
                    </select>
                    </div>
                </div>
            </div>
            <AddAnswer Answer={props.Answer} Type={Type} />
            <div style={OptionStyle}>
                <h3>選択肢</h3>
                <button type="button" className="btn btn-secondary" onClick={addOption}>選択肢追加</button>
                <div id={"options_area_"+props.Id} className="my-2 list-group list-group-flush">{props.Option.map((option,index)=>
                    <div className="list-group-item">
                        <AddOption type={Type} name={"opt_"+props.Id} text={option['option']} answer={props.Answer} Index={index} Id={option['OptionId']} />
                    </div>)}
                </div>
            </div>
        </div>
    );
}
function AddAnswer(props){
    switch(props.Type){
        case 'ShortText':
            return(
                <div className="my-2 input-group">
                    <span className="input-group-text">回答</span>
                    <input type="text" name="answer" className="form-control js-data-question" placeholder="回答" defaultValue={props.Answer} />
                </div>
            );
            break;
        case 'LongText':
            return(
                <div className="my-2 input-group">
                    <span className="input-group-text">回答</span>
                    <textarea name="answer" className="form-control js-data-question" placeholder="回答" defaultValue={props.Answer} />
                </div>
            );
            break;
        default:
            return null;
    }
}
function AddOption(props){
    const [Check,setCheck]=useState(false);
    useEffect(()=>{
        if(props.answer.indexOf(props.Id)>-1){
            setCheck(true);
        }else{
            setCheck(false);
        }
    },[])
    function clickOpt(e){
        if(e.target.checked){
            setCheck(true);
        }else{
            setCheck(false);
        }
    }
    return(
        <div className="input-group">
            <div className="input-group-text">
                <input type={props.type} name={props.name} className="form-check js-data-question-answer" defaultValue={props.Id} checked={Check} onChange={clickOpt} />
            </div>
            <input type="text" name="option" className="form-control js-data-question-option" placeholder="選択肢" maxLength={100} data-id={props.Id}  defaultValue={props.text} required />
        </div>
    );
}