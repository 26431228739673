import { useState } from 'react';
import {Link} from 'react-router-dom';
import { Store } from './common/store';
export default function Home(){
    let tmpStore=Store.getState();
    const [store,setStore]=useState(tmpStore);
    if(!tmpStore['UserId']){
        let checkStore=setInterval(updateStore,10);
        function updateStore(){
            let tmpStore=Store.getState();
            setStore(tmpStore);
            if(tmpStore['UserId']){
                clearInterval(checkStore);
            }
        }
    }
    return(
        <section className="mt-4 pt-1">
            <h2 className="display-1 text-center">
                Welcome to <span className="text-basic">CanTeach</span>!
            </h2>
            <div className="my-3 text-center">
                <p className="fs-3">
                いつでもどこでも自由な学びを
                </p>
                <nav className="container mt-5">
                    <div className="row justify-content-center">{(store['UserType']==0 || store['UserType']==1) ?
                        <Link to="/student" className="col-sm-3 mb-4 text-decoration-none text-dark link-scale" target="_blank">
                            <div className="border border-2 shadow-sm m-2 p-1 text-basic">
                                <i className="fas fa-school d-block my-4 home__icon"></i>
                                <span className="fs-4">学ぶ</span>
                            </div>
                        </Link>:''}{(store['UserType']==0 || store['UserType']==2) ? 
                        <Link to="/teacher" className="col-sm-3 mb-4 text-decoration-none text-dark link-scale" target="_blank">
                            <div className="border border-2 shadow-sm m-2 p-1 text-sub">
                                <i className="fas fa-chalkboard-teacher d-block my-4 home__icon"></i>
                                <span className="fs-4">教える</span>
                            </div>
                        </Link>:''}{(store['serma'] && (store['UserType']==0 || store['UserType']==2)) ? 
                        <Link to="/serma" className="col-sm-3 mb-4 text-decoration-none text-dark link-scale" target="_blank">
                            <div className="border border-2 shadow-sm m-2 p-1 text-hilight">
                                <i className="far fa-calendar-alt d-block my-4 home__icon"></i>
                                <span className="fs-4">SerMa</span>
                            </div>
                        </Link>:''}{(store['web'] && (store['UserType']==0 || store['UserType']==2)) ? 
                        <Link to="/lp" className="col-sm-3 mb-4 text-decoration-none text-dark link-scale" target="_blank">
                            <div className="border border-2 shadow-sm m-2 p-1 text-sub">
                                <i className="fab fa-internet-explorer d-block my-4 home__icon"></i>
                                <span className="fs-4">LP</span>
                            </div>
    </Link>:''}
                        <Link to="/account" className="col-sm-3 mb-4 text-decoration-none text-dark link-scale" target="_blank">
                            <div className="border border-2 shadow-sm m-2 p-1 text-gray">
                                <i className="fas fa-user-cog d-block my-4 home__icon"></i>
                                <span className="fs-4">アカウント</span>
                            </div>
                        </Link>{store['UserRight']!=0 ?
                        <Link to="/admin" className="col-sm-3 mb-4 text-decoration-none text-dark link-scale" target="_blank">
                            <div className="border border-2 shadow-sm m-2 p-1 text-gray">
                                <i className="fas fa-cog d-block my-4 home__icon"></i>
                                <span className="fs-4">管理者機能</span>
                            </div>
                        </Link>:''}
                    </div>
                </nav>
            </div>
        </section>);
}
/*
                                <img src="./../assets/img/account.jpeg" alt="アカウント" className="d-block w-75 m-3" />
                                <img src="./../assets/img/setting.jpeg" alt="管理者機能" className="d-block w-75 m-3" />
                                <img src="./../assets/img/calendar.jpeg" alt="SerMa" className="d-block w-75 m-3" />
                                <img src="./../assets/img/teacher.jpeg" alt="教える" className="d-block w-75 m-3" />
                                <img src="./../assets/img/student.jpeg" alt="学ぶ" className="d-block w-75 m-3" />

*/