


export default function UploadedFile(props){
    function doDelete(e){
        e.target.parentNode.getElementsByClassName('js-data-file-delete')[0].value=e.target.dataset.id;
        e.target.parentNode.parentNode.parentNode.style.display="none";
    }
    function moveTo(e){
        window.open(e.target.dataset.link,'_blank');
    }
    return(
        <div>
            <h5>
                アップロード済み
            </h5>
            <div className="ms-3">
                <div className="row">{props.Item.map((item,index)=>
                    <div className="col-auto border">
                        <div className="row justify-content-between px-1 py-2" key={index}>
                            <button type="button" className="col btn btn-light" data-link={item['link']} onClick={moveTo}>{item['FileName']}</button>
                            <div className="col-auto">
                                <input type="hidden" className="js-data-file-delete" />
                                <button type="button" className="btn btn-secondary" data-id={item['FileId']} onClick={doDelete}>削除</button>
                            </div>
                        </div>
                    </div>)}
                </div>
            </div>
        </div>
    )
}