import { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { useParams } from "react-router-dom";
import Fetch from "../common/fetch";
import AlertBox from "../component/AlertBox";
import BreadCrumb from "../component/breadcrumb";
import Examination from "../component/Examination";
import ExaminationOutline from "../component/ExaminationOutline";

export default function Exam(){
    const {student,id}=useParams();
    const [Alert,setAlert]=useState({'type':'danger','style':false,'msgs':[]});
    const [Res,setRes]=useState(false);
    const [BCrumb,setBCrumb]=useState({
        'CurrentText':'テスト',
        'items':[
            {
                'index':1,
                'href':'/student',
                'text':'ホーム'
            }
        ]
    });
    const [Exam,setExam]=useState();
    useEffect(()=>{
        let send={
            'url':'v1/student/course/'+student+'/examination/'+id,
            'method':'GET'
        }
        Fetch(send).then(data=>{
            if(data['result']){
                setExam(data['payloads']);
                setAlert({'type':'danger','style':false,'msgs':[]});
                setBCrumb({
                    'CurrentText':data['payloads']['ExaminationTitle'],
                    'items':[
                        {
                            'index':1,
                            'href':'/student',
                            'text':'ホーム'
                        },
                        {
                            'index':2,
                            'href':'/student/course/'+student,
                            'text':data['payloads']['CourseName']
                        }
                    ]
                });
                setRes(true);
            }else{
                setAlert({'type':'danger','style':true,'msgs':[],'description':data['error']['description']});
            }
        }).catch(error=>console.log(error));
    },[])
    function doStart(e){
        e.target.parentNode.remove();
        ReactDOM.render(<Examination Quiz={Exam['questions']} Limit={Exam['limit']} Submit={doSubmit} />,document.getElementById('exam_area'));
    }
    function doSubmit(){
        let body=[];
        let parent=document.getElementById('examination');
        let data=parent.getElementsByClassName('js-data-txt')
        for(let i=0;i<data.length;++i){
            body.push({"QuestionId":data[i].dataset.id,'answer':data[i].value});
        }
        data=parent.getElementsByClassName('js-data-opt');
        for(let i=0;i<data.length;++i){
            let opts=data[i].getElementsByClassName('js-opt');
            let items=[];
            for(let n=0;n<opts.length;++n){
                if(opts[n].checked){
                    items.push(opts[n].value);
                }
            }
            body.push({"QuestionId":data[i].dataset.id,'answer':items});
        }
        let send={
            'url':'v1/student/course/'+student+'/examination/'+id,
            'method':'PUT',
            'body':{
                'answers':body
            }
        }
        Fetch(send).then(data=>{
            if(data['result']){
                setAlert({'type':'danger','style':false,'msgs':[]});
                document.getElementById('exam_area').textContent="提出しました。";
            }else{
                setAlert({'type':'danger','style':true,'msgs':[],'description':data['error']['description']});
            }
        }).catch(error=>console.log(error));
    }
    return(
        <div>
            <BreadCrumb item={BCrumb} />
            <AlertBox Item={Alert} />{Res?<>
            <div className="row">
                <div className="col-12">
                    <ExaminationOutline Item={Exam} />{Exam['editable'] && Exam['questions']?
                    <div>
                        <p>
                            受験を開始するには受験開始ボタンを押してください。
                        </p>
                        <button type="button" className="btn btn-primary" onClick={doStart}>受験開始</button>
                    </div>:
                    <div className="alert alert-info">{!Exam['questions'] ?
                        '既に受験しており，複数回受験は許可されていません。':
                        '公開期間ではないもしくは受講が修了しているため，受験できません。'}
                    </div>}
                </div>
            </div>
            <div className="row">
                <div id="exam_area" className="col-12"></div>
            </div></>:''}
        </div>
    );
}