import { useEffect, useState } from "react";
import ReactDOM from 'react-dom';
import { useParams } from "react-router";
import BreadCrumb from "../component/breadcrumb";
import Fetch from "../common/fetch";
import AlertBox from "../component/AlertBox";
import CourseForm from "../component/CourseForm";
import Upload from "../common/upload";
import SendJson from "../component/SendJson";
import { updateVariation } from "../../App";
import Loading from "../component/Loading";

let LoadingMsg=[];
export default function EditCourse(){
    const {id}=useParams();
    const [BCrumb,setBCrumb]=useState({'CurrentText':'シラバス編集','items':[{'index':1,'href':'/teacher','text':'ホーム'}]});
    const [Alert,setAlert]=useState({'type':'danger','style':false,'msgs':[]});

	const [Visible,setVisible]=useState(false);
	const [Msg,setMsg]=useState(LoadingMsg);

    useEffect(()=>{
        getCourse();
    },[])
    function getCourse(visible=true){
        setVisible(true);
        let send={
            'url':'/v1/syllabus/'+id,
            'method':'GET'
        }
        Fetch(send).then(data=>{
            if(data['result']){
                let item=data['payloads'];
                if(data['payloads']['keywords'].length==0){item['keywords']=[""];}
                if(data['payloads']['needs'].length==0){item['needs']=[""];}
                if(data['payloads']['plans'].length==0){item['plans']=[""];}
                ReactDOM.render(<CourseForm Item={item} Submit={doEntry} Delete={doDelete} />,document.getElementById('content_area'));
                setBCrumb({
                    CurrentText: data['payloads']['CourseName']+'編集',
                    items: [
                        {
                            index: 1,
                            href: "/teacher",
                            text: "ホーム"
                        },
                        {
                            index:2,
                            href:'/teacher/course/'+id,
                            text:data['payloads']['CourseName']
                        }
                    ],
                });
            }else{
                setAlert({'type':'danger','style':true,'description':data['error']['description']});
            }
            LoadingMsg=[];
            if(visible){
                setVisible(false);
            }
        }).catch((error)=>{
            LoadingMsg.push({type:'warning',title:'エラー',content:'通信エラーが発生しました'});
            setMsg(LoadingMsg);
            LoadingMsg=[];
            if(visible){
                setVisible(false);
            }

            console.log(error);
        });
    }
    function doEntry(){
        
		setVisible(true);
		LoadingMsg.push({type:'info',title:'確認中',content:'ファイルを確認中',delay:5000});
		setMsg(LoadingMsg);

        let file=document.getElementsByClassName('js-file')[0];
        if(file.value!=""){
            let fd=new FormData();
            fd.append('file[]',file.files[0]);
            fd.append('FileName[]',file.dataset.name);

            LoadingMsg.push({type:'info',title:'アップロード中',content:'ファイルをアップロード中',delay:5000});
            setMsg(LoadingMsg);
    
            Upload(fd).then(data=>{
                if(data['result']){
                    LoadingMsg.push({type:'success',title:'アップロード完了',content:'ファイルをアップロードしました',delay:5000});
                    setMsg(LoadingMsg);
            
                    doSumit(data['payloads']['FileIds'][0]);
                }else{
                    LoadingMsg.push({type:'danger',title:'アップロード失敗',content:'ファイルをアップロードできませんでした',delay:5000});
                    setMsg(LoadingMsg);
                    LoadingMsg=[];
                    setVisible(false);
                    window.scroll({top:0});

                    setAlert({'type':'danger','style':true,'description':data['error']['description']});
                }
            }).catch((error)=>{
                LoadingMsg.push({type:'warning',title:'エラー',content:'通信エラーが発生しました'});
                setMsg(LoadingMsg);
                LoadingMsg=[];
                setVisible(false);
    
                console.log(error);
            });
        }else{
            doSumit(document.getElementById('TeacherCourseContract').dataset.id);
        }
    }
    function doSumit(FileId){
        LoadingMsg.push({type:'info',title:'確認中',content:'入力内容を確認しています',delay:5000});
        setMsg(LoadingMsg);
        
        let parent=document.getElementById('CourseForm');
        let {error,body}=SendJson(parent.getElementsByClassName('js-data'),true);
        
        if(error.length){
            setAlert({'type':'danger','style':true,'msgs':error});

			LoadingMsg.push({type:'danger',title:'入力不備',content:'入力内容を確認してください',delay:5000});
			setMsg(LoadingMsg);
			setVisible(false);
            window.scroll({top:0});

            return;

        }else{
            let send={
                'url':'v1/course/'+id,
                'method':'PUT',
                'body':body
            }
            let term=parent.getElementsByClassName('js-data-term');
            send['body']['term']=[term[0].value,term[1].value];
            send['body']['keywords']=[];
            send['body']['plans']=[];
            send['body']['needs']=[];
            let data=parent.getElementsByClassName('js-data-keywords');
            for(let i=0;i<data.length;++i){
                if(data[i].value!=""){
                    send['body']['keywords'].push(data[i].value);
                }
            }
            data=parent.getElementsByClassName('js-data-plans');
            for(let i=0;i<data.length;++i){
                if(data[i].value!=""){
                    send['body']['plans'].push(data[i].value);
                }
            }
            data=parent.getElementsByClassName('js-data-needs');
            for(let i=0;i<data.length;++i){
                if(data[i].value!=""){
                    send['body']['needs'].push(data[i].value);
                }
            }
            send['body']['contract']=FileId;
            send['body']['teacher']=SendJson(parent.getElementsByClassName('js-data-teacher'));

            LoadingMsg.push({type:'info',title:'送信中',content:'入力内容を送信しています',delay:5000});
            setMsg(LoadingMsg);

            Fetch(send).then(data=>{
                if(data['result']){
                    getCourse(false);
                    LoadingMsg.push({type:'success',title:'送信完了',content:'入力内容を送信しました',delay:5000});
                    setMsg(LoadingMsg);

                    setAlert({'type':'success','style':true,'msgs':["コースを編集しました。"]});
                    updateVariation('TeacherCourse');
                }else{
                    LoadingMsg.push({type:'danger',title:'エラー',content:'入力内容が保存できませんでした',delay:5000});
                    setMsg(LoadingMsg);

                    setAlert({'type':'danger','style':true,'description':data['error']['description']});
                }
                LoadingMsg=[];
                setVisible(false);
                window.scroll({top:0});
            }).catch((error)=>{
                LoadingMsg.push({type:'warning',title:'エラー',content:'通信エラーが発生しました'});
                setMsg(LoadingMsg);
                LoadingMsg=[];
                setVisible(false);

                console.log(error);
            });
        }
    }
    function doDelete(){
        setVisible(true);
        LoadingMsg.push({type:'info',title:'送信中',content:'情報を送信しています',delay:5000});
        setMsg(LoadingMsg);

        let send={
            'url':'v1/course/'+id,
            'method':'DELETE'
        }
        Fetch(send).then(data=>{
            if(data['result']){

                LoadingMsg.push({type:'success',title:'送信完了',content:'情報を送信しました',delay:5000});
                setMsg(LoadingMsg);
                LoadingMsg=[];
                setVisible(false);

                setAlert({'type':'success','style':true,'msgs':["コースを削除しました。"]});
                updateVariation('TeacherCourse');
                document.getElementById('content_area').textContent="";
            }else{
                LoadingMsg.push({type:'danger',title:'エラー',content:'削除できませんでした',delay:5000});
                setMsg(LoadingMsg);
                LoadingMsg=[];
                setVisible(false);
                window.scroll({top:0});

                setAlert({'type':'danger','style':true,'description':data['error']['description']});
            }
        })
        .catch((error)=>{
            LoadingMsg.push({type:'warning',title:'エラー',content:'通信エラーが発生しました'});
            setMsg(LoadingMsg);
            setVisible(false);

            console.log(error);
        });
    }
    return(
        <div>
            <BreadCrumb item={BCrumb} />
            <AlertBox Item={Alert} />
            <Loading visible={Visible} msgs={Msg} />
            <div id="content_area"></div>
        </div>
    )
}