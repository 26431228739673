import { useEffect, useState } from "react";
import { useParams } from "react-router";
import BreadCrumb from "../component/breadcrumb";
import Fetch from "../common/fetch";
import AlertBox from "../component/AlertBox";

export default function Cancellation(){
    const {course}=useParams();
    const [BCrumb,setBCrumb]=useState({'CurrentText':'解約申請','items':[{'index':1,'href':'/teacher','text':'ホーム'}]});
    const [Alert,setAlert]=useState({'type':'danger','style':false,'msgs':[]});
    const [Data,setData]=useState([]);
    useEffect(()=>{
        let send={
            'url':'v1/course/'+course+'/cancellation',
            'method':'GET'
        }
        Fetch(send).then(data=>{
            if(data['result']){
                setAlert({'type':'danger','style':false,'msgs':[]});
                setData(data['payloads']['cancellations']);
                setBCrumb({
                    CurrentText: '解約申請',
                    items: [
                        {
                            index: 1,
                            href: "/teacher",
                            text: "ホーム"
                        },
                        {
                            index:2,
                            href:'/teacher/course/'+course,
                            text:data['payloads']['CourseName']
                        }
                    ],
                });
            }else{
                setAlert({'type':'danger','style':true,'description':data['error']['description']});
            }
        }).catch(error=>console.log(error));
    },[])
    function doCancel(e){
        let send={
            'url':'v1/course/'+course+'/cancellation/'+e.target.dataset.id,
            'method':'PUT',
            'body':[]
        }
        Fetch(send).then(data=>{
            if(data['result']){
                setAlert({'type':'success','style':true,'msgs':[e.target.dataset.name+'さんの解約申請を承諾しました。']});
                e.target.parentNode.textContent="解約済み";
            }else{
                setAlert({'type':'danger','style':true,'description':data['error']['description']});
            }
        }).catch(error=>console.log(error));
    }
    return(
        <div>
            <BreadCrumb item={BCrumb} />
            <AlertBox Item={Alert} />
            <div className="row">
                <div className="col-12">
                <h2>
                    解約申請者
                </h2>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                <form>
                    <table className="table table-striped table-hover">
                        <thead>
                            <tr>
                                <th>
                                    申請者名
                                </th>
                                <th>
                                    状態
                                </th>
                            </tr>
                        </thead>
                        <tbody>{Data.map((user,index)=>
                            <tr>
                                <td>
                                    {user['UserName']}
                                </td>
                                <td>{user['condition'] ? <span>解約済み</span>:
                                    <button type="button" className="btn btn-danger" data-id={user['CancellationId']} data-name={user['UserName']} onClick={doCancel}>解約</button>}
                                </td>
                            </tr>)}
                        </tbody>
                    </table>
                </form>
                </div>
            </div>
        </div>

    )
}