import Escape from "./Escape";

/**
 *show Alert
 *
 * @export
 * @param {*} props.Item={'type':'danger','style':false,'msgs':[],'description':data['error']['description']}
 * @return {*} 
 */
export default function AlertBox(props){
    let Style;
    let msgs=[];
    if(props.Item.style){
        Style={display:'block'}
    }else{
        Style={display:'none'}
    }
    if(props.Item.description){
        let keys=Object.keys(props.Item.description);
        for(let i=0;i<keys.length;++i){
            for(let n=0;n<props.Item.description[keys[i]].length;++n){
                msgs.push(props.Item.description[keys[i]][n]);
            }
        }
        /*let errors=[];
        keys.forEach(function(e){
            let error=props.Item.description[e];
            for(let i=0;i<error.length;++i){
                errors.push(error[i]);
            }
        });
        msgs=errors;*/
    }else{
        msgs=props.Item.msgs;
    }
    return(
        <div className="row col-12">
            <div className={"alert alert-"+props.Item.type} role="alert" style={Style}>{Array.isArray(msgs)?
                <ul className="m-0">
                    {msgs.map((msg,index)=><>{msg && <li key={index}>{msg}</li>}</>)}
                </ul>:Escape(msgs)}
            </div>
        </div>
    );
}