import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Fetch from "../common/fetch";
import LineBtn from "../component/LineBtn";
import { UserProfile } from "./user";

export default function ProfileTeacher(){
    const {id}=useParams();
    const [Visible,setVisible]=useState(true);
    const [Profile,setProfile]=useState(false);
    useEffect(()=>{
        let send={
            url:'v1/getProfile/T'+id,
            method:'GET'
        }
        Fetch(send).then(data=>{
            if(data['result']){
                setProfile(data['payloads']);
            }
            setVisible(false);
        }).catch(error=>{
            setVisible(false);
            console.log(error);
        })
    },[])
    return (
        <div>{(Profile && Profile['profiles'] && Profile['courses']) ?
            <>
                <p>
                    {Profile['courses']['CourseName']}講師
                </p>
                <h3 className="mt-0 mb-3">
                    {Profile['profiles']['UserName']}
                </h3>
                <h4 className="my-2">
                    {Profile['courses']['CourseName']}に関するお問い合わせ
                </h4>
                <Link to={"/course/"+Profile['courses']['CourseId']+"/syllabus"}>
                    {Profile['courses']['CourseName']}ページ
                </Link>
                <div className="alert alert-info">{Profile['courses']['link']!="" && 
                    <div className="row">
                        <div className="col-2">
                            ホームページ
                        </div>
                        <div className="col-auto">
                            <a href={Profile['courses']['link']} target="_blank">
                                {Profile['courses']['link']}
                            </a>
                        </div>
                    </div>}{Profile['courses']['email']!="" && 
                    <div className="row">
                        <div className="col-2">
                            email
                        </div>
                        <div className="col-auto">
                            <a href={"email:"+Profile['courses']['email']} target="_blank">
                                {Profile['courses']['email']}
                            </a>
                        </div>
                    </div>}{Profile['courses']['tel']!="" && 
                    <div className="row">
                        <div className="col-2">
                            Tel
                        </div>
                        <div className="col-auto">
                            <a href={"tel:"+Profile['courses']['tel']} target="_blank">
                                {Profile['courses']['tel']}
                            </a>
                        </div>
                    </div>}{Profile['courses']['line']!="" && 
                    <div className="row">
                        <div className="col-2">
                            LINE
                        </div>
                        <div className="col-auto">
                            <LineBtn Link={Profile['courses']['line']} Text="ともだち追加" target="_blank" />
                        </div>
                    </div>}
                </div>
                <div>
                    <h4 className="my-2">
                        ユーザープロフィール
                    </h4>
                    <UserProfile Profile={Profile['profiles']} />
                </div>
            </>:""
        }</div>
    );
}