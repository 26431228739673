import { useEffect, useState } from "react";
import ReactDOM from 'react-dom';
import { useParams } from "react-router";
import BreadCrumb from "../component/breadcrumb";
import Fetch from "../common/fetch";
import AlertBox from "../component/AlertBox";
import TextForm from "../component/TextForm";
import Upload from "../common/upload";
import Loading from "../component/Loading";

let LoadingMsg=[];
export default function EntryText(){
    const {course,folder}=useParams();
    const [BCrumb,setBCrumb]=useState({'CurrentText':'新規教材','items':[{'index':1,'href':'/teacher','text':'ホーム'}]});
    const [Alert,setAlert]=useState({'type':'danger','style':false,'msgs':[]});

	const [Visible,setVisible]=useState(false);
	const [Msg,setMsg]=useState(LoadingMsg);

    useEffect(()=>{
        setVisible(true);
        let send={
            'url':'v1/course/'+course+'/folder/'+folder,
            'method':'GET'
        }
        Fetch(send).then(data=>{
            if(data['result']){
                setAlert({'type':'danger','style':false,'msgs':[]});
                ReactDOM.render(<TextForm Submit={doEntry} />,document.getElementById('content_area'));
                setBCrumb({
                    CurrentText: '新規教材',
                    items: [
                        {
                            index: 1,
                            href: "/teacher",
                            text: "ホーム"
                        },
                        {
                            index:2,
                            href:'/teacher/course/'+course,
                            text:data['payloads']['CourseName']
                        },
                        {
                            index:3,
                            href:'/teacher/course/'+course+'/text/'+folder,
                            text:data['payloads']['FolderName']
                        }
                    ],
                });
            }else{
                setAlert({'type':'danger','style':true,'description':data['error']['description']});
            }
            setVisible(false);
        }).catch((error)=>{
            LoadingMsg.push({type:'warning',title:'エラー',content:'通信エラーが発生しました'});
            setMsg(LoadingMsg);
            setVisible(false);

            console.log(error);
        });
    },[])
    function doEntry(body){
        
		setVisible(true);
		LoadingMsg.push({type:'info',title:'確認中',content:'ファイルを確認中',delay:5000});
		setMsg(LoadingMsg);

        let files=document.getElementsByClassName('js-file');
        if(files.length>1){
            let fd=new FormData();
            for(let i=0;i<files.length;++i){
                fd.append('file['+i+']',files[i].files[0]);
                fd.append('FileName['+i+']',files[i].dataset.name);
            }

            LoadingMsg.push({type:'info',title:'アップロード中',content:'ファイルをアップロード中',delay:5000});
            setMsg(LoadingMsg);
    
            Upload(fd).then(data=>{
                if(data['result']){
                    LoadingMsg.push({type:'success',title:'アップロード完了',content:'ファイルをアップロードしました',delay:5000});
                    setMsg(LoadingMsg);
                    LoadingMsg=[];
            
                    body['files']=data['payloads']['FileIds'];
                    doSubmit(body);
                }else{
                    LoadingMsg.push({type:'danger',title:'アップロード失敗',content:'ファイルをアップロードできませんでした',delay:5000});
                    setMsg(LoadingMsg);
                    LoadingMsg=[];
                    setVisible(false);
                    window.scroll({top:0});

                    setAlert({'type':'danger','style':true,'description':data['error']['description']});
                }
            }).catch((error)=>{
                LoadingMsg.push({type:'warning',title:'エラー',content:'通信エラーが発生しました'});
                setMsg(LoadingMsg);
                LoadingMsg=[];
                setVisible(false);
    
                console.log(error);
            });
        }else{
            doSubmit(body);
        }
    }
    function doSubmit(body){
        LoadingMsg.push({type:'info',title:'確認中',content:'入力内容を確認しています',delay:5000});
        setMsg(LoadingMsg);
        
        let send={
            'url':'v1/course/'+course+'/folder/'+folder+'/text',
            'method':'POST',
            'body':body
        }

        LoadingMsg.push({type:'info',title:'送信中',content:'入力内容を送信しています',delay:5000});
        setMsg(LoadingMsg);

        Fetch(send).then(data=>{
            if(data['result']){

                LoadingMsg.push({type:'success',title:'送信完了',content:'入力内容を送信しました',delay:5000});
                setMsg(LoadingMsg);
                setVisible(false);
                LoadingMsg=[];
                window.scroll({top:0});

                setAlert({'type':'success','style':true,'msgs':["教材を登録しました。"]});
                document.getElementById('content_area').textContent="";
            }else{
                LoadingMsg.push({type:'danger',title:'エラー',content:'入力内容が保存できませんでした',delay:5000});
                setMsg(LoadingMsg);
                LoadingMsg=[];
                setVisible(false);
                window.scroll({top:0});

                setAlert({'type':'danger','style':true,'description':data['error']['description']});
            }
        }).catch((error)=>{
            LoadingMsg.push({type:'warning',title:'エラー',content:'通信エラーが発生しました'});
            setMsg(LoadingMsg);
            LoadingMsg=[];
            setVisible(false);

            console.log(error);
        });
    }
    return(
        <div>
            <BreadCrumb item={BCrumb} />
            <AlertBox Item={Alert} />
            <Loading visible={Visible} msgs={Msg} />
            <div id="content_area"></div>
        </div>
    )
}