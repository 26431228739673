import { useEffect, useState } from "react";
import ReactDOM from 'react-dom';
import { useParams } from "react-router";
import BreadCrumb from "../component/breadcrumb";
import Fetch from "../common/fetch";
import CardList from "../component/CardList";
import Syllabus from "../component/Syllabus";
import { Link } from "react-router-dom";
import TextCarousel from "../component/TextCarousel";
import AlertBox from "../component/AlertBox";
import { Store } from "../common/store";
import Loading from "../component/Loading";

let LoadingMsg=[];
export default function Course(){
    const {id}=useParams();
    const [BCrumb,setBCrumb]=useState({'CurrentText':'コース','items':[{'index':1,'href':'/teacher','text':'ホーム'}]})
    const [Data,setData]=useState({"CourseId": "","CourseName": "","contacts": [],"texts": [],"reports": [],"examinations": [],"questions": [],"teachers":[],"links": {"AddTeacher": "","AddCourse": ""}});
    const [Contact,setContact]=useState({"title":'新着情報','items':[]});
    const [Text,setText]=useState([]);
    const [Report,setReport]=useState({"title":'課題','items':[]});
    const [Examination,setExamination]=useState({"title":'テスト','items':[]});
    const [Question,setQuestion]=useState({"title":'質問','items':[]});
    const [Step,setStep]=useState({"title":'ステップ配信','items':[]});
    const [Error,setError]=useState(true);
    const [Alert,setAlert]=useState({'type':'danger','style':false,'msgs':[]});
    const [TeacherAlert,setTeacherAlert]=useState({'type':'danger','style':false,'msgs':[]});
    let store=Store.getState();
    let UserId=store['UserId'];

	const [Visible,setVisible]=useState(false);
	const [Msg,setMsg]=useState(LoadingMsg);

    useEffect(()=>{
        setVisible(true);
        let send={
            'url':'v1/course/'+id,
            'method':'GET'
        }
        Fetch(send).then(data=>{
            if(data['result']){
                setAlert({'type':'danger','style':false,'msgs':[]});
                setData(data['payloads']);
                setBCrumb({'CurrentText':data['payloads']['CourseName'],'items':[{'index':1,'href':'/teacher','text':'ホーム'}]});
                let items=[];
                let vals=data['payloads']['contacts'];
                for(let i=0;i<vals.length;++i){
                    items.push({'index':i,'href':'/teacher/course/'+id+'/info/'+vals[i]['ContactId'],'text':vals[i]['ContactTitle']});
                }
                setContact({"title":'新着情報','items':items});
                items=[];
                vals=data['payloads']['texts'];
                for(let i=0;i<vals.length;++i){
                    items.push({'index':i,'icon':vals[i]['icon']['link'],'href':'/teacher/course/'+id+'/text/'+vals[i]['FolderId'],'text':vals[i]['FolderName']});
                }
                setText(items);
                //ReactDOM.render(<TextCarousel Item={items} />,document.getElementById('text_area'));
                items=[];
                vals=data['payloads']['reports'];
                for(let i=0;i<vals.length;++i){
                    items.push({'index':i,'href':'/teacher/course/'+id+'/report/'+vals[i]['ReportId'],'text':vals[i]['ReportTitle']});
                }
                setReport({"title":'課題','items':items});
                items=[];
                vals=data['payloads']['examinations'];
                for(let i=0;i<vals.length;++i){
                    items.push({'index':i,'href':'/teacher/course/'+id+'/examination/'+vals[i]['ExaminationId'],'text':vals[i]['ExaminationTitle']});
                }
                setExamination({"title":'テスト','items':items});
                items=[];
                vals=data['payloads']['steps'];
                for(let i=0;i<vals.length;++i){
                    items.push({'index':i,'href':'/teacher/course/'+id+'/step/'+vals[i]['StepId']+'/edit','text':vals[i]['StepTitle']});
                }
                setStep({"title":'ステップ配信','items':items});
                /*items=[];
                vals=data['payloads']['questions'];
                for(let i=0;i<vals.length;++i){
                    items.push({'index':i,'href':'/teacher/course/'+id+'/question/'+vals[i]['QuestionId'],'text':vals[i]['QuestionTitle']});
                }
                setQuestion({"title":'質問','items':items});*/
            }else{
                console.log(data);
                setError(false);
                setAlert({'type':'danger','style':true,'description':data['error']['description']});
            }
            setVisible(false);
        })
        .catch((error)=>{
            LoadingMsg.push({type:'warning',title:'エラー',content:'通信エラーが発生しました'});
            setMsg(LoadingMsg);
            setVisible(false);

            console.log(error);
        });
    },[])
    function deleteTeacher(e){
        LoadingMsg.push({type:'info',title:'送信中',content:'情報を送信しています',delay:5000});
        setMsg(LoadingMsg);
        setVisible(true);
        let send={
            'url':'v1/course/'+id+'/teacher/'+e.target.dataset.id,
            'method':'DELETE'
        }
        Fetch(send).then(data=>{
            if(data['result']){

                LoadingMsg.push({type:'success',title:'送信完了',content:'情報を送信しました',delay:5000});
                setMsg(LoadingMsg);
                setVisible(false);

                setTeacherAlert({'type':'success','style':true,'msgs':[e.target.dataset.name+"を削除しました。"]});
                e.target.parentNode.parentNode.parentNode.remove();
            }else{
                LoadingMsg.push({type:'danger',title:'エラー',content:'駆除できませんでした',delay:5000});
                setMsg(LoadingMsg);
                setVisible(false);
                window.scroll({top:0});

                setTeacherAlert({'type':'danger','style':true,'description':data['error']['description']});
            }
        })
        .catch((error)=>{
            LoadingMsg.push({type:'warning',title:'エラー',content:'通信エラーが発生しました'});
            setMsg(LoadingMsg);
            setVisible(false);

            console.log(error);
        });
    }
    return(
        <div>
            <BreadCrumb item={BCrumb} />
            <AlertBox Item={Alert} />
            <Loading visible={Visible} msgs={Msg} />
            {Error ?<>
            <div className="row">
                <div className="col-auto">
                <h2>
                    {Data['CourseName']}
                </h2>
                </div>
            </div>
            <div className="row">
                <div className="col-12 mb-3">
                    <div className="mt-1 text-end">
                        <Link to={"/teacher/course/"+id+"/folder"} className="btn btn-secondary">教材フォルダー管理</Link>
                    </div>
                    <div id="text_area" className="my-1 p-5">
                        <TextCarousel Item={Text} />
                    </div>
                </div>
            </div>{/*
            完成後の制作
            <div className="row">
                <div className="col-12">
                    <Card>
                        <Card.Header>
                            進捗状況
                        </Card.Header>
                        <Card.Body className="table-responsive">
                            <table className="table align-middle">
                                <thead>
                                    <tr>
                                        <th style={{width:'30%'}}>
                                            受講者名
                                        </th>
                                        <th style={{width:'70%'}}>
                                            進捗状況
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            USER NAME
                                        </td>
                                        <td>
                                            <Accordion flush>
                                                <Accordion.Item eventKey="0">
                                                    <Accordion.Header>
                                                        <ProgressBar now={60} label={"60%"} className="w-100" />
                                                    </Accordion.Header>
                                                    <Accordion.Body className="px-2 py-0">
                                                        <Accordion flush>
                                                            <Accordion.Item eventKey="0">
                                                                <Accordion.Header>
                                                                    <Container>
                                                                        <Row>
                                                                            <Col sm="auto">
                                                                                FOLDER NAME
                                                                            </Col>
                                                                            <Col>
                                                                                <ProgressBar now={45} label={"45%"} />
                                                                            </Col>
                                                                        </Row>
                                                                    </Container>
                                                                </Accordion.Header>
                                                                <Accordion.Body>
                                                                    <table className="table">
                                                                        <tbody>
                                                                            <tr>
                                                                                <th>TEXT NAME</th>
                                                                                <td>視聴完了</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th>TEXT NAME2</th>
                                                                                <td>既読</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th>TEXT NAME3</th>
                                                                                <td>未読</td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </Accordion.Body>
                                                            </Accordion.Item>
                                                        </Accordion>
                                                        <Accordion flush>
                                                            <Accordion.Item eventKey="0">
                                                                <Accordion.Header>
                                                                    <Container>
                                                                        <Row>
                                                                            <Col sm="auto">
                                                                                FOLDER NAME2
                                                                            </Col>
                                                                            <Col>
                                                                                <ProgressBar now={45} label={"45%"} />
                                                                            </Col>
                                                                        </Row>
                                                                    </Container>
                                                                </Accordion.Header>
                                                                <Accordion.Body>
                                                                    <table className="table">
                                                                        <tbody>
                                                                            <tr>
                                                                                <th>TEXT NAME</th>
                                                                                <td>視聴完了</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th>TEXT NAME2</th>
                                                                                <td>既読</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th>TEXT NAME3</th>
                                                                                <td>未読</td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </Accordion.Body>
                                                            </Accordion.Item>
                                                        </Accordion>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </Accordion>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </Card.Body>
                    </Card>
                </div>
            </div>*/}
            <div className="row">
                <div className="col-sm-6 col-md-6 my-2">
                    <CardList item={Contact} />
                    <Link to={"/teacher/course/"+id+'/info'}>新規連絡</Link>
                </div>{Data['reports']?
                <div className="col-sm-6 col-md-6 my-2">
                    <CardList item={Report} />
                    <Link to={"/teacher/course/"+id+'/report'}>新規課題</Link>
                </div>:""}{Data['examinations']?
                <div className="col-sm-6 col-md-6 my-2">
                    <CardList item={Examination} />
                    <Link to={"/teacher/course/"+id+'/examination'}>新規テスト</Link>
                </div>:""}
                <div className="col-sm-6 col-md-6 my-2">
                    <CardList item={Step} />
                    <Link to={"/teacher/course/"+id+'/step'}>新規ステップ</Link>
                </div>
                {/* <div className="col-sm-6 col-md-6 my-2">
                    <CardList item={Question} />
</div>*/}
                <div className="col-sm-6 col-md-6 my-2">
                <div className="card w-100">
                    <div className="card-header">
                    その他
                    </div>
                    <div className="card-body">
                    <div className="list-group list-group-flush">
                        <a href="#" className="btn list-grpup-item list-group-item-action border-bottom" data-bs-toggle="modal" data-bs-target="#syllabusModal">
                        シラバス
                        </a>
                        <Link to={"/teacher/course/"+id+"/edit"} className="list-group-item list-group-item-action">
                        シラバス編集
                        </Link>
                        <a href="#" className="btn list-grpup-item list-group-item-action border-bottom" data-bs-toggle="modal" data-bs-target="#teacherModal">
                        講師管理
                        </a>
                        <a href="#" className="btn list-grpup-item list-group-item-action border-bottom" data-bs-toggle="modal" data-bs-target="#courseModal">
                        コース受講登録URL
                        </a>
                        <Link to={"/teacher/course/"+id+"/student"} className="list-group-item list-group-item-action">
                        受講者情報
                        </Link>
                        <Link to={"/teacher/course/"+id+"/grade"} className="list-group-item list-group-item-action">
                        成績
                        </Link>
                        <Link to={"/teacher/course/"+id+"/cancellation/"} className="list-group-item list-group-item-action">
                        解約申請
                        </Link>
                    </div>
                    </div>
                    <div className="modal fade w-100" id="syllabusModal" tabIndex={-1} aria-labelledby="syllabusModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-xl modal-dialog-scrollable">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="syllabusModalLabel">シラバス</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                            </div>
                            <div className="modal-body">
                                <Syllabus Id={id} />
                            </div>
                        </div>
                    </div>
                    </div>
                    <div className="modal fade w-100" id="teacherModal" tabIndex={-1} aria-labelledby="teacherModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-xl modal-dialog-scrollable">
                            <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="teacherModalLabel">講師管理</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                            </div>
                            <div className="modal-body">
                                <h5>
                                    講師管理
                                </h5>
                                <div className="my-2">
                                    <AlertBox Item={TeacherAlert} />
                                    <div className="list-group">{Data['teachers'].map(teacher=>
                                        <div className="list-group-item" key={teacher['TeacherId']}>{teacher['UserId']==UserId?'自分':
                                            <div className="row">
                                                <a href={"/profile/teacher"+teacher['TeacherId']} className="col" target="_blank">
                                                    {teacher['UserName']}
                                                </a>{teacher['UserId']==Data['UserId']?
                                                <div className="col-auto">
                                                    <button type="button" className="btn btn-danger fas fa-trash-alt" data-id={teacher['TeacherId']} data-name={teacher['UserName']} onClick={deleteTeacher}></button>
                                                </div>:''}
                                            </div>}
                                        </div>
                                        )}
                                    </div>
                                </div>
                                <h5>
                                    講師追加
                                </h5>
                                <div>
                                    <p>
                                        以下のURLを追加したいユーザーに送信し，登録してください。
                                    </p>
                                    <input type="text" className="form-control" onFocus={(e)=>e.target.select()} readOnly defaultValue={Data['links']['AddTeacher']} />
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal fade w-100" id="courseModal" tabIndex={-1} aria-labelledby="courseModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-xl">
                            <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="courseModalLabel">コース受講登録URL</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                            </div>
                            <div className="modal-body">
                                <input type="text" className="form-control" onFocus={(e)=>e.target.select()} readOnly defaultValue={Data['links']['AddCourse']} />
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
            </>:""}
        </div>
    )
}