import { useState } from "react";
import BreadCrumb from "../component/breadcrumb";
import Fetch from "../common/fetch";
import AlertBox from "../component/AlertBox";
import ProjectForm from "./ProjectForm";


export default function ProjectEntry(){
    const [BCrumb,setBCrumb]=useState({'CurrentText':'新規プロジェクト','items':[{'index':1,'href':'/serma','text':'ホーム'}]});
    const [Alert,setAlert]=useState({'type':'danger','style':false,'msgs':[]});
    const [Content,setContent]=useState({});
    function doEntry(body){
        let send={
            'url':'v1/serma/project/',
            'method':'POST',
            'body':body
        }
        Fetch(send).then(data=>{
            if(data['result']){
                setAlert({'type':'success','style':true,'msgs':["プロジェクトを作成しました。"]});
                document.getElementById('ProjectForm').remove();
                window.scroll({top:0});
            }else{
                setAlert({'type':'danger','style':true,'description':data['error']['description']});
            }
        }).catch(error=>console.log(error));
    }
    return(
        <div>
            <BreadCrumb item={BCrumb} />
            <AlertBox Item={Alert} />
            <ProjectForm Submit={doEntry} funcs={[setAlert]} />
        </div>
    )
}