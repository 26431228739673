import { useEffect, useState } from "react";
import ReactDOM from 'react-dom';
import { useParams } from "react-router";
import BreadCrumb from "../component/breadcrumb";
import Fetch from "../common/fetch";
import AlertBox from "../component/AlertBox";
import AccountForm from "../component/AccountForm";
import { updateVariation } from "../../App";
import { Store } from "../common/store";

export default function EditAccount(){
    const {id}=useParams();
    const [BCrumb,setBCrumb]=useState({'CurrentText':'決済設定','items':[{'index':1,'href':'/teacher','text':'ホーム'}]});
    const [Alert,setAlert]=useState({'type':'danger','style':false,'msgs':[]});
    useEffect(()=>{
        let send={
            'url':'v1/teacher/account/'+id,
            'method':'GET'
        }
        Fetch(send).then(data=>{
            if(data['result']){
                setAlert({'type':'danger','style':false,'msgs':[]});
                ReactDOM.render(<AccountForm Item={data['payloads']} Submit={doEntry} Delete={doDelete} />,document.getElementById('content_area'));
                setBCrumb({
                    CurrentText: data['payloads']['AccountName']+'編集',
                    items: [
                        {
                            index: 1,
                            href: "/teacher",
                            text: "ホーム"
                        }
                    ],
                });
            }else{
                setAlert({'type':'danger','style':true,'description':data['error']['description']});
            }
        }).catch(error=>console.log(error));
    },[])
    function doEntry(send){
        send['url']='v1/teacher/account/'+id;
        send['method']='PUT';
        Fetch(send).then(data=>{
            if(data['result']){
                setAlert({'type':'success','style':true,'msgs':["決済方法を編集しました。"]});
                window.scroll({top:0});
                updateVariation('TeacherAccount')
            }else{
                setAlert({'type':'danger','style':true,'description':data['error']['description']});
            }
        }).catch(error=>console.log(error));
    }
    function doDelete(){
        let send={}
        send['url']='v1/teacher/account/'+id;
        send['method']='DELETE';
        Fetch(send).then(data=>{
            if(data['result']){
                setAlert({'type':'success','style':true,'msgs':["決済方法を削除しました。"]});
                updateVariation('TeacherAccount')
                document.getElementById('content_area').textContent="";
            }else{
                setAlert({'type':'danger','style':true,'description':data['error']['description']});
            }
        }).catch(error=>console.log(error));
    }
    return(
        <div>
            <BreadCrumb item={BCrumb} />
            <AlertBox Item={Alert} />
            <div id="content_area"></div>
        </div>
    )
}