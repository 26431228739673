import { useState } from "react";
import { useParams, Link } from "react-router-dom";
import { Store } from "../common/store";
import BreadCrumb from "../component/breadcrumb";
import Syllabus from "../component/Syllabus";

export default function EntryCourseSyllabus(){
    const {id}=useParams();
    let Auth=Store.getState()['Auth'];
    const BCrumb={
        'CurrentText':'コースシラバス',
        'items':Auth?[
            {
                'index':1,
                'href':'/student',
                'text':'ホーム'
            },
            {
                'index':2,
                'href':'/student/course/search',
                'text':'コース検索'
            }
        ]:[]
    };
    const [Data,setData]=useState(false);
    return(
        <div>
            <BreadCrumb item={BCrumb} />
            <div className="row mb-3">
                <div className="col-12">
                    <Link to={'/course/'+id+'/add'} className="btn btn-primary">受講登録</Link>
                </div>
            </div>
            {Data['condition']?
                Data['condition']['teacher']?
                <>
                    <div className="alert alert-info">
                        講師登録しています
                    </div>{Data['condition']['student'].length?
                    <div className="row my-2">{Data['condition']['student'].map(student=>
                        <div className="col-auto" key={student}>
                            <Link to={"/student/course/"+student} className="btn btn-secondary">受講コースページ</Link>
                        </div>
                    )}
                    </div>:""}
                </>:""
            :''}
            <Syllabus Id={id} get={setData} />
            <div className="row mt-2">
                <div className="col-12">
                    <Link to={'/course/'+id+'/add'} className="btn btn-primary">受講登録</Link>
                </div>
            </div>
        </div>
    );
}